<app-utility-apps [utilityApps]="utilityApps"></app-utility-apps>

<div class="page-container trade-review-container container">
  <app-breadcrumb [crumbConfig]="crumbConfig"></app-breadcrumb>

  @if (tradeId === '') {
    <app-rb-grid
      [gridConfig]="gridConfig"
      [getSetup]="getGridSetup"
      [getData]="getGridData"
    ></app-rb-grid>
  } @else {
    <app-trade-review-detail [tradeId]="tradeId"></app-trade-review-detail>
  }
</div>
