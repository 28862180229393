import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../../services/session-storage.service';
import { DateTime } from 'luxon';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import * as Highcharts from 'highcharts';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-system-usage',
  templateUrl: './systemUsage.component.html',
  styleUrls: ['./systemUsage.component.scss'],
})
export class SystemUsageComponent implements OnInit {
  @ViewChild('usageGrid', { static: false }) usageGrid;
  @Input() mode;

  private environment;
  private baseUrl: string;
  Highcharts: typeof Highcharts = Highcharts;
  loading = false;
  unitsManaged = this.ss.get('globals')?.user.managerUnits;
  unit =
    this.unitsManaged && this.unitsManaged.length > 0
      ? this.unitsManaged[0].id
      : null;
  app = this.ss.get('currentApp');
  startDate = new FormControl(
    DateTime.now().toUTC().minus({ days: 30 }).startOf('day').toISO()
  );
  endDate = new FormControl(DateTime.now().toUTC().endOf('day').toISO());
  data;
  viewMode = 'chart';
  units: any[];
  filteredUnits;
  unitCtrl = new FormControl();
  selectedUnit;
  chart = { data: null, legend: null };
  months = 5;
  monthOptions = [3, 6, 12, 18, 24, 30, 36];
  limit30 = true;

  constructor(
    private dbs: DashboardService,
    private ss: SessionStorageService,
    private router: Router,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;

    const rights = this.ss.get('rights');
    this.limit30 = rights?.includes('limit30Days');

    this.getUnits();
    const globals = this.ss.get('globals');
    this.selectedUnit = globals?.user.managerUnits[0].id;
    this.unitCtrl.setValue(globals?.user.managerUnits[0]);

    this.getChart();
    this.getData();
  }

  getChart() {
    this.loading = true;
    this.dbs.getUsageChart(this.selectedUnit, +this.months).subscribe(x => {
      this.chart.data = x.charts[0].Data;
      this.chart.legend = x.charts[0].Legend;
      this.loading = false;
    });
  }

  getData(user?) {
    this.loading = true;

    const back30 = new Date();
    back30.setDate(back30.getDate() - 30);

    const start = this.limit30
      ? back30.toISOString()
      : DateTime.fromJSDate(new Date(this.startDate.value))
          .startOf('day')
          .toISO();
    const end = this.limit30
      ? new Date().toISOString()
      : DateTime.fromJSDate(new Date(this.endDate.value)).endOf('day').toISO();

    this.dbs.getUsageData(this.selectedUnit, start, end).subscribe(x => {
      this.data = x;
      this.loading = false;
    });
  }

  getUnits() {
    this.filteredUnits = this.unitCtrl.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(unit => {
        if (unit) {
          return this._filterUnits(unit);
        }
        return [];
      })
    );

    this.selectedUnit = this.filteredUnits[0];
    // });
  }

  exportExcel() {
    this.usageGrid.saveAsExcel();
  }

  exportCSV() {
    this.loading = true;
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const start = this.startDate.value.toISOString();
    const end = this.endDate.value.toISOString();

    this.dbs.getUsageData(this.selectedUnit, start, end, true).subscribe(x => {
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(x);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, 'system-usage.csv', {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
        this.loading = false;
      };
      this.loading = false;
    });
  }

  selectUnit(ev) {
    this.selectedUnit = ev.option.value.id;
    this.getChart();
    this.getData();
  }

  displayFn(val) {
    return val ? val.id : '';
  }

  private _filterUnits(value: string) {
    const list = this.dbs.unitSearch(value, true).pipe(
      map(x => {
        return x.names;
      })
    );
    return list;
  }
}
