<div class="page-container unified-questionnaire-container container unified">
  <div class="row">
    <app-left-nav
      [currentApp]="currentApp"
      [stepInput]="currentStep"
      (tabChanged)="handleTabChange($event, true)"
      (appChanged)="handleAppChange(true, $event)"
      (appPage)="handleAppPage($event)"
      (formChange)="handleFormChange($event)"
      [iwValidateTrack]="iwValidate"
      [iwProg]="progSelectedIW?.selectionType"
      [liValidateTrack]="liValidate"
      [modules]="modules"
    ></app-left-nav>

    <div class="content">
      @if (!loading) {
        <h1 class="unified-header mb-2">
          {{ currentAppDisplay() }}
          @if (currentApp !== 'rb' && currentStepDisplay()) {
            - {{ currentStepDisplay() }}
          }
        </h1>
      }
      @if (checkStatus()) {
        <app-formly
          #formlyComponent
          [hidden]="hideForm() || !profileData"
          [context]="tabContext"
          [data]="profileData"
          [externalSubmit]="formSubmitted"
          (newProfile)="handleNewProfile($event)"
          (formChange)="handleModelChange($event)"
          class="mx-0"
        ></app-formly>
      }
      <!--  -->
      <!-- PP Pages -->
      <!--  -->
      @if (currentStep === 'results' && currentApp === 'pp') {
        <app-pp-results
          [hidden]="unfFlowSvc.getloading()"
          [profile]="this.profile"
          (prodsSelected)="handlePPProds($event)"
        ></app-pp-results>
      }
      <!--  -->
      <!-- AW Pages -->
      <!--  -->
      @if (currentApp === 'aw') {
        @if (currentStep === 'compare-results') {
          <app-aw-comparison></app-aw-comparison>
        }
        @if (currentStep === 'results') {
          <app-annuities-result
            [hidden]="unfFlowSvc.getloading() && !downloadingFactsheet"
            (downloadFactsheet)="downloadFactsheet($event)"
            [hasSummaryModule]="modStatusSvc.showCaseSummary()"
          ></app-annuities-result>
        }
      }
      <!--  -->
      <!-- IW Pages -->
      <!--  -->
      @if (currentApp === 'iw') {
        @if (currentStep === 'program-selection') {
          <program-selection
            [hidden]="unfFlowSvc.getloading()"
            (navigate)="handleTabChange($event)"
            (progSelected)="handleIWProg($event)"
          ></program-selection>
        }
        @if (currentStep === 'investment-model') {
          <app-model-selection
            [hidden]="unfFlowSvc.getloading()"
            (navigate)="handleTabChange($event)"
          ></app-model-selection>
        }
        @if (currentStep === 'fund-allocations' || currentStep === 'advisory') {
          <app-fund-allocations
            [hidden]="unfFlowSvc.getloading()"
            (navigate)="handleTabChange($event)"
          ></app-fund-allocations>
        }
        @if (currentStep === 'validate') {
          <app-iw-result
            [hidden]="unfFlowSvc.getloading()"
            [hasSummaryModule]="modStatusSvc.showCaseSummary()"
          ></app-iw-result>
        }
      }

      <!--  -->
      <!-- LI Pages -->
      <!--  -->
      @if (currentApp === 'li') {
        @if (currentStep === 'results' || currentStep === 'needs') {
          <results
            [hidden]="unfFlowSvc.getloading()"
            (navigate)="handleTabChange($event)"
          ></results>
        }
        @if (currentStep === 'compare') {
          <app-scenarios
            [hidden]="unfFlowSvc.getloading()"
            (navigate)="handleTabChange($event)"
          ></app-scenarios>
        }
        @if (currentStep === 'validate') {
          <app-validate
            [hidden]="unfFlowSvc.getloading()"
            (navigate)="handleTabChange($event)"
            [hasSummaryModule]="modStatusSvc.showCaseSummary()"
          ></app-validate>
        }
        @if (currentStep === 'products') {
          <app-product-result
            [hidden]="unfFlowSvc.getloading()"
          ></app-product-result>
        }
      }
      <!--  -->
      <!-- Summary Page -->
      <!--  -->
      @if (currentApp === 'summary') {
        <app-unified-summary-page
          (navigateTo)="handleSummaryNavigate($event)"
          (summaryClient)="setSummaryClient($event)"
        ></app-unified-summary-page>
      }

      <div class="buttons row" [hidden]="unfFlowSvc.getloading()">
        @if (currentStep === 0 || currentStep === '0') {
          <span class="back-button-placeholder pull-left"></span>
        } @else {
          <button
            mat-button
            (click)="handleTabChange('back')"
            class="btn btn-primary pull-left"
            type="button"
            [disabled]="navDisabled || unfFlowSvc.getSaving()"
          >
            <mat-icon>arrow_back</mat-icon>
            Back
          </button>
        }

        @if (navDisabled || unfFlowSvc.getSaving()) {
          <span class="saving-indicator">
            <span>Save in Progress</span>
            <mat-progress-bar
              mode="indeterminate"
              color="accent"
            ></mat-progress-bar>
          </span>
        }

        @if (currentApp !== 'summary') {
          @if (
            (currentStep !== 'results' &&
              currentStep !== 'validate' &&
              currentStep !== 'products') ||
            ((currentStep === 'results' || currentStep === 'validate') &&
              modStatusSvc.showCaseSummary()) ||
            (currentApp === 'li' &&
              currentStep === 'results' &&
              !modStatusSvc.showCaseSummary()) ||
            (currentApp === 'li' &&
              currentStep === 'products' &&
              modStatusSvc.showCaseSummary())
          ) {
            <button
              mat-button
              (click)="handleTabChange('next')"
              class="btn btn-primary pull-right next-button"
              type="button"
              [disabled]="navDisabled || unfFlowSvc.getSaving()"
            >
              Next <mat-icon>arrow_forward</mat-icon>
            </button>
          }
        }
      </div>
    </div>
  </div>
</div>
<ngx-loading
  [show]="unfFlowSvc.getloading()"
  [config]="{ primaryColour: '#ffffff' }"
></ngx-loading>
