import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-yesno',
  styleUrls: ['form-yesno.component.scss'],
  template: `<mat-checkbox
      [formControl]="formControl"
      [formlyAttributes]="field"
      (change)="change($event, to, field)"
      [required]="props.required"
      ><span [innerHTML]="props.label"></span
    ></mat-checkbox>
    @if (formControl.hasError('regRequired')) {
      <mat-error
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.
      </mat-error>
    } `,
})
export class FormlyYesNoComponent extends FieldType {
  change(ev, to, field) {
    field.model[field.key] = ev.checked;
  }
}
