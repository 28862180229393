import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { MatDialog } from '@angular/material/dialog';
import { UnitProfileHistoryDialogComponent } from '../../../admin/unit-profile-history-dialog/unit-profile-history-dialog.component';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-form-tabs',
  styleUrls: ['tabs.component.scss'],
  template: `
    <button
      mat-button
      type="button"
      (click)="viewHistory()"
      color="primary"
      class="unit-profile-button pull-right"
    >
      <mat-icon>view_list</mat-icon>View History
    </button>
    <button
      mat-button
      type="submit"
      color="primary"
      class="unit-profile-button pull-right"
    >
      <mat-icon>save</mat-icon>Save Unit Profile
    </button>
    @if (field.fieldGroup) {
      <mat-tab-group class="col-12">
        @for (
          step of field.fieldGroup;
          track step;
          let index = $index;
          let last = $last
        ) {
          @if (
            (step.props.right && rights.includes(step.props.right)) ||
            (!step.props.right &&
              (step.hide === undefined || step.hide === false))
          ) {
            <mat-tab label="{{ step.props.label }}">
              <ng-template matTabContent>
                <formly-field [field]="step"></formly-field>
              </ng-template>
            </mat-tab>
          }
        }
      </mat-tab-group>
    }
    <button
      mat-button
      type="submit"
      color="primary"
      class="unit-profile-button pull-right"
    >
      <mat-icon>save</mat-icon>Save Unit Profile
    </button>
  `,
})
export class FormlyTabsComponent extends FieldType<FormlyFieldConfig> {
  dialogRef;
  rights = this.ss.get('rights');

  constructor(
    private dialog: MatDialog,
    private ss: SessionStorageService
  ) {
    super();
  }

  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup.every(f => this.isValid(f));
  }

  viewHistory() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(UnitProfileHistoryDialogComponent, {
        panelClass: 'beacon-dialog',
        data: {
          unit: this.props.context.unit,
          solution: this.props.context.app,
        },
        maxHeight: '90vh',
        minHeight: '50vh',
        width: '90vw',
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }
}
