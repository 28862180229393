<div class="page-container asset-container container">
  <app-breadcrumb [crumb]="'product-results'"></app-breadcrumb>
  <h1 class="header col-12">
    Results for {{ name }}
    <app-print-dropdown
      class="pull-right"
      [params]="{ profile: id }"
      [isRollover]="isRolloverCase"
      (standardReport)="handleReportEvent($event)"
      *hasPermission="'DropdownReportMenu'"
    ></app-print-dropdown>
  </h1>

  <div class="w-100 toolbar">
    @if (!rights?.includes('DropdownReportMenu') && !hidePrint) {
      <button mat-raised-button class="report" (click)="report()">
        <mat-icon class="mat-icon material-icons">insert_drive_file </mat-icon
        >Download Report
      </button>
    }
  </div>
  @if (assessmentTypes.length < 1) {
    <div class="products-container">
      <ul class="products col-12">
        @for (x of products; track x) {
          <li class="product col-12 {{ x.ScoreClass }}">
            <div class="col-12 row">
              <div class="col-10 content">
                <ng-container *hasPermission="'RelevanceBridgeIcon'">
                  @switch (x.ScoreClass) {
                    @case ('positive') {
                      <mat-icon>check_circle</mat-icon>
                    }
                    @case ('neutral') {
                      <mat-icon>error</mat-icon>
                    }
                    @case ('negative') {
                      <mat-icon>remove_circle</mat-icon>
                    }
                  }
                </ng-container>
                <h4>
                  {{ x.ShortName }}
                  <span *hasPermission="'!RelevanceHideBridgeScore'">
                    | {{ x.score / 10 }}</span
                  >
                </h4>
                <div class="sub">{{ x.subhead }}</div>
              </div>
              <div class="col-2 button">
                <button mat-button (click)="expand($event)">
                  <span class="collapsed-content">
                    <mat-icon>add_circle</mat-icon> Details
                  </span>
                  <span class="expanded-content">
                    <mat-icon>remove_circle</mat-icon> Close
                  </span>
                </button>
              </div>
            </div>
            @if (x.PlanGrid) {
              <div class="plan-grid-table col-11">
                <table class="plan-grid">
                  <thead>
                    <tr>
                      @for (h of x.PlanGrid.Header; track h) {
                        <th>{{ h }}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    @for (t of x.PlanGrid.Data; track t) {
                      <tr>
                        @for (z of t; track z) {
                          <td>{{ z }}</td>
                        }
                      </tr>
                    }
                  </tbody></table
                >
              </div>
            }
            <div class="details col-12">
              <!-- Need to Fix this left align conditional if we get more tabs than the grid -->
              <mat-tab-group
                mat-stretch-tabs
                [ngClass]="{ 'left-align': !x.grids || x.grids.length <= 0 }"
              >
                <mat-tab label="Reasons">
                  @if (x.ComplianceChart) {
                    <app-sub-item-chart
                      [data]="x.ComplianceChart"
                      [chartTitle]="'Compliance Information'"
                    >
                    </app-sub-item-chart>
                  }
                  <app-reason-text
                    class="col-6"
                    [data]="x.reasons"
                  ></app-reason-text>
                </mat-tab>
                @if (x.grids && x.grids.length > 0) {
                  <mat-tab label="Product Summary">
                    @for (gr of x.grids; track gr) {
                      <div class="grid-table w-100">
                        <div class="table-header w-100">{{ gr.title }}</div>
                        @for (tb of gr.rows; track tb) {
                          <table
                            [ngClass]="{
                              w33: gr.rows.length === 3,
                              w50: gr.rows.length === 2,
                              w100: gr.rows.length === 1
                            }"
                          >
                            @for (rw of tb.table; track rw) {
                              <tr>
                                <td [innerHTML]="rw.label"></td>
                                <td [innerHTML]="rw.value"></td>
                              </tr>
                            }
                          </table>
                        }
                      </div>
                    }
                  </mat-tab>
                }
                @if (x.bridgeCharts && x.bridgeCharts.Bridges) {
                  <mat-tab label="{{ x.bridgeCharts.Label }}">
                    @for (
                      chartGroup of x.bridgeCharts.Bridges;
                      track chartGroup
                    ) {
                      @if (chartGroup) {
                        <div class="chart-container clearfix mb-5">
                          @for (
                            chrt of chartGroup.charts;
                            track chrt;
                            let idx = $index
                          ) {
                            <h4 class="chart-header">{{
                              chartGroup.header
                            }}</h4>
                            <div class="chart pull-left w-50 px-4">
                              <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="chrt.Data"
                                class="w-90 mx-auto"
                              ></highcharts-chart>
                              @if (chrt.Legend) {
                                <div
                                  class="chart-legend"
                                  [ngClass]="{
                                    'col-12': chrt.LegendPosition === 'bottom',
                                    'col-6 ml-auto':
                                      chrt.LegendPosition === 'right'
                                  }"
                                >
                                  @if (chrt.Legend.header) {
                                    <table>
                                      <thead>
                                        <tr>
                                          @for (
                                            th of chrt.Legend.header;
                                            track th
                                          ) {
                                            @if (th.toLowerCase() !== 'color') {
                                              <th>
                                                {{ th }}
                                              </th>
                                            }
                                          }
                                        </tr>
                                      </thead>
                                      <tbody>
                                        @for (
                                          tr of chrt.Legend.data;
                                          track tr
                                        ) {
                                          <tr>
                                            @for (
                                              td of tr | keyvalue: returnZero;
                                              track td;
                                              let i = $index
                                            ) {
                                              @if (
                                                chrt.Legend.header[
                                                  i
                                                ].toLowerCase() !== 'color'
                                              ) {
                                                <td>
                                                  @if (
                                                    chrt.Legend.header[
                                                      i
                                                    ].toLowerCase() !==
                                                      'color' &&
                                                    chrt.Legend.header[0].toLowerCase() ===
                                                      'color' &&
                                                    i === 1
                                                  ) {
                                                    <span
                                                      [style.background]="
                                                        tr['Color']
                                                      "
                                                      class="indicator"
                                                    ></span>
                                                  }
                                                  {{ td.value }}
                                                </td>
                                              }
                                            }
                                          </tr>
                                        }
                                      </tbody>
                                    </table>
                                  } @else {
                                    @for (c of chrt.Legend; track c) {
                                      <div>
                                        <strong>{{ c.label }}</strong>
                                        {{ c.value }}
                                      </div>
                                    }
                                  }
                                </div>
                              }
                            </div>
                            @if (
                              chartGroup.charts.length === 1 ||
                              (chartGroup.charts.length > 1 && idx === 1)
                            ) {
                              <div
                                class="explanation-text"
                                [ngClass]="{
                                  'pull-left w-50':
                                    chartGroup.charts.length === 1,
                                  'w-100': chartGroup.charts.length > 1
                                }"
                              >
                                @if (chartGroup.ShortDescription) {
                                  <p class="mx-auto mt-3">
                                    {{ chartGroup.ShortDescription }}
                                  </p>
                                }
                                @if (chartGroup.reasons) {
                                  <ul class="criteria-list mt-3">
                                    @for (r of chartGroup.reasons; track r) {
                                      <li class="mt-2">
                                        <span [innerHTML]="r.headline"></span>
                                        <span
                                          *hasPermission="
                                            '!RelevanceHideReasonScores'
                                          "
                                        >
                                          | {{ r.score / 10 }}</span
                                        >
                                      </li>
                                    }
                                  </ul>
                                }
                                @if (chartGroup[x.dynamicContent]) {
                                  <p class="mx-auto mt-3">
                                    {{ x[x.dynamicContent] }}
                                  </p>
                                }
                              </div>
                            }
                          }
                        </div>
                      }
                    }
                  </mat-tab>
                }
              </mat-tab-group>
            </div>
            <div
              class="rating-line {{ x.ScoreClass }}"
              [style.width.%]="x.score / 10"
            ></div>
          </li>
        }
      </ul>
    </div>
  }
  @if (assessmentTypes.length > 0) {
    <div class="products-container">
      @for (grp of assessmentTypes; track grp) {
        @switch (grp) {
          @case ('validate') {
            <h2>Products Validated</h2>
          }
          @case ('rollover') {
            <h2>Rollovers</h2>
          }
          @case ('select') {
            <h2>Products Considered</h2>
          }
        }
        <ul class="products col-12 mb-5">
          @for (x of products; track x) {
            @if (x.assessmenttype === grp) {
              <li
                class="product col-12 {{ x.ScoreClass }} {{ x.expandedClass }}"
              >
                <div class="col-12 row">
                  <div class="col-10 content">
                    <ng-container *hasPermission="'RelevanceBridgeIcon'">
                      @switch (x.ScoreClass) {
                        @case ('positive') {
                          <mat-icon>check_circle</mat-icon>
                        }
                        @case ('neutral') {
                          <mat-icon>error</mat-icon>
                        }
                        @case ('negative') {
                          <mat-icon>remove_circle</mat-icon>
                        }
                      }
                    </ng-container>
                    <h4>
                      {{ x.ShortName }}
                      <span *hasPermission="'!RelevanceHideBridgeScore'">
                        | {{ x.score / 10 }}</span
                      >
                    </h4>
                    <div class="sub">{{ x.subhead }}</div>
                  </div>
                  <div class="col-2 button">
                    <button mat-button (click)="expand($event)">
                      <span class="collapsed-content">
                        <mat-icon>add_circle</mat-icon> Details
                      </span>
                      <span class="expanded-content">
                        <mat-icon>remove_circle</mat-icon> Close
                      </span>
                    </button>
                  </div>
                </div>
                @if (x.PlanGrid) {
                  <div class="plan-grid-table col-11">
                    <table class="plan-grid">
                      <thead>
                        <tr>
                          @for (h of x.PlanGrid.Header; track h) {
                            <th>{{ h }}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        @for (t of x.PlanGrid.Data; track t) {
                          <tr>
                            @for (z of t; track z) {
                              <td>{{ z }}</td>
                            }
                          </tr>
                        }
                      </tbody></table
                    >
                  </div>
                }

                @if (x.SubItemChart) {
                  <app-sub-item-chart
                    [data]="x.SubItemChart"
                    [chartTitle]="'Brokerage Subtypes'"
                    [chartStyle]="'minimal'"
                  ></app-sub-item-chart>
                }
                @if (x.BridgeDetailChart) {
                  <app-sub-item-chart
                    [data]="x.BridgeDetailChart"
                    [chartTitle]="x.BridgeDetailChart.Label"
                    [chartStyle]="'minimal'"
                  ></app-sub-item-chart>
                }

                <div class="details col-12">
                  <!-- Need to Fix this left align conditional if we get more tabs than the grid -->
                  <mat-tab-group
                    mat-stretch-tabs
                    [ngClass]="{
                      'left-align':
                        (!x.grids || x.grids.length <= 0) &&
                        (!x.bridgeCharts ||
                          (x.bridgeCharts && !x.bridgeCharts.Bridges))
                    }"
                  >
                    <mat-tab label="Reasons">
                      @if (x.ComplianceChart) {
                        <app-sub-item-chart
                          [data]="x.ComplianceChart"
                          [chartTitle]="'Compliance Information'"
                        >
                        </app-sub-item-chart>
                      }
                      <app-reason-text
                        class="col-6"
                        [data]="x.reasons"
                      ></app-reason-text>
                    </mat-tab>
                    @if (x.grids && x.grids.length > 0) {
                      <mat-tab label="Product Summary">
                        @for (gr of x.grids; track gr) {
                          <div class="grid-table w-100">
                            <div class="table-header w-100">{{ gr.title }}</div>
                            @for (tb of gr.rows; track tb) {
                              <table
                                [ngClass]="{
                                  w33: gr.rows.length === 3,
                                  w50: gr.rows.length === 2,
                                  w100: gr.rows.length === 1
                                }"
                              >
                                @for (rw of tb.table; track rw) {
                                  <tr>
                                    <td [innerHTML]="rw.label"></td>
                                    <td [innerHTML]="rw.value"></td>
                                  </tr>
                                }
                              </table>
                            }
                          </div>
                        }
                      </mat-tab>
                    }
                    @if (x.bridgeCharts && x.bridgeCharts.Bridges) {
                      <mat-tab label="{{ x.bridgeCharts.Label }}">
                        @for (
                          chartGroup of x.bridgeCharts.Bridges;
                          track chartGroup
                        ) {
                          @if (chartGroup) {
                            <div class="chart-container clearfix mb-5">
                              <h4 class="chart-header">{{
                                chartGroup.header
                              }}</h4>
                              @for (
                                chrt of chartGroup.charts;
                                track chrt;
                                let idx = $index
                              ) {
                                <div class="chart pull-left w-50 px-4">
                                  <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="chrt.Data"
                                    class="w-90 mx-auto"
                                  ></highcharts-chart>
                                  @if (chrt.Legend) {
                                    <div
                                      class="chart-legend"
                                      [ngClass]="{
                                        'col-12':
                                          chrt.LegendPosition === 'bottom',
                                        'col-6 ml-auto':
                                          chrt.LegendPosition === 'right'
                                      }"
                                    >
                                      @if (chrt.Legend.header) {
                                        <table>
                                          <thead>
                                            <tr>
                                              @for (
                                                th of chrt.Legend.header;
                                                track th
                                              ) {
                                                @if (
                                                  th.toLowerCase() !== 'color'
                                                ) {
                                                  <th>
                                                    {{ th }}
                                                  </th>
                                                }
                                              }
                                            </tr>
                                          </thead>
                                          <tbody>
                                            @for (
                                              tr of chrt.Legend.data;
                                              track tr
                                            ) {
                                              <tr>
                                                @for (
                                                  td of tr
                                                    | keyvalue: returnZero;
                                                  track td;
                                                  let i = $index
                                                ) {
                                                  @if (
                                                    chrt.Legend.header[
                                                      i
                                                    ].toLowerCase() !== 'color'
                                                  ) {
                                                    <td>
                                                      @if (
                                                        chrt.Legend.header[
                                                          i
                                                        ].toLowerCase() !==
                                                          'color' &&
                                                        chrt.Legend.header[0].toLowerCase() ===
                                                          'color' &&
                                                        i === 1
                                                      ) {
                                                        <span
                                                          [style.background]="
                                                            tr['Color']
                                                          "
                                                          class="indicator"
                                                        ></span>
                                                      }
                                                      {{ td.value }}
                                                    </td>
                                                  }
                                                }
                                              </tr>
                                            }
                                          </tbody>
                                        </table>
                                      } @else {
                                        @for (c of chrt.Legend; track c) {
                                          <div>
                                            <strong>{{ c.label }}</strong>
                                            {{ c.value }}</div
                                          >
                                        }
                                      }
                                    </div>
                                  }
                                </div>
                                @if (
                                  chartGroup.charts.length === 1 ||
                                  (chartGroup.charts.length > 1 && idx === 1)
                                ) {
                                  <div
                                    class="explanation-text"
                                    [ngClass]="{
                                      'pull-left w-50':
                                        chartGroup.charts.length === 1,
                                      'w-100': chartGroup.charts.length > 1
                                    }"
                                  >
                                    @if (chartGroup.ShortDescription) {
                                      <p class="mx-auto mt-3">
                                        {{ chartGroup.ShortDescription }}
                                      </p>
                                    }
                                    @if (chartGroup.reasons) {
                                      <ul class="criteria-list mt-3">
                                        @for (
                                          r of chartGroup.reasons;
                                          track r
                                        ) {
                                          <li class="mt-2">
                                            <span
                                              [innerHTML]="r.headline"
                                            ></span>
                                            <span
                                              *hasPermission="
                                                '!RelevanceHideReasonScores'
                                              "
                                            >
                                              | {{ r.score / 10 }}
                                            </span>
                                          </li>
                                        }
                                      </ul>
                                    }
                                    @if (chartGroup.dynamicContent) {
                                      <p class="mx-auto mt-3">
                                        {{ chartGroup.dynamicContent }}
                                      </p>
                                    }
                                  </div>
                                }
                              }
                            </div>
                          }
                        }
                      </mat-tab>
                    }
                  </mat-tab-group>
                </div>
                <div
                  class="rating-line {{ x.ScoreClass }}"
                  [style.width.%]="x.score / 10"
                ></div>
              </li>
            }
          }
        </ul>
      }
    </div>
  }
  <ngx-loading [show]="loading"></ngx-loading>
</div>
