import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-checkbox',
  styleUrls: ['form-checkbox.component.scss'],
  template: `
    <mat-checkbox
      [formControl]="formControl"
      [formlyAttributes]="field"
      (change)="change()"
      [required]="props.required"
      ><span [innerHTML]="props.label"></span
    ></mat-checkbox>
    @if (
      formControl.hasError('required') && !formControl.hasError('regRequired')
    ) {
      <mat-error>This field is required</mat-error>
    }
    @if (formControl.hasError('regRequired')) {
      <mat-error
        >This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.</mat-error
      >
    }
  `,
})
export class FormlyCheckboxComponent extends FieldType {
  change() {}
}
