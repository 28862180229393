<div class="page-container program-container container unified">
  @if (selProgram) {
    <div class="program-container col-12">
      @for (grp of assmntTypes; track grp) {
        @if (grp === 'validate') {
          <h3>Program to Validate</h3>
        }
        @if (grp === 'select') {
          <h3>Programs to Consider</h3>
        }
        <ul class="funds col-12">
          @for (x of programs; track x) {
            @if (x.assessmentType === grp) {
              <li
                class="fund col-12"
                [ngClass]="{
                  expanded: x.assessmentType === 'validate',
                  positive: x.score > scoring.positive[0],
                  neutral:
                    x.score >= scoring.neutral[0] &&
                    x.score <= scoring.neutral[1],
                  negative: x.score < scoring.negative[1],
                  selected: x.selected
                }"
              >
                <div class="col-12 row">
                  @if (
                    (validationCase && grp === 'validate') || !validationCase
                  ) {
                    <div class="col-2">
                      <button
                        mat-button
                        class="select"
                        (click)="selectFund(x.name, x)"
                      >
                        @if (!x.selected) {
                          <span><mat-icon>check_circle</mat-icon> Select</span>
                        }
                        @if (x.selected) {
                          <span><mat-icon>update</mat-icon> Review</span>
                        }
                      </button>
                    </div>
                  }
                  <div
                    class="center"
                    [ngClass]="{
                      'col-7 col-lg-8':
                        (validationCase && grp === 'validate') ||
                        !validationCase,
                      'col-9 col-lg-10': validationCase && grp !== 'validate'
                    }"
                  >
                    <h4 class="content">
                      <ng-container *hasPermission="'RelevanceBridgeIcon'">
                        @if (x.score > scoring.positive[0]) {
                          <mat-icon>check_circle</mat-icon>
                        }
                        @if (
                          x.score >= scoring.neutral[0] &&
                          x.score <= scoring.neutral[1]
                        ) {
                          <mat-icon>error</mat-icon>
                        }
                        @if (x.score < scoring.negative[1]) {
                          <mat-icon>remove_circle</mat-icon>
                        }
                      </ng-container>
                      {{ x.name }}
                      <span *hasPermission="'!RelevanceHideBridgeScore'">
                        | {{ x.score }}</span
                      >
                    </h4>
                  </div>
                  <div class="col-2">
                    <button mat-button (click)="expand($event)">
                      <span class="collapsed-content">
                        <mat-icon>add_circle</mat-icon> Details
                      </span>
                      <span class="expanded-content">
                        <mat-icon>remove_circle</mat-icon> Close
                      </span>
                    </button>
                  </div>
                </div>
                <div class="details col-10 mx-auto">
                  @if (x.description) {
                    <p class="sub">{{ x.description }}</p>
                  }
                  @if (x.bulletList) {
                    <ul>
                      @for (bul of x.bulletList; track bul) {
                        <li>{{ bul }}</li>
                      }
                    </ul>
                  }
                  <h5>Why this program scored this way:</h5>
                  <app-reason-text
                    class="col-12"
                    [data]="x.reasons"
                  ></app-reason-text>
                </div>
                <div
                  class="rating-line"
                  [ngClass]="{
                    positive: x.score > scoring.positive[0],
                    neutral:
                      x.score >= scoring.neutral[0] &&
                      x.score <= scoring.neutral[1],
                    negative: x.score < scoring.negative[1]
                  }"
                  [style.width.%]="x.score"
                ></div>
              </li>
            }
          }
        </ul>
      }
    </div>
  }
</div>
@if (internalLoading) {
  <ngx-loading [show]="loading || internalLoading"></ngx-loading>
}
