<div class="row">
  <div mat-dialog-header class="col-12">
    <h1>Calculate Income</h1>
  </div>
  <div>
    <mat-dialog-content #scroll class="dialog-container col-12">
      <form #calculateForm="ngForm" class="row">
        @if (modalData.AnnuityWizard) {
          <mat-form-field class="col-6">
            <mat-label>State of Issue</mat-label>
            <mat-select
              [(ngModel)]="modalData.AnnuityWizard.StateOfIssue"
              name="state"
              required
            >
              @for (state of states; track state) {
                <mat-option [value]="state.value">
                  {{ state.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        }

        @if (modalData.ClientEstimates) {
          <mat-form-field class="col-6">
            <mat-label>Age</mat-label>
            <input
              matInput
              [(ngModel)]="modalData.ClientEstimates.Age"
              name="age"
              type="number"
              step="1"
              required
            />
          </mat-form-field>
        }

        @if (modalData.AnnuityWizard) {
          <mat-form-field class="col-6">
            <mat-label>Age of Annuitant at First Withdrawal</mat-label>
            <input
              matInput
              [(ngModel)]="modalData.AnnuityWizard.AgeNeeded"
              name="ageNeeded"
              type="number"
              step="1"
              required
            />
          </mat-form-field>
        }

        <div
          class="container col-6 radio-btns"
          [ngClass]="{
            invalid: invalidAfterSubmit && !modalData.ClientPerson.Gender
          }"
        >
          <div class="w-100">Gender*</div>
          @if (modalData.ClientPerson) {
            <mat-radio-group
              [(ngModel)]="modalData.ClientPerson.Gender"
              name="gender"
              required
            >
              <mat-radio-button value="M">Male</mat-radio-button>
              <mat-radio-button value="F" class="ml-2">Female</mat-radio-button>
            </mat-radio-group>
          }
        </div>

        <div
          class="container col-11 radio-btns"
          [ngClass]="{
            invalid: invalidAfterSubmit && !modalData.ClientPerson.Gender
          }"
        >
          <div class="w-100">Solve For Premium or Income Needed*</div>
          @if (modalData.ClientPerson) {
            <mat-radio-group
              [(ngModel)]="
                modalData.AnnuityWizard && modalData.AnnuityWizard.SolveType
              "
              name="solveForPremium"
              required
            >
              <mat-radio-button value="F">Premium</mat-radio-button>
              <mat-radio-button value="B" class="ml-2">Income</mat-radio-button>
            </mat-radio-group>
          }
        </div>

        @if (
          modalData.AnnuityWizard && modalData.AnnuityWizard.SolveType === 'F'
        ) {
          <mat-form-field class="col-6">
            <mat-label>Premium Amount</mat-label>
            @if (modalData.AnnuityWizard) {
              <input
                matInput
                [maskito]="dollarMask"
                maxlength="20"
                [(ngModel)]="modalData.AnnuityWizard.InitialPremium"
                name="premium"
                required
              />
            }
          </mat-form-field>
        }

        @if (
          modalData.AnnuityWizard && modalData.AnnuityWizard.SolveType === 'B'
        ) {
          <mat-form-field class="col-6">
            <mat-label>Annual Income Amount</mat-label>
            @if (modalData.AnnuityWizard) {
              <input
                matInput
                [maskito]="dollarMask"
                maxlength="20"
                [(ngModel)]="modalData.AnnuityWizard.IncomeNeeded"
                name="premium"
                required
              />
            }
          </mat-form-field>
        }

        <div
          class="container col-6 radio-btns"
          [ngClass]="{
            invalid:
              invalidAfterSubmit && !modalData.AnnuityWizard.JointProtection
          }"
        >
          <div class="w-100">Single or Joint*</div>
          @if (modalData.AnnuityWizard) {
            <mat-radio-group
              [(ngModel)]="modalData.AnnuityWizard.JointProtection"
              name="jointSingle"
              required
            >
              <mat-radio-button value="1">Single</mat-radio-button>
              <mat-radio-button value="2" class="ml-3">Joint</mat-radio-button>
            </mat-radio-group>
          }
        </div>

        @if (
          modalData.AnnuityWizard?.JointProtection === 2 ||
          modalData.AnnuityWizard?.JointProtection === '2'
        ) {
          <mat-form-field class="mt-2 col-6">
            <mat-label>Joint Annuitant Age</mat-label>
            <input
              matInput
              [(ngModel)]="modalData.SpouseEstimates.Age"
              name="spouseAge"
              type="number"
              step="1"
              required
            />
          </mat-form-field>
        }

        @if (
          modalData.AnnuityWizard?.JointProtection === 2 ||
          modalData.AnnuityWizard?.JointProtection === '2'
        ) {
          <div
            class="container mt-2 col-6 radio-btns"
            [ngClass]="{
              invalid: invalidAfterSubmit && !modalData.SpouseEstimates.Gender
            }"
          >
            <div class="w-100">Joint Annuitant Gender*</div>
            <mat-radio-group
              [(ngModel)]="modalData.SpouseEstimates.Gender"
              name="spouseGender"
              required
            >
              <mat-radio-button value="M">Male</mat-radio-button>
              <mat-radio-button value="F" class="ml-3">Female</mat-radio-button>
            </mat-radio-group>
          </div>
        }
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="col-11 buttons pull-right">
      <button mat-raised-button (click)="cancel()" class="close">
        Cancel
      </button>
      <button
        mat-raised-button
        color="accent"
        class="calculate"
        (click)="save()"
      >
        Calculate
      </button>
    </mat-dialog-actions>
  </div>
</div>
