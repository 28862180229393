import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import { PostTradeService } from '../../post-trade.service';
import { utilityApps } from '../../../lib/post-trade-utility-apps';

@Component({
  selector: 'app-trade-review',
  templateUrl: './trade-review.component.html',
  styleUrls: ['./trade-review.component.scss'],
})
export class TradeReviewComponent implements OnInit, OnDestroy {
  constructor(
    public ptSvc: PostTradeService,
    private activatedRoute: ActivatedRoute,
    private ss: SessionStorageService,
    private route: Router
  ) {}

  rights = [];
  unsubscribe: Subject<any> = new Subject();
  tradeId = '';
  gridConfig = {
    title: 'Transaction Monitor',
    gridName: 'TradeReviewGrid',
    showGridFilters: true,
    showFilterManager: true,
    manualSearch: true,
    searchButtonText: 'Update Search',
    showBulkActions: true,
    downloadGridButton: true,
    gridActionColumn: true,
    rowSelector: 'TradeID',
    gridType: 'trade',
    detailViewRoute: '/trade-review/',
    getSetup: this.getGridSetup,
    getData: this.getGridData,
  };
  crumbConfig = [
    {
      label: 'Post Transaction',
      link: 'post-trade/TR',
    },
    {
      label: 'Transaction Monitor',
      link: 'trade-review',
    },
  ];
  utilityApps = utilityApps;

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (params.id) {
          this.tradeId = params.id;
          this.crumbConfig.push({
            label: this.tradeId,
            link: `trade-review/${this.tradeId}`,
          });
        } else {
          this.tradeId = '';
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  getGridSetup() {
    return this.ptSvc.getPostGridSetup();
  }

  getGridData(reqData) {
    return this.ptSvc.getPostTradeGridData(reqData);
  }
}
