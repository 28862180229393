import { AfterContentInit, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-drop',
  styleUrls: ['form-drop.component.scss'],
  template: `
    <mat-form-field class="w-100">
      <mat-label>{{ to.label }}</mat-label>
      <mat-select
        [formControl]="formControl"
        [placeholder]="props.placeholder || props.label"
        [formlyAttributes]="field"
        class="w-100"
        [required]="props.required"
        (selectionChange)="props.change ? to.change(field, formControl) : ''"
      >
        @for (opt of props?.options; track opt.value) {
          <mat-option [value]="opt.value">{{ opt.label }}</mat-option>
        }
      </mat-select>
      @if (props.help) {
        <mat-icon
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
        >
          help
        </mat-icon>
      }
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error>
          This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.
        </mat-error>
      }
    </mat-form-field>
  `,
})
export class FormlyDropComponent extends FieldType implements AfterContentInit {
  ngAfterContentInit(): void {
    if (this.key === 'AnnuityWizard_TermToValidate') {
      this.props.options = this.field.parent.parent.model.multiTermOptions;
    }
  }
}
