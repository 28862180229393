<ul>
  @if (showFilter) {
    <li>
      <input class="k-textbox" (input)="onInput($event)" />
    </li>
  }
  @for (item of currentData; track item; let i = $index) {
    <li
      #itemElement
      (click)="onSelectionChange(valueAccessor(item), itemElement)"
      [ngClass]="{ 'k-state-selected': isItemSelected(item) }"
    >
      <input
        type="checkbox"
        id="chk-{{ valueAccessor(item) }}"
        (focus)="onFocus(itemElement)"
        class="k-checkbox"
        [checked]="isItemSelected(item)"
      />
      <label
        class="k-multiselect-checkbox k-checkbox-label"
        for="chk-{{ valueAccessor(item) }}"
      >
        {{ textAccessor(item) }}
      </label>
    </li>
  }
</ul>
