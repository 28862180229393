<div class="page-container account-grouping-detail-container container pt-2">
  <h1 class="header col-12 mb-1"> Group Details - {{ groupId }} </h1>
  @if (!loading) {
    <div class="actions-container mb-3">
      <button mat-button (click)="nav('account-grouping')" color="primary">
        <mat-icon>arrow_back_ios</mat-icon> Back to Account Grouping
      </button>
    </div>
  }

  <div class="info-card mb-4">
    <div class="card-header">
      <span class="card-title mb-0"> Linked Accounts </span>

      <span>
        <button mat-raised-button (click)="linkAccount()">
          <mat-icon>add_link</mat-icon> Link Account
        </button>
      </span>
    </div>
    <table class="details col-12">
      <thead>
        <tr>
          <th>Actions</th>
          @for (header of accountGroupDetailsKey; track header.label) {
            <th>{{ header.label }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @if (accountGroup && accountGroup.length > 0) {
          @for (
            account of accountGroup;
            track account.AccountID;
            let idx = $index
          ) {
            <tr>
              <td width="75">
                <div class="action-button-container">
                  @if (account.AccountID === account.PrimaryAccountID) {
                    <button
                      mat-icon-button
                      class="fs-button"
                      (click)="togglePrimaryAccount(account)"
                      color="accent"
                      matTooltip="Primary Account"
                    >
                      <mat-icon>turned_in</mat-icon>
                    </button>
                  } @else {
                    <button
                      mat-icon-button
                      class="fs-button"
                      (click)="togglePrimaryAccount(account)"
                      matTooltip="Mark as Primary Account"
                    >
                      <mat-icon>turned_in_not</mat-icon>
                    </button>
                  }
                  <button
                    mat-icon-button
                    class="fs-button"
                    (click)="removeAccount(account)"
                    color="warn"
                    matTooltip="Remove Account"
                  >
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                </div>
              </td>
              @for (
                accountKey of accountGroupDetailsKey;
                track accountKey.label
              ) {
                <td>
                  @if (
                    accountKey.valueMask && accountKey.valueMask === 'currency'
                  ) {
                    <span>{{ account[accountKey.varName] | currency }}</span>
                  } @else if (
                    accountKey.valueMask && accountKey.valueMask === 'date'
                  ) {
                    <span>{{
                      account[accountKey.varName] | localTime: 'shortDateTime'
                    }}</span>
                  } @else {
                    {{ account[accountKey.varName] }}
                  }
                </td>
              }
            </tr>
          }
        } @else {
          <tr>
            <td colspan="8" class="no-records"> No Records found </td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  <ngx-loading [show]="loading"></ngx-loading>
</div>
