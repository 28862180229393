<mat-toolbar class="row footer">
  <div class="col-4">&copy; Copyright CapitalRock {{ year }}</div>
  <div class="app-info col-8 pull-right">
    @if (globals) {
      <div class="app-info">
        @if (uiVer) {
          <span class="ml-40">UI: {{ uiVer }}</span>
        }
        @if (engine) {
          <span class="ml-40">Engine: {{ engine }}</span>
        }
        @if (kb) {
          <span class="ml-40">KB Info: {{ kb }}</span>
        }
        @if (ui) {
          <span class="ml-40">UI Config: {{ ui }}</span>
        }
      </div>
    }
  </div>
  <div class="col-12 cr-disclaimer" [innerHtml]="disclaimer"></div>
  <div class="col-12 kendo"
    >The RightBRIDGE&reg; system includes Progress® Telerik UI® components
    that are the intellectual property and copyrights of Telerik. Check the
    following link for the proper name.
    <a href="https://www.telerik.com/name-mapping"
      >https://www.telerik.com/name-mapping</a
    ></div
  >
</mat-toolbar>
