<div class="client-info-container">
  <h1 class="header col-12">
    @if (isProspect) {
      <mat-icon class="status-indicator" matTooltip="Is Prospect">
        person_outline
      </mat-icon>
    }
    {{ name }}
    @if (clientData) {
      <button mat-button class="header-button" (click)="dlReport()">
        <mat-icon>insert_drive_file</mat-icon>
        <span>Download Report</span>
      </button>
      @if (!addToList) {
        <button mat-button class="header-button" (click)="removeFromCallList()">
          <mat-icon>delete</mat-icon>
          <span>Remove From Call List</span>
        </button>
      }
      @if (addToList) {
        <button mat-button class="header-button" (click)="addToCallList()">
          <mat-icon>call</mat-icon>
          <span>Add to Call List</span>
        </button>
      }
      <button
        mat-button
        class="header-button"
        (click)="editProfile()"
        *hasPermission="'ShowEditLink'"
      >
        <mat-icon>edit</mat-icon>
        <span>Edit Profile</span>
      </button>
      <button mat-button class="header-button" (click)="addLifeEvent()">
        <mat-icon>person_add</mat-icon>
        <span>Add Life Event</span>
      </button>
    }
  </h1>
  @if (info) {
    <mat-tab-group
      mat-stretch-tabs
      [(selectedIndex)]="tabIndex"
      [ngClass]="{ open: tabsOpen }"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="w-100 inner" (click)="tabClick($event, 0)">
            <mat-icon>assignment_ind</mat-icon>
            Client Summary
          </div>
        </ng-template>
        <ul class="w-100 info-summary row">
          @if (
            info['ClientPerson^Age'] &&
            info['ClientPerson^Age'].display &&
            info['ClientPerson^Age'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>cake</mat-icon> Age: </strong>
              {{ info['ClientPerson^Age'].display }}
            </li>
          }
          @if (
            info['ClientPerson^MaritalStatus'] &&
            info['ClientPerson^MaritalStatus'].display &&
            info['ClientPerson^MaritalStatus'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>wc</mat-icon> Marital Status: </strong>
              {{ info['ClientPerson^MaritalStatus'].display }}
            </li>
          }
          @if (
            info['SpousePerson^FirstName'] &&
            info['SpousePerson^FirstName'].display &&
            info['SpousePerson^FirstName'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>face</mat-icon> Spouse Name: </strong>
              {{ info['SpousePerson^FirstName'].display }}
              {{ info['SpousePerson^LastName'].display }}
            </li>
          }
          @if (
            info['ClientPerson^NumberOfChidren'] &&
            info['ClientPerson^NumberOfChidren'].display &&
            info['ClientPerson^NumberOfChidren'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>child_friendly</mat-icon> # Children: </strong>
              {{ info['ClientPerson^NumberOfChidren'].display }}
            </li>
          }
          @if (
            info['ClientPerson^Occupation'] &&
            info['ClientPerson^Occupation'].display &&
            info['ClientPerson^Occupation'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>work</mat-icon> Occupation: </strong>
              {{ info['ClientPerson^Occupation'].display }}
            </li>
          }
          @if (
            info['Client^ClientSince'] &&
            info['Client^ClientSince'].display &&
            info['Client^ClientSince'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>people</mat-icon> Client Since: </strong>
              {{ info['Client^ClientSince'].display }}
            </li>
          }
          @if (
            info['ClientDN^ClientRating'] &&
            info['ClientDN^ClientRating'].display &&
            info['ClientDN^ClientRating'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>rate_review</mat-icon> Rating: </strong>
              {{ info['ClientDN^ClientRating'].display }}
            </li>
          }
          @if (
            info['ClientDN^CallBackScore'] &&
            info['ClientDN^CallBackScore'].display &&
            info['ClientDN^CallBackScore'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong>
                <mat-icon>phone_callback</mat-icon> Call Back Score:
              </strong>
              {{ info['ClientDN^CallBackScore'].display }}
            </li>
          }
          @if (
            info['Client^ProfileCompleteness'] &&
            info['Client^ProfileCompleteness'].display &&
            info['Client^ProfileCompleteness'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong>
                <mat-icon>data_usage</mat-icon> Data Completeness:
              </strong>
              {{ info['Client^ProfileCompleteness'].display }}
            </li>
          }
          @if (
            info['FinancialCalc^FirmHeldAssets'] &&
            info['FinancialCalc^FirmHeldAssets'].display &&
            info['FinancialCalc^FirmHeldAssets'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong>
                <mat-icon>account_balance</mat-icon> Firm Held Assets:
              </strong>
              {{ info['FinancialCalc^FirmHeldAssets.display'] }}
            </li>
          }
          @if (
            info['FinancialCalc^TotalAssets'] &&
            info['FinancialCalc^TotalAssets.display'] &&
            info['FinancialCalc^TotalAssets.display'].length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>local_atm</mat-icon> Known Assets: </strong>
              {{ info['FinancialCalc^TotalAssets'].display }}
            </li>
          }
          @if (
            info['LifeInsuranceCalc^TotalFaceAmountClient'] &&
            info['LifeInsuranceCalc^TotalFaceAmountClient'].display &&
            info['LifeInsuranceCalc^TotalFaceAmountClient'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>security</mat-icon> Life Insurance: </strong>
              {{ info['LifeInsuranceCalc^TotalFaceAmountClient'].display }}
            </li>
          }
          @if (
            info['ClientFinancial^Income'] &&
            info['ClientFinancial^Income'].display &&
            info['ClientFinancial^Income'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>money</mat-icon> Income: </strong>
              {{ info['ClientFinancial^Income'].display }}
            </li>
          }
          @if (
            info['Client^RiskTolerance'] &&
            info['Client^RiskTolerance'].display &&
            info['Client^RiskTolerance'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>assessment</mat-icon> Risk Tolerance: </strong>
              {{ info['Client^RiskTolerance'].display }}
            </li>
          }
          @if (
            info['ClientFinancial^TaxBracket'] &&
            info['ClientFinancial^TaxBracket'].display &&
            info['ClientFinancial^TaxBracket'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>pie_chart</mat-icon> Tax Bracket: </strong>
              {{ info['ClientFinancial^TaxBracket'].display }}
            </li>
          }
          @if (
            info['Client_Rep^RepCode'] &&
            info['Client_Rep^RepCode'].display &&
            info['Client_Rep^RepCode'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong>
                <mat-icon>perm_contact_calendar</mat-icon> Rep Code:
              </strong>
              {{ info['Client_Rep^RepCode.display'] }}
            </li>
          }
          @if (
            info['MarketCategories^Category'] &&
            info['MarketCategories^Category'].display &&
            info['MarketCategories^Category'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong>
                <mat-icon>table_chart</mat-icon> Market Categories:
              </strong>
              {{ info['MarketCategories^Category'].display }}
            </li>
          }
          @if (
            info['Client^ID'] &&
            info['Client^ID'].display &&
            info['Client^ID'].display.length
          ) {
            <li class="col-12 col-md-4">
              <strong> <mat-icon>contacts</mat-icon> Reference #: </strong>
              {{ info['Client^ID'].display }}
            </li>
          }
        </ul>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="w-100 inner" (click)="tabClick($event, 1)">
            <mat-icon class="example-tab-icon">account_balance</mat-icon>
            Accounts
          </div>
        </ng-template>
        @if (this.accounts.investment['data'].length) {
          <strong>Investment Accounts</strong>
          <table class="account-table">
            <thead>
              @for (
                label of this.accounts.investment['labels'] | keyvalue;
                track label
              ) {
                <th>{{ label.value }}</th>
              }
            </thead>
            <tbody>
              @for (acct of this.accounts.investment['data']; track acct) {
                <tr>
                  @for (data of acct | keyvalue; track data) {
                    <td>{{ data.value }}</td>
                  }
                </tr>
              }
            </tbody>
          </table>
        }
        @if (this.accounts.annuity['data'].length) {
          <strong>Annuity Holdings</strong>
          <table class="account-table">
            <thead>
              @for (
                label of this.accounts.annuity['labels'] | keyvalue;
                track label
              ) {
                <th>{{ label.value }}</th>
              }
            </thead>
            <tbody>
              @for (acct of this.accounts.annuity['data']; track acct) {
                <tr>
                  @for (data of acct | keyvalue; track data) {
                    <td>{{ data.value }}</td>
                  }
                </tr>
              }
            </tbody>
          </table>
        }
        @if (this.accounts.lifeInsurance['data'].length) {
          <strong>Insurance Policies</strong>
          <table class="account-table">
            <thead>
              @for (
                label of this.accounts.lifeInsurance['labels'] | keyvalue;
                track label
              ) {
                <th>{{ label.value }}</th>
              }
            </thead>
            <tbody>
              @for (acct of this.accounts.lifeInsurance['data']; track acct) {
                <tr>
                  @for (data of acct | keyvalue; track data) {
                    <td>{{ data.value }}</td>
                  }
                </tr>
              }
            </tbody>
          </table>
        }
        @if (this.accounts.longTerm['data'].length) {
          <strong>Long Term Care Insurance</strong>
          <table class="account-table">
            <thead>
              @for (
                label of this.accounts.longTerm['labels'] | keyvalue;
                track label
              ) {
                <th>{{ label.value }}</th>
              }
            </thead>
            <tbody>
              @for (acct of this.accounts.longTerm['data']; track acct) {
                <tr>
                  @for (data of acct | keyvalue; track data) {
                    <td>{{ data.value }}</td>
                  }
                </tr>
              }
            </tbody>
          </table>
        }
        @if (noAccounts) {
          <h4>No Accounts Have Been Entered For This Client</h4>
        }
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="w-100 inner" (click)="tabClick($event, 2)">
            <mat-icon class="example-tab-icon">description</mat-icon>
            Notes
          </div>
        </ng-template>
        <button
          mat-button
          (click)="addNote()"
          class="add-note"
          color="primary"
          *hasPermission="'SaveNotes'"
          ><mat-icon>note_add</mat-icon>Add New Note</button
        >
        @if (notes && notes.noteCount > 0) {
          <table>
            <thead>
              <th class="date-column">Date</th>
              <th class="note-column">Note</th>
            </thead>
            <tbody>
              @for (nt of notes.notes; track nt) {
                <tr>
                  <td>
                    <div class="client-table-data">
                      <button
                        mat-icon-button
                        (click)="togglePin(nt)"
                        matTooltip="Note Pinned?"
                      >
                        <mat-icon
                          class="pin-icon"
                          [ngClass]="{ pinned: nt.isPinned }"
                          >star</mat-icon
                        >
                      </button>
                      <span>{{ nt.createDate | date: 'shortDate' }}</span>
                    </div>
                  </td>
                  <td
                    ><div class="client-table-data">{{ nt.noteText }}</div></td
                  >
                </tr>
              }
            </tbody>
          </table>
        } @else {
          <h4>No Notes Have Been Entered For This Client</h4>
        }
      </mat-tab>
    </mat-tab-group>
  }
</div>
<ngx-loading [show]="loading"></ngx-loading>
