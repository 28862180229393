@if (crumbConfig) {
  <div class="breadcrumb" id="breadcrumb">
    @if (toolsLanding) {
      <button mat-button (click)="nav('tools')">
        <mat-icon>home</mat-icon>
        <span class="text-container">Home</span>
      </button>
    } @else {
      <button mat-button (click)="nav('')">
        <mat-icon>home</mat-icon>
        <span class="text-container">Home</span>
      </button>
    }

    @for (
      crumb of crumbConfig;
      track crumb;
      let idx = $index, first = $first, last = $last
    ) {
      /
      <button mat-button (click)="nav(crumb.link)" [disabled]="last">
        <span class="text-container">{{ crumb.label }}</span>
      </button>
    }
  </div>
} @else {
  <ul class="row">
    @for (
      crumb of crumbs;
      track crumb;
      let idx = $index, first = $first, last = $last
    ) {
      @if ((hideCrumbs && idx > 1) || !hideCrumbs) {
        <li>
          @if (!last || first) {
            <a [routerLink]="crumb.link">{{ crumb.label }}</a>
          }
          @if (last && !first) {
            <strong>{{ crumb.label }}</strong>
          }
          @if (!last) {
            <mat-icon>arrow_forward</mat-icon>
          }
        </li>
      }
    }
  </ul>
}
