<div class="password-suggs">
  <small class="feedback-warning">{{ feedback.warning }}</small>
  <ul class="feedback">
    <h4>Password Suggestions:</h4>
    @for (suggestion of feedback.suggestions; track suggestion) {
      <li class="feedback-suggestion">
        {{ suggestion }}
      </li>
    }
  </ul>
</div>
