<div class="page-container book-management-container container">
  <app-breadcrumb [crumbConfig]="crumbConfig"></app-breadcrumb>

  @if (!policyId) {
    <app-book-management-grid></app-book-management-grid>
  }

  @if (policyId) {
    <app-policy-details [policyId]="policyId"></app-policy-details>
  }
</div>
