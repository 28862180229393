<div class="page-container aw-comparison-container">
  <button mat-flat-button class="reset-columns-button" (click)="resetColumns()">
    <mat-icon>restore</mat-icon> Restore Columns
  </button>
  <div class="comparison-link-container mb-2">
    See Annuity Product Comparisons on
    <a
      href="https://ejprod.sharepoint.com/sites/JL-US-BR-Annuities/SitePages/web044635.aspx"
      target="_blank"
    >
      JonesLink
    </a>
    for a full comparison of annuity products and features
  </div>
  @for (label of annuityLabels; track label) {
    <div class="annuity-comparison-container mb-4">
      @if (grids[label.annuityType]) {
        <div class="annuity-comparison-header">
          <h3>
            {{ label.label }}
          </h3>
          <button
            mat-button
            class="grid-column-button ml-3 pull-right"
            [matMenuTriggerFor]="menu"
          >
            <mat-icon>view_column</mat-icon>Column Options
          </button>
          <mat-menu #menu="matMenu" class="column-menu">
            <button
              (click)="toggleVisible($event, label.annuityType, 'all')"
              mat-menu-item
              [ngClass]="{ selected: allSelected(label.annuityType) }"
            >
              <mat-icon class="empty">check_box_outline_blank</mat-icon>
              <mat-icon class="checked">check_box</mat-icon>
              Select All
            </button>
            @for (col of grids[label.annuityType].headers; track col) {
              @if (!hiddenMenuItems.includes(col.DataField)) {
                <button
                  (click)="
                    toggleVisible($event, label.annuityType, col.DataField)
                  "
                  mat-menu-item
                  [value]="col.DataField"
                  [ngClass]="{ selected: !col.Hidden }"
                >
                  <mat-icon class="empty">check_box_outline_blank</mat-icon>
                  <mat-icon class="checked">check_box</mat-icon>
                  {{ stripTags(col.Label) }}
                </button>
              }
            }
          </mat-menu>
        </div>
        <div class="comparison-grid-container">
          <kendo-grid
            #comparisonGrid="kendoGrid"
            class="col-12"
            [kendoGridBinding]="grids[label.annuityType].data"
            [resizable]="true"
            [sortable]="{ allowUnsort: true, mode: 'multiple' }"
          >
            @for (column of grids[label.annuityType].headers; track column) {
              @if (column.Type !== 'parent') {
                <kendo-grid-column-group
                  [hidden]="column.Hidden"
                  [width]="column.DataField === 'RiderFee' ? 50 : 75"
                  [headerClass]="'non-parent'"
                >
                  <kendo-grid-column
                    [field]="column.DataField"
                    [title]="column.Label"
                    [sortable]="true"
                  >
                    <ng-template kendoGridHeaderTemplate>
                      <span [innerHTML]="column.Label"></span>
                      @if (
                        column.Label === 'Payment Option' ||
                        column.Label === 'Payment Frequency'
                      ) {
                        <sup> * </sup>
                      }
                      @if (column.Label === 'Payment Frequency') {
                        <sup> * </sup>
                      }
                    </ng-template>
                    @if (column.Type === 'perc'; as dataItem) {
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>
                          {{ dataItem[column.DataField] | percent: '1.2-2' }}
                        </span>
                      </ng-template>
                    }
                    @if (column.Type === 'curr'; as dataItem) {
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>
                          {{
                            dataItem[column.DataField]
                              | currency: 'USD' : 'symbol' : '1.0-0'
                          }}
                        </span>
                      </ng-template>
                    }
                    @if (column.Type === 'link'; as dataItem) {
                      <ng-template kendoGridCellTemplate let-dataItem>
                        @if (dataItem[column.DataField]) {
                          <span>
                            <a
                              [href]="dataItem[column.DataField]"
                              target="_blank"
                            >
                              POS Aid
                            </a>
                          </span>
                        }
                      </ng-template>
                    }
                    @if (column.DataField === 'InitialPremium'; as dataItem) {
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>
                          {{ initialPremium }}
                        </span>
                      </ng-template>
                    }
                    @if (column.Label === 'Annuity'; as dataItem) {
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>
                          {{ dataItem.Carrier }} -
                          {{ dataItem[column.DataField] }}
                        </span>
                      </ng-template>
                    }
                    @if (
                      column.Type !== 'perc' &&
                        column.Type !== 'curr' &&
                        column.Type !== 'link' &&
                        column.DataField !== 'InitialPremium' &&
                        column.Label !== 'Annuity';
                      as dataItem
                    ) {
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>
                          {{ dataItem[column.DataField] }}
                        </span>
                      </ng-template>
                    }
                  </kendo-grid-column>
                </kendo-grid-column-group>
              }
              @if (column.Type === 'parent' && column.Children.length) {
                <kendo-grid-column-group
                  [title]="column.Label"
                  [hidden]="column.Hidden"
                  [headerClass]="'parent'"
                >
                  @for (
                    childCol of column.Children;
                    track childCol;
                    let i = $index
                  ) {
                    <kendo-grid-column
                      [field]="childCol.DataField"
                      [title]="childCol.Label"
                      [headerClass]="
                        childCol.Label === 'Age ' + deferralAge
                          ? 'child deferral'
                          : 'child'
                      "
                      [width]="75"
                      [sortable]="true"
                    >
                      @if (childCol.Type === 'curr'; as dataItem) {
                        <ng-template kendoGridCellTemplate let-dataItem>
                          @if (dataItem[childCol.DataField]) {
                            <span
                              [class]="
                                childCol.Label === 'Age ' + deferralAge
                                  ? 'deferral'
                                  : ''
                              "
                            >
                              {{
                                dataItem[childCol.DataField]
                                  | currency: 'USD' : 'symbol' : '1.0-0'
                              }}
                            </span>
                          }
                          @if (!dataItem[childCol.DataField]) {
                            <span
                              [class]="
                                childCol.Label === 'Age ' + deferralAge
                                  ? 'deferral blank'
                                  : 'blank'
                              "
                            >
                              --
                            </span>
                          }
                        </ng-template>
                      }
                      @if (childCol.Type === 'perc'; as dataItem) {
                        <ng-template kendoGridCellTemplate let-dataItem>
                          @if (dataItem[childCol.DataField]) {
                            <span
                              [class]="
                                childCol.Label === 'Age ' + deferralAge
                                  ? 'deferral'
                                  : ''
                              "
                            >
                              {{
                                dataItem[childCol.DataField] | percent: '1.2'
                              }}
                            </span>
                          }
                          @if (!dataItem[childCol.DataField]) {
                            <span
                              [class]="
                                childCol.Label === 'Age ' + deferralAge
                                  ? 'deferral blank'
                                  : 'blank'
                              "
                            >
                              --
                            </span>
                          }
                        </ng-template>
                      }
                      @if (
                        childCol.Type !== 'perc' && childCol.Type !== 'curr';
                        as dataItem
                      ) {
                        <ng-template kendoGridCellTemplate let-dataItem>
                          @if (dataItem[childCol.DataField]) {
                            <span>
                              {{ dataItem[childCol.DataField] }}
                            </span>
                          }
                          @if (!dataItem[childCol.DataField]) {
                            <span> -- </span>
                          }
                        </ng-template>
                      }
                    </kendo-grid-column>
                  }
                </kendo-grid-column-group>
              }
              @if (initialPremium) {
                <kendo-grid-column
                  [field]="initialPremium"
                  [title]="'Initial Premium'"
                  [hidden]="true"
                  [sortable]="true"
                ></kendo-grid-column>
              }
            }
          </kendo-grid>
        </div>
        @if (label.annuityType === 'SPIA' || label.annuityType === 'DIA') {
          <div>
            <div>
              <p class="disclaimer mt-2">
                <sup>*</sup>Immediate income annuities generally begin payments
                within 13 months of purchase. Deferred income annuities
                generally begin payments 24 months to 10 years after purchase.
              </p>
              <p class="disclaimer">
                <sup>**</sup>Immediate and Deferred Income annuity contracts are
                based off the annuitant(s) birthdate(s) which may impact the
                withdrawal amount.
              </p>
              <p class="disclaimer">
                <sup>***</sup>Additional payment options and frequencies are
                available for election
              </p>
            </div>
          </div>
        }
      }
    </div>
  }
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<div class="bottom-disclosure">
  <p class="mb-0 mt-4">
    <strong>Disclosure:</strong> Guaranteed withdrawal amounts are not the only
    consideration when recommending an annuity to clients. You should consider
    the guaranteed withdrawal amount in addition to the other features,
    provisions and guarantees of the annuity contract.
  </p>
  <p class="mb-0 mt-3 warn">
    <strong>
      INTERNAL USE ONLY - DO NOT SHOW or DISTRIBUTE TO THE PUBLIC.
    </strong>
  </p>
  <p class="mb-0 mt-0 warn">
    <strong>
      This material DOES NOT meet the requirements for use in printed or media
      form.
    </strong>
  </p>
</div>
