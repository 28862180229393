@if (props.label) {
  <label [innerHTML]="props.label"></label>
}
@if (scrollMode === 'virtual') {
  <button
    mat-button
    color="primary"
    class="grid-button header-button pull-right"
    (click)="exportToExcel(dataGrid)"
  >
    <mat-icon>cloud_download</mat-icon>Download Excel File
  </button>
}
<kendo-grid
  class="col-12"
  #dataGrid="kendoGrid"
  [kendoGridBinding]="gridData"
  [pageable]="true"
  [sortable]="{ allowUnsort: true, mode: 'multiple' }"
  [filterable]="'menu'"
  [columnMenu]="true"
  [height]="gridHeight"
  [selectable]="{
    enabled: isSelectable(),
    mode: 'multiple',
    checkboxOnly: true
  }"
  [rowSelected]="rowSelected"
  (selectionChange)="onSelect($event)"
  (detailExpand)="onExpandHandler($event)"
  [scrollable]="scrollMode"
>
  @if (selectColumn) {
    <kendo-grid-checkbox-column
      showSelectAll="true"
    ></kendo-grid-checkbox-column>
  }
  @for (column of columns; track column) {
    <ng-template>
      <kendo-grid-column
        field="{{ column.DataField }}"
        title="{{ column.Label }}"
        [hidden]="isHidden(column.DataField)"
      >
        @if (column.ControlType) {
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            let-columnIndex="columnIndex"
            let-rowIndex="rowIndex"
          >
            @switch (column.ControlType) {
              @case ('dropDown') {
                <mat-form-field>
                  <mat-select
                    placeholder="Set {{ column.Label }}"
                    (selectionChange)="
                      selectionEvent($event, rowIndex, column.DataField)
                    "
                    [value]="dataItem[column.DataField]"
                  >
                    @for (opt of column.options; track opt) {
                      <mat-option [value]="opt.value">{{
                        opt.label
                      }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              @case ('radioButton') {
                <mat-radio-group
                  aria-label="Select an option"
                  (change)="selectionEvent($event, rowIndex, column.DataField)"
                  [value]="dataItem[column.DataField]"
                >
                  @for (radio of column.options; track radio.value) {
                    <mat-radio-button value="{{ radio.value }}">
                      {{ radio.label }}
                    </mat-radio-button>
                  }
                </mat-radio-group>
              }
              @case ('text') {
                <mat-form-field>
                  <input
                    matInput
                    [value]="dataItem[column.DataField]"
                    (keyup)="
                      textChange($event, rowIndex, column.DataField, dataItem)
                    "
                  />
                </mat-form-field>
              }
              @case ('number') {
                <mat-form-field>
                  <input
                    matInput
                    type="number"
                    [value]="dataItem[column.DataField]"
                    (keyup)="
                      textChange($event, rowIndex, column.DataField, dataItem)
                    "
                  />
                </mat-form-field>
              }
              @case ('currency') {
                <mat-form-field>
                  <input
                    matInput
                    type="number"
                    [value]="dataItem[column.DataField]"
                    [maskito]="dollarMask"
                    maxlength="20"
                    (keyup)="
                      textChange($event, rowIndex, column.DataField, dataItem)
                    "
                  />
                </mat-form-field>
              }
              @case ('datePicker') {
                <mat-form-field>
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepicker]="picker" />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              }
            }
          </ng-template>
        }
      </kendo-grid-column>
    </ng-template>
  }
  <ng-template
    kendoGridDetailTemplate
    let-dataItem
    [kendoGridDetailTemplateShowIf]="showSubgrid"
  >
    <section>
      <table>
        <thead>
          @for (col of dataItem['subgrid'].headers; track col.Label) {
            @if (!col.Hidden) {
              <th>
                {{ col.Label }}
              </th>
            }
          }
        </thead>
        <tbody>
          @for (subItem of dataItem['subgrid'].data; track subItem) {
            <tr>
              @for (col of dataItem['subgrid'].headers; track col) {
                @if (!col.Hidden) {
                  <td>
                    @if (col.IsLink) {
                      <mat-checkbox
                        (change)="
                          subRowSelected(
                            $event,
                            subItem,
                            dataItem['subgridDef']
                          )
                        "
                        [(ngModel)]="subItem[col.DataField]"
                      >
                      </mat-checkbox>
                    } @else {
                      {{ subItem[col.DataField] }}
                    }
                  </td>
                }
              }
            </tr>
          }
        </tbody>
      </table>
    </section>
  </ng-template>
  <kendo-grid-excel [fetchData]="excelData"></kendo-grid-excel>
</kendo-grid>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
