<div class="reports-container page-container mt-5 w-100">
  <app-utility-toolbar
    [current]="'reporting'"
    [vertical]="true"
    class="w-15 vertical"
  ></app-utility-toolbar>
  <div class="reporting-content-container w-85 p-2">
    @if (!loading) {
      <mat-tab-group
        class="w-100"
        #tabGroup
        (selectedTabChange)="tabChanged($event)"
      >
        <mat-tab
          label="Report Request Portal"
          *hasPermission="'ReportRequestPortal'"
        >
          <ng-template matTabContent>
            <p class="col-12 mt-3 mb-0 title">{{ reportRequestTitle }}</p>
            <p class="col-12 subtitle">{{ reportRequestSubtitle }}</p>
            <h4 class="col-12">Request Report</h4>
            <div class="mt-2 ml-3">
              <app-report-request
                [baseUrl]="baseUrl"
                [dashboardSettings]="dashboardSettings"
              ></app-report-request>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Unit Settings Report">
          <ng-template matTabContent>
            <h4 class="col-12 mt-3">Unit Settings Report</h4>
            <div class="mt-2 ml-3">
              <app-unit-settings [baseUrl]="baseUrl"></app-unit-settings>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Active Users">
          <ng-template matTabContent>
            <h4 class="col-12 mt-3">Active Users</h4>
            <div class="mt-2 ml-3">
              <app-active-users [baseUrl]="baseUrl"></app-active-users>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Load History" *hasPermission="'LoadHistory'">
          <h4 class="col-12 mt-3">Load History</h4>
          <ng-template matTabContent>
            <app-load-history></app-load-history>
          </ng-template>
        </mat-tab>
        <mat-tab
          label="Unit Profile History"
          *hasPermission="'UnitProfileHistory'"
        >
          <ng-template matTabContent>
            <h4 class="col-12 mt-3">Unit Profile History</h4>
            <div class="mt-2 ml-3">
              <app-unit-profile-history
                [baseUrl]="baseUrl"
              ></app-unit-profile-history>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Unit Usage" *hasPermission="'systemUnitUsageReport'">
          <ng-template matTabContent>
            <h4 class="col-12 mt-3">Usage Report</h4>
            <div class="mt-2 ml-3">
              <app-unit-usage></app-unit-usage>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Unit Session Activity">
          <ng-template matTabContent>
            <h4 class="col-12 mt-3">Usage Session Activity</h4>
            <div class="mt-2 ml-3">
              <app-unit-session-activity></app-unit-session-activity>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab
          label="Bridge Action"
          *hasPermission="'BridgeActionDetailReport'"
        >
          <ng-template matTabContent>
            <h4 class="col-12 mt-3">Bridge Action Report</h4>
            <div class="mt-2 ml-3">
              <app-bridge-action></app-bridge-action>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab
          label="Bridge Density Report"
          *hasPermission="'BridgeDensityReport'"
        >
          <ng-template matTabContent>
            <h4 class="col-12 mt-3">Bridge Density Report</h4>
            <div class="mt-2 ml-3">
              <app-bridge-density></app-bridge-density>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab
          label="Sales Tracking Report"
          *hasPermission="'SalesTrackingReport'"
        >
          <ng-template matTabContent>
            <h4 class="mt-3 col-12">Sales Tracking Report</h4>
            <div class="mt-2 ml-3">
              <app-sales-tracking></app-sales-tracking>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Mutual Fund Report" *hasPermission="'MutualFundReport'">
          <ng-template matTabContent>
            <h4 class="mt-3 col-12">Mutual Fund Report</h4>
            <div class="mt-2 ml-3">
              <app-mutual-funds [baseUrl]="baseUrl"></app-mutual-funds>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab
          label="ReasonText™ Report"
          *hasPermission="'ReasonTextReport'"
        >
          <ng-template matTabContent>
            <div class="row col-12">
              <h4 class="col-12 mt-3">ReasonText™ Report</h4>
              <div class="mt-2 ml-3">
                <app-reason-text-report
                  [baseUrl]="baseUrl"
                ></app-reason-text-report>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab
          label="Rep Exceptions Report"
          *hasPermission="'RepExceptionsReport'"
        >
          <ng-template matTabContent>
            <div class="row col-12">
              <h4 class="col-12 mt-3">Rep Exceptions Report</h4>
              <div class="mt-2 ml-3">
                <app-rep-exceptions-report
                  [baseUrl]="baseUrl"
                  [alertIds]="alertIds"
                ></app-rep-exceptions-report>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    }
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
