<div
  class="page-container results-container container unified"
  [ngClass]="{ 'se-container': currentApp === 'se' }"
>
  @if (currentApp !== 'se') {
    <h1 class="header col-12">
      @if (currentApp === 'li' || currentApp === 'cp') {
        @if (currentApp !== 'cp') {
          <button
            mat-button
            class="header-button"
            [disabled]="canCompare()"
            (click)="compare()"
          >
            <mat-icon>compare_arrows</mat-icon>
            <span>Compare</span>
          </button>
        }
        @if (
          (!hidePrint && currentApp !== 'cp') ||
          !rights.includes('DropdownReportMenu')
        ) {
          <button
            mat-button
            class="header-button mr-2"
            (click)="report()"
            [disabled]="
              !selectedProduct ||
              (selectedProduct && selectedProduct.length < 1)
            "
          >
            <mat-icon>insert_drive_file</mat-icon>
            <span>{{ printText }}</span>
          </button>
        }
      }
    </h1>
  }

  @if (currentApp === 'se') {
    <app-breadcrumb [crumb]="crumbType"></app-breadcrumb>
  }

  @if (currentApp === 'se') {
    <client-info
      [client]="id"
      [name]="name"
      [dataSet]="seData"
      (profileNavigate)="handleProfileChange($event)"
    ></client-info>
  }
  <div class="w-100 toolbar clearfix">
    @if (currentApp === 'se') {
      <mat-button-toggle-group class="view-toggle" [(ngModel)]="viewMode">
        <mat-button-toggle value="grid">
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list">
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    }
    @if (currentApp !== 'se' && currentApp !== 'li') {
      @if (currentApp !== 'cp') {
        <button mat-raised-button class="compare" (click)="compare()">
          <mat-icon class="mat-icon material-icons">compare_arrows</mat-icon
          >Compare
        </button>
      }
      @if (!hidePrint && currentApp !== 'cp') {
        <button mat-raised-button class="report" (click)="report()">
          <mat-icon class="mat-icon material-icons">insert_drive_file</mat-icon
          >Download report
        </button>
      }
    }
    @if (needsList) {
      <mat-form-field class="w-25">
        <mat-select
          placeholder="Filter Products by Need"
          [(ngModel)]="selectedNeed"
        >
          <mat-option value="all"> See All Products </mat-option>
          @for (n of needsList; track n) {
            <mat-option [value]="n.BridgeID">
              {{ n.BridgeName }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  </div>
  @if (currentApp !== 'li') {
    <div class="products-container {{ viewMode }} {{ currentApp }}">
      @if (viewMode === 'list') {
        <ul class="products col-12 row">
          @for (x of products; track x) {
            @if (x.Hide !== 'Y') {
              @if (setFiltering(x.NeedBridgeIDs)) {
                <li
                  class="product col-12 {{ x.ScoreClass }}"
                  [ngClass]="{ 'se-list-product': currentApp === 'se' }"
                >
                  <div class="col-12 row justify-content-between">
                    <div
                      class="col-10 content"
                      [ngClass]="{ 'se-content': currentApp === 'se' }"
                    >
                      <h4 [ngClass]="{ 'no-pad': currentApp !== 'se' }">
                        <div class="relevance-indicator">
                          <ng-container>
                            @if (x.ScoreClass === 'positive') {
                              <mat-icon>check_circle</mat-icon>
                            }
                            @if (x.ScoreClass === 'neutral') {
                              <mat-icon>error</mat-icon>
                            }
                            @if (x.ScoreClass === 'negative') {
                              <mat-icon>remove_circle</mat-icon>
                            }
                          </ng-container>
                        </div>
                        {{ x.ShortName }}
                        <span *hasPermission="'!RelevanceHideBridgeScore'">
                          | {{ scoreFormat(x.score) }}</span
                        >
                      </h4>
                      @if (currentApp === 'se') {
                        <button
                          class="no-thanks actions se-action-btns"
                          mat-icon-button
                          matTooltip="No Thank You"
                          matTooltipPosition="above"
                          (click)="
                            bridgeAction('noThanks', x.id, x.name);
                            $event.stopPropagation()
                          "
                        >
                          <mat-icon>thumb_down</mat-icon>
                        </button>
                      }
                      @if (currentApp === 'se') {
                        <button
                          class="follow-up actions se-action-btns"
                          mat-icon-button
                          matTooltip="Follow Up"
                          matTooltipPosition="above"
                          (click)="
                            bridgeAction('followUp', x.id, x.name);
                            $event.stopPropagation()
                          "
                        >
                          <mat-icon>update</mat-icon>
                        </button>
                      }
                      @if (currentApp === 'se') {
                        <button
                          class="like actions se-action-btns"
                          mat-icon-button
                          matTooltip="I Like It"
                          matTooltipPosition="above"
                          (click)="
                            bridgeAction('like', x.id, x.name);
                            $event.stopPropagation()
                          "
                        >
                          <mat-icon>thumb_up</mat-icon>
                        </button>
                      }
                      <div class="sub">{{ x.subhead }}</div>
                    </div>
                    <div class="col-1 expand-button">
                      <button mat-icon-button (click)="expand($event)">
                        <span class="collapsed-content">
                          <mat-icon>expand_circle_down</mat-icon>
                        </span>
                        <span class="expanded-content">
                          <mat-icon>expand_circle_down</mat-icon>
                        </span>
                      </button>
                    </div>
                  </div>
                  @if (x.PlanGrid) {
                    <div class="plan-grid-table col-11">
                      <table class="plan-grid">
                        <thead>
                          <tr>
                            @for (h of x.PlanGrid.Header; track h) {
                              <th>{{ h }}</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          @for (t of x.PlanGrid.Data; track t) {
                            <tr>
                              @for (z of t; track z) {
                                <td>{{ z }}</td>
                              }
                            </tr>
                          }
                        </tbody></table
                      >
                    </div>
                  }
                  <div class="details w-100 row">
                    <h5 class="w-100">Why this product scored this way:</h5>
                    <app-reason-text
                      class="col-12"
                      [ngClass]="{ 'pl-0': currentApp === 'se' }"
                      [data]="x.reasons"
                    ></app-reason-text>
                  </div>
                  <div
                    class="rating-line {{ x.ScoreClass }}"
                    [style.width.%]="scoreFormat(x.score)"
                  ></div>
                </li>
              }
            }
          }
        </ul>
      }
      @if (viewMode === 'grid') {
        <ul class="products col-12 row">
          @for (x of products; track x; let idx = $index) {
            @if (x.Hide !== 'Y') {
              <li
                class="product {{ x.ScoreClass }} col-6 row"
                [ngClass]="{ 'se-product': currentApp === 'se' }"
              >
                <div class="se-card-content d-flex flex-column col-12">
                  <div class="se-card-item">
                    <h4
                      class="{{ x.ScoreClass }}"
                      [ngClass]="{ 'se-grid-h4 row': currentApp === 'se' }"
                    >
                      <div class="priority-indicator">
                        <mat-icon>{{ x.icon }}</mat-icon>
                      </div>
                      {{ x.ShortName }}
                      <span *hasPermission="'!RelevanceHideBridgeScore'">
                        | {{ scoreFormat(x.score) }}</span
                      >
                    </h4>
                    <div class="row">
                      <div class="col-7 row">
                        @if (currentApp !== 'se') {
                          <div class="col-12 content">
                            <div class="description hidden">{{
                              x.description
                            }}</div>
                            <ul class="details">
                              @for (r of x.reasons; track r; let i = $index) {
                                <ngTemplate>
                                  @if (i < 3) {
                                    <li class="col-12 reason">
                                      <strong>
                                        <span [innerHTML]="r.headline"></span>
                                        <span
                                          *hasPermission="
                                            '!RelevanceHideReasonScores'
                                          "
                                        >
                                          | {{ scoreFormat(r.score) }}</span
                                        >
                                      </strong>
                                    </li>
                                  }
                                </ngTemplate>
                              }
                            </ul>
                          </div>
                        }
                        @if (currentApp === 'se') {
                          <div
                            class="col-12 content"
                            [ngClass]="{ 'se-content': currentApp === 'se' }"
                          >
                            <div class="description notHidden">{{
                              x.description
                            }}</div>
                            <ul class="details">
                              @for (r of x.reasons; track r; let i = $index) {
                                <ngTemplate>
                                  @if (i < 3) {
                                    <li class="col-12 reason">
                                      <strong>
                                        <span [innerHTML]="r.headline"></span>
                                        <span
                                          *hasPermission="
                                            '!RelevanceHideReasonScores'
                                          "
                                        >
                                          | {{ scoreFormat(r.score) }}</span
                                        >
                                      </strong>
                                    </li>
                                  }
                                </ngTemplate>
                              }
                            </ul>
                          </div>
                        }
                      </div>
                      <div
                        class="col-5 {{ x.ScoreClass }}"
                        [ngClass]="{
                          'chart-container': x.formattedChart,
                          'info-container': !x.formattedChart
                        }"
                      >
                        @if (x.formattedChart) {
                          <div
                            [chart]="x.formattedChart"
                            class="chart-div"
                          ></div>
                        }
                        @if (!x.formattedChart) {
                          <div class="infographic w-100 {{ x.ScoreClass }}">
                            <div class="inner">
                              @if (x.Detail) {
                                <div
                                  class="detail"
                                  [ngClass]="{ smaller: x.Detail.length > 6 }"
                                >
                                  {{ x.Detail }}
                                </div>
                              }
                              @if (x.detailname) {
                                <div class="detailName">
                                  {{ x.detailname }}
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="se-card-item mt-auto">
                    <div class="row buttons-need w-100 align-self-end">
                      <button mat-button color="primary" (click)="details(x)">
                        <span class="collapsed-content">
                          <mat-icon>list_alt</mat-icon>
                          More Details
                        </span>
                      </button>
                      @if (currentApp === 'se') {
                        <button
                          class="no-thanks bridge-actions"
                          mat-icon-button
                          matTooltip="No Thank You"
                          matTooltipPosition="above"
                          (click)="
                            bridgeAction('noThanks', x.id, x.name);
                            $event.stopPropagation()
                          "
                          color="primary"
                        >
                          <mat-icon>thumb_down</mat-icon>
                        </button>
                      }
                      @if (currentApp === 'se') {
                        <button
                          class="follow-up bridge-actions"
                          mat-icon-button
                          matTooltip="Follow Up"
                          matTooltipPosition="above"
                          (click)="bridgeAction('followUp', x.id, x.name)"
                          color="primary"
                        >
                          <mat-icon>update</mat-icon>
                        </button>
                      }
                      @if (currentApp === 'se') {
                        <button
                          class="like bridge-actions"
                          mat-icon-button
                          matTooltip="I Like It"
                          matTooltipPosition="above"
                          (click)="
                            bridgeAction('like', x.id, x.name);
                            $event.stopPropagation()
                          "
                          color="primary"
                        >
                          <mat-icon>thumb_up</mat-icon>
                        </button>
                      }
                    </div>
                    <div class="rating-container row">
                      <div
                        class="rating-line {{ x.ScoreClass }}"
                        [style.width.%]="x.score"
                      ></div>
                    </div>
                  </div>
                </div>
              </li>
            }
          }
        </ul>
      }
    </div>
  }
  @if (currentApp === 'li') {
    <div class="products-container {{ viewMode }} li">
      @for (category of lifeCategories; track category) {
        @if (category === 'selected' && selectedProduct.length) {
          <h3 class="w-50 pull-left">
            Selected Product{{ products.selected.length > 1 ? 's' : '' }}
          </h3>
        }
        @if (category === 'perm' && products[category].length) {
          <h3 class="w-50 pull-left"> Permanent Product Options </h3>
        }
        @if (category === 'term' && products[category].length) {
          <h3 class="w-50 pull-left"> Term Product Options </h3>
        }
        @if (hiddenItems) {
          <button
            mat-button
            class="show pull-right"
            color="primary"
            (click)="showLi(category)"
          >
            @if (category === 'term' && products[category].length) {
              <span>
                @if (!showAllTerm) {
                  <span> <mat-icon>add_box</mat-icon> Show All </span>
                }
                @if (showAllTerm) {
                  <span>
                    <mat-icon>indeterminate_check_box</mat-icon> Show Less
                  </span>
                }
                Term Product Options
              </span>
            }
            @if (category === 'perm' && products[category].length) {
              <span>
                @if (!showAllPerm) {
                  <span> <mat-icon>add_box</mat-icon> Show All </span>
                }
                @if (showAllPerm) {
                  <span>
                    <mat-icon>indeterminate_check_box</mat-icon> Show Less
                  </span>
                }
                Permanent Product Options
              </span>
            }
          </button>
        }
        @if (viewMode === 'list') {
          <ul
            class="products col-12 row"
            [ngClass]="{
              'perm-product-container':
                category === 'perm' ||
                (category === 'selected' && selectedProduct.length),
              'temp-product-container': category === 'term'
            }"
          >
            @for (x of products[category]; track x) {
              @if (
                (!x.hidden || (x.hidden && x.unhide)) &&
                checkSelected(x, category)
              ) {
                <li
                  class="product col-12 {{ x.ScoreClass }}"
                  [class.selected]="x.SelectedBridge"
                >
                  @if (
                    rights.includes('BridgeNotes') &&
                    !moduleNotes.include &&
                    x.SelectedBridge &&
                    (x.vars['CurrentBridgeNote^Required'].raw[0] === 2 ||
                      (x.vars['CurrentBridgeNote^Required'].raw[0] === 3 &&
                        x.negativeReason))
                  ) {
                    <div
                      class="notes-notification"
                      [ngClass]="{ valid: x.notesValid }"
                    >
                      @if (x.notesValid) {
                        <mat-icon>thumb_up</mat-icon>Required Notes Entered for
                        This Item
                      } @else {
                        <mat-icon>error</mat-icon>Notes Required for This Item
                      }
                    </div>
                  }
                  <div class="w100 unified-content">
                    @if (x.ProductTypeHeader) {
                      <div
                        class="type-indicator {{
                          x.ProductTypeHeader.toLowerCase()
                        }}"
                      >
                        {{ x.ProductTypeHeader }}</div
                      >
                    }
                    <div class="score-icon {{ x.ScoreClass }}">
                      <ng-container *hasPermission="'RelevanceBridgeIcon'">
                        @if (x.ScoreClass === 'positive') {
                          <mat-icon>check_circle</mat-icon>
                        }
                        @if (x.ScoreClass === 'neutral') {
                          <mat-icon>error</mat-icon>
                        }
                        @if (x.ScoreClass === 'negative') {
                          <mat-icon>remove_circle</mat-icon>
                        }
                      </ng-container>
                    </div>
                    <div class="actions">
                      @if (!x.SelectedBridge) {
                        <button
                          mat-button
                          (click)="
                            selectProduct($event, x); $event.stopPropagation()
                          "
                        >
                          Select
                        </button>
                      }
                      @if (x.SelectedBridge) {
                        <button
                          mat-button
                          (click)="
                            deselectProduct($event, x); $event.stopPropagation()
                          "
                        >
                          Deselect
                        </button>
                      }
                    </div>
                    <div class="content">
                      <h4>
                        <span class="product-name">
                          {{ x.ShortName }}
                          <span
                            class="product-score"
                            *hasPermission="'!RelevanceHideBridgeScore'"
                          >
                            | {{ scoreFormat(x.score) }}</span
                          >
                        </span>
                      </h4>
                      <mat-checkbox
                        [(ngModel)]="compareChecked[x.name]"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="
                          compareToggle($event, x.name, null, x.SelectedBridge);
                          $event.stopPropagation()
                        "
                        [disabled]="x.SelectedBridge"
                      >
                        Compare
                      </mat-checkbox>
                    </div>
                    <div class="details-button-container">
                      <button mat-icon-button (click)="expand($event)">
                        <span class="collapsed-content">
                          <mat-icon>expand_circle_down</mat-icon>
                        </span>
                        <span class="expanded-content">
                          <mat-icon>expand_circle_down</mat-icon>
                        </span>
                      </button>
                    </div>
                  </div>
                  @if (x.PlanGrid) {
                    <div class="plan-grid-table col-11 mt-4">
                      <table class="plan-grid">
                        <thead>
                          <tr>
                            @for (h of x.PlanGrid.Header; track h) {
                              <th>{{ h }}</th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          @for (t of x.PlanGrid.Data; track t) {
                            <tr>
                              @for (z of t; track z) {
                                <td>{{ z }}</td>
                              }
                            </tr>
                          }
                        </tbody></table
                      >
                    </div>
                  }
                  @if (x.ComplianceChart) {
                    <app-sub-item-chart
                      class="mt-4"
                      [data]="x.ComplianceChart"
                      [chartTitle]="'Regulation Review'"
                    ></app-sub-item-chart>
                  }
                  <div class="details row w-100">
                    <h5 class="w-100">Why this product scored this way:</h5>
                    <app-reason-text
                      class="col-12"
                      [data]="x.reasons"
                    ></app-reason-text>
                    @if (
                      rights.includes('BridgeNotes') && !moduleNotes.include
                    ) {
                      <app-notes-entry
                        class="w-100 mt-2"
                        [bridgeVal]="x.reasons"
                        (noteEntered)="handleNotesValidation($event, x)"
                        [notesData]="{
                          app: 'li',
                          profile: id,
                          bridgeId:
                            x.vars['CurrentBridgeNote^NoteBridgeID'].raw[0],
                          note: x.vars['CurrentBridgeNote^NoteText'].raw[0],
                          prompt: x.vars['CurrentBridgeNote^Prompt'].raw[0],
                          required:
                            (x.SelectedBridge &&
                              x.vars['CurrentBridgeNote^Required'].raw[0] ===
                                2) ||
                            (x.vars['CurrentBridgeNote^Required'].raw[0] ===
                              3 &&
                              x.negativeReason)
                        }"
                      ></app-notes-entry>
                    }
                  </div>
                  <div
                    class="rating-line {{ x.ScoreClass }}"
                    [style.width.%]="x.score"
                  ></div>
                </li>
              }
            }
          </ul>
        }
      }
      @if (moduleNotes.include && !loading) {
        <app-notes-entry
          class="w-100 mt-2"
          [moduleNotes]="true"
          (noteEntered)="handleNotesValidation($event)"
          [notesData]="{
            app: 'li',
            profile: id,
            note: moduleNotes.note,
            prompt: moduleNotes.prompt,
            required: moduleNotes.required
          }"
          [moduleBridges]="products['selected']"
          (updateModuleNote)="updateModuleNote($event)"
        ></app-notes-entry>
      }
    </div>
  }
  @if (productsCharts[0]) {
    <p class="disclaimer">{{ productsCharts[0].Disclaimer }}</p>
  }
  <ngx-loading [show]="loading"></ngx-loading>
</div>
