@if (isDialog) {
  <div mat-dialog-header>
    <h1>Hierarchy Search</h1>
  </div>
} @else {
  <div>
    <h4 class="hierarchy-title">Hierarchy Search</h4>
  </div>
}
<div mat-dialog-content>
  <form>
    <div class="row w-100">
      @if (this.rights?.includes('DisplayAndSearchOnUnitId')) {
        <div
          class="unit"
          [ngClass]="{
            'col-4': data.mode !== 'caseExplorer',
            'col-6': data.mode === 'caseExplorer'
          }"
        >
          <mat-form-field>
            <input
              matInput
              placeholder="Select a Unit"
              [matAutocomplete]="unitAuto"
              [formControl]="unitCtrl"
              (blur)="currentApp !== 'se' && checkSelection()"
            />
            <mat-autocomplete
              #unitAuto="matAutocomplete"
              (optionSelected)="selectUnit($event)"
              [displayWith]="displayFn"
            >
              @for (unit of filteredUnits; track unit.id) {
                <mat-option [value]="unit">
                  {{ unit.name }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
      }
      @if (this.rights?.includes('DisplayAndSearchOnUserId')) {
        <div
          class="user"
          [ngClass]="{
            'col-3': data.mode !== 'caseExplorer',
            'col-6': data.mode === 'caseExplorer'
          }"
        >
          <mat-form-field>
            <input
              matInput
              placeholder="Select a User"
              [matAutocomplete]="userAuto"
              [formControl]="userCtrl"
            />
            <mat-autocomplete
              #userAuto="matAutocomplete"
              (optionSelected)="selectUser($event)"
              [displayWith]="displayFn"
            >
              @for (user of filteredUsers | async; track user) {
                <mat-option [value]="user">{{ user.name }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
      }
      @if (this.rights?.includes('DisplayAndSearchOnRepID')) {
        @if (data.mode !== 'caseExplorer') {
          <div class="rep col-5">
            <mat-form-field>
              <input
                matInput
                placeholder="Select a Rep"
                [matAutocomplete]="repAuto"
                [formControl]="repCtrl"
              />
              <mat-autocomplete
                #repAuto="matAutocomplete"
                (optionSelected)="selectRep($event)"
                [displayWith]="displayReps"
              >
                @for (rep of filteredRepCodes | async; track rep) {
                  <mat-option [value]="rep">
                    {{ rep.repCode }} - {{ rep.name }} ({{ rep.unitName }})
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </div>
        }
      }
    </div>
  </form>
</div>
@if (isDialog) {
  <div mat-dialog-actions class="row buttons">
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      color="accent"
      (click)="save()"
      [disabled]="!selectedUnit && !selectedUser && !selectedRepCode"
    >
      Save
    </button>
  </div>
}
