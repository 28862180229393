import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { MaskService } from '../../services/mask.service';

@Component({
  selector: 'app-fund-lookup-dialog',
  templateUrl: './fund-lookup-dialog.component.html',
  styleUrls: ['./fund-lookup-dialog.component.scss'],
})
export class FundLookupDialogComponent implements OnInit {
  dollarMask = this.mask.dollarMaskSpecs();
  percentMask = this.mask.percentMaskSpecs();
  percentAllocated = 100;
  header;
  grids;
  dollarControl = new FormControl();
  percControl = new FormControl();
  investTotal;
  informationOnly = false;

  constructor(
    public dialogRef: MatDialogRef<FundLookupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private iws: InvestmentWizardService,
    private mask: MaskService
  ) {}

  ngOnInit() {
    this.data = this.data.fund
      ? this.data
      : { fund: { FundName: null, FundID: null } };
    this.header = `${this.data.fund.FundName} | ${this.data.fund.FundID}`;
    this.investTotal = this.data.amount;
    this.iws.fundDetails(this.data.fund.FundID).subscribe(x => {
      this.grids = x.results;
    });
    this.informationOnly = this.data.mode == 'info';
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  save() {
    this.data.fund.allocation =
      this.removePercentMask(this.percControl.value) / 100;
    this.dialogRef.close({ data: { fund: this.data.fund } });
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  updateValues(ev, type, field) {
    const input = field.value;
    let val;
    switch (type) {
      case 'dollar':
        val = (this.removeMoneyMask(input) / this.investTotal) * 100;

        if (input === 0 && this.percControl.value != input) {
          this.percControl.setValue(input);
        } else if (val && this.percControl.value != val && !isNaN(val)) {
          this.percControl.setValue(val);
        } else if (!val && this.percControl.value != val) {
          this.percControl.setValue(0);
        }
        break;

      case 'perc':
        val = (this.removePercentMask(input) / 100) * this.investTotal;

        if (input === 0 && this.dollarControl.value != input) {
          this.dollarControl.setValue(input);
        } else if (val && this.dollarControl.value != val && !isNaN(val)) {
          this.dollarControl.setValue(val);
        } else if (!val && this.dollarControl.value != val) {
          this.dollarControl.setValue(0);
        }
    }
  }

  validateTotal() {}

  removeMoneyMask(obj) {
    if (obj && typeof obj == 'string') {
      return Number(obj.replace(/\,/gi, '').replace('$', ''));
    } else if (obj && typeof obj == 'number') {
      return obj;
    }
  }

  removePercentMask(obj) {
    if (obj && typeof obj == 'string') {
      return Number(obj.replace(/\,/gi, '').replace('%', ''));
    } else {
      return obj;
    }
  }
}
