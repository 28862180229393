<div class="container unified-report-container">
  @if (printParams && !generating) {
    <h1>Print a Report</h1>
  }

  @if (printParams && !generating) {
    <mat-form-field class="col-6">
      <input
        matInput
        name="text"
        placeholder="Please Enter a Custom Filename"
        [(ngModel)]="filename"
      />
    </mat-form-field>
  }

  @if (printParams && !generating) {
    <h4>Please Select Reports and Options to Include</h4>
  }
  @if (printParams && !generating) {
    <div class="report-sections">
      @if (printParams.ClientProfilingOut.ValidPPRan !== 'Y') {
        <div class="app-fields-container pb-3 no-pp-warning">
          <span class="warning">
            <mat-icon>warning</mat-icon>Please return to the Product Profiler
            and run a valid profile to view Consolidated Report.
          </span>
        </div>
      }
      <!-- IW Section -->
      @if (appAccess.includes('IW')) {
        <div class="app-fields-container iw">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'InvestmentWizard')"
            [checked]="enablePrint.InvestmentWizard"
            [disabled]="
              printParams.IWProfilingResults.IWRan !== 'Y' ||
              printParams.ClientProfilingOut.ValidPPRan !== 'Y'
            "
          >
            Investment Wizard
          </mat-checkbox>
          @if (printParams.IWProfilingResults.IWRan !== 'Y') {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (enablePrint.InvestmentWizard) {
            <div class="options col-12">
              @if (printParams.UnitQuestions.NoteField) {
                <mat-form-field class="w-100">
                  <textarea
                    class="w-100"
                    matInput
                    placeholder="Application Specfic Notes"
                    rows="5"
                  ></textarea>
                </mat-form-field>
              }
            </div>
          }
        </div>
      }
      <!-- AW Section -->
      @if (appAccess.includes('AW')) {
        <div class="app-fields-container aw">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'AnnuityWizard')"
            [checked]="enablePrint.AnnuityWizard"
            [disabled]="
              printParams.AWProfilingResults.AWRan !== 'Y' ||
              !awProductsSelected ||
              printParams.ClientProfilingOut.ValidPPRan !== 'Y'
            "
          >
            Annuity Wizard
          </mat-checkbox>
          @if (
            printParams.AWProfilingResults.AWRan !== 'Y' || !awProductsSelected
          ) {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (enablePrint['AWClientReport']) {
            <div class="options col-12">
              @if (printParams.UnitQuestions.NoteField) {
                <mat-form-field class="w-100">
                  <textarea
                    class="w-100"
                    matInput
                    placeholder="Application Specfic Notes"
                    rows="5"
                  ></textarea>
                </mat-form-field>
              }
            </div>
          }
        </div>
      }
      <!-- LI Section -->
      @if (appAccess.includes('LI')) {
        <div class="app-fields-container aw">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'LifeWizard')"
            [checked]="enablePrint['LifeWizard']"
            [disabled]="
              printParams.LIProfilingResults.LIRan !== 'Y' ||
              printParams.ClientProfilingOut.ValidPPRan !== 'Y'
            "
          >
            Life Insurance Wizard
          </mat-checkbox>
          @if (printParams.LIProfilingResults.LIRan !== 'Y') {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (enablePrint['LIClientReport']) {
            <div class="options col-12">
              @if (printParams.UnitQuestions.NoteField) {
                <mat-form-field class="w-100">
                  <textarea
                    class="w-100"
                    matInput
                    placeholder="Application Specfic Notes"
                    rows="5"
                  ></textarea>
                </mat-form-field>
              }
            </div>
          }
        </div>
      }
    </div>
  }
  <!-- </form> -->

  @if (printParams && !generating) {
    <div class="buttons row">
      <button
        mat-raised-button
        color="accent"
        (click)="generateReport()"
        [disabled]="printParams.ClientProfilingOut.ValidPPRan !== 'Y'"
      >
        Generate Report
      </button>
      <button mat-raised-button (click)="cancel()">Cancel</button>
    </div>
  }
</div>

@if (generating) {
  <div class="message">
    <h1>Please wait while your report is generated</h1>
  </div>
}

<!-- <ngx-loading [show]="loading"></ngx-loading> -->
