import {
  Component,
  Inject,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { PostTradeService } from '../../post-trade.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-external-grouping-dialog',
  templateUrl: './external-grouping-dialog.component.html',
  styleUrls: ['./external-grouping-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExternalGroupingDialogComponent implements OnInit, OnDestroy {
  unsubscribe = new Subject();
  recommendedGroups;
  selectedGroup = '';
  loading = false;
  tableView = true;
  groupForm: FormGroup;
  AccountIDControl = new FormControl<any>('');
  HashedSSNControl = new FormControl<any>('');
  valueHashed = false;
  headers;

  constructor(
    public dialogRef: MatDialogRef<ExternalGroupingDialogComponent>,
    private ptSvc: PostTradeService,
    private snacky: MatSnackBar,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.getGridSetup();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getGridSetup() {
    this.loading = true;
    this.ptSvc.getAgGridSetup().subscribe(
      resp => {
        this.headers = resp['headers'];
        if (this.data.externalInitData) {
          const formGroup = {};
          const formKeys = Object.keys(this.data.externalInitData);
          formKeys.map(keyName => {
            formGroup[keyName] = new FormControl();
          });
          this.groupForm = new UntypedFormGroup(formGroup);
        }

        this.getSuggestedGroups(true);
      },
      error => {
        console.error('GRID SETUP ERROR: ', error);
        this.loading = false;
      }
    );
  }

  getSuggestedGroups(initialGet?) {
    const queryData = { search: {} };
    if (initialGet) {
      queryData['system'] = true;
      const formKeys = Object.keys(this.data.externalInitData);
      formKeys.map(keyName => {
        if (this.data.externalInitData[keyName]) {
          queryData['search'][keyName] = {
            searchString: this.data.externalInitData[keyName],
          };
        }
      });
    } else {
      const formKeys = Object.keys(this.groupForm['value']);
      formKeys.map(keyName => {
        if (this.groupForm['value'][keyName]) {
          queryData['search'][keyName] = {
            searchString: this.groupForm['value'][keyName],
          };
        }
      });
    }
    this.ptSvc.getAccountGroupingGridData(queryData).subscribe(resp => {
      // TODO: This will need to be refactored when the BE gets the responses uniform
      if (resp['data']) {
        this.recommendedGroups = resp;
      }
      this.loading = false;
    });
  }

  linkAccount() {
    this.ptSvc
      .linkAccount(this.selectedGroup, this.data.externalInitData.AccountID)
      .subscribe(
        () => {
          this.snacky.open(`Account Linked!`, 'Close', {
            duration: 3000,
          });
          this.dialogRef.close();
        },
        err => {
          this.snacky.open(
            `Whoops something went wrong, please try again later!`,
            'Close',
            {
              duration: 3000,
            }
          );
          console.error(err);
        }
      );
  }

  selectGroup(accountId) {
    if (this.selectedGroup === accountId) {
      this.selectedGroup = '';
    } else {
      this.selectedGroup = accountId;
    }
  }

  viewAccountGroup(group) {
    window.open(
      `${this.location.prepareExternalUrl('')}account-grouping/${
        group.GroupID
      }`,
      '_blank'
    );
  }

  toggleView() {
    if (!this.tableView) {
      this.loading = true;
      this.getSuggestedGroups();
    }
    this.tableView = !this.tableView;
  }

  hashInput(ev, groupField) {
    if (ev.target.value) {
      this.ptSvc.getHashString(ev.target.value).subscribe(resp => {
        this.valueHashed = true;
        if (resp['resultString']) {
          this.groupForm.controls[groupField].setValue(resp['resultString']);
          this[groupField + 'Control'].setValue(resp['resultString']);
        }
      });
    }
  }
}
