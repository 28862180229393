import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-section-header',
  styleUrls: ['form-sectionHeader.component.scss'],
  template: `
    @if (props.label) {
      <label
        class="section col-12"
        [innerHTML]="props.label"
        [ngClass]="{ border: to.labelBorder }"
      ></label>
    }
    @if (props.prompt) {
      <p class="explanation" [innerHTML]="props.prompt"></p>
    }
  `,
})
export class FormlySectionHeaderComponent extends FieldType {
  @HostBinding('class') classes = 'header-section';

  constructor() {
    super();
  }
}
