import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-radio-validation',
  styleUrls: ['form-radioValidation.component.scss'],
  template: `
    @if (props.label) {
      <label
        class="section w-100"
        [class.mat-error]="formControl.errors"
        [innerHTML]="props.label"
      >
        @if (props.required) {
          <sup>*</sup>
        }
      </label>
    }
    @if (props.help) {
      <mat-icon
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
      >
        help
      </mat-icon>
    }
    @if (props.prompt) {
      <p
        class="explanation"
        [class.mat-error]="formControl.errors"
        [innerHTML]="props.prompt"
      ></p>
    }
    @if (props.placeholder) {
      <p
        class="italic"
        [class.mat-error]="formControl.errors"
        [innerHTML]="props.placeholder"
      ></p>
    }

    <mat-radio-group
      class="radio-group radio-group-validate col-12"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="props.required"
      (change)="props.change ? to.change(field, formControl) : ''"
    >
      @for (opt of to?.options; track opt) {
        <mat-radio-button class="radio-button" [value]="opt.value">
          @switch (opt.value) {
            @case (0) {
              <mat-icon>highlight_off</mat-icon>
            }
            @case (1) {
              <mat-icon>stars</mat-icon>
            }
            @case (2) {
              <mat-icon>find_in_page</mat-icon>
            }
            @case ('RET') {
              <mat-icon>beach_access</mat-icon>
            }
            @case ('CF') {
              <mat-icon>school</mat-icon>
            }
            @case ('OTH') {
              <mat-icon>multiline_chart</mat-icon>
            }
            @case ('LI') {
              <mat-icon>security</mat-icon>
            }
          }
          {{ opt.label }}
        </mat-radio-button>
      }
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error>
          This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.
        </mat-error>
      }
    </mat-radio-group>
  `,
})
export class FormlyRadioValidationComponent extends FieldType {}
