<mat-card appearance="outlined" class="col-12 stretch case-activity">
  <mat-card-header>
    Case Activity Statistics
    <button
      mat-button
      color="white"
      class="download"
      (click)="exportExcel(gridExportChooser())"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
  </mat-card-header>
  <mat-card-content class="row">
    <div class="col-12 mb-3 toolbar">
      <mat-button-toggle-group [(ngModel)]="dataView" class="ml-2">
        <mat-button-toggle value="top5">Top 5</mat-button-toggle>
        <mat-button-toggle value="data">Case Data</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field class="ml-2">
        <mat-label>Solution</mat-label>
        <mat-select
          [(ngModel)]="solution"
          (selectionChange)="getCaseStatsSummary(true)"
        >
          @for (sltn of solutions; track sltn) {
            <mat-option [value]="sltn.value">{{ sltn.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ml-2">
        <mat-label>View Mode</mat-label>
        <mat-select
          [(ngModel)]="flatOrGrouped"
          (selectionChange)="updateFlatGrouped()"
        >
          <mat-option value="FLAT">Bridge View (Flat)</mat-option>
          <mat-option value="GROUP">Case View (Grouped)</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-button-toggle-group [(ngModel)]="extendedData" class="ml-2">
        <mat-button-toggle [value]="false">Standard View</mat-button-toggle>
        <mat-button-toggle [value]="true">Extended View</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-form-field class="mr-2">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="rangePickerOne">
          <input
            matStartDate
            placeholder="Start date"
            [formControl]="startDateCtrl"
            (dateChange)="dateChange()"
          />
          <input
            matEndDate
            placeholder="End date"
            [formControl]="endDateCtrl"
            (dateChange)="dateChange()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="rangePickerOne"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #rangePickerOne></mat-date-range-picker>
      </mat-form-field>
      @if (dataView === 'data') {
        <mat-form-field class="pull-right mr-2">
          <mat-label>Sort By</mat-label>
          <mat-select [(ngModel)]="sort" (selectionChange)="updateSorting()">
            @for (sort of sortingPresets; track sort) {
              <mat-option [value]="sort.value">{{ sort.label }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
    <div class="button-row">
      @if (history.length) {
        <button mat-button (click)="goBack()">
          <mat-icon>arrow_back</mat-icon> Back
        </button>
      }
    </div>
    <!--  -->
    <!-- Start Top 5 View -->
    <!--  -->
    <div class="col-12 top-5 row" [hidden]="dataView !== 'top5'">
      <div class="col-6">
        <label>Users With Most Cases Ran</label>
        <table>
          <thead>
            <th>User</th>
            <th>Cases Ran</th>
          </thead>
          <tbody>
            @for (ran of casesRan; track ran) {
              <tr>
                <td (click)="dataRowClicked(ran, 'user')" class="clickable">{{
                  ran.UserName
                }}</td>
                <td (click)="dataRowClicked(ran, 'user')" class="clickable">{{
                  ran.CaseCount
                }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      <div class="col-6">
        <label>Most Score Class Changes</label>
        <table>
          <thead>
            <th>User</th>
            <th>Case</th>
            <th>Classes Changed</th>
            <th>Run Count</th>
          </thead>
          <tbody>
            @for (clss of classesChanged; track clss) {
              <tr>
                <td>{{ clss.UserName }}</td>
                <td (click)="dataRowClicked(clss, 'client')" class="clickable">
                  {{ clss.ClientName }}
                  <button
                    mat-icon-button
                    (click)="viewCase($event, clss.ProfileID)"
                    matTooltip="Open Case"
                    class="pull-right mr-5"
                    color="primary"
                    ><mat-icon>work</mat-icon></button
                  >
                </td>
                <td (click)="dataRowClicked(clss, 'count')" class="clickable">
                  {{ clss.Changes }}
                  <button
                    mat-icon-button
                    (click)="viewHistory($event, clss)"
                    matTooltip="View Case History"
                    class="pull-right mr-5"
                    color="warn"
                    ><mat-icon>history</mat-icon></button
                  >
                </td>
                <td>{{ clss.RunCount }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
    <!--  -->
    <!-- End Top 5 View -->
    <!--  -->

    <!--  -->
    <!-- Start Data View -->
    <!--  -->

    <div class="col-12 data-view" [hidden]="dataView !== 'data'">
      <h2>{{ gridTitle }}</h2>
      <kendo-grid
        class="col-12 case-stats-activity-grid"
        #resultsGrid="kendoGrid"
        [kendoGridBinding]="gridData.Data"
        [group]="grouping"
        [sortable]="{
          allowUnsort: true,
          mode: 'multiple'
        }"
        [sort]="sort"
        [columnMenu]="true"
        [style.maxHeight.px]="500"
        [filterable]="'menu'"
        (cellClick)="handleRowClick($event)"
        [hidden]="profileHistory.History.length > 1"
      >
        @for (column of gridData.Headers; track column) {
          <kendo-grid-column
            [field]="column.DataField"
            [title]="column.Label"
            [sortable]="column.Sort"
            [hidden]="isHidden(column.DataField)"
            [filterable]="column.Filter"
            [groupable]="column.Group"
            [media]="column.RespVis"
            [width]="column.DataField === 'User' ? '275' : null"
            [class]="{
              'text-center':
                column.DataField === 'ScoreClass' ||
                column.DataField === 'ProductChanges' ||
                column.DataField === 'ScoreClassChanges' ||
                column.DataField === 'ProductType' ||
                column.DataField === 'AssessmentType',
              clickable:
                column.DataField === 'User' || column.DataField === 'ClientName'
            }"
          >
            @if (column.DataField === 'User'; as dataItem) {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem.User }}
                <button
                  mat-icon-button
                  (click)="viewHistory($event, dataItem)"
                  matTooltip="View Case History"
                  class="pull-right"
                  color="warn"
                  ><mat-icon>history</mat-icon></button
                >
                <button
                  mat-icon-button
                  (click)="viewCase($event, dataItem.ProfileID)"
                  matTooltip="Open Case"
                  class="pull-right"
                  color="primary"
                  ><mat-icon>work</mat-icon></button
                >
              </ng-template>
            }
            @if (column.DataField === 'ClientName') {
              <ng-template kendoGridCellTe let-columnIndex="columnIndex">
                {{ dataItem.ClientName }}
                @if (columnIndex === 0) {
                  <button
                    mat-icon-button
                    (click)="viewHistory($event, dataItem)"
                    matTooltip="View Case History"
                    class="pull-right"
                    color="warn"
                    ><mat-icon>history</mat-icon></button
                  >
                }
                @if (columnIndex === 0) {
                  <button
                    mat-icon-button
                    (click)="viewCase($event, dataItem.ProfileID)"
                    matTooltip="Open Case"
                    class="pull-right"
                    color="primary"
                    ><mat-icon>work</mat-icon></button
                  >
                }
              </ng-template>
            }
            @if (column.DataField === 'ScoreClass'; as dataItem) {
              <ng-template kendoGridCellTemplate let-dataItem>
                @if (
                  dataItem.ScoreClass &&
                  dataItem.ScoreClass.toLowerCase() === 'positive'
                ) {
                  <mat-icon class="positive">check_circle</mat-icon>
                }
                @if (
                  dataItem.ScoreClass &&
                  dataItem.ScoreClass.toLowerCase() === 'neutral'
                ) {
                  <mat-icon class="neutral">error</mat-icon>
                }
                @if (
                  dataItem.ScoreClass &&
                  dataItem.ScoreClass.toLowerCase() === 'negative'
                ) {
                  <mat-icon class="negative">remove_circle</mat-icon>
                }
              </ng-template>
            }
            @if (column.DataField === 'DateTime'; as dataItem) {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem['DateTime'] | date: 'short' }}
              </ng-template>
            }
            @if (column.Validation; as dataItem) {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ findValue(dataItem[column.DataField], column.Validation) }}
              </ng-template>
            }
          </kendo-grid-column>
        }
        @if (gridData['subHead']; as dataItem) {
          <ng-template kendoGridDetailTemplate let-dataItem>
            <section>
              <button
                mat-button
                class="pull-left"
                (click)="getProfileHistory(dataItem.ProfileID)"
              >
                <mat-icon>search</mat-icon>
                View Details
              </button>
              <table class="w-90 pull-right">
                <thead>
                  @for (header of gridData['subHead']; track header) {
                    <th>
                      {{ header.Label }}
                    </th>
                  }
                </thead>
                <tbody>
                  @for (product of dataItem.products; track product) {
                    <tr>
                      @for (header of gridData['subHead']; track header) {
                        <td>
                          @if (header.DataField === 'ScoreClass') {
                            @if (
                              product.ScoreClass &&
                              product.ScoreClass.toLowerCase() === 'positive'
                            ) {
                              <mat-icon class="positive">check_circle</mat-icon>
                            }
                            @if (
                              product.ScoreClass &&
                              product.ScoreClass.toLowerCase() === 'neutral'
                            ) {
                              <mat-icon class="neutral">error</mat-icon>
                            }
                            @if (
                              product.ScoreClass &&
                              product.ScoreClass.toLowerCase() === 'negative'
                            ) {
                              <mat-icon class="negative"
                                >remove_circle</mat-icon
                              >
                            }
                          } @else {
                            {{ product[header.DataField] }}
                          }
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            </section>
          </ng-template>
        }
        <kendo-grid-excel></kendo-grid-excel>
      </kendo-grid>

      <kendo-grid
        class="col-12 case-stats-activity-grid"
        #historyGrid="kendoGrid"
        [kendoGridBinding]="profileHistory.History"
        [group]="grouping"
        [style.maxHeight.px]="500"
        (cellClick)="historyRowClick($event, profileHistory.ProfileID)"
        [hidden]="
          profileHistory.History.length < 1 ||
          (profileHistoryDetail.additions &&
            profileHistoryDetail.additions.length > 1) ||
          (profileHistoryDetail.changes &&
            profileHistoryDetail.changes.length > 1) ||
          (profileHistoryDetail.deletions &&
            profileHistoryDetail.deletions.length > 1)
        "
      >
        <kendo-grid-column field="date" title="Date"> </kendo-grid-column>
        <kendo-grid-column field="userId" title="User"> </kendo-grid-column>
        <kendo-grid-column field="solution" title="Solution">
        </kendo-grid-column>
        <kendo-grid-excel></kendo-grid-excel>
      </kendo-grid>

      <span
        [hidden]="
          !profileHistoryDetail.additions ||
          profileHistoryDetail.additions.length < 1
        "
      >
        <h4>Additions</h4>
        <kendo-grid
          class="col-12 case-stats-activity-grid"
          #historyAdditionsGrid="kendoGrid"
          [kendoGridBinding]="profileHistoryDetail.additions"
          [group]="grouping"
          [style.maxHeight.px]="250"
        >
          <kendo-grid-column field="variable" title="Variable">
          </kendo-grid-column>
          <kendo-grid-column field="label" title="Label"> </kendo-grid-column>
          <kendo-grid-column field="oldvalue" title="Old Value">
          </kendo-grid-column>
          <kendo-grid-column field="newvalue" title="New Value">
          </kendo-grid-column>
          <kendo-grid-excel></kendo-grid-excel>
        </kendo-grid>
      </span>

      <span
        [hidden]="
          !profileHistoryDetail.changes ||
          profileHistoryDetail.changes.length < 1
        "
      >
        <h4>Changes</h4>
        <kendo-grid
          class="col-12 case-stats-activity-grid"
          #historyChangesGrid="kendoGrid"
          [kendoGridBinding]="profileHistoryDetail.changes"
          [group]="grouping"
          [style.maxHeight.px]="250"
        >
          <kendo-grid-column field="variable" title="Variable">
          </kendo-grid-column>
          <kendo-grid-column field="label" title="Label"> </kendo-grid-column>
          <kendo-grid-column field="oldvalue" title="Old Value">
          </kendo-grid-column>
          <kendo-grid-column field="newvalue" title="New Value">
          </kendo-grid-column>
          <kendo-grid-excel></kendo-grid-excel>
        </kendo-grid>
      </span>

      <span
        [hidden]="
          !profileHistoryDetail.deletions ||
          profileHistoryDetail.deletions.length < 1
        "
      >
        <h4>Deletions</h4>
        <kendo-grid
          class="col-12 case-stats-activity-grid"
          #historyDeletionsGrid="kendoGrid"
          [kendoGridBinding]="profileHistoryDetail.deletions"
          [group]="grouping"
          [style.maxHeight.px]="250"
        >
          <kendo-grid-column field="variable" title="Variable">
          </kendo-grid-column>
          <kendo-grid-column field="label" title="Label"> </kendo-grid-column>
          <kendo-grid-column field="oldvalue" title="Old Value">
          </kendo-grid-column>
          <kendo-grid-column field="newvalue" title="New Value">
          </kendo-grid-column>
          <kendo-grid-excel></kendo-grid-excel>
        </kendo-grid>
      </span>
    </div>
    <!--  -->
    <!-- End Data View -->
    <!--  -->
  </mat-card-content>
  <ngx-loading
    [show]="loading"
    [config]="{ fullScreenBackdrop: false }"
  ></ngx-loading>
</mat-card>
