<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Top Sales/Service Opportunities </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Opportunities Available</h4>
      </div>
    }
    @if (state === 'loaded') {
      <table>
        <thead>
          <th>Suggestion</th>
          <th class="text-center"># Relevant</th>
          <th class="text-center">Avg Score</th>
          <th class="text-center">Avg Rank</th>
        </thead>
        <tbody>
          @for (brd of bridges; track brd) {
            <tr (click)="goToSearch($event, brd.id)">
              <td>{{ brd.name }}</td>
              <td class="text-center">{{ brd.relevant }}</td>
              <td class="text-center">{{ brd.score }}</td>
              <td class="text-center">{{ brd.rank }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
  </mat-card-content>
</mat-card>
