<mat-form-field class="mr-3">
  <input
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="density.unit"
    [matAutocomplete]="densityAuto"
    name="densityUnitsControl"
    [formControl]="densityUnitsControl"
  />
  <mat-autocomplete #densityAuto="matAutocomplete">
    @for (unit of filteredUnits | async; track unit) {
      <mat-option [value]="unit.id">
        {{ unit.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>

<mat-form-field class="mr-3">
  <input matInput placeholder="score" [(ngModel)]="density.score" />
</mat-form-field>

<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getBridgeDensity()"
  [disabled]="!density.unit || !density.score"
>
  Get Report
</button>
@if (bridgeDensityData.data) {
  <button
    mat-button
    color="primary"
    class="grid-button pull-right"
    [disabled]="!bridgeDensityData.data"
    (click)="exportToExcel(bridgeDensityGrid)"
  >
    <mat-icon>cloud_download</mat-icon>Download Excel File
  </button>
}
<kendo-grid
  #bridgeDensityGrid="kendoGrid"
  [hidden]="!bridgeDensityData.data"
  class="col-12 mt-3 usage-grid"
  [kendoGridBinding]="bridgeDensityData.data"
  [height]="600"
  [selectable]="true"
>
  @for (col of bridgeDensityData.headers; track col) {
    <kendo-grid-column
      [field]="col.DataField"
      [title]="col.Label"
    ></kendo-grid-column>
  }
  <kendo-grid-excel
    fileName="RightBridge-BridgeDensityReport.xlsx"
  ></kendo-grid-excel>
</kendo-grid>
