<div class="page-container templates-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12"
    >Investment Templates
    <button mat-button class="header-button" (click)="newTemplate()">
      <mat-icon>insert_drive_file</mat-icon>
      <span>New Template</span>
    </button>
    <button mat-button class="header-button mr-1" (click)="addSharedTemplate()">
      <mat-icon>post_add</mat-icon>
      <span>Add Shared Template</span>
    </button>
  </h1>
  <kendo-grid
    class="col-12 template-grid"
    #templateGrid="kendoGrid"
    [scrollable]="'none'"
    [kendoGridBinding]="templates"
    [pageSize]="pagesize"
    [pageable]="true"
    [selectable]="false"
  >
    <kendo-grid-column field="name" title="Template Name">
      <ng-template kendoGridCellTemplate let-dataItem>
        @if (dataItem.scope === 'firm') {
          <mat-icon
            class="row-icon"
            matTooltip="Firm Template"
            [matTooltipShowDelay]="1000"
          >
            business
          </mat-icon>
        } @else {
          <mat-icon
            class="row-icon"
            matTooltip="Your Template"
            [matTooltipShowDelay]="1000"
          >
            person
          </mat-icon>
        }
        {{ dataItem.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="funds" title="Details">
      <ng-template kendoGridCellTemplate let-data>
        <ul class="fund-list">
          @for (fnd of data.funds; track fnd.ticker) {
            <li>
              {{ funds | json }}{{ fnd.ticker }} - {{ fnd.name }} |
              {{ fnd.percent | percent }}
            </li>
          }
        </ul>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="created" title="Date created">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.created | date: short }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="edited" title="Last Date Edited">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.edited | date: short }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="edited"
      title="Share Template"
      class="text-center"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <mat-slide-toggle
          [disabled]="dataItem.scope === 'firm'"
          [checked]="dataItem.scope === 'private'"
          (change)="sharingChange($event, dataItem)"
        ></mat-slide-toggle>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="edited" title="Sharing ID">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          mat-icon-button
          (click)="clipboardCopy($event, dataItem.TemplateID)"
          matTooltip="Click to Copy Template Share ID"
          [matTooltipShowDelay]="1000"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column width="100" class="text-center" [columnMenu]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          mat-icon-button
          [disabled]="
            dataItem.scope === 'firm' && !rights.includes('edit_firm_templates')
          "
          (click)="editTemplate(dataItem)"
          matTooltip="Edit Template"
          [matTooltipShowDelay]="1000"
        >
          <mat-icon aria-label="Copy Profile">edit</mat-icon>
        </button>
        <button
          mat-icon-button
          [disabled]="
            dataItem.scope === 'firm' && !rights.includes('edit_firm_templates')
          "
          (click)="deleteTemplate(dataItem.TemplateID)"
          matTooltip="Delete Template"
          [matTooltipShowDelay]="1000"
        >
          <mat-icon aria-label="Delete Profile">delete</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
