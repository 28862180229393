<mat-form-field class="col-12 col-md-6">
  <mat-select
    [placeholder]="placeholder"
    (selectionChange)="templateChosen($event)"
    [disabled]="
      (!firmTemplates && !templates) ||
      (firmTemplates &&
        firmTemplates.length > 1 &&
        templates &&
        templates.length > 1)
    "
  >
    @if (firmTemplates) {
      <mat-optgroup label="Firm Templates">
        @for (template of firmTemplates; track template) {
          <mat-option [value]="template">
            {{ template.name }}
          </mat-option>
        }
      </mat-optgroup>
    }
    @if (templates) {
      <mat-optgroup label="User Templates">
        @for (template of templates; track template) {
          <mat-option [value]="template">
            {{ template.name }}
          </mat-option>
        }
      </mat-optgroup>
    }
  </mat-select>
  <mat-hint></mat-hint>
</mat-form-field>
