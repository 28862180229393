<div class="col-12 top-5 row mb-5">
  <div class="col-6">
    <h4>Users With Most Cases Ran <small>(Last 30 days)</small></h4>
    <table>
      <thead>
        <th>User</th>
        <th>Cases Ran</th>
        <th>View User</th>
      </thead>
      <tbody>
        @for (ran of casesRan; track ran) {
          <tr>
            <td
              (click)="viewUser($event, { id: ran.UserID, name: ran.UserName })"
              class="clickable"
            >
              {{ ran.UserName }}</td
            >
            <td
              (click)="viewUser($event, { id: ran.UserID, name: ran.UserName })"
              class="clickable"
              >{{ ran.CaseCount }}</td
            >
            <td
              (click)="viewUser($event, { id: ran.UserID, name: ran.UserName })"
              class="clickable"
            >
              <mat-icon color="warn">find_in_page</mat-icon>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  <div class="col-6">
    <h4>Most Score Class Changes <small>(Last 30 days)</small></h4>
    <table>
      <thead>
        <th>User</th>
        <th>Case</th>
        <th>Classes Changed</th>
        <th>Run Count</th>
        <th>Open Case</th>
        <th>Case History</th>
      </thead>
      <tbody>
        @for (clss of classesChanged; track clss) {
          <tr>
            <td>{{ clss.UserName }}</td>
            <td (click)="viewHistory($event, clss)" class="clickable">
              {{ clss.ClientName }}
            </td>
            <td (click)="viewHistory($event, clss)" class="clickable">
              {{ clss.Changes }}
            </td>
            <td>{{ clss.RunCount }}</td>
            <td class="text-center">
              <button
                mat-icon-button
                (click)="viewCase($event, clss)"
                matTooltip="Open Case"
                class="pull-right"
                color="primary"
              >
                <mat-icon>work</mat-icon>
              </button>
            </td>
            <td class="text-center">
              <button
                mat-icon-button
                (click)="viewHistory($event, clss)"
                matTooltip="View Case History"
                class="pull-right"
                color="warn"
              >
                <mat-icon>history</mat-icon>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
