import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PostTradeService } from '../../post-trade.service';
import { AccountGroupingDialogComponent } from '../../account-grouping/account-grouping-dialog/account-grouping-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-account-grouping',
  templateUrl: './account-grouping.component.html',
  styleUrls: ['./account-grouping.component.scss'],
})
export class AccountGroupingComponent implements OnInit, OnDestroy {
  constructor(
    public ptSvc: PostTradeService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private route: Router
  ) {}

  unsubscribe: Subject<any> = new Subject();
  groupId = '';
  gridConfig = {
    title: 'Account Grouping',
    gridName: 'AccountGroupingGrid',
    showGridFilters: true,
    showFilterManager: false,
    manualSearch: true,
    searchButtonText: 'Search Results',
    showBulkActions: false,
    downloadGridButton: true,
    gridActionColumn: true,
    rowSelector: 'GroupID',
    gridType: 'account-grouping',
    detailViewRoute: '/account-grouping/',
    getSetup: this.getGridSetup,
    getData: this.getGridData,
  };
  crumbConfig = [
    {
      label: 'Post Transaction',
      link: 'post-trade',
    },
    {
      label: 'Account Grouping',
      link: 'account-grouping',
    },
  ];

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (params.id) {
          this.groupId = params.id;
          this.crumbConfig.push({
            label: this.groupId,
            link: `account-grouping/${this.groupId}`,
          });
        } else {
          this.groupId = '';
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  getGridSetup() {
    return this.ptSvc.getAgGridSetup();
  }

  getGridData(reqData) {
    return this.ptSvc.getAccountGroupingGridData(reqData);
  }

  createNewGroup() {
    this.dialog.open(AccountGroupingDialogComponent, {
      data: {
        createGroup: true,
      },
      width: '80vw',
      maxWidth: '80vw',
      autoFocus: false,
    });
  }
}
