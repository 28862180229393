<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Active PPfA's and Connectors
    <button mat-button (click)="load()" class="header-button download-link"
      ><mat-icon>cloud_download</mat-icon></button
    >
  </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'loaded' || state === 'none') {
      <span>
        <div mat-dialog-content class="dialog-container">
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Rows</mat-label>
              <select
                matNativeControl
                (change)="updateRowCount($event.target.value)"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
            </mat-form-field>
            <mat-form-field class="col-3">
              <input
                matInput
                [(ngModel)]="startDate"
                placeholder="Start Date MM-DD-YYYY"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <input
                matInput
                [(ngModel)]="endDate"
                placeholder="End Date MM-DD-YYYY"
              />
            </mat-form-field>
            <div class="col-3">
              @if (state === 'loaded' || state === 'none') {
                <button
                  mat-button
                  (click)="download()"
                  class="header-button download-link"
                  ><mat-icon>save</mat-icon></button
                >
              }
            </div>
          </div>
        </div>
      </span>
    }
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Annuities Available</h4>
      </div>
    }
    @if (state === 'error') {
      <strong>Error Loading Rates</strong>
    }
    @if (expiring) {
      <strong>Expiring Annuity Rates</strong>
    }
    @if (expiring) {
      <table>
        <thead>
          <th>Carrier</th>
          <th>Contract</th>
          <th>Carrier Code</th>
          <th>Cusip</th>
          <th>Contract Code</th>
          <th>Version Date</th>
        </thead>
        <tbody>
          @for (a of expiring; track a) {
            <tr
              [className]="
                GetClass(a['RateEffectiveDate'], a['RateDeprecateDate'])
              "
            >
              <td>{{ a['CarrierName'] }}</td>
              <td>{{ a['Contract'] }}</td>
              <td>{{ a['CarrierCode'] }}</td>
              <td>{{ a['Cusip'] }}</td>
              <td>{{ a['ContractCode'] }}</td>
              <td>{{ a['VersionDate'] }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
    @if (updating) {
      <strong>Updating Annuity Rates</strong>
    }
    @if (updating) {
      <table>
        <thead>
          <th>Carrier</th>
          <th>Contract</th>
          <th>Carrier Code</th>
          <th>Cusip</th>
          <th>Contract Code</th>
          <th>Version Date</th>
        </thead>
        <tbody>
          @for (a of updating; track a) {
            <tr
              [className]="
                GetClass(a['RateEffectiveDate'], a['RateDeprecateDate'])
              "
            >
              <td>{{ a['CarrierName'] }}</td>
              <td>{{ a['Contract'] }}</td>
              <td>{{ a['CarrierCode'] }}</td>
              <td>{{ a['Cusip'] }}</td>
              <td>{{ a['ContractCode'] }}</td>
              <td>{{ a['VersionDate'] }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
  </mat-card-content>
</mat-card>
