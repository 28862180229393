import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-input-exp',
  styleUrls: ['form-inputExp.component.scss'],
  template: `
    @if (props.label) {
      <label class="section col-12" [innerHTML]="props.label"></label>
    }
    @if (props.prompt) {
      <p class="explanation" [innerHTML]="props.prompt"></p>
    }
    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.placeholder }}</mat-label>
      <input
        matInput
        [placeholder]="props.placeholder || props.label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
        (change)="props.change ? to.change(field, formControl) : ''"
        type="{{ to.number == true ? 'number' : '' }}"
        min="{{ to.min }}"
        max="{{ to.max }}"
        maxlength="{{ to.maxLength ? to.maxLength : '' }}"
      />
      @if (props.help) {
        <mat-icon
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
          >help</mat-icon
        >
      }
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error>
          This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.
        </mat-error>
      }
    </mat-form-field>
  `,
})
export class FormlyCustomInputExpComponent extends FieldType {}
