<div class="page-container container unified">
  <h1 class="header col-12">
    <button
      mat-button
      color="primary"
      class="header-button"
      (click)="compare()"
      [disabled]="canCompare()"
    >
      <mat-icon>compare_arrows</mat-icon>
      <span>Compare</span>
    </button>
  </h1>
  <div class="row">
    @if (!loading && !data) {
      <div class="message-container col-8 offset-2 my-3 row">
        <h3>
          The information entered indicates that the insured has adequate
          coverage or is over-insured, there is no need for additional coverage.
          We are unable to provide results in this situation.
        </h3>
      </div>
    }

    @if (
      !loading &&
      this.products.tempLifeProducts.length <= 0 &&
      this.products.permLifeProducts.length <= 0
    ) {
      <div class="message-container col-8 offset-2 my-3 row">
        <h3>
          Based on the data entered for the insured, no results were found. We
          are unable to provide results in this situation.
        </h3>
      </div>
    }

    @if (
      !loading &&
      (this.products.tempLifeProducts.length > 0 ||
        this.products.permLifeProducts.length > 0)
    ) {
      <div class="products-container col-7">
        @for (category of products | keyvalue; track category) {
          <div
            class="col-12"
            [ngClass]="{
              'perm-product-container': category.key === 'permLifeProducts',
              'temp-product-container': category.key === 'tempLifeProducts'
            }"
          >
            @if (category.key === 'permLifeProducts') {
              <h3>Permanent Product Options</h3>
            }
            @if (category.key === 'tempLifeProducts') {
              <h3>Term Product Options</h3>
            }
            @if (category.value.length > 0) {
              <ul class="products col-12 mb-5">
                @for (prod of category.value; track prod; let i = $index) {
                  @if (!prod.hidden) {
                    <li
                      class="product col-12 {{ prod.ScoreClass }} {{
                        prod.expandedClass
                      }}"
                      [class.selected]="
                        (selectedPerm &&
                          selectedPerm.vars['CurrentLifeItem^LifeItemID']
                            .raw[0] ===
                            prod.vars['CurrentLifeItem^LifeItemID'].raw[0]) ||
                        (selectedTemp &&
                          selectedTemp?.vars['CurrentLifeItem^LifeItemID']
                            .raw[0] ===
                            prod.vars['CurrentLifeItem^LifeItemID'].raw[0])
                      "
                    >
                      <div class="col-12 row">
                        <div class="col-11 content">
                          <span class="score-icon {{ prod.ScoreClass }}">
                            @switch (prod.ScoreClass) {
                              @case ('positive') {
                                <mat-icon>check_circle</mat-icon>
                              }
                              @case ('neutral') {
                                <mat-icon>error</mat-icon>
                              }
                              @case ('negative') {
                                <mat-icon>remove_circle</mat-icon>
                              }
                            }
                          </span>
                          @if (category.key === 'permLifeProducts') {
                            <button
                              mat-button
                              color="primary"
                              class="mr-5 select-button"
                              (click)="
                                selectProducts($event, prod, false, false);
                                $event.stopPropagation()
                              "
                            >
                              @if (
                                selectedPerm &&
                                selectedPerm.vars['CurrentLifeItem^LifeItemID']
                                  .raw[0] ===
                                  prod.vars['CurrentLifeItem^LifeItemID'].raw[0]
                              ) {
                                Deselect {{ prod.selectedBridge }}
                              }
                              @if (
                                !selectedPerm ||
                                (selectedPerm &&
                                  selectedPerm.vars[
                                    'CurrentLifeItem^LifeItemID'
                                  ].raw[0] !==
                                    prod.vars['CurrentLifeItem^LifeItemID']
                                      .raw[0])
                              ) {
                                Select
                              }
                            </button>
                          }
                          @if (category.key === 'tempLifeProducts') {
                            <button
                              mat-button
                              color="primary"
                              class="mr-5 select-button"
                              (click)="
                                selectProducts($event, prod, false, false);
                                $event.stopPropagation()
                              "
                            >
                              @if (
                                selectedTemp &&
                                selectedTemp?.vars['CurrentLifeItem^LifeItemID']
                                  .raw[0] ===
                                  prod.vars['CurrentLifeItem^LifeItemID'].raw[0]
                              ) {
                                Deselect
                              }
                              @if (
                                !selectedTemp ||
                                (selectedTemp &&
                                  selectedTemp?.vars[
                                    'CurrentLifeItem^LifeItemID'
                                  ].raw[0] !==
                                    prod.vars['CurrentLifeItem^LifeItemID']
                                      .raw[0])
                              ) {
                                Select
                              }
                            </button>
                          }
                          <div>
                            <h4>
                              {{ prod.ShortName }}
                              <span
                                *hasPermission="'!RelevanceHideBridgeScore'"
                              >
                                | {{ scoreFormat(prod.score) }}</span
                              >
                            </h4>
                            <mat-checkbox
                              [(ngModel)]="compareChecked[prod.LifeItemID]"
                              [ngModelOptions]="{ standalone: true }"
                              (click)="
                                compareToggle($event, prod);
                                $event.stopPropagation()
                              "
                            >
                              Compare
                            </mat-checkbox>
                          </div>
                        </div>
                        <div class="col-1 button d-flex align-items-center">
                          <button
                            class="mb-0"
                            mat-icon-button
                            (click)="expand($event)"
                          >
                            <span class="expand-toggle-icon">
                              <mat-icon>expand_circle_down</mat-icon>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div class="details col-12">
                        <div class="details-container row">
                          <h5 class="col-12 pl-0">Description</h5>
                          @if (prod.ShortDescription) {
                            <p
                              class="w-100 pb-3"
                              [innerHTML]="prod.ShortDescription"
                            ></p>
                          }
                          @if (showReasons) {
                            @if (prod.ComplianceChart) {
                              <div class="table-container">
                                <h5 class="col-12 pl-0">Regulation Review</h5>
                                <div class="tables w-100">
                                  <table class="compliance-details">
                                    <thead>
                                      <tr>
                                        @for (
                                          x of prod.ComplianceChart.Header;
                                          track x;
                                          let idx = $index
                                        ) {
                                          <th
                                            [class.text-center]="
                                              idx === 1 || idx === 3
                                            "
                                          >
                                            {{ x }}
                                          </th>
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      @for (
                                        t of prod.ComplianceChart.Data;
                                        track t;
                                        let idx = $index
                                      ) {
                                        <tr [ngClass]="t.Status">
                                          @if (
                                            idx < prod.ComplianceChart.Limit
                                          ) {
                                            <td>
                                              <strong>{{ t.Area }}</strong>
                                            </td>
                                            <td class="text-center">
                                              @if (t.Status === 'Positive') {
                                                <mat-icon>check</mat-icon>
                                              }
                                              @if (t.Status === 'Negative') {
                                                <mat-icon>close</mat-icon>
                                              }
                                              @if (t.Status === 'Warning') {
                                                <mat-icon>
                                                  priority_high
                                                </mat-icon>
                                              }
                                            </td>
                                          }
                                        </tr>
                                      }
                                    </tbody>
                                  </table>
                                  <table class="compliance-details">
                                    <thead>
                                      <tr>
                                        @for (
                                          x of prod.ComplianceChart.Header;
                                          track x
                                        ) {
                                          <th>{{ x }}</th>
                                        }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      @for (
                                        t of prod.ComplianceChart.Data;
                                        track t;
                                        let idx = $index
                                      ) {
                                        <tr [ngClass]="t.Status">
                                          @if (
                                            idx >= prod.ComplianceChart.Limit
                                          ) {
                                            <td>
                                              <strong>{{ t.Area }}</strong>
                                            </td>
                                            <td class="text-center">
                                              @if (t.Status === 'Positive') {
                                                <mat-icon>check</mat-icon>
                                              }
                                              @if (t.Status === 'Negative') {
                                                <mat-icon>close</mat-icon>
                                              }
                                              @if (t.Status === 'Warning') {
                                                <mat-icon>
                                                  priority_high
                                                </mat-icon>
                                              }
                                            </td>
                                          }
                                        </tr>
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            }
                            <h5 class="col-12 mx-auto">
                              Reasons for this Product
                            </h5>
                            @for (z of prod.reasons; track z) {
                              <div
                                class="details col-12 px-0 mx-auto {{
                                  z.reasonType.toLowerCase()
                                }}"
                              >
                                <strong class="header">
                                  <img
                                    alt="product reason"
                                    src="{{
                                      './assets/' +
                                        path +
                                        z.reasonType.toLowerCase() +
                                        '.png'
                                    }}"
                                  />
                                  <span [innerHTML]="z.headline"></span>
                                  <span
                                    *hasPermission="
                                      '!RelevanceHideReasonScores'
                                    "
                                  >
                                    | {{ scoreFormat(z.score) }}
                                  </span>
                                </strong>
                                @if (z.explanation) {
                                  <p
                                    class="explanation"
                                    [innerHTML]="z.explanation"
                                  ></p>
                                }
                              </div>
                            }
                          }
                        </div>
                      </div>
                    </li>
                  }
                }
              </ul>
            } @else {
              <div class="product no-products row">
                <mat-icon class="col-1">report</mat-icon>
                <h5 class="col-9 pl-0">
                  Based on the questionnaire input, no strategies are available
                  for this product option.
                </h5>
              </div>
            }
          </div>
        }
      </div>
    }
    @if (
      !loading &&
      (this.products.tempLifeProducts.length > 0 ||
        this.products.permLifeProducts.length > 0)
    ) {
      <div class="charts col-5">
        <h3>Selected Product Mix</h3>
        <slider-charts
          [fundData]="fundData"
          (mixChange)="handleMixChange($event)"
          (cashFlowChange)="handleCashFlowChange($event)"
        ></slider-charts>
        <div class="disclaimer col-12 mt-3 px-0" [innerHTML]="disclaimer">
          NOTICE: The strategies represented are not actual products for sale.
          Graphs displaying rates, premium, death benefit and cash value are for
          educational purposes only and are designed to demonstrate a
          relationship between premiums paid and future values.
        </div>
      </div>
    }
  </div>
</div>
