<mat-card appearance="outlined" class="col-12 stretch top-opps">
  <mat-card-header> Top Opportunities </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Opportunities Available</h4>
      </div>
    }
    @if (state === 'loaded') {
      <table>
        <thead>
          <th class="name">Name</th>
          <th class="suggestion">Suggestion</th>
        </thead>
        <tbody>
          @for (opp of opportunities; track opp) {
            <tr (click)="goToProfile($event, opp.id)">
              <td>{{ opp.name }}</td>
              <td class="suggestion">
                @for (sg of opp.suggestions; track sg; let idx = $index) {
                  @if (idx > 0) {
                    ,
                  }
                  <span class="sugg">
                    <strong>{{ sg.Name }}</strong
                    >&nbsp;<span>{{ sg.Relevance }}</span>
                  </span>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    }
  </mat-card-content>
</mat-card>
