import { Component, HostBinding, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-compact-repeat-section',
  styleUrls: ['form-compactRepeat.component.scss'],
  template: `
    @if (!table) {
      @for (field of field.fieldGroup; track field; let i = $index) {
        <div class="row compact-repeat">
          <formly-group
            [field]="field"
            [class]="'w-95 row formly-form-container'"
          >
          </formly-group>
          <div class="button-container remove clearfix w-5">
            <button
              mat-icon-button
              class="btn btn-danger w-100"
              color="warn"
              type="button"
              (click)="remove(i)"
              ><mat-icon class="mr-2">add_circle</mat-icon></button
            >
          </div>
        </div>
      }
      <div class="button-container clearfix col-4 offset-4 mt-3">
        <button
          mat-button
          class="btn btn-primary w-100"
          color="primary"
          type="button"
          (click)="add()"
          ><mat-icon class="mr-2">add_circle</mat-icon>Add</button
        >
      </div>
    } @else {
      <table class="w-100">
        <thead>
          <tr>
            @for (col of columns; track col.label) {
              <th>{{ col.label }}</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (field of field.fieldGroup; track field) {
            <tr> </tr>
          }
        </tbody>
      </table>
    }
  `,
})
export class FormlyCompactRepeatComponent
  extends FieldArrayType
  implements OnInit
{
  // TODO: add explicit constructor

  @HostBinding('class') classes =
    'w-100 form-section row compact-repeat-container';

  columns = [];
  table = false;
  columnWidth;

  ngOnInit() {
    this.onPopulate(this.field);

    this.table = this.field.fieldArray['props'].layout == 'table';

    this.columns = this.field.fieldArray['fieldGroup'].map(el => {
      return { label: el.props.label, key: el.key };
    });

    this.columnWidth = 100 / this.columns.length;
  }
}
