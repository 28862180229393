import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateHandlingService {
  toUseableString(dateValue, position) {
    const jsDate = new Date(dateValue);
    if (position === 'start') {
      return DateTime.fromJSDate(jsDate)
        .toUTC()
        .startOf('day')
        .toJSDate()
        .toUTCString();
    } else if (position === 'end') {
      return DateTime.fromJSDate(jsDate)
        .plus({ days: 1 })
        .toUTC()
        .startOf('day')
        .toJSDate()
        .toUTCString();
    }
    return DateTime.fromJSDate(jsDate).toUTC().toJSDate().toUTCString();
  }

  toUseableIso(dateValue, position) {
    const jsDate = new Date(dateValue);
    if (position === 'start') {
      return DateTime.fromJSDate(jsDate).startOf('day').toISO();
    } else if (position === 'end') {
      return DateTime.fromJSDate(jsDate).plus({ days: 1 }).toISO();
    }
    return DateTime.fromJSDate(jsDate).toISO();
  }

  thirtyDays(value?) {
    const startDate = DateTime.now(value)
      .minus({ days: 30 })
      .toUTC()
      .startOf('day')
      .toJSDate()
      .toUTCString();
    const endDate = DateTime.now(value)
      .plus({ days: 1 })
      .toUTC()
      .startOf('day')
      .toJSDate()
      .toUTCString();

    return {
      start: startDate,
      end: endDate,
    };
  }
}
