import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-message',
  styleUrls: ['form-message.component.scss'],
  template: `
    @if (props.label) {
      <label
        class="section col-12 {{ to.messageType }}"
        [innerHtml]="props.label"
      ></label>
    }
  `,
})
export class FormlyMessageComponent extends FieldType {}
