<h2 mat-dialog-title>{{ data.dataItem?.unit }}</h2>
<mat-dialog-content>
  @if (detailData) {
    <div class="reports-container">
      <kendo-grid
        class="col-12 mt-3 usage-grid"
        [kendoGridBinding]="detailData.data"
      >
        <ng-template kendoGridToolbarTemplate>
          <kendo-grid-spacer></kendo-grid-spacer>
          <button type="button" kendoGridExcelCommand [svgIcon]="excelSVG">
            Export to Excel
          </button>
          <button kendoGridPDFCommand [svgIcon]="pdfSVG">Export to PDF</button>
        </ng-template>
        @for (col of detailData.headers; track $index) {
          <kendo-grid-column
            [field]="col.DataField"
            [title]="col.Label"
            [hidden]="col.Hidden"
          >
          </kendo-grid-column>
        }
        <kendo-grid-pdf
          fileName="{{ data.dataItem?.unit }}_Actions.pdf"
          [repeatHeaders]="true"
        ></kendo-grid-pdf>
        <kendo-grid-excel
          fileName="{{ data.dataItem?.unit }}_Actions.xlsx"
        ></kendo-grid-excel>
      </kendo-grid>
    </div>
  }
  @if (loading) {
    <app-simple-loading [message]="'Loading Data...'"></app-simple-loading>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
