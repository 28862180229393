<div class="page-container account-grouping-container container">
  <app-breadcrumb [crumbConfig]="crumbConfig"></app-breadcrumb>

  @if (groupId === '') {
    <app-rb-grid
      [gridConfig]="gridConfig"
      [getSetup]="getGridSetup"
      [getData]="getGridData"
    >
      <span class="approval-container">
        <button
          mat-raised-button
          color="primary"
          class="custom-grid-control mr-0 ml-2"
          (click)="createNewGroup()"
        >
          <mat-icon class="mr-2">group_add</mat-icon>New Group
        </button>
      </span>
    </app-rb-grid>
  } @else {
    <app-account-grouping-detail
      [groupId]="groupId"
    ></app-account-grouping-detail>
  }
</div>
