import {
  Component,
  OnInit,
  NgZone,
  ViewChildren,
  OnDestroy,
} from '@angular/core';
import { AnnuityWizardService } from '../../annuity-wizard/services/annuity-wizard-service.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { UnifiedFlowService } from '../unified.service';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Component({
  selector: 'app-aw-comparison',
  templateUrl: './aw-comparison.component.html',
  styleUrls: ['./aw-comparison.component.scss'],
})
export class AwComparisonComponent implements OnInit, OnDestroy {
  @ViewChildren('comparisonGrid') comparisonGrid;
  public hidden: string[] = [];
  gridColumns;
  gridData = {
    data: null,
    headers: null,
    subHead: new Map(),
  };
  ogResp;
  gridLabels = [];
  grids = {};
  id: string;
  hideFields = [
    'MandE',
    'DeferralBonus',
    'Name',
    'StepUpFrequency',
    'FeeCalcType',
    'PortfolioRestrictions',
    'PortfolioRestrictionsSimpleText',
    'EstimatedBenefitBase',
    'EstimatedBenefitPercent',
    'BenefitBaseIncreaseType',
  ];
  hiddenMenuItems = ['DisplayName', 'EstimatedGMWBAtTarget', 'RiderFee'];
  defaultVisible = [
    'DisplayName',
    'EstimatedGMWBAtTarget',
    'RiderName',
    'RiderFee',
    'Age65EstimatedBenefitPercent',
    'DeferralBonusString',
    'PaymentOption',
    'PaymentFrequency',
  ];
  unsubscribe: Subject<any> = new Subject();
  loading = true;
  deferralAge;
  initialPremium;
  annuityLabels = [
    {
      annuityType: 'VA',
      label: 'Variable Annuities with Living Benefits',
    },
    {
      annuityType: 'FIA',
      label: 'Fixed Indexed Annuities with Living Benefits',
    },
    {
      annuityType: 'VIA',
      label: 'Registered Index-Linked Annuities',
    },
    {
      annuityType: 'FA',
      label: 'Fixed Annuities with Living Benefits',
    },
    {
      annuityType: 'DIA',
      label: 'Income Annuities (Deferred)',
    },
    {
      annuityType: 'SPIA',
      label: 'Income Annuities (Immediate)',
    },
  ];

  constructor(
    private aws: AnnuityWizardService,
    private params: ActivatedRoute,
    private ss: SessionStorageService,
    public unfFlowSvc: UnifiedFlowService,
    public prefSrvc: UserPreferencesService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.params.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.id = params.id;
      this.getResults(params.id);
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getResults(profileId) {
    const save = this.unfFlowSvc.getSaving();
    if (save) {
      setTimeout(() => {
        this.getResults(profileId);
      }, 250);
    } else {
      this.aws.getIncomeIterations(profileId).subscribe(resp => {
        this.deferralAge = resp.DeferralAge;
        this.ogResp = resp;
        this.formatGridData(resp);
        this.loading = false;
      });
    }
  }

  formatGridData(data, reset = false) {
    if (data.InitialPremium) {
      this.initialPremium = data.InitialPremium;
    }
    if (data.Annuities.length) {
      data.Annuities.map(annuity => {
        if (!this.gridLabels.includes(annuity.AnnuityType)) {
          this.gridLabels.push(annuity.AnnuityType);
          this.grids[annuity.AnnuityType] = {
            data: annuity.data,
            headers: this.buildGridHeaders(annuity),
            subHead: new Map(),
          };
        }
      });

      if (!reset) {
        if (+data.DeferralAge - +data.CurrentAge > 1) {
          this.annuityLabels.splice(
            this.annuityLabels.findIndex(label => label.annuityType === 'SPIA'),
            1
          );
        } else {
          this.annuityLabels.splice(
            this.annuityLabels.findIndex(label => label.annuityType === 'DIA'),
            1
          );
        }
      }
      this.checkColumnVisibility(reset);
    }
  }

  buildGridHeaders(annuity) {
    const returnedHeaders = [
      {
        DataField: 'DisplayName',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Annuity',
        RespVis: '',
        Sort: true,
        Type: 'string',
      },
      {
        DataField: 'EstimatedGMWBAtTarget',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Minimum Withdrawal by Deferral Age',
        RespVis: '',
        Sort: true,
        Type: 'parent',
        Children: this.buildSubHeaders(annuity, 'EstimatedGMWBAtTarget'),
      },
      {
        DataField: 'InitialPremium',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Initial<br /> Premium',
        RespVis: '',
        Sort: true,
        Type: 'string',
      },
    ];

    if (
      annuity.AnnuityType === 'VA' ||
      annuity.AnnuityType === 'VIA' ||
      annuity.AnnuityType === 'FIA' ||
      annuity.AnnuityType === 'FA'
    ) {
      returnedHeaders.splice(2, 0, {
        DataField: 'RiderName',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Income Rider Name',
        RespVis: '',
        Sort: true,
        Type: 'string',
      });
      returnedHeaders.push(
        {
          DataField: 'RiderFee',
          Filter: true,
          Group: false,
          Hidden: true,
          Label: 'Rider<br /> Fee',
          RespVis: '',
          Sort: true,
          Type: 'perc',
        },
        {
          DataField: 'FeeCalcType',
          Filter: true,
          Group: false,
          Hidden: true,
          Label: this.defineLabel('FeeCalcType', annuity),
          RespVis: '',
          Sort: true,
          Type: 'string',
        },
        {
          DataField: 'DeferralBonusString',
          Filter: true,
          Group: false,
          Hidden: true,
          Label: 'Def. Credit',
          RespVis: '',
          Sort: true,
          Type: 'string',
        }
      );
    }
    if (annuity.AnnuityType === 'VA' || annuity.AnnuityType === 'VIA') {
      returnedHeaders.splice(returnedHeaders.length - 1, 0, {
        DataField: `Age${this.deferralAge}EstimatedBenefitPercent`,
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Withdrawal %',
        RespVis: '',
        Sort: true,
        Type: 'perc',
      });
      returnedHeaders.push({
        DataField: 'StepUpFrequency',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Step-Up<br /> Frequency',
        RespVis: '',
        Sort: true,
        Type: 'string',
      });
    }
    if (
      annuity.AnnuityType === 'FA' ||
      annuity.AnnuityType === 'FIA' ||
      annuity.AnnuityType === 'VA'
    ) {
      returnedHeaders.push({
        DataField: 'BenefitBaseIncreaseType',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Increase<br /> Type',
        RespVis: '',
        Sort: true,
        Type: 'string',
      });
    }
    if (annuity.AnnuityType === 'DIA' || annuity.AnnuityType === 'SPIA') {
      returnedHeaders.splice(returnedHeaders.length - 1, 0, {
        DataField: 'PaymentOption',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Payment Option',
        RespVis: '',
        Sort: true,
        Type: 'string',
      });
      returnedHeaders.push({
        DataField: 'PaymentFrequency',
        Filter: true,
        Group: false,
        Hidden: true,
        Label: 'Payment Frequency',
        RespVis: '',
        Sort: true,
        Type: 'string',
      });
    }
    returnedHeaders.push({
      DataField: 'Link',
      Filter: true,
      Group: false,
      Hidden: true,
      Label: 'EDJ<br /> Marketing',
      RespVis: '',
      Sort: true,
      Type: 'link',
    });

    return returnedHeaders;
  }

  buildSubHeaders(annuity, subLabel) {
    const dataKeys = Object.keys(annuity.data[0]);
    const subKeys = [];
    dataKeys.map(dataKey => {
      if (dataKey.includes(subLabel)) {
        const subKeyLabel = dataKey.replace(subLabel, '').split('e').join('e ');
        if (
          annuity.AnnuityType !== 'DIA' &&
          annuity.AnnuityType !== 'SPIA' &&
          (subLabel === 'EstimatedGMWBAtTarget' ||
            (subLabel === 'EstimatedBenefitBase' &&
              subKeyLabel === 'Age ' + this.deferralAge))
        ) {
          subKeys.push({
            DataField: dataKey,
            Filter: true,
            Group: false,
            Hidden: false,
            Label: subKeyLabel,
            RespVis: '',
            Sort: true,
            Type: 'curr',
          });
        }

        if (
          (annuity.AnnuityType === 'DIA' || annuity.AnnuityType === 'SPIA') &&
          subLabel === 'EstimatedGMWBAtTarget' &&
          subKeyLabel === 'Age ' + this.deferralAge
        ) {
          subKeys.push({
            DataField: dataKey,
            Filter: true,
            Group: false,
            Hidden: false,
            Label: subKeyLabel,
            RespVis: '',
            Sort: true,
            Type: 'curr',
          });
        }

        if (
          annuity.AnnuityType === 'DIA' &&
          ((subLabel === 'EstimatedGMWBAtTarget' &&
            subKeyLabel === `Age ${+this.deferralAge + 1}`) ||
            (subLabel === 'EstimatedGMWBAtTarget' &&
              subKeyLabel === `Age ${+this.deferralAge + 2}`))
        ) {
          subKeys.push({
            DataField: dataKey,
            Filter: true,
            Group: false,
            Hidden: false,
            Label: subKeyLabel,
            RespVis: '',
            Sort: true,
            Type: 'curr',
          });
        }
      }
    });

    return subKeys;
  }

  defineLabel(dataField, dataGroup) {
    if (
      (dataField === 'FeeCalcType' && dataGroup.AnnuityType === 'VA') ||
      (dataField === 'FeeCalcType' && dataGroup.AnnuityType === 'VIA') ||
      (dataField === 'FeeCalcType' && dataGroup.AnnuityType === 'FA') ||
      (dataField === 'FeeCalcType' && dataGroup.AnnuityType === 'FIA')
    ) {
      return 'Rider Fee<br /> Based On';
    }
    return '';
  }

  checkColumnVisibility(reset) {
    if (!reset) {
      this.prefSrvc.getColumns().subscribe(resp => {
        this.gridLabels.map(dataGroup => {
          if (this.grids[dataGroup].headers) {
            this.grids[dataGroup].headers.map(colHeader => {
              this.grids[dataGroup].data.map(cellData => {
                if (
                  cellData[colHeader.DataField] &&
                  cellData[colHeader.DataField] !== '' &&
                  !this.hideFields.includes(colHeader.DataField)
                ) {
                  colHeader.Hidden = false;
                } else if (
                  colHeader.Type === 'parent' &&
                  colHeader.Children.length &&
                  !this.hideFields.includes(colHeader.DataField)
                ) {
                  colHeader.Hidden = false;
                }
              });

              if (
                (resp?.awComparisonVis &&
                  resp.awComparisonVis[dataGroup]?.length > 1 &&
                  resp.awComparisonVis[dataGroup]?.includes(
                    colHeader.DataField
                  )) ||
                this.hiddenMenuItems.includes(colHeader.DataField)
              ) {
                colHeader.Hidden = false;
              } else {
                colHeader.Hidden = true;
              }

              if (
                (!resp || resp?.awComparisonVis[dataGroup]?.length <= 0) &&
                this.defaultVisible.includes(colHeader.DataField)
              ) {
                colHeader.Hidden = false;
              }
            });
          }
        });

        this.fitColumns();
      });
    } else {
      this.gridLabels.map(dataGroup => {
        if (this.grids[dataGroup].headers) {
          this.grids[dataGroup].headers.map(colHeader => {
            this.grids[dataGroup].data.map(cellData => {
              if (
                cellData[colHeader.DataField] &&
                cellData[colHeader.DataField] !== '' &&
                !this.hideFields.includes(colHeader.DataField)
              ) {
                colHeader.Hidden = false;
              } else if (
                colHeader.Type === 'parent' &&
                colHeader.Children.length &&
                !this.hideFields.includes(colHeader.DataField)
              ) {
                colHeader.Hidden = false;
              } else {
                colHeader.Hidden = true;
              }
            });
          });
        }
      });

      this.fitColumns();
    }
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.comparisonGrid.map(grid => grid.autoFitColumns());
      });
  }

  saveUserDefinedColumns() {
    const visibleColumns = {};

    this.gridLabels.map(gridKey => {
      const columns = [];
      this.grids[gridKey].headers.map(header => {
        if (!header.Hidden) {
          columns.push(header.DataField);
        }
      });
      visibleColumns[gridKey] = columns;
    });
    this.prefSrvc.saveColumns({ awComparisonVis: visibleColumns }).subscribe();
  }

  toggleVisible(ev, dataGroup, dataField) {
    ev.stopPropagation();
    ev.preventDefault();

    if (dataField === 'all') {
      if (!this.allSelected(dataGroup)) {
        this.toggleAll(dataGroup, false);
      } else {
        this.toggleAll(dataGroup, true);
      }
    } else {
      const headerIndex = this.grids[dataGroup].headers.findIndex(
        header => header.DataField === dataField
      );
      this.grids[dataGroup].headers[headerIndex].Hidden =
        !this.grids[dataGroup].headers[headerIndex].Hidden;
    }
    this.saveUserDefinedColumns();
    this.fitColumns();
  }

  allSelected(dataGroup) {
    return (
      this.grids[dataGroup]?.headers.findIndex(
        header => header.Hidden === true
      ) === -1
    );
  }

  toggleAll(dataGroup, value) {
    this.grids[dataGroup].headers.map((header, index) => {
      if (!this.hiddenMenuItems.includes(header.DataField)) {
        this.grids[dataGroup].headers[index].Hidden = value;
      }
    });
  }

  stripTags(label) {
    return label.replace(/(<([^>]+)>)/gi, '');
  }

  resetColumns() {
    this.formatGridData(this.ogResp, true);
    this.saveUserDefinedColumns();
  }
}
