<div class="page-container asset-container container se-container">
  <app-breadcrumb [crumb]="'se-details'"></app-breadcrumb>
  @if (need) {
    <h1 class="header col-12">
      {{ need.ShortName }} Need Details
      <div class="buttons">
        @if (currentApp === 'se') {
          <button
            class="no-thanks bridge-actions"
            mat-icon-button
            matTooltip="No Thank You"
            matTooltipPosition="above"
            (click)="
              bridgeAction('noThanks', id, idx); $event.stopPropagation()
            "
            color="primary"
          >
            <mat-icon>thumb_down</mat-icon>
          </button>
        }
        @if (currentApp === 'se') {
          <button
            class="follow-up bridge-actions"
            mat-icon-button
            matTooltip="Follow Up"
            matTooltipPosition="above"
            (click)="bridgeAction('followUp', id, idx)"
            color="primary"
          >
            <mat-icon>update</mat-icon>
          </button>
        }
        @if (currentApp === 'se') {
          <button
            class="like bridge-actions"
            mat-icon-button
            matTooltip="I Like It"
            matTooltipPosition="above"
            (click)="bridgeAction('like', id, idx); $event.stopPropagation()"
            color="primary"
          >
            <mat-icon>thumb_up</mat-icon>
          </button>
        }
      </div>
    </h1>
  }

  @if (need) {
    <div class="w-100 toolbar clearfix">
      <button mat-button class="back" (click)="back()" color="primary">
        <mat-icon class="mat-icon material-icons">arrow_back</mat-icon>Back to
        Results
      </button>
      <button mat-button class="header-button" (click)="report()">
        <mat-icon>insert_drive_file</mat-icon>
        <span>Download Report</span>
      </button>
    </div>
  }
  @if (need) {
    <div class="needs-container">
      <div class="col-12 row pr-0">
        <div class="col-6 content pr-5">
          <p class="description" [innerHTML]="need.ShortDescription"></p>
          <ul class="details">
            @for (r of need.reasons; track r) {
              <ngTemplate>
                <div class="col-12 reason {{ r.reasonType.toLowerCase() }}">
                  <strong class="header">
                    <img
                      src="{{
                        './assets/' + path + r.reasonType.toLowerCase() + '.png'
                      }}"
                    />
                    <span [innerHTML]="r.headline"></span>
                    <span *hasPermission="'!RelevanceHideReasonScores'">
                      | {{ r.score / 10 }}
                    </span>
                  </strong>
                  @if (r.explanation) {
                    <p class="explanation" [innerHTML]="r.explanation"></p>
                  }
                </div>
              </ngTemplate>
            }
          </ul>
        </div>
        <div class="col-6 chart-container">
          @if (need.formattedChart) {
            <div [chart]="need.formattedChart"></div>
          }
          @if (need.formattedChart && need.ChartLegend) {
            <div class="chart-legend">
              @if (need.ChartLegend.header) {
                <table>
                  <thead>
                    <tr>
                      @for (th of need.ChartLegend.header; track th) {
                        <th>{{ th }}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    @for (tr of need.ChartLegend.data; track tr) {
                      <tr>
                        @for (td of tr; track td) {
                          <td>{{ td }}</td>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              } @else {
                @for (c of need.ChartLegend; track c) {
                  <div>
                    <strong>{{ c.label }}</strong> {{ c.value }}
                  </div>
                }
              }
            </div>
          }
          <div class="info-container">
            @if (!need.formattedChart) {
              <div class="infographic w-100 {{ need.ScoreClass }}">
                <div class="inner">
                  <div
                    class="detail"
                    [ngClass]="{ smaller: need.Detail.length > 6 }"
                  >
                    {{ need.Detail }}
                  </div>
                  <div class="detailName">{{ need.detailname }}</div>
                </div>
              </div>
            }
          </div>
          @if (currentApp !== 'se') {
            <h5>Solutions that may help address this need:</h5>
          }
          @if (currentApp !== 'se') {
            <div class="products clearfix">
              <ul>
                @for (
                  p of need.products ? need.products.slice(0, 3) : [];
                  track p
                ) {
                  <li class="product {{ p.scoreClass }}">
                    <h4>{{ p.name }} </h4>
                    <div class="sub">{{ p.subhead }}</div>
                    <div
                      class="rating-line {{ need.ScoreClass }}"
                      [style.width.%]="p.score / 10"
                    ></div>
                  </li>
                }
              </ul>
              <button
                mat-button
                class="moreProducts"
                color="primary"
                (click)="productDetails()"
              >
                <span class="collapsed-content">
                  See All Products
                  <mat-icon>arrow_forward</mat-icon>
                </span>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>
<ngx-loading [show]="loading"></ngx-loading>
