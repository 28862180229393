import { Component, Input } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyPipe, PercentPipe } from '@angular/common';

@Component({
  selector: 'app-product-detail-tab',
  templateUrl: './product-detail-tab.component.html',
  styleUrls: ['./product-detail-tab.component.scss'],
  standalone: true,
  imports: [CurrencyPipe, MatTabsModule, MatIconModule, PercentPipe],
})
export class ProductDetailTabComponent {
  @Input() label;
  @Input() dataItem;
  @Input() tab;
  @Input() noResultsType;

  findValue(val, list) {
    if (val || val === 0 || (!val && typeof val == 'boolean')) {
      const display = list.find(x => x.value == val)
        ? list.find(x => x.value == val).label
        : val;
      return display;
    }
    return null;
  }
}
