export const caseHistoryKey = [
  { label: 'Case ID', varName: 'CRID' },
  { label: 'Open Date', varName: 'StatusDate', valueMask: 'date' },
  { label: 'Status', varName: 'StatusName' },
];
export const sideKey = { B: 'Buy', S: 'Sell' };
export const statusKey = {
  PEN: 'Pending',
  APP: 'Approved',
  SYS: 'System Approved',
  BLK: 'Bulk Approved',
  INP: 'In Process',
  REJ: 'Rejected',
  REO: 'Reopened',
  REV: 'Reviewed',
  tst: 'Test',
};
export const scoreClassKey = {
  Positive: 'check_circle',
  Warning: 'error',
  Negative: 'remove_circle',
};
export const yesNoKey = {
  Y: 'Yes',
  N: 'No',
};
export const entityKey = {
  P: 'Person',
  E: 'Entity',
  O: 'Trust',
};
