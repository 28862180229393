import { Component } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-toggle',
  styleUrls: ['form-toggle.component.scss'],
  template: `
    @if (props.label) {
      <label class="section" class="w-100" [innerHTML]="props.label"></label>
    }
    @if (props.help) {
      <mat-icon
        class="help-icon"
        matTooltip="{{ to.help }}"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
      >
        help
      </mat-icon>
    }
    <mat-slide-toggle
      [formControl]="formControl"
      [formlyAttributes]="field"
      class="w-100"
      [required]="props.required"
    ></mat-slide-toggle>
    @if (
      formControl.hasError('required') && !formControl.hasError('regRequired')
    ) {
      <mat-error>This field is required</mat-error>
    }
    @if (formControl.hasError('regRequired')) {
      <mat-error>
        This field is needed for suitability review. You can opt out below if
        the applicant refuses to provide the information.
      </mat-error>
    }
  `,
})
export class FormlyToggleComponent extends FieldType {}
