<div class="page-container search-container container">
  <div class="row w-85">
    <app-breadcrumb [crumb]="'se-opportunities'"></app-breadcrumb>
    <h1 class="header col-12">Find Opportunities</h1>
    <div class="filter-container w-100">
      @if (!hideTiles) {
        <div class="row justify-content-between search-tile-group">
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('suggestion')">
              <h4><mat-icon>storage</mat-icon>By Suggestion</h4>
              <p
                >This search finds clients and prospects for a specific
                suggestion(s).</p
              >
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('timeSensitive')">
              <h4><mat-icon>timer</mat-icon>Time Sensitive</h4>
              <p
                >Suggestions that are driven by deadlines and may be lost if not
                contacted.</p
              >
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('highScore')">
              <h4><mat-icon>assessment</mat-icon>High Score</h4>
              <p>Find suggestions that have the highest relevance score.</p>
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('callList')">
              <h4><mat-icon>contacts</mat-icon>Call List</h4>
              <p
                >Find the clients and prospects you have added to your call
                list.</p
              >
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('callBack')">
              <h4> <mat-icon>phone_callback</mat-icon>Call Back Date </h4>
              <p
                >Search by follow-up date. Use a start and end date to filter
                the list for a given day, week or month.</p
              >
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('opp')">
              <h4
                ><mat-icon>perm_contact_calendar</mat-icon>Best
                Opportunities</h4
              >
              <p
                >Use this search to find the clients and prospects with the most
                high scoring suggestions.</p
              >
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('lastAction')">
              <h4><mat-icon>event</mat-icon>Last Action</h4>
              <p
                >Retrieve clients and prospects for a given action such as: I
                like it or Follow-up.</p
              >
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('name')">
              <h4><mat-icon>account_circle</mat-icon>Name</h4>
              <p
                >Look up a client or prospect by first and last name. You can
                use partial names.</p
              >
            </div>
          </div>
          <div class="col-6 col-md-4 search-tile">
            <div class="inner" (click)="setSearchType('demographics')">
              <h4> <mat-icon>public</mat-icon>Demographics </h4>
              <p
                >Look up a client or prospect by first and last name. You can
                use partial names.</p
              >
            </div>
          </div>
        </div>
      }
      @if (hideTiles) {
        <div class="search-button-types w-100 row">
          <mat-button-toggle-group [(ngModel)]="searchType">
            <mat-button-toggle
              value="suggestion"
              (click)="setSearchType('suggestion')"
            >
              <mat-icon>storage</mat-icon>By Suggestion
            </mat-button-toggle>
            <mat-button-toggle
              value="timeSensitive"
              (click)="setSearchType('timeSensitive')"
            >
              <mat-icon>timer</mat-icon>Time Sensitive
            </mat-button-toggle>
            <mat-button-toggle
              value="highScore"
              (click)="setSearchType('highScore')"
            >
              <mat-icon>assessment</mat-icon>High Score
            </mat-button-toggle>
            <mat-button-toggle
              value="callList"
              (click)="setSearchType('callList')"
            >
              <mat-icon>contacts</mat-icon>Call List
            </mat-button-toggle>
            <mat-button-toggle
              value="callBack"
              (click)="setSearchType('callBack')"
            >
              <mat-icon>phone_callback</mat-icon>Call Back Date
            </mat-button-toggle>
            <mat-button-toggle value="opp" (click)="setSearchType('opp')">
              <mat-icon>perm_contact_calendar</mat-icon>Best Opportunities
            </mat-button-toggle>
            <mat-button-toggle
              value="lastAction"
              (click)="setSearchType('lastAction')"
            >
              <mat-icon>event</mat-icon>Last Action
            </mat-button-toggle>
            <mat-button-toggle value="name" (click)="setSearchType('name')">
              <mat-icon>account_circle</mat-icon>Name
            </mat-button-toggle>
            <mat-button-toggle
              value="demographics"
              (click)="setSearchType('demographics')"
            >
              <mat-icon>public</mat-icon>Demographics
            </mat-button-toggle>
          </mat-button-toggle-group>
          <div class="w-100 hierarchy">
            @if (selectedUnit || selectedUser || selectedRepCode) {
              <p>
                <strong>Searching as: </strong>
                @if (selectedUnit && !selectedUser && !selectedRepCode) {
                  <ng-container class="search-as"
                    >Unit: {{ selectedUnit.name }}
                  </ng-container>
                }
                @if (selectedUser && !selectedRepCode) {
                  <ng-container class="search-as"
                    >User: {{ selectedUser.name }}</ng-container
                  >
                }
                @if (selectedRepCode) {
                  <ng-container class="search-as"
                    >Rep Code: {{ selectedRepCode.name }}</ng-container
                  >
                }
              </p>
            }
            @if (!(selectedUnit || selectedUser || selectedRepCode)) {
              <button mat-button (click)="setSearchType(searchType)">
                <mat-icon>search</mat-icon>Change Search Filters
              </button>
            }
            @if (selectedUnit || selectedUser || selectedRepCode) {
              <button mat-button (click)="clearHierarchy()" class="clear">
                <mat-icon>clear</mat-icon>
              </button>
            }
            @if (selectedUnit || selectedUser || selectedRepCode) {
              <button mat-button (click)="setSearchType(searchType)">
                <mat-icon>search</mat-icon>
              </button>
            }
          </div>
        </div>
      }
    </div>
    @if (ogData && hideTiles) {
      @if (searchType === 'demographics' && searchModel) {
        <p>
          <span><strong>Demographics search based on </strong></span>
          @if (searchModel.postalCode) {
            <span>Postal Code: {{ searchModel.postalCode }}</span>
          }
          @if (searchModel.city) {
            <span>City: {{ searchModel.city }}</span>
          }
          @if (searchModel.ageFrom || searchModel.ageTo) {
            <span
              >Age range: {{ searchModel.ageFrom }} to
              {{ searchModel.ageTo }}</span
            >
          }
          @if (searchModel.incomeFrom || searchModel.incomeTo) {
            <span
              >Income range: {{ searchModel.incomeFrom }} to
              {{ searchModel.incomeTo }}</span
            >
          }
          @if (searchModel.assetsFrom || searchModel.assetsTo) {
            <span
              >Assets range: {{ searchModel.assetsFrom }} to
              {{ searchModel.assetsTo }}</span
            >
          }
          @if (searchModel.productsFrom || searchModel.productsTo) {
            <span
              >Products range: {{ searchModel.productsFrom }} to
              {{ searchModel.productsTo }}</span
            >
          }
          @if (searchModel.profileFrom || searchModel.profileTo) {
            <span
              >Client Profile range: {{ searchModel.profileFrom }} to
              {{ searchModel.profileTo }}</span
            >
          }
          @if (searchModel.source) {
            <span>Tax Year: {{ searchModel.source }}</span>
          }
          @if (
            searchModel.marketCategory &&
            (searchModel.marketCategory.youngConsumers ||
              searchModel.marketCategory.preRetirees ||
              searchModel.marketCategory.retirees ||
              searchModel.marketCategory.smallBusiness ||
              searchModel.marketCategory.professionals ||
              searchModel.marketCategory.others)
          ) {
            <span
              >Market Category
              @if (searchModel.marketCategory.youngConsumers) {
                <span>{{ searchModel.marketCategory.youngConsumers }},</span>
              }
              @if (searchModel.marketCategory.preRetirees) {
                <span>{{ searchModel.marketCategory.preRetirees }},</span>
              }
              @if (searchModel.marketCategory.retirees) {
                <span>{{ searchModel.marketCategory.retirees }},</span>
              }
              @if (searchModel.marketCategory.smallBusiness) {
                <span>{{ searchModel.marketCategory.smallBusiness }},</span>
              }
              @if (searchModel.marketCategory.professionals) {
                <span>{{ searchModel.marketCategory.professionals }},</span>
              }
              @if (searchModel.marketCategory.others) {
                <span>{{ searchModel.marketCategory.others }}</span>
              }
            </span>
          }
        </p>
      }
      <kendo-grid
        class="col-12 search-grid"
        #resultsGrid="kendoGrid"
        [kendoGridBinding]="ogData.data"
        [pageSize]="pageSize"
        [pageable]="pageableData"
        [sortable]="true"
        [filterable]="'menu'"
        [columnMenu]="true"
        [filter]="filter"
        [selectable]="{ mode: 'single' }"
        (selectionChange)="viewProfile($event)"
        (pageChange)="pageChange($event)"
      >
        <ng-template kendoGridToolbarTemplate>
          <button
            class="export"
            [ngClass]="{ disabled: !selectedProfiles.length }"
            color="primary"
            kendoGridExcelCommand
            [disabled]="!selectedProfiles.length"
          >
            <mat-icon>list_alt</mat-icon>Export to Excel
          </button>
          @if (ogData && searchType) {
            <button mat-button class="clear-search" (click)="clearSearch()">
              <mat-icon>clear</mat-icon>Clear Search
            </button>
          }
          <div class="bulk">
            <mat-form-field>
              <mat-select
                placeholder="Perform Bulk Action"
                [(ngModel)]="bulkAction"
                name="bulkAction"
              >
                <mat-option value="2300">I Like it</mat-option>
                <mat-option value="2200">No Thanks</mat-option>
                <mat-option value="addToList">Add to Call List</mat-option>
                <mat-option value="removeFromList"
                  >Remove From Call List</mat-option
                >
                <mat-option value="clientReport"
                  >Download Client Report</mat-option
                >
                <mat-option value="excel">Expanded Data Excel</mat-option>
              </mat-select>
            </mat-form-field>
            <button
              mat-button
              (click)="executeBulk()"
              [disabled]="!selectedProfiles.length"
              ><mat-icon>play_circle_filled</mat-icon>Execute</button
            >
          </div>
        </ng-template>
        <kendo-grid-column
          [columnMenu]="false"
          [sortable]="false"
          [filterable]="false"
          [groupable]="false"
          [class]="'check-center'"
          [width]="50"
        >
          <ng-template kendoGridHeaderTemplate>
            <mat-checkbox
              (change)="selectAll($event)"
              color="primary"
              [(ngModel)]="allSelected"
            ></mat-checkbox>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <mat-checkbox
              class="k-grid-ignore-click"
              (change)="selectRow($event, dataItem)"
              color="primary"
              [(ngModel)]="dataItem.slctd"
            ></mat-checkbox>
          </ng-template>
        </kendo-grid-column>
        <!-- Call List, Prospect and Follow up flag. -->
        <kendo-grid-column
          [columnMenu]="false"
          [sortable]="false"
          [filterable]="false"
          [groupable]="false"
          class="indicators"
          [width]="92"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <mat-icon
              class="status-indicator"
              [ngClass]="{
                active:
                  dataItem.IsInCallList === '1' ||
                  dataItem.IsInCallList === 'True'
              }"
              matTooltip="In Call List"
              >contacts</mat-icon
            >
            <mat-icon
              class="status-indicator"
              [ngClass]="{
                active:
                  dataItem.IsProspect === '1' || dataItem.IsProspect === 'True'
              }"
              matTooltip="Is Prospect"
              >person_outline</mat-icon
            >
            <mat-icon
              class="status-indicator"
              [ngClass]="{
                active:
                  dataItem.followupflag === '1' ||
                  dataItem.followupflag === 'True'
              }"
              matTooltip="Follow Up"
              >update</mat-icon
            >
          </ng-template>
        </kendo-grid-column>
        @for (column of gridColumns; track column) {
          <kendo-grid-column
            [field]="column.DataField"
            [hidden]="isHidden(column.DataField)"
            [title]="column.Label"
            [sortable]="column.Sort"
            [filterable]="column.Filter"
            [media]="column.RespVis"
          ></kendo-grid-column>
        }
        <kendo-grid-excel
          fileName="Opportunities.xlsx"
          [fetchData]="exportExcelData"
        ></kendo-grid-excel>
      </kendo-grid>
    }
  </div>
</div>
<ngx-loading [show]="loading"></ngx-loading>
