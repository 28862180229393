<div
  class="page-container questionnaire-container container {{
    formContext.type
  }}-container"
  id="scrollContainer"
>
  <div class="questions-container row">
    @if (model['UnitQuestions_PageHeadline']) {
      <span class="page-message">
        {{ model['UnitQuestions_PageHeadline'] }}
      </span>
    }
    <div class="w-100">
      <form [formGroup]="form" (ngSubmit)="saveForm(model)">
        <formly-form
          #questionnaire
          [fields]="fields"
          [model]="model"
          [form]="form"
          [ngClass]="{
            'col-12 row': formContext.type !== 'unified',
            'w-100': formContext.type === 'unified'
          }"
          class="{{ formContext.type }}"
          (modelChange)="modelUpdate($event)"
        ></formly-form>
        <div class="col-9 error">
          @if (!form.valid && submitted && checkCustomError()) {
            @for (msg of customMessages; track msg) {
              <p>
                <mat-icon>warning</mat-icon><span>{{ msg }}</span>
              </p>
            }
          }
        </div>
        <div class="col-12 col-lg-9 pt-4 opt-out">
          @if (
            ((model['LifeWizard_AssessmentType'] === '2' ||
              model['LifeWizard_AssessmentType'] === 2) &&
              submitted &&
              formErrors.includes('regRequired')) ||
            ((model['LifeWizard_AssessmentType'] === '2' ||
              model['LifeWizard_AssessmentType'] === 2) &&
              model['regSkipped']) ||
            ((model['AnnuityWizard_AssessmentType'] === '2' ||
              model['AnnuityWizard_AssessmentType'] === 2) &&
              submitted &&
              formErrors.includes('regRequired')) ||
            ((model['AnnuityWizard_AssessmentType'] === '2' ||
              model['AnnuityWizard_AssessmentType'] === 2) &&
              model['regSkipped'])
          ) {
            <p>
              <mat-checkbox
                [(ngModel)]="model['regSkipped']"
                [ngModelOptions]="{ standalone: true }"
                (change)="handleRegClick()"
              ></mat-checkbox>
              <span class="opt-out-message" [innerHTML]="optOutMessage"></span>
            </p>
          }
        </div>
        <div class="buttons col-12 row" #buttons>
          @if (!loading && !sections && !formContext.type) {
            <button
              mat-raised-button
              type="submit"
              color="accent"
              class="main-button"
              >{{ nextButtonText }}
              <mat-icon>arrow_forward</mat-icon>
            </button>
          }
        </div>
        @if (debug) {
          <pre>{{ model | json }}</pre>
        }
      </form>
    </div>
  </div>
  @if (context && context.type !== 'unified') {
    <ngx-loading [show]="loading"></ngx-loading>
  }
</div>
