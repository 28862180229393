<app-utility-apps [utilityApps]="utilityApps"></app-utility-apps>

<div class="page-container rep-review-container container">
  <app-breadcrumb [crumbConfig]="crumbConfig"></app-breadcrumb>

  @if (repId === '') {
    <app-rb-grid
      [gridConfig]="gridConfig"
      [getSetup]="getGridSetup"
      [getData]="getGridData"
    ></app-rb-grid>
  } @else {
    <app-rep-review-detail [repId]="repId"></app-rep-review-detail>
  }
</div>
