<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    Fixed Annuities
    <button mat-button (click)="load()" class="header-button download-link">
      <mat-icon>cloud_download</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'loaded' || state === 'none') {
      <span>
        <div mat-dialog-content class="dialog-container">
          <div class="row">
            <mat-form-field class="col-2">
              <mat-label>Rows</mat-label>
              <select
                matNativeControl
                (change)="updateRowCount($event.target.value)"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
            </mat-form-field>
            <mat-form-field class="col-3">
              <input
                matInput
                [(ngModel)]="startDate"
                placeholder="Start Date MM-DD-YYYY"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <input
                matInput
                [(ngModel)]="endDate"
                placeholder="End Date MM-DD-YYYY"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <input matInput [(ngModel)]="numDays" placeholder="Num Days" />
            </mat-form-field>
            <div class="col-1">
              @if (state === 'loaded' || state === 'none') {
                <button
                  mat-button
                  (click)="timespanDownload()"
                  class="header-button download-link"
                >
                  <mat-icon>save</mat-icon>
                </button>
              }
            </div>
          </div>
        </div>
      </span>
    }
    @if (state === 'loaded' || state === 'none') {
      <button
        mat-button
        (click)="download()"
        class="header-button download-link"
      >
        <mat-icon>save</mat-icon>
      </button>
    }
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Annuities Available</h4>
      </div>
    }
    @if (state === 'error') {
      <strong>Error Loading Rates</strong>
    }
    @if (expiring) {
      <strong>Expiring Fixed Annuity Rates</strong>
    }
    @if (expiring) {
      <table>
        <thead>
          <th>Carrier</th>
          <th>Carrier Code</th>
          <th>ID</th>
          <th>Cusip</th>
          <th>Product</th>
          <th>Version</th>
          <th>Term</th>
          <th>BaseRate</th>
          <th>Guarantee Length</th>
          <th>Bonus</th>
          <th>PreMin</th>
          <th>PremMax</th>
          <th>ROP</th>
          <th>Effective Date</th>
          <th>Expiration</th>
        </thead>
        <tbody>
          @for (a of expiring; track a) {
            <tr
              [className]="
                GetClass(a['Rate Effective Date'], a['Rate Deprecate Date'])
              "
            >
              <td>{{ a['Carrier Name'] }}</td>
              <td>{{ a['Carrier Code'] }}</td>
              <td>{{ a['id'] }}</td>
              <td>{{ a['Cusip'] }}</td>
              <td>{{ a['Product'] }}</td>
              <td>{{ a['Version'] }}</td>
              <td>{{ a['Term'] }}</td>
              <td>{{ a['Base Rate'] }}</td>
              <td>{{ a['Guarantee Length'] }}</td>
              <td>{{ a['Bonus Rate'] }}</td>
              <td>{{ a['Premium Range Min'] }}</td>
              <td>{{ a['Premium Range Max'] }}</td>
              <td>{{ a['ROP'] }}</td>
              <td>{{ a['Rate Effective Date'] }}</td>
              <td>{{ a['Rate Deprecate Date'] }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
    @if (updating) {
      <strong>Fixed Annuity Rates Updating</strong>
    }
    @if (updating) {
      <table>
        <thead>
          <th>Carrier</th>
          <th>Carrier Code</th>
          <th>ID</th>
          <th>Cusip</th>
          <th>Product</th>
          <th>Version</th>
          <th>Term</th>
          <th>BaseRate</th>
          <th>Guarantee Length</th>
          <th>Bonus</th>
          <th>PreMin</th>
          <th>PremMax</th>
          <th>ROP</th>
          <th>Effective Date</th>
          <th>Expiration</th>
        </thead>
        <tbody>
          @for (a of updating; track a) {
            <tr
              [className]="
                GetClass(a['Rate Effective Date'], a['Rate Deprecate Date'])
              "
            >
              <td>{{ a['Carrier Name'] }}</td>
              <td>{{ a['Carrier Code'] }}</td>
              <td>{{ a['id'] }}</td>
              <td>{{ a['Cusip'] }}</td>
              <td>{{ a['Product'] }}</td>
              <td>{{ a['Version'] }}</td>
              <td>{{ a['Term'] }}</td>
              <td>{{ a['Base Rate'] }}</td>
              <td>{{ a['Guarantee Length'] }}</td>
              <td>{{ a['Bonus Rate'] }}</td>
              <td>{{ a['Premium Range Min'] }}</td>
              <td>{{ a['Premium Range Max'] }}</td>
              <td>{{ a['ROP'] }}</td>
              <td>{{ a['Rate Effective Date'] }}</td>
              <td>{{ a['Rate Deprecate Date'] }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
  </mat-card-content>
</mat-card>
