<div class="page-container container unified">
  <div class="row">
    <div class="col-12">
      @if (!rights?.includes('DropdownReportMenu') && !hasSummaryModule) {
        <button
          color="primary"
          mat-raised-button
          class="print-button pull-right mb-3"
          (click)="report()"
        >
          <mat-icon>insert_drive_file</mat-icon>
          <span>{{ printText }}</span>
        </button>
      }
    </div>
  </div>
  <div class="row">
    <div class="products-container col">
      <div class="product-container">
        <div class="row">
          <h2 class="mb-2 col"
            >Validated Product{{ validateProducts.length > 1 ? 's' : '' }}</h2
          >
          <app-print-dropdown
            class="print-button"
            [params]="{ profile: id, color: 'primary' }"
            (standardReport)="report($event)"
            *hasPermission="'DropdownReportMenu'"
          ></app-print-dropdown>
        </div>
        <ul class="products col-12">
          @for (prod of validateProducts; track prod) {
            <li class="col-12 product validated expanded {{ prod.ScoreClass }}">
              <div class="col-12 row">
                <ng-container *hasPermission="'BridgeNotes'">
                  @if (
                    prod.vars['CurrentBridgeNote^Required'].raw[0] === 2 ||
                    (prod.vars['CurrentBridgeNote^Required'].raw[0] === 3 &&
                      prod.negativeReason)
                  ) {
                    <div
                      class="notes-notification"
                      [ngClass]="{ valid: prod.notesValid }"
                    >
                      @if (prod.notesValid) {
                        <mat-icon>thumb_up</mat-icon>Required Notes Entered for
                        This Item
                      } @else {
                        <mat-icon>error</mat-icon>Notes Required for This Item
                      }
                    </div>
                  }
                </ng-container>
                <div class="col-11 content">
                  @if (prod.ProductTypeHeader) {
                    <div
                      class="type-indicator {{
                        prod.ProductTypeHeader.toLowerCase()
                      }}"
                    >
                      {{ prod.ProductTypeHeader }}</div
                    >
                  }
                  <div class="score-icon {{ prod.ScoreClass }}">
                    <ng-container *hasPermission="'RelevanceBridgeIcon'">
                      @if (prod.ScoreClass === 'positive') {
                        <mat-icon>check_circle</mat-icon>
                      }
                      @if (prod.ScoreClass === 'neutral') {
                        <mat-icon>error</mat-icon>
                      }
                      @if (prod.ScoreClass === 'negative') {
                        <mat-icon>remove_circle</mat-icon>
                      }
                    </ng-container>
                  </div>
                  <h4>
                    <span class="product-name">
                      {{ prod.ShortName }}
                      <span
                        class="product-score"
                        *hasPermission="'!RelevanceHideBridgeScore'"
                      >
                        | {{ scoreFormat(prod.score) }}</span
                      >
                    </span>
                  </h4>
                </div>
                <div class="col-1 button">
                  <button
                    class="collapse-button"
                    mat-icon-button
                    (click)="expand($event)"
                  >
                    <span class="collapsed-content">
                      <mat-icon>expand_circle_down</mat-icon>
                    </span>
                    <span class="expanded-content">
                      <mat-icon>expand_circle_down</mat-icon>
                    </span>
                  </button>
                </div>
              </div>
              <div class="details-container">
                @if (prod.ComplianceChart) {
                  <app-sub-item-chart
                    [data]="prod.ComplianceChart"
                    [chartTitle]="'Regulation Review'"
                  ></app-sub-item-chart>
                }
                <h5 class="col-12">Reasons for this Product</h5>
                <div class="reasons-container w-100">
                  @for (z of prod.reasons; track z) {
                    <div class="details col-6 {{ z.reasonType.toLowerCase() }}">
                      <strong class="header">
                        @if (z.reasonType) {
                          <img
                            src="{{
                              './assets/' +
                                path +
                                z.reasonType.toLowerCase() +
                                '.png'
                            }}"
                            [alt]="z.reasontType"
                          />
                        }
                        <span [innerHtml]="z.headline"></span>
                        <span *hasPermission="'!RelevanceHideReasonScores'">
                          | {{ scoreFormat(z.score) }}</span
                        >
                      </strong>
                      <p class="explanation" [innerHtml]="z.explanation"></p>
                    </div>
                  }
                </div>
                @if (rights.includes('BridgeNotes')) {
                  <app-notes-entry
                    class="w-100 mt-2"
                    [bridgeVal]="prod.reasons"
                    (noteEntered)="handleNotesValidation($event, prod)"
                    [notesData]="{
                      app: 'li',
                      profile: this.id,
                      bridgeId:
                        prod.vars['CurrentBridgeNote^NoteBridgeID'].raw[0],
                      note: prod.vars['CurrentBridgeNote^NoteText'].raw[0],
                      prompt: prod.vars['CurrentBridgeNote^Prompt'].raw[0],
                      required:
                        (prod.SelectedBridge &&
                          prod.vars['CurrentBridgeNote^Required'].raw[0] ===
                            2) ||
                        (prod.vars['CurrentBridgeNote^Required'].raw[0] === 3 &&
                          prod.negativeReason)
                    }"
                  ></app-notes-entry>
                }
              </div>
            </li>
          }
        </ul>
      </div>
    </div>

    @if (alternatives.length > 0) {
      <div class="products-container col-12 mt-4">
        <div class="product-container">
          <h2 class="mb-2">Alternatives</h2>
          <ul class="products col-12">
            @for (prod of alternatives; track prod) {
              <li class="col-12 product {{ prod.ScoreClass }}">
                <div class="col-12 row">
                  <div class="col-11 content">
                    @if (prod.ProductTypeHeader) {
                      <div
                        class="type-indicator {{
                          prod.ProductTypeHeader.toLowerCase()
                        }}"
                      >
                        {{ prod.ProductTypeHeader }}</div
                      >
                    }
                    <div class="score-icon {{ prod.ScoreClass }}">
                      <ng-container *hasPermission="'RelevanceBridgeIcon'">
                        @if (prod.ScoreClass === 'positive') {
                          <mat-icon>check_circle</mat-icon>
                        }
                        @if (prod.ScoreClass === 'neutral') {
                          <mat-icon>error</mat-icon>
                        }
                        @if (prod.ScoreClass === 'negative') {
                          <mat-icon>remove_circle</mat-icon>
                        }
                      </ng-container>
                    </div>
                    <h4>
                      <span class="product-name">
                        {{ prod.ShortName }}
                        <span
                          class="product-score"
                          *hasPermission="'!RelevanceHideBridgeScore'"
                        >
                          | {{ scoreFormat(prod.score) }}</span
                        >
                      </span>
                    </h4>
                  </div>
                  <div class="col-1 button">
                    <button mat-icon-button (click)="expand($event)">
                      <span class="collapsed-content">
                        <mat-icon>expand_circle_down</mat-icon>
                      </span>
                      <span class="expanded-content">
                        <mat-icon>expand_circle_down</mat-icon>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="details-container">
                  @if (prod.ComplianceChart) {
                    <app-sub-item-chart
                      [data]="prod.ComplianceChart"
                      [chartTitle]="'Regulation Review'"
                    ></app-sub-item-chart>
                  }
                  <h5 class="col-12">Reasons for this Product</h5>
                  <div class="reasons-container w-100">
                    @for (z of prod.reasons; track z) {
                      <div
                        class="details col-6 {{ z.reasonType.toLowerCase() }}"
                      >
                        <strong class="header">
                          @if (z.reasonType) {
                            <img
                              src="{{
                                './assets/' +
                                  path +
                                  z.reasonType.toLowerCase() +
                                  '.png'
                              }}"
                              [alt]="z.reasontType"
                            />
                          }
                          <span [innerHtml]="z.headline"></span>
                          <span *hasPermission="'!RelevanceHideReasonScores'">
                            | {{ scoreFormat(z.score) }}
                          </span>
                        </strong>
                        <p class="explanation" [innerHtml]="z.explanation"></p>
                      </div>
                    }
                  </div>
                </div>
              </li>
            }
          </ul>
        </div>
      </div>
    }
    <div class="report-button row w-100 buttons">
      <button
        mat-raised-button
        *hasPermission="'ELinLIDynamic'"
        (click)="externalAuditAction()"
        color="primary"
        class="external-link"
      >
        <mat-icon>link</mat-icon>{{ externalAudit?.label }}
      </button>
    </div>
  </div>
</div>
