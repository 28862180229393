<div class="page-container summary-container container">
  <app-breadcrumb [crumb]="'summary'"></app-breadcrumb>
  <h1 class="header col-12">Client Summary for {{ data.client.name }}</h1>
  <div class="buttons row top">
    <button mat-raised-button class="combined-button">
      <span class="mat-button-wrapper">
        <mat-icon>insert_drive_file</mat-icon>Download Combined Report
      </span>
    </button>
  </div>
  <div class="summary">
    @if (data.applications.aw) {
      <h1>Annuity Wizard</h1>
      <div class="app-container">
        @for (x of data.applications.aw.tracks; track x) {
          <div class="w-100 product">
            <h2 [className]="x.status">
              {{ x.type }}
              @if (x.status === 'completed') {
                <button mat-button>
                  <mat-icon>insert_drive_file</mat-icon>Download Report
                </button>
              }
              @if (x.status === 'partial') {
                <div class="status">
                  <mat-icon>av_timer</mat-icon>Needs to be Completed<span
                    >|</span
                  >
                  <button mat-button>
                    Complete <mat-icon>arrow_forward</mat-icon>
                  </button>
                </div>
              }
              @if (x.status === 'notStarted') {
                <div class="status">
                  <mat-icon>error</mat-icon>Not Started<span>|</span>
                  <button mat-button>
                    Begin <mat-icon>arrow_forward</mat-icon>
                  </button>
                </div>
              }
            </h2>
            <ul class="aw col-12 row">
              @for (prod of x.selectedProducts; track prod; let idx = $index) {
                <li>
                  <span
                    class="type-indicator {{ prod.productType.toLowerCase() }}"
                  >
                    {{ prod.productType }}
                  </span>
                  {{ prod.carrier }} {{ prod.product }} |
                  {{ prod.premium | currency }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
    @if (data.applications.iw) {
      <h1>Investment Wizard</h1>
      <div class="app-container">
        <div class="row">
          <div class="info-container col-10">
            <h2 class="info">
              <mat-icon>assessment</mat-icon>Investment Model:
              <span>{{ data.applications.iw.investmentModel }}</span>
            </h2>
            <h2 class="info">
              <mat-icon>assignment</mat-icon>Program:
              <span>{{ data.applications.iw.investmentProgram }}</span>
            </h2>
            <h2 class="info">
              <mat-icon>account_balance_wallet</mat-icon>Investment Amount:
              <span>
                {{ data.applications.iw.investmentAmount | currency }}
              </span>
            </h2>
          </div>
          <div class="action-container col-2">
            <button mat-button>
              <mat-icon>insert_drive_file</mat-icon>Download Report
            </button>
          </div>
        </div>
        @for (
          fundClass of data.applications.iw.selectedFunds;
          track fundClass
        ) {
          <div class="fundClass">
            <h2>
              {{ fundClass.assetClass }}
              <span class="amount pr-3">
                Amount Allocated: {{ fundClass.amount | currency }}
              </span>
            </h2>
            <ul class="iw col-12 row">
              @for (prod of fundClass.funds; track prod; let idx = $index) {
                <li>
                  <div class="w-100 pr-3 pl-3">
                    @if (!fundClass.postponed) {
                      <div class="w-100">
                        {{ prod.name }}
                        <span class="amount">
                          {{ prod.amount | currency }}
                        </span>
                      </div>
                    } @else {
                      Funds to Selected at a Later Date
                    }
                  </div>
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
    @if (data.applications.li) {
      <h1>Life Insurance Wizard</h1>
      <div class="app-container">
        <div class="row">
          <div class="info-container col-10">
            <h2 class="info">
              <mat-icon>supervisor_account</mat-icon>Total Need:
              <span>{{ data.applications.li.totalNeed | currency }}</span>
            </h2>
            <h2 class="info">
              <mat-icon>pie_chart</mat-icon>Product Mix:
              <span>{{ data.applications.li.productMix.perm | percent }}</span>
              Permanent,
              <span>{{ data.applications.li.productMix.temp | percent }}</span>
              Temporary
            </h2>
          </div>
          <div class="action-container col-2">
            <button mat-button>
              <mat-icon>insert_drive_file</mat-icon>Download Report
            </button>
          </div>
        </div>
        <div class="product">
          <h2>Products Selected</h2>
          <ul class="li col-12 row">
            @for (
              product of data.applications.li.selectedProducts;
              track product
            ) {
              <li>
                <div class="w-100 pr-3">
                  <span class="type-indicator {{ product.type.toLowerCase() }}">
                    {{ product.type.toUpperCase() }}
                  </span>
                  {{ product.name }}
                  <span class="amount">{{ product.amount | currency }}</span>
                </div>
              </li>
            }
          </ul>
        </div>
      </div>
    }
  </div>
  <div class="buttons row bottom">
    <button mat-raised-button class="combined-button">
      <span class="mat-button-wrapper">
        <mat-icon>insert_drive_file</mat-icon>Download Combined Report
      </span>
    </button>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
