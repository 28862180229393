import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { LifeWizardService } from '../life-wizard.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportDialogComponent } from '../../report-dialog/report-dialog.component';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';
import { EnvironmentService } from '../../services/environment.service';
import { ConsolidatedReportModalComponent } from '../../shared-components/consolidated-report-modal/consolidated-report-modal.component';
import { UnifiedFlowService } from '../../unified-flow/unified.service';
import { FormService } from '../../conditional-form/form-service.service';

export interface FundData {
  permPercent: number;
  tempPercent: number;
  perm: any;
  temp: any;
}

@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss'],
})
export class ValidateComponent implements OnInit, AfterViewInit {
  @Input() hasSummaryModule;

  private environment;
  private baseUrl: string;

  data;
  dataSet;
  fundData: FundData = <FundData>{};
  validateProducts = [];
  id;
  path: string;
  loading = false;
  showReportNotes = false;
  externalLink;
  externalAudit;
  crossApp;
  printText = 'Download Report';
  printPrompt;
  printNote;
  rights;
  includeAll = false;
  altCount = 0;
  alternatives = [];
  limitAlts = false;

  constructor(
    private rbs: RightBridgeApiService,
    private lws: LifeWizardService,
    private ss: SessionStorageService,
    private params: ActivatedRoute,
    private tes: TagsEvaluationService,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private unfFlowSvc: UnifiedFlowService,
    private frmSvc: FormService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.path = this.environment.assets ? this.environment.assets : '';
    this.rights = this.ss.get('rights');

    this.setLoading(true);
    this.params.params.subscribe(params => {
      this.id = params.id;
      this.dataSet = params.dataSet || 'default';
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.rbs.getGlobals(null, null, 'li').subscribe(({ results }) => {
        this.externalLink = results.standardPostBack || null;
        this.externalAudit = results.dynamicPostBack || null;
        if (results?.GlobalsGroup?.UnitRegulation?.AlternativeCount > 0) {
          this.includeAll = true;
          this.altCount =
            results?.GlobalsGroup?.UnitRegulation?.AlternativeCount;
        }

        this.limitAlts = results?.GlobalsGroup?.UnitRegulation
          ?.LimitAltsToSameType
          ? results?.GlobalsGroup?.UnitRegulation?.LimitAltsToSameType == 'Y'
          : false;
        this.getProducts();
      });
    });
  }

  getProducts() {
    const save = this.unfFlowSvc.getSaving();
    if (save) {
      setTimeout(() => {
        this.getProducts();
      }, 250);
    } else {
      this.lws.getLifeProducts(this.id, this.includeAll).subscribe(x => {
        x.LifeProducts.forEach(z => {
          if (
            z.Validate &&
            z.Validate.toLowerCase() == 'true' &&
            (z.bridgetype != 'InsuranceNeed' || z.BridgeType != 'InsuranceNeed')
          ) {
            this.validateProducts.push(z);
          } else if (
            z.Validate &&
            z.Validate.toLowerCase() == 'false' &&
            this.alternatives.length < this.altCount &&
            ((z.bridgetype && z.bridgetype != 'InsuranceNeed') ||
              (z.BridgeType && z.BridgeType != 'InsuranceNeed'))
          ) {
            if (this.limitAlts) {
              let type;
              if (!this.validateProducts[0]) {
                const prod = x.LifeProducts.find(y => {
                  return (
                    y.Validate &&
                    y.Validate.toLowerCase() == 'true' &&
                    (y.bridgetype != 'InsuranceNeed' ||
                      y.BridgeType != 'InsuranceNeed')
                  );
                });
                type = prod?.BridgeType;
              } else {
                type = this.validateProducts[0]?.BridgeType;
              }

              if (z.BridgeType == type) {
                this.alternatives.push(z);
              }
            } else {
              this.alternatives.push(z);
            }
          }
        });

        this.filterProducts(this.validateProducts);
        this.filterProducts(this.alternatives, false);
        this.showReportNotes =
          x.vars['UnitSettings^ShowBeforePrintNote'].raw[0];

        const globals = this.ss.get('globals');

        this.crossApp = globals.crossApp || null;
        this.printText =
          x.vars['UnitSettings^DownloadBtnTxt'] &&
          x.vars['UnitSettings^DownloadBtnTxt'].display[0]
            ? x.vars['UnitSettings^DownloadBtnTxt'].display[0]
            : this.printText;
        this.printPrompt =
          x.vars['UnitSettings^BeforePrintPrompt'] &&
          x.vars['UnitSettings^BeforePrintPrompt'].display[0]
            ? x.vars['UnitSettings^BeforePrintPrompt'].display[0]
            : this.printPrompt;
        this.printNote =
          x.vars['LifeWizard^BeforePrintNote'] &&
          x.vars['LifeWizard^BeforePrintNote'].display[0]
            ? x.vars['LifeWizard^BeforePrintNote'].display[0]
            : this.printNote;

        this.setLoading(false);
      });
    }
    this.frmSvc.formModified.set({
      modified: false,
      count: 0,
      values: {},
      app: 'li',
    });
  }

  filterProducts(prods, notesNeeded = true) {
    prods.forEach(el => {
      if (el.ComplianceChart) {
        el.ComplianceChart = JSON.parse(el.ComplianceChart);
      }

      if (el.reasons) {
        el.reasons.forEach((reas, i) => {
          el.reasons[i].headline = reas.headline
            ? this.tes.evalTags(reas.headline)
            : reas.headline;
          el.reasons[i].explanation = reas.explanation
            ? this.tes.evalTags(reas.explanation)
            : reas.explanation;
          el.negativeReason =
            reas.reasonType == 'Negative' ? true : el.negativeReason;
        });

        if (notesNeeded) {
          const requiredNotes =
            el.vars['CurrentBridgeNote^Required'].raw[0] == 2 ||
            (el.vars['CurrentBridgeNote^Required'].raw[0] == 3 &&
              el.negativeReason);
          const notesEntered =
            el.vars['CurrentBridgeNote^NoteText'].raw[0] &&
            el.vars['CurrentBridgeNote^NoteText'].raw[0].length > 0;

          el.notesValid =
            (requiredNotes && notesEntered) || !requiredNotes ? true : false;

          if (!el.notesValid) {
            this.unfFlowSvc.setNoteValidity({ app: 'li', valid: false });
          }
          if (el.notesValid) {
            this.unfFlowSvc.setNoteValidity({ app: 'li', valid: true });
          }
        }
      }

      // this.validateProducts.push(el);
    });
  }

  processTags(prod) {}

  navigate() {
    this.router.navigate([
      '/li/needs/' + this.id,
      { validate: true, dataSet: this.dataSet },
    ]);
  }

  report() {
    const dialogRef = this.dialog.open(ReportDialogComponent, {
      panelClass: 'report-dialog',
      data: {
        hideDetails: true,
        showNotes: this.showReportNotes,
        beforePrintPrompt: this.printPrompt,
        printNote: this.printNote,
      },
    });
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    dialogRef.afterClosed().subscribe(result => {
      this.setLoading(true);
      if (result && result != 'cancel') {
        const prods = { bridgeIds: [] };
        prods.bridgeIds = [
          this.validateProducts[0].vars['CurrentLifeItem^LifeItemID'].raw[0],
        ];

        if (result.notes) {
          const data = {};
          data['LifeWizard.BeforePrintNote'] = result.notes;
          this.rbs
            .saveProfile(this.id, data, false, false, 'li')
            .subscribe(x => {
              this.lws.getReport(this.id, prods).subscribe(data => {
                const date = new Date();
                const filename =
                  result.filename && result.filename != null
                    ? result.filename + '.pdf'
                    : 'LifeWizard-' +
                      date.getMonth() +
                      date.getDay() +
                      date.getFullYear() +
                      '.pdf';

                let fileData;
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                  fileData = reader.result;
                  saveAs(fileData, filename, {
                    proxyURL: `${this.baseUrl}/util/proxy`,
                    proxyTarget: '_self',
                    forceProxy: iosSafari,
                  });
                  this.setLoading(false);
                };
              });
            });
        } else {
          this.lws.getReport(this.id, prods).subscribe(data => {
            const date = new Date();
            const filename =
              result.filename && result.filename != null
                ? result.filename + '.pdf'
                : 'LifeWizard-' +
                  date.getMonth() +
                  date.getDay() +
                  date.getFullYear() +
                  '.pdf';

            let fileData;
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = () => {
              fileData = reader.result;
              saveAs(fileData, filename, {
                proxyURL: `${this.baseUrl}/util/proxy`,
                proxyTarget: '_self',
                forceProxy: iosSafari,
              });
              this.setLoading(false);
            };
          });
        }
      } else {
        this.setLoading(false);
      }
    });
  }

  externalAction() {
    let link = this.externalLink.link || null;
    const silentPrint = this.externalLink.silentPrint,
      pb = this.externalLink.includePostBack || null,
      includePID = this.externalLink.includePID,
      newTab = this.externalLink.RedirectSameTab ? '_self' : '_blank',
      outsideLink = link.includes('http');

    const prods = { bridgeIds: [] };
    prods.bridgeIds = [
      this.validateProducts[0].vars['CurrentLifeItem^LifeItemID'].raw[0],
    ];

    if (includePID) {
      link += this.id;
    }

    if (silentPrint && pb) {
      this.setLoading(true);
      this.lws.getReport(this.id, prods, silentPrint).subscribe(() => {
        this.rbs.postback(this.id).subscribe(() => {
          if (outsideLink) {
            window.open(link, newTab);
          } else {
            this.router.navigateByUrl(link);
          }
          this.setLoading(false);
        });
      });
    } else if (pb) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        if (outsideLink) {
          window.open(link, newTab);
        } else {
          this.router.navigateByUrl(link);
        }
        this.setLoading(false);
      });
    } else if (silentPrint) {
      this.setLoading(true);
      this.rbs.getReport(this.id, prods, silentPrint).subscribe(() => {
        if (outsideLink) {
          window.open(link, newTab);
        } else {
          this.router.navigateByUrl(link);
        }
        this.setLoading(false);
      });
    } else {
      if (outsideLink) {
        window.open(link, newTab);
      } else {
        this.router.navigateByUrl(link);
      }
      this.setLoading(false);
    }
  }

  externalAuditAction() {
    const silentPrint = this.externalAudit.silentPrint,
      newTab = this.externalAudit.RedirectSameTab ? '_self' : '_blank',
      customReportSolution =
        this.externalAudit.postbackSolution &&
        this.externalAudit.postbackSolution.length > 0
          ? this.externalAudit.postbackSolution
          : null;

    const prods = { bridgeIds: [] };
    prods.bridgeIds = [
      this.validateProducts[0].vars['CurrentLifeItem^LifeItemID'].raw[0],
    ];

    this.setLoading(true);

    let externalModal;
    try {
      externalModal = JSON.parse(this.externalAudit.postBackModalName);
    } catch (error) {
      console.error('Not valid json for modal: ', error);
    }

    if (externalModal) {
      let dialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: externalModal.Title,
          content: externalModal.Body,
          confirm: 'Submit',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        if (result != 'cancel') {
          this.rbs
            .extPostbackData(this.id, result, customReportSolution)
            .subscribe(x => {
              if (x && x.results != null && x.results.length > 0) {
                const link = x.results;
                this.setLoading(false);
                window.open(link, newTab);
              } else {
                let dialogRef = this.dialog.open(WarnDialogComponent, {
                  panelClass: 'warn-dialog',
                  data: {
                    headline: 'Warning',
                    content:
                      'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                    confirm: 'OK',
                    hideCancel: true,
                  },
                });
                dialogRef.afterClosed().subscribe(() => {
                  dialogRef = null;
                  this.setLoading(false);
                });
              }
            });
        } else {
          this.setLoading(false);
          console.warn('User opted out of submission');
        }
      });
    } else if (
      this.externalAudit.postBackModalName &&
      this.externalAudit.postBackModalName.length > 0
    ) {
      let dialogRef = this.dialog.open(ConsolidatedReportModalComponent, {
        panelClass: 'consolidated-report',
        data: {
          profile: this.id,
          silentPrint: true,
        },
        minWidth: '80vw',
        minHeight: '95vh',
        maxHeight: '95vh',
      });

      dialogRef.afterClosed().subscribe(result => {
        dialogRef = null;
        this.rbs
          .extPostbackData(this.id, result, customReportSolution)
          .subscribe(x => {
            if (x && x.results != null) {
              const link = x.results;
              this.setLoading(false);
              window.open(link, newTab);
            } else {
              let dialogRef = this.dialog.open(WarnDialogComponent, {
                panelClass: 'warn-dialog',
                data: {
                  headline: 'Warning',
                  content:
                    'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                  confirm: 'OK',
                  hideCancel: true,
                },
              });
              dialogRef.afterClosed().subscribe(() => {
                dialogRef = null;
                this.setLoading(false);
              });
            }
          });
      });
    } else {
      this.lws.getReport(this.id, prods, silentPrint).subscribe(() => {
        this.rbs.extPostback(this.id, customReportSolution).subscribe(x => {
          if (x && x.results != null) {
            const link = x.results;
            const outsideLink = link.includes('http');

            if (outsideLink) {
              window.open(link, newTab);
            } else {
              this.router.navigateByUrl(link);
            }

            this.setLoading(false);
          } else {
            let dialogRef = this.dialog.open(WarnDialogComponent, {
              panelClass: 'warn-dialog',
              data: {
                headline: 'Warning',
                content:
                  'The service you are trying to connect to is not currently available. Please report this to your system administrator and try again later.',
                confirm: 'OK',
                hideCancel: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              dialogRef = null;
              this.setLoading(false);
            });
          }
        });
      });
    }
  }

  goCrossApp(app) {
    const pb = this.crossApp.includePostBack || null;
    const silentPrint = this.crossApp.silentPrint || null;

    if (silentPrint && pb) {
      this.setLoading(true);
      this.rbs.getReport(this.id, null, silentPrint).subscribe(() => {
        this.rbs.postback(this.id).subscribe(() => {
          this.setLoading(false);
          this.router.navigate(['/' + app + '/questionnaire/' + this.id]);
        });
      });
    } else if (pb && !silentPrint) {
      this.setLoading(true);
      this.rbs.postback(this.id).subscribe(() => {
        this.setLoading(false);
        this.router.navigate(['/' + app + '/questionnaire/' + this.id]);
      });
    } else if (silentPrint && !pb) {
      this.setLoading(true);
      this.rbs.getReport(this.id, null, silentPrint).subscribe(() => {
        this.setLoading(false);
        this.router.navigate(['/' + app + '/questionnaire/' + this.id]);
      });
    } else {
      this.router.navigate(['/' + app + '/questionnaire/' + this.id]);
    }
  }

  scoreFormat(scr) {
    return scr > 100 ? 100 : scr;
  }

  handleNotesValidation(ev, item) {
    item.notesValid = ev;
    this.unfFlowSvc.setNoteValidity({
      app: 'li',
      valid: !(this.validateProducts.filter(x => !x.notesValid).length > 0),
    });
  }

  setLoading(state) {
    this.unfFlowSvc.setLoading(state);
  }

  expand(event) {
    const el = this.closestByClass(event.target, 'product');
    el.classList.toggle('expanded');
  }

  closestByClass(el, clazz) {
    while (!el.classList.contains(clazz)) {
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    return el;
  }
}
