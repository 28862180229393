import { Component, OnInit, Input } from '@angular/core';
import { SubItemCharts } from '../../models/subItemCharts-models';

@Component({
  selector: 'app-sub-item-chart',
  templateUrl: './sub-item-chart.component.html',
  styleUrls: ['./sub-item-chart.component.scss'],
})
export class SubItemChartComponent implements OnInit {
  @Input() data: SubItemCharts;
  @Input() chartTitle: string;
  @Input() chartStyle: string;

  chart = {} as SubItemCharts;
  title;
  style;

  constructor() {}

  ngOnInit() {
    this.chart = { ...this.data };

    if (this.chart.data) {
      this.chart.Data = [...this.chart.data];
      delete this.chart.data;
    }

    if (this.chart.header) {
      this.chart.Header = [...this.chart.header];
      delete this.chart.header;
    }

    this.title = this.chartTitle;
    this.style = this.chartStyle;
  }
}
