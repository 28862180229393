<div class="page-container unified container tools-launcher-container">
  <div class="apps w-100">
    @for (unit of toolUnits; track unit) {
      <div class="app-container">
        <span class="app-banner">
          <mat-icon>home_repair_service</mat-icon>
          <span>
            {{ unit.label }}
          </span>
        </span>
        <div class="app-message">
          {{ unit.description }}
        </div>
        <div class="line"></div>
        <div class="tool-links-container">
          @for (tool of unit.unitTools; track tool) {
            @if (!tool.ToolExternal) {
              <a
                class="tool-link"
                (click)="onLinkClick(tool.ToolLink, tool.ToolName)"
              >
                <div class="tool-link-label">
                  <mat-icon>launch</mat-icon> {{ tool.ToolName }}
                </div>
                <div class="tool-link-description">
                  {{ tool.ToolDescription }}
                </div>
              </a>
              @if (tool.ToolExternal) {
                <a class="tool-link" [href]="tool.ToolLink" target="_blank">
                  <div class="tool-link-label">
                    <mat-icon>launch</mat-icon> {{ tool.ToolName }}
                  </div>
                  <div class="tool-link-description">
                    {{ tool.ToolDescription }}
                  </div>
                </a>
              }
            }
          }
        </div>
      </div>
    }
  </div>
</div>
