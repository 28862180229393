<div class="account-review-dashboard-container container pt-3">
  <div class="row">
    <div class="col-4">
      <div class="table-label"> Pending Records </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              @for (header of pendingRecordsTable; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            <tr>
              @for (trade of pendingRecordsTable; track trade.label) {
                <td>
                  <div class="pending-trade-info">
                    <mat-icon class="mr-1" [class]="trade.varName">
                      {{ trade.icon }}
                    </mat-icon>
                    {{ trade.count }}
                  </div>
                </td>
              }
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-8">
      <div class="table-label"> Aging Cases </div>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th></th>
              @for (header of agingCasesHeaders; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @if (agingCases && agingCases.length > 0) {
              @for (case of agingCases; track case.CRID) {
                <tr>
                  <td>
                    <button
                      mat-icon-button
                      class="fs-button"
                      (click)="viewCase(case.CRID)"
                      color="primary"
                      matTooltip="View Case"
                    >
                      <mat-icon>preview</mat-icon>
                    </button>
                  </td>
                  @for (varRef of agingCasesHeaders; track varRef.label) {
                    @if (varRef.dataMask === 'date') {
                      <td>{{
                        case[varRef.varName] | localTime: 'shortDate'
                      }}</td>
                    } @else {
                      <td>{{ case[varRef.varName] }}</td>
                    }
                  }
                </tr>
              }
            } @else {
              <tr>
                <td colspan="8" class="no-records"> No Records found </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <ngx-loading [show]="loading"></ngx-loading>
</div>
