import { ActivatedRoute, Router } from '@angular/router';
import { AiSummaryComponent } from '../../ai-summary/ai-summary.component';
import { CaseManagementService } from '../case-management.service';
import { CaseProfile } from '../../models/case-management-model';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { getFilterMenuText } from '../../lib/grid-sort-text';
import {
  KENDO_PDFVIEWER,
  PDFViewerTool,
} from '@progress/kendo-angular-pdfviewer';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxLoadingModule } from 'ngx-loading';
import { Platform } from '@angular/cdk/platform';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { saveAs } from '@progress/kendo-file-saver';
import { SessionStorageService } from '../../services/session-storage.service';
import { UnifiedFlowService } from '../../unified-flow/unified.service';
import { WarnDialogComponent } from '../../warn-dialog/warn-dialog.component';
import { BreadcrumbComponent } from '../../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-case-manager',
  templateUrl: './case-manager.component.html',
  styleUrls: ['./case-manager.component.scss'],
  standalone: true,
  imports: [
    AiSummaryComponent,
    BreadcrumbComponent,
    CommonModule,
    FormsModule,
    KENDO_PDFVIEWER,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    NgxLoadingModule,
    ReactiveFormsModule,
  ],
})
export class CaseManagerComponent implements OnInit {
  public getFilterMenuText = getFilterMenuText;
  activeCaseFile: any;
  aiSummaryText: string;
  aiSummaryTool = false;
  baseUrl: string;
  canDeleteFiles = false;
  canRespondToAssignee = false;
  canUploadFiles = false;
  caseDetails = {};
  caseFiles = null;
  caseHistoryData = [];
  caseHistoryHeaders = [
    { fieldName: 'AssignedToName', label: 'Assigned To' },
    { fieldName: 'DateLastUpdated', label: 'Updated' },
    { fieldName: 'SavedByName', label: 'Saved By' },
    { fieldName: 'StatusName', label: 'Status Name' },
    { fieldName: 'Title', label: 'Title' },
    { fieldName: 'Type', label: 'Type' },
  ];
  caseProfile: CaseProfile;
  caseProfileID: string;
  caseTitle = '';
  caseUsers = [];
  changesMade;
  CRID: string;
  defaultReviewerStatus: number;
  defaultUser;
  editCaseTitle = false;
  editMessage = '';
  editMessageId = null;
  editNoteType = 1;
  environment;
  file;
  fileName;
  fileNameDisplay;
  fileType;
  globals = this.ss.get('globals');
  isCaseManager = false;
  loading = false;
  message = '';
  messages = [];
  mostRecentMessage = null;
  mostRecentUpdateItem = null;
  noteType = 1;
  noteTypes = [
    { value: 1, label: 'Public' },
    { value: 2, label: 'Private' },
  ];
  pdfBase64: any;
  pdfViewerTools: PDFViewerTool[] = [
    'pager',
    'spacer',
    'zoom',
    'zoomInOut',
    'selection',
  ];
  profileID: any;
  reassignOnSave = false;
  reportSettings = {};
  rights: string[];
  scrollInterval: NodeJS.Timeout;
  selectedCaseFile = null;
  showCaseHistory = false;
  showPrintReportBtn = false;
  statusOptions = [];
  crumbConfig = [
    {
      label: 'Case Management',
      link: 'case-management',
    },
  ];

  constructor(
    private cms: CaseManagementService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CaseManagerComponent>,
    private envSvc: EnvironmentService,
    private params: ActivatedRoute,
    private pltfrm: Platform,
    private rbs: RightBridgeApiService,
    private route: Router,
    private snacky: MatSnackBar,
    private ss: SessionStorageService,
    private unfFlowSvc: UnifiedFlowService
  ) {}

  ngOnInit() {
    this.params.params.subscribe(params => {
      this.CRID = params.CRID;
      this.crumbConfig.push({
        label: this.CRID,
        link: `case-management/case-manager/${this.CRID}`,
      });
    });
    this.loading = true;
    this.defaultUser = this.globals ? this.globals.user : null;
    this.profileID = this.ss.get('globals')
      ? this.ss.get('globals')['user']['id']
      : null;
    this.getCaseManagementProfile(this.CRID);
    this.getCaseRecordNotes(this.CRID);
    this.getCaseRecordFilesList(this.CRID);
    this.getCaseManagementHistory(this.CRID);
    this.rights = this.ss.get('rights');
    this.isCaseManager = this.rights && this.rights.includes('CaseManager');
    this.canDeleteFiles =
      this.rights && this.rights.includes('DeleteCMDocument');
    this.canUploadFiles = this.rights && this.rights.includes('CMUploadFiles');
    this.canRespondToAssignee =
      this.rights && this.rights.includes('RespondToAssignee');
    this.aiSummaryTool = this.rights && this.rights.includes('AISummaryAccess');
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  saveBtnDisabled() {
    if (
      this.canRespondToAssignee &&
      this.caseProfile?.CaseManagement?.AssignedTo === this.profileID &&
      new Date(this.mostRecentMessage?.CreateDate).getTime() -
        new Date(this.mostRecentUpdateItem?.DateLastUpdated).getTime() >=
        0
    ) {
      return false;
    }
    if (!this.canRespondToAssignee) {
      return !this.changesMade;
    }
    return true;
  }

  getCaseManagementProfile(id) {
    this.cms.getCaseManagementProfile(id).subscribe(resp => {
      this.caseProfile = resp;
      this.caseTitle = resp['CaseManagement']['Title'];
      this.caseDetails = resp['CaseManagement'];
      this.statusOptions = resp['ActiveStatuses'];
      this.reportSettings = resp['UnitCMReport'];
      this.showPrintReportBtn = resp['UnitCMReport']
        ? Boolean(
            resp['UnitCMReport']['ShowPrintButton'] &&
              resp['UnitCMReport']['PrintButtonStatuses'].includes(
                this.caseDetails['StatusID']
              )
          )
        : null;
      if (this.canRespondToAssignee) {
        this.defaultReviewerStatus = resp['RespondToReviewer']?.DefaultStatusID;
      }
      if (resp['Client_Rep']['RepCode'][0]) {
        this.getCaseManagementUsers(resp['Client_Rep']['RepCode'][0]);
      }
      this.aiSummaryTool = resp['CMAISummary']['DisplayAISummary'];
      if (this.aiSummaryTool) {
        this.aiSummaryText = resp['CMAISummaryResponse']['AISummary'];
      }
      this.loading = false;
    });
  }

  getCaseManagementUsers(repCode) {
    this.cms.getCaseManagementUsers(repCode).subscribe(resp => {
      this.caseUsers = resp['Users'];
    });
  }

  getCaseRecordFilesList(id) {
    this.cms.listCaseRecordFiles(id).subscribe(resp => {
      this.caseFiles = resp['data'];
    });
  }

  getCaseManagementHistory(id) {
    this.cms.getCaseManagementHistory(id).subscribe(resp => {
      this.caseHistoryData = resp['CaseManagementHistory'];
      this.caseProfileID = resp['CaseToProfile']?.ProfileID[0];
      this.caseHistoryData.forEach(caseItem => {
        caseItem.DateLastUpdated = new Date(
          caseItem.DateLastUpdated
        ).toLocaleDateString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
        });
      });
      this.showCaseHistory = true;
      const updateTimes = [];
      this.mostRecentUpdateItem = null;
      this.caseHistoryData.forEach(historyItem => {
        const thisItemTime =
          new Date().getTime() -
          new Date(historyItem.DateLastUpdated).getTime();
        updateTimes.push(thisItemTime);
        if (Math.min(...updateTimes) === thisItemTime) {
          this.mostRecentUpdateItem = historyItem;
        }
      });
    });
  }

  onFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  getCaseRecordFile(caseFile) {
    this.loading = true;
    this.cms.getCaseRecordFile(caseFile.id).subscribe(resp => {
      const filename = `${caseFile.Name}`;
      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(resp);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
        this.loading = false;
      };
    });
  }

  loadFileIntoViewer(caseFile) {
    this.cms.getCaseRecordFile(caseFile.id).subscribe(resp => {
      this.activeCaseFile = caseFile.Name;
      let viewFileData;
      const reader = new FileReader();
      reader.readAsDataURL(resp);
      reader.onloadend = () => {
        viewFileData = reader.result;
        this.pdfBase64 = viewFileData.replace(
          'data:application/octet-stream;base64,',
          ''
        );
      };
    });
  }

  deleteCaseRecordFile(caseFile) {
    let dialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Warning',
        content: `Are you sure you want to delete ${caseFile.Name}.${caseFile.Type}?`,
        confirm: 'Yes',
        hideCancel: false,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'continue') {
        this.cms.deleteCaseRecordFile(caseFile.id).subscribe(() => {
          this.getCaseRecordFilesList(this.CRID);
        });
      }
      dialogRef = null;
    });
  }

  getCaseRecordNotes(id) {
    this.cms.getCaseRecordNotes(id).subscribe(resp => {
      this.messages = resp['data'];
      this.scrollToBottom();
      const updateTimes = [];
      this.mostRecentMessage = null;
      this.messages.forEach(message => {
        if (message.CreatedByUserId === this.profileID) {
          const thisItemTime =
            new Date().getTime() - new Date(message.CreateDate).getTime();
          updateTimes.push(thisItemTime);
          if (Math.min(...updateTimes) === thisItemTime) {
            this.mostRecentMessage = message;
          }
        }
      });
    });
  }

  submitNote() {
    const newNote = {
      CaseRecordId: this.CRID,
      NoteText: this.message,
      NoteTypeId: this.noteType === 1 ? 1 : this.noteType,
    };

    this.cms.addCaseRecordNote(newNote).subscribe(() => {
      this.getCaseRecordNotes(this.CRID);
      this.message = '';
    });
  }

  editNote(note) {
    this.editMessageId = note.NoteId;
    this.editMessage = note.NoteText;
    this.editNoteType = note.NoteTypeId * 1;
  }

  submitEdit() {
    const saveNote = {
      CaseRecordId: this.CRID,
      NoteText: this.editMessage,
      NoteTypeId: this.editNoteType,
      NoteId: this.editMessageId,
    };
    this.cms.editCaseRecordNote(saveNote).subscribe(() => {
      this.getCaseRecordNotes(this.CRID);
      this.message = '';
    });
    this.editMessageId = null;
  }

  scrollToBottom() {
    if (this.loading) {
      this.scrollInterval = setInterval(() => this.scrollToBottom(), 200);
    } else {
      clearInterval(this.scrollInterval);
      const chatContainer = document.querySelector('.chat-messages-container');
      const oldScrollPostion = chatContainer.scrollHeight;
      chatContainer.scrollTo({
        top: oldScrollPostion,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  changeValue(value) {
    this.changesMade = value;
  }

  isArray(value) {
    return Array.isArray(value);
  }

  saveCaseChanges() {
    this.loading = true;
    let snackyMessage = 'Saved!';
    const caseDetails = this.caseProfile;

    let assignedToName =
      this.caseUsers.find(
        user => user.UserID === this.caseProfile['CaseManagement']['AssignedTo']
      )?.UserName || null;

    if (this.canRespondToAssignee) {
      const updateTimes = [];
      let tempAssignedTo = this.caseProfile['CaseManagement']['AssignedTo'];
      let tempAssignedToName = assignedToName;
      this.caseHistoryData.forEach(dataItem => {
        if (dataItem.AssignedToName !== assignedToName) {
          const thisItemTime =
            new Date().getTime() - new Date(dataItem.DateLastUpdated).getTime();
          updateTimes.push(thisItemTime);
          if (Math.min(...updateTimes) === thisItemTime) {
            tempAssignedToName = dataItem.AssignedToName;
            tempAssignedTo = dataItem.AssignedTo;
          }
        }
      });
      this.caseProfile['CaseManagement']['AssignedTo'] = tempAssignedTo;
      assignedToName = tempAssignedToName;
      snackyMessage = `Saved and Assigned to ${assignedToName}`;
      caseDetails['CaseManagement']['StatusID'] = this.defaultReviewerStatus
        ? this.defaultReviewerStatus
        : this.caseProfile['CaseManagement']['StatusID'];
    }

    caseDetails['CaseManagement']['AssignedToName'] = assignedToName;

    this.cms.saveCaseManagementRecord(this.CRID, caseDetails).subscribe(() => {
      this.snacky.open(snackyMessage, 'Close', {
        duration: 5000,
      });
      this.message = '';
      this.changeValue(false);
      this.getCaseRecordNotes(this.CRID);
      this.getCaseManagementProfile(this.CRID);
      this.getCaseManagementHistory(this.CRID);
      this.loading = false;
    });
  }

  completeFileUpload() {
    this.loading = true;
    const formData = new FormData();
    const fileName = this.file['name'];
    const fileType = this.file['type'];
    formData.append('File1', this.file);
    this.fileName = '';
    this.cms
      .saveCaseRecordFile(this.CRID, formData, fileName, fileType)
      .subscribe(
        () => {
          this.getCaseRecordNotes(this.CRID);
          this.getCaseRecordFilesList(this.CRID);
          this.message = '';
          this.snacky.open(
            'File uploaded, please allow some time for it to appear in your new Case Management record.',
            'Close',
            {
              duration: 3000,
            }
          );

          this.loading = false;
        },
        error => {
          this.snacky.open(`There was an error uploading the file.`, 'Close', {
            duration: 10000,
          });

          this.loading = false;
        }
      );
  }

  saveCaseTitle() {
    this.caseProfile['CaseManagement']['Title'] = this.caseTitle;
    this.saveCaseChanges();
    this.toggleEditCaseTitle(false);
  }

  cancelEditCaseTitle() {
    this.caseTitle = this.caseProfile['CaseManagement']['Title'];
    this.toggleEditCaseTitle(false);
  }

  toggleEditCaseTitle(value) {
    this.editCaseTitle = value;
  }

  submitPostback() {
    let warnDialogRef = this.dialog.open(WarnDialogComponent, {
      panelClass: 'warn-dialog',
      data: {
        headline: 'Are you sure?',
        content: `You want to submit this case?`,
        confirm: 'Yes',
        hideCancel: false,
      },
    });

    warnDialogRef.afterClosed().subscribe(result => {
      if (result == 'continue') {
        this.rbs.postback(this.caseDetails['CRID'], 'cm').subscribe(() => {
          this.snacky.open('Case successfully submitted!', 'Close', {
            duration: 3000,
          });
          this.dialogRef.close({ data: 'submitted' });
        });
      }
      warnDialogRef = null;
    });
  }
  printCMReport(ev) {
    this.unfFlowSvc.setLoading(true);
    const dialogRef = this.dialog.open(LoadingDialogComponent, {
      panelClass: 'app-loading-dialog',
      data: {
        headline: 'Please wait',
        content: 'Your report is generating. This may take a moment...',
      },
      disableClose: true,
    });
    const payload = {
      ReportType: 'casemanagement',
      profileID: this.caseDetails['CRID'],
      UIConfig: 'cm-report',
    };

    this.rbs.getCustomReport(payload, 'cm').subscribe(data => {
      const date = new Date();
      const filename =
        'RightBridge-Report-' +
        date.getMonth() +
        date.getDay() +
        date.getFullYear() +
        '.pdf';

      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });

        if (dialogRef) {
          dialogRef.componentInstance.headline = 'Complete!';
          dialogRef.componentInstance.content =
            'Your report has been generated! Thank you for your patience.';
          dialogRef.componentInstance.endModal();
        }
        this.unfFlowSvc.setLoading(false);
      };
    });
  }
  nav(location) {
    if (this.changesMade) {
      let warnDialogRef = this.dialog.open(WarnDialogComponent, {
        panelClass: 'warn-dialog',
        data: {
          headline: 'Are you sure?',
          content: `You have unsaved changes. Would you like to leave this page?`,
          confirm: 'Yes',
          hideCancel: false,
        },
      });

      warnDialogRef.afterClosed().subscribe(result => {
        if (result == 'continue') {
          if (location === 'caseSummary') {
            if (this.caseProfileID) {
              this.route.navigate([`/case/summary/${this.caseProfileID}/0`]);
            } else {
              this.snacky.open(
                'There was an error navigating to this case. Please contact your administrator.',
                'Close',
                {
                  duration: 8000,
                }
              );
            }
          } else {
            this.route.navigate([`/${location}`]);
          }
        }
        warnDialogRef = null;
      });
    } else {
      if (location === 'caseSummary') {
        if (this.caseProfileID) {
          this.route.navigate([`/case/summary/${this.caseProfileID}/0`]);
        } else {
          this.snacky.open(
            'There was an error navigating to this case. Please contact your administrator.',
            'Close',
            {
              duration: 8000,
            }
          );
        }
      } else {
        this.route.navigate([`/${location}`]);
      }
    }
  }
  expand(ev, currentCard: string) {
    const el = ev.target.closest(`.${currentCard}`);
    if (el) {
      el.classList.toggle('expanded');
    }
  }
}
