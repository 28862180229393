<div mat-dialog-header>
  <h1>Create New Case</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <p
    >You're almost there! Please provide the state of issue and the general
    purpose for this annuity recommendation in order to create a new case.</p
  >
  <form #profileForm="ngForm">
    <mat-form-field class="mr-2 col-4">
      <mat-label>State of Issue</mat-label>
      <mat-select [(value)]="modalData.state" name="state" required>
        @for (state of states; track state) {
          <mat-option [value]="state.value">
            {{ state.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mr-2 col-4">
      <mat-label>Annuity Purpose</mat-label>
      <mat-select [(value)]="modalData.purpose" name="purpose" required>
        <mat-option value="1">Income</mat-option>
        <mat-option value="5">Accumulation</mat-option>
        <mat-option value="3">Death Benefit</mat-option>
      </mat-select>
    </mat-form-field>
    @if (data.accountPref?.toLowerCase() === 'y') {
      <mat-form-field
        class="mr-2 col-4"
        [required]="data.accountPref?.toLowerCase() === 'y'"
      >
        <mat-label>Account Type</mat-label>
        <mat-select [(value)]="modalData.acctType" name="type">
          <mat-option value="BK">Brokerage</mat-option>
          <mat-option value="ADV">Advisory</mat-option>
        </mat-select>
      </mat-form-field>
    }
    <mat-form-field class="mr-2 col-5">
      <mat-label>Annuity</mat-label>
      <input matInput [(ngModel)]="annuity" disabled name="annuity" />
    </mat-form-field>
    @if (rider) {
      <mat-form-field class="mr-2 col-5">
        <mat-label>Rider</mat-label>
        <input matInput [(ngModel)]="rider" disabled name="rider" />
      </mat-form-field>
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="accent" (click)="save()">Save</button>
</mat-dialog-actions>
