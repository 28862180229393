import { OnInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-utility-apps',
  templateUrl: './utility-apps.component.html',
  styleUrls: ['./utility-apps.component.scss'],
})
export class UtilityAppsComponent implements OnInit {
  @Input() utilityApps;
  @Input() showLearnButton;
  rights = [];

  constructor(
    private ss: SessionStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
  }

  hasUtilityApps() {
    let hasAppsAndRights = false;
    this.utilityApps.map(app => {
      if (app.right && this.rights.includes(app.right)) {
        hasAppsAndRights = true;
      }
    });
    return this.showLearnButton || hasAppsAndRights;
  }

  goToPage(link: string): void {
    this.router.navigate([link]);
  }
}
