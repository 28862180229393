<div mat-dialog-header>
  <h1>Compare Products</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <div class="row compare-container col-12">
    @for (t of tables; track t) {
      <kendo-grid
        #grid
        [kendoGridBinding]="t.gridData"
        [sortable]="t.type === 'horz'"
        class="compare-table w-100"
        [resizable]="true"
        [style.maxHeight.pct]="90"
      >
        @if (t.type !== 'horz') {
          <kendo-grid-column
            field="metric"
            title=""
            width="105"
            [locked]="true"
          ></kendo-grid-column>
        }
        @if (t.type !== 'horz') {
          @for (column of t.columns; track column) {
            <kendo-grid-column
              field="{{ column.id }}"
              title="{{ column.name }}"
              [width]="300"
            ></kendo-grid-column>
          }
        }
        @if (t.type === 'horz') {
          @for (column of t.columns; track column) {
            <kendo-grid-column
              field="{{ column.id }}"
              title="{{ column.name }}"
              [width]="column.id === 'name' ? 250 : 150"
              [locked]="column.id === 'name'"
            ></kendo-grid-column>
          }
        }
      </kendo-grid>
      @if (t.footer && t.footer.footnotes) {
        @for (z of t.footer.footnotes; track z) {
          <p class="small">{{ z }}</p>
        }
      }
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-stroked-button color="primary" (click)="downloadComparisonPdf()">
    <mat-icon>download</mat-icon>Download Comparison
  </button>
  <button mat-raised-button (click)="dialogRef.close('Cancel')">Close</button>
</mat-dialog-actions>
<ngx-loading
  [show]="loading"
  [config]="{
    fullScreenBackdrop: true,
    backdropBackgroundColour: 'rgba(0,0,0,.5)'
  }"
></ngx-loading>
