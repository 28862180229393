import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-book-management',
  templateUrl: './book-management.component.html',
  styleUrls: ['./book-management.component.scss'],
})
export class BookManagementComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {}

  unsubscribe: Subject<any> = new Subject();
  policyId = '';
  crumbConfig = [
    {
      label: 'Book Management',
      link: 'book-management',
    },
  ];

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (!isEmpty(params) && params?.id !== 'undefined') {
          this.policyId = params.id;
          this.crumbConfig.push({
            label: 'Policy Details',
            link: `book-management/${this.policyId}`,
          });
        } else {
          this.policyId = '';
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
