<mat-dialog-header>
  <h1>{{ headline }}</h1>
</mat-dialog-header>
<mat-dialog-content #scroll class="dialog-container">
  <form>
    <mat-form-field class="col-6" [ngClass]="{ 'col-12': data.app === 'cp' }">
      <input
        matInput
        [(ngModel)]="reportOptions.filename"
        name="text"
        [placeholder]="placeholder"
      />
    </mat-form-field>
    @if (data.app !== 'cp') {
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="reportOptions.threshold"
          name="number"
          placeholder="Minimum Bridge Score"
        />
      </mat-form-field>
    }
    <mat-checkbox
      [(ngModel)]="reportOptions.annReview"
      name="annReview"
      class="col-6"
    >
      Include Annual Review
    </mat-checkbox>
    <mat-checkbox
      [(ngModel)]="reportOptions.needSummary"
      name="needSummary"
      class="col-6"
    >
      Include Needs Summary
    </mat-checkbox>
    <mat-checkbox
      [(ngModel)]="reportOptions.needDetail"
      name="needDetail"
      class="col-6"
    >
      Include Needs Detail
    </mat-checkbox>
    @if (data.app === 'cp') {
      <mat-checkbox
        [(ngModel)]="reportOptions.prodSummary"
        name="prodSummary"
        class="col-6"
      >
        Include Product Summary
      </mat-checkbox>
    }
    @if (data.app === 'cp') {
      <mat-checkbox
        [(ngModel)]="reportOptions.prodDetail"
        name="prodDetail"
        class="col-6"
      >
        Include Product Details
      </mat-checkbox>
    }
    <mat-checkbox [(ngModel)]="specific" name="specific" class="col-6">
      Include Specific Suggestions
    </mat-checkbox>
    @if (data.needs && data.needs.length && specific) {
      <h4>Needs to Include</h4>
    }
    @if (specific) {
      @for (nd of data.needs; track nd) {
        <mat-checkbox
          [(ngModel)]="selectedBridges[nd.name]"
          name="{{ nd.name }}"
          class="col-6"
        >
          {{ nd.ShortName }}
        </mat-checkbox>
      }
    }
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="save()">
    {{ confirm }}
  </button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
