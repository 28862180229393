<div class="page-container asset-container container unified">
  <div class="row">
    <div class="chart-container col-12 row">
      <div class="chart col-12 col-lg-6">
        <div [chart]="chartData"></div>
        <h3 [hidden]="!bridges">{{ modelData.name }}</h3>
      </div>
      <div class="table-container col-12 col-lg-6">
        <ngx-datatable
          #table
          class="material"
          [rows]="rows"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="0"
          [rowHeight]="50"
        >
          <ngx-datatable-column name="className">
            <ng-template ngx-datatable-header-template>
              Asset Class
            </ng-template>
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span class="indicator" [style.background]="row.color"></span>
              {{ value }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="percentage" [flexGrow]="1">
            <ng-template ngx-datatable-header-template>
              Proposed Allocation
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{ value }}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
  <div class="reasons-container">
    <div class="row buttons-row">
      <div class="left col-12 col-lg-6">
        {{ modelData.desc }}
      </div>
      <div class="right col-12 col-lg-6">
        <mat-button-toggle-group
          #modelButtonGroup="matButtonToggleGroup"
          [hidden]="customModel && selModel === customModel.ModelID"
          [disabled]="savingStatus()"
        >
          @if (downModel && downModel.ModelID !== 'noModel') {
            <mat-button-toggle (click)="updateModel('down')" value="down">
              <mat-icon>keyboard_arrow_left</mat-icon>Less Aggressive
            </mat-button-toggle>
          }
          @if (downModel && downModel.ModelID === 'noModel') {
            <mat-button-toggle value="down" disabled>
              <mat-icon>keyboard_arrow_left</mat-icon>No Options
            </mat-button-toggle>
          }
          <mat-button-toggle (click)="updateModel('rec')" value="rec">
            Recommended Model
          </mat-button-toggle>
          @if (upModel && upModel.ModelID !== 'noModel') {
            <mat-button-toggle (click)="updateModel('up')" value="up">
              More Aggressive
              <mat-icon>keyboard_arrow_right</mat-icon>
            </mat-button-toggle>
          }
          @if (upModel && upModel.ModelID === 'noModel') {
            <mat-button-toggle value="up" disabled>
              No Options
              <mat-icon>keyboard_arrow_right</mat-icon>
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
        @if (customAlloc && customModel && selModel !== customModel.ModelID) {
          <button
            mat-raised-button
            color="primary"
            class="custom pull-left"
            (click)="updateModel('custom', true)"
          >
            <mat-icon>view_quilt</mat-icon>Use Custom Model
          </button>
        }
        @if (customAlloc && customModel && selModel === customModel.ModelID) {
          <button
            mat-raised-button
            color="primary"
            class="custom pull-left"
            (click)="updateModel('rec')"
          >
            <mat-icon>settings_backup_restore</mat-icon>Use Default Models
          </button>
        }
      </div>
    </div>
    <div class="row">
      <h3>Why your client scored this way:</h3>
    </div>
    @for (b of bridges; track b) {
      <div class="row">
        <strong class="col-12 large">
          <span
            [innerHtml]="
              evalTags(b.outline.sections.ShortName) | safeContent: 'html'
            "
          >
          </span>
          <span *hasPermission="'!RelevanceHideBridgeScores'">
            | {{ b.score / 10 }}
          </span>
        </strong>
        <div class="reasons container">
          @for (r of b.trueRules; track r) {
            <div class="reason row col-11 mx-auto">
              <strong>
                <img src="{{ evalIcon(r.score) }}" alt="reason score" />
                <span [innerHTML]="evalTags(r.headline)"></span>
                <span *hasPermission="'!RelevanceHideReasonScores'">
                  | {{ r.score / 10 }}
                </span>
              </strong>
              <span
                class="explanation"
                [innerHTML]="evalTags(r.explanation)"
              ></span>
            </div>
          }
        </div>
      </div>
    }
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
