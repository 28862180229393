import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ReportingService } from '../../reporting/reporting.service';
import { EnvironmentService } from '../../services/environment.service';
import { Platform } from '@angular/cdk/platform';
import { saveAs } from '@progress/kendo-file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-product-shelf-compare-dialog',
  templateUrl: './product-shelf-compare-dialog.component.html',
  styleUrls: ['./product-shelf-compare-dialog.component.scss'],
})
export class ProductShelfCompareDialogComponent implements OnInit {
  @ViewChild('scroll', { static: true }) private scrollContainer: ElementRef;
  @ViewChild(GridComponent, { static: false }) grid: GridComponent;

  loading = false;
  funds;
  gridData = [];
  columns = [];
  formattedFunds = [];
  tables = [];
  private baseUrl: string;
  private environment;
  tableDef = {
    id: 'compare1',
    name: 'compare1',
    label: 'table label',
    type: 'vert',
    header: {
      title: '',
      bullets: [],
    },
    footer: {
      footnotes: [],
    },
    columns: [
      {
        var: 'header1',
        label: 'Label',
      },
      {
        var: 'header1',
        label: 'Fund',
      },
    ],
    rows: [],
  };

  constructor(
    public dialogRef: MatDialogRef<ProductShelfCompareDialogComponent>,
    private rptSvc: ReportingService,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private snacky: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.funds = this.data.funds;
    this.tableDef.rows = this.data.rows ? [...this.data.rows] : [];
    this.formatTable(this.funds, this.tableDef);
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
  }

  formatTable(funds, table) {
    const rows = table['rows'],
      header = table['header'],
      footer = table['footer'],
      data = [],
      columnData = [];

    this.formattedFunds = [];

    funds?.forEach((x, i) => {
      const currFund = x;
      this.formattedFunds[i] = {};
      rows.forEach(y => {
        if (!y.header) {
          if (y.var == 'name') {
            this.formattedFunds[i][y.var] = currFund[y.var];
          } else if (
            Object.prototype.hasOwnProperty.call(currFund, y.var) &&
            y.validation
          ) {
            const display = y.validation.find(
              val =>
                val.value === currFund[y.var] || val.label === currFund[y.var]
            );
            this.formattedFunds[i][y.var] = display ? display.label : null;
          } else if (Object.prototype.hasOwnProperty.call(currFund, y.var)) {
            if (y.format) {
              switch (y.format) {
                case 'perc2':
                  this.formattedFunds[i][y.var] = `${(
                    currFund[y.var] * 100
                  ).toFixed(2)}%`;
                  break;
                case 'perc':
                  this.formattedFunds[i][y.var] = `${(
                    currFund[y.var] * 100
                  ).toFixed(0)}%`;
                  break;
                case 'curr':
                  this.formattedFunds[i][y.var] = currFund[
                    y.var
                  ].toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  });
                  break;
                default:
                  this.formattedFunds[i][y.var] = currFund[y.var];
                  break;
              }
            } else {
              this.formattedFunds[i][y.var] = currFund[y.var];
            }
          }
        }
      });
      this.formattedFunds[i].id = x;
    });
    this.formattedFunds.forEach((x, i) => {
      columnData[i] = { id: 'a' + x.id.uniqueCode, name: x.id.productName };
    });

    const dataLength = this.formattedFunds.length - 1,
      propertiesLength = rows.length - 1;

    for (let i = 0; i <= propertiesLength; i++) {
      data[i] = {};
      for (let j = 0; j <= dataLength; j++) {
        const currentItem = this.formattedFunds[j];
        const property = Object.keys(currentItem)[i];
        const label =
          property &&
          property != 'id' &&
          rows.find(q => {
            return q.var == property;
          })
            ? rows.find(q => {
                return q.var == property;
              }).label
            : null;

        if (j === 0) {
          data[i]['metric'] = label;
        }

        data[i]['a' + currentItem.id.uniqueCode] = currentItem[property];
      }
    }

    this.gridData = data.filter(x => {
      let content = false;
      Object.keys(x).forEach((z, i) => {
        if (i > 0 && !content) {
          content = (x[z] || x[z] == 0) && (x[z].length > 0 || !isNaN(x[z]));
        }
      });
      return content;
    });
    this.columns = columnData;

    this.scrollContainer.nativeElement.scrollTop = 0;
    if (this.columns.length > 0) {
      this.tables.push({
        gridData: this.gridData,
        columns: this.columns,
        header: header,
        footer: footer,
      });
    }
  }

  horizontalTable(funds, table) {
    const header = null,
      footer = table['footer'];

    funds.forEach(el => {
      el.name = el.ShortName;
      if (el.subBridges) {
        table.rows.forEach(col => {
          if (!Object.keys(el).includes(col.var)) {
            el.subBridges.forEach(sub => {
              Object.keys(sub).forEach(() => {
                el[col.var] = sub[col.var] ? sub[col.var] : el[col.var];
              });
            });
          }
        });
      }
    });

    this.gridData = funds;
    this.columns = table.rows.map(z => {
      return { id: z.var, name: z.label };
    });

    this.tables.push({
      gridData: this.gridData,
      columns: this.columns,
      header: header,
      footer: footer,
      type: 'horz',
    });
  }

  downloadComparisonPdf() {
    this.loading = true;
    const reportData = {
      gridData: this.gridData,
      columns: this.columns,
    };

    this.rptSvc.getComparisonReport(reportData, 'aw').subscribe(
      resp => {
        const filename = `annuity-comparison.pdf`;
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        let fileData;
        const reader = new FileReader();

        reader.readAsDataURL(resp);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
        };
        this.loading = false;
      },
      () => {
        this.snacky.open(
          `There was an error downloading this file. Please try again or contact your administrator.`,
          'Close',
          {
            duration: 10000,
          }
        );
        this.loading = false;
      }
    );
  }
}
