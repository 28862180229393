import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-product-compare-modal',
  templateUrl: './product-compare-modal.component.html',
  styleUrls: ['./product-compare-modal.component.scss'],
})
export class ProductCompareModalComponent implements OnInit {
  changeDates = this.data.changeDates;
  runCounts = this.data.runCounts;
  dialogTitle = '';
  gridRows;
  highlightExclusions = ['ChangeID', 'DateTime'];

  constructor(
    private admnSrvc: AdminService,
    private dialogRef: MatDialogRef<ProductCompareModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const { productCode, profileId, solution, changeIDs } = this.data;
    const changeHistoryData = {
      ProfileID: profileId,
      ProductCode: productCode,
      ChangeID: changeIDs,
    };

    this.admnSrvc
      .getProductChangeHistory(changeHistoryData, solution)
      .subscribe(({ productName, rows }) => {
        this.dialogTitle = productName;
        this.gridRows = rows;
      });
  }

  close() {
    this.dialogRef.close({ data: 'cancel' });
  }
}
