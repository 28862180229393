<mat-dialog-content class="dialog-container">
  <h1>{{ data.name }}</h1>
  <mat-toolbar color="primary">
    <button mat-button> <mat-icon>compare_arrows</mat-icon>Compare </button>
    <button mat-button>
      <mat-icon>view_list</mat-icon>Show More Options
    </button>
  </mat-toolbar>
  <div class="row">
    <ul class="funds">
      @for (x of funds; track x) {
        <li class="fund">
          <div class="title row">
            <button
              mat-button
              (click)="
                selectFund($event, {
                  name: x.name,
                  cusip: x.cusip,
                  amount: 0,
                  symbol: x.symbol,
                  assetClass: x.assetClass
                })
              "
            >
              <mat-icon>check_circle</mat-icon> Select
            </button>
            <h4 class="col-9">
              {{ x.name }}
            </h4>
            <button mat-button (click)="expand($event)">
              <mat-icon>add_circle</mat-icon> Details
            </button>
          </div>
          <h5 class="col-11 mx-auto">Average Annual Returns</h5>
          <table class="details col-11 mx-auto">
            <thead>
              <tr>
                <th>{{ x.name }}</th>
                <th>YTD</th>
                <th>1 Mo</th>
                <th>1 Yr</th>
                <th>3 Yr</th>
                <th>5 Yr</th>
                <th>10 Yr</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>NAV</td>
                <td>4.51</td>
                <td>0.49</td>
                <td>14.31</td>
                <td>7.69</td>
                <td>11.49</td>
                <td>6.03</td>
              </tr>
              <tr>
                <td>w/ Sales Charges</td>
                <td>-3.23</td>
                <td>-1.39</td>
                <td>-4.16</td>
                <td>-3.06</td>
                <td>-3.02</td>
                <td>-1.01</td>
              </tr>
              <tr>
                <td>Difference from Benchmark(Custom Blended)</td>
                <td>-2.56</td>
                <td>-1.32</td>
                <td>-2.98</td>
                <td>-0.97</td>
                <td>-1.54</td>
                <td>-0.06</td>
              </tr>
              <tr>
                <td>% Rank in Category</td>
                <td>93</td>
                <td>95</td>
                <td>80</td>
                <td>74</td>
                <td>83</td>
                <td>56</td>
              </tr>
              <tr>
                <td># of Funds in Category</td>
                <td>1438</td>
                <td>1486</td>
                <td>1353</td>
                <td>1198</td>
                <td>1060</td>
                <td>793</td>
              </tr>
              <tr>
                <td>Standard Deviation</td>
                <td>&mdash;</td>
                <td>&mdash;</td>
                <td>&mdash;</td>
                <td>13.12</td>
                <td>14.82</td>
                <td>17.72</td>
              </tr>
            </tbody>
          </table>
          <h5 class="col-11 mx-auto">Reasons for this Fund</h5>
          <div class="details col-11 mx-auto">
            <strong
              ><img src="./assets/positive.png" />The objective of this fund
              matches your risk and timeline</strong
            >
            <p
              >This investment seeks to provide for retirement outcomes based on
              quantitatively measured risk. The fund invests its assets in a
              combination of equity (including real estate investment trusts)
              and bond exchange traded funds and a money market fund (the
              "underlying funds") in proportion to the fund's own comprehensive
              investment strategy. As of March 31, 2016, the fund held
              approximately 75% of its assets in underlying funds that invest
              primarily in equity securities, 25% of its assets in underlying
              funds that invest primarily in bonds and the remainder of its
              assets in underlying funds that invest primarily in money market
              instruments.</p
            >
          </div>
          <div class="details col-11 mx-auto">
            <strong
              ><img src="./assets/positive.png" />This funds Capture Ratio of
              this product is lower than its peers</strong
            >
            <p
              >The Capture Ratio is calculated by dividing a funds 'Upside
              Capture' by its 'Downside Capture' ratios. The Upside Capture
              Ratio measures a manager’s performance in up markets relative to a
              particular benchmark. Downside Capture Ratio measures a manager’s
              performance in down markets relative to a particular benchmark.
              This comparative tool would demonstrate the manager’s
              effectiveness at managing the downside risks of the market. The
              Capture Ratio for this fund is: XX% for that past 3 years, XX% for
              the past 5 years, and XX% for the past 10 years.</p
            >
          </div>
        </li>
      }
    </ul>
    <div class="row buttons">
      @if (data.multiple) {
        <button
          mat-raised-button
          color="accent"
          (click)="dialogRef.close('Save')"
          >Save</button
        >
      }
      <button mat-raised-button (click)="dialogRef.close('Cancel')"
        >Cancel</button
      >
    </div>
  </div>
</mat-dialog-content>
