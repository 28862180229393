<span class="utility-apps-container">
  @for (utility of utilityApps; track utility.link) {
    @if ((utility.right && rights?.includes(utility.right)) || !utility.right) {
      <button
        mat-stroked-button
        class="menu-button utility-app"
        (click)="goToPage(utility.link)"
      >
        {{ utility.label }} <mat-icon>{{ utility.icon }}</mat-icon>
      </button>
    }
  }

  @if (showLearnButton) {
    <button
      mat-stroked-button
      class="menu-button utility-app"
      (click)="goToPage('/training')"
    >
      Training<mat-icon>school</mat-icon>
    </button>
  }

  @if (hasUtilityApps) {
    <button
      mat-stroked-button
      [matMenuTriggerFor]="utilityAppMenu"
      class="menu-button utility-app-toggle"
    >
      <mat-icon class="mr-0">widgets</mat-icon>
    </button>
  }

  <mat-menu
    #utilityAppMenu="matMenu"
    direction="down"
    yPosition="below"
    class="unified-menu"
  >
    @for (utility of utilityApps; track utility.link) {
      @if (
        (utility.right && rights?.includes(utility.right)) || !utility.right
      ) {
        <button mat-button class="menu-button" (click)="goToPage(utility.link)">
          {{ utility.label }} <mat-icon>{{ utility.icon }}</mat-icon>
        </button>
      }
    }
    @if (showLearnButton) {
      <button mat-button class="menu-button" (click)="goToPage('/training')">
        Training<mat-icon>school</mat-icon>
      </button>
    }
  </mat-menu>
</span>
