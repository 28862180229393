<mat-form-field class="mr-3">
  <input
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="mutualFund.unit"
    [matAutocomplete]="mutualFundAuto"
    name="mutualFundsUnitsControl"
    [formControl]="mutualFundsUnitsControl"
  />
  <mat-autocomplete #mutualFundAuto="matAutocomplete">
    @for (unit of filteredUnits | async; track unit) {
      <mat-option [value]="unit.id">
        {{ unit.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="mr-3">
  <input
    matInput
    [matDatepicker]="startPicker"
    placeholder="Start Date"
    [(ngModel)]="mutualFund.start"
  />
  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
  <mat-datepicker #startPicker></mat-datepicker>
</mat-form-field>
<mat-form-field class="mr-3">
  <input
    matInput
    [matDatepicker]="endPicker"
    placeholder="End Date"
    [(ngModel)]="mutualFund.end"
  />
  <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
  <mat-datepicker #endPicker></mat-datepicker>
</mat-form-field>
<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getMfReport()"
  [disabled]="!mutualFund.unit"
>
  Download
</button>
@if (mutualFundData.data) {
  <kendo-grid
    class="col-12 mt-3 usage-grid"
    [kendoGridBinding]="mutualFundData.data"
    [height]="600"
    [selectable]="true"
  >
    @for (col of mutualFundData['header']; track col) {
      <kendo-grid-column
        [field]="col.DataField"
        [title]="col.Label"
      ></kendo-grid-column>
    }
  </kendo-grid>
}
