import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Template } from '../../models/account-models';
import { AccountsService } from '../services/accounts-service.service';
import { MaskService } from '../../services/mask.service';

@Component({
  selector: 'app-shared-template-modal',
  templateUrl: './shared-template-modal.component.html',
  styleUrls: ['./shared-template-modal.component.scss'],
})
export class SharedTemplateModalComponent {
  template: Template = {} as Template;
  loading = false;
  templateId = new FormControl();

  constructor(
    private dialogRef: MatDialogRef<SharedTemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private as: AccountsService,
    private msk: MaskService
  ) {}

  saveData() {
    this.loading = true;
    this.as.copyTemplate(this.templateId.value).subscribe(resp => {
      this.loading = false;
      this.dialogRef.close(resp.results);
      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    });
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
