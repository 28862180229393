import { Component, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, Subject } from 'rxjs';
import { SessionStorageService } from '../../../services/session-storage.service';

@Component({
  selector: 'app-form-stepper',
  styleUrls: ['stepper.component.scss'],
  template: `<mat-horizontal-stepper (selectionChange)="stepChange($event)">
    @for (
      step of field.fieldGroup | visible;
      track step;
      let index = $index;
      let last = $last;
      let first = $first
    ) {
      @if (step.hide === undefined || step.hide === false) {
        <mat-step
          errorMessage="Required Fields Not Filled Out"
          [ngClass]="{ error: checkStepValidity(index) }"
        >
          <ng-template matStepLabel>
            <span [ngClass]="{ error: checkStepValidity(index) }">{{
              step.props.label
            }}</span>
            <span class="message"
              >All Required Fields Have Not Been Filled Out</span
            >
          </ng-template>
          <formly-field [field]="step"></formly-field>
          <div class="row">
            @if (!first) {
              <button
                mat-button
                matStepperPrevious
                class="btn btn-primary pull-left"
                type="button"
              >
                <mat-icon>arrow_back</mat-icon>
                Back
              </button>
            }
            @if (!last) {
              <button
                mat-button
                matStepperNext
                class="btn btn-primary pull-right"
                type="button"
                [disabled]="last"
              >
                Next<mat-icon>arrow_forward</mat-icon>
              </button>
            }
            @if (last) {
              <button
                (click)="checkClick()"
                mat-raised-button
                type="submit"
                color="accent"
                class="main-button"
                [disabled]="loading"
              >
                {{ loading ? 'Loading...' : nextButtonText }}
                @if (!loading) {
                  <mat-icon>arrow_forward</mat-icon>
                }
              </button>
            }
          </div>
        </mat-step>
      }
    }
    <ngx-loading [show]="loading"></ngx-loading>
  </mat-horizontal-stepper>`,
})
export class FormlyStepperComponent
  extends FieldType<FormlyFieldConfig>
  implements OnInit
{
  @Output() stepChanged;

  errorMessage$;
  formErrors = {};
  touchedChanged$;
  visitedSteps = {};
  nextButtonText: string;
  app;
  loading = false;

  constructor(private ss: SessionStorageService) {
    super();
  }

  ngOnInit() {
    this.app = this.ss.get('currentApp');

    switch (this.app) {
      case 'iw':
        this.nextButtonText = 'Next';
        break;
      case 'aw':
        this.nextButtonText = 'Evaluate Annuities';
        break;
      case 'pp':
        this.nextButtonText = 'Review Results';
        break;
      case 'cp':
        this.nextButtonText = 'View Client Needs';
        break;
      case 'li':
        this.nextButtonText = 'Next';
        break;
      default:
        this.nextButtonText = 'Submit';
        break;
    }
  }

  checkClick() {
    setTimeout(() => {
      if (this.form.status !== 'INVALID') {
        this.loading = true;
      }
    });
  }

  getErrors(fg) {
    this.formErrors = {};

    const visible = fg.filter(x => !x.hide);
    visible.forEach((group, idx) => {
      this.formErrors[`step${idx}`] = false;

      group.fieldGroup.forEach(field => {
        if (field.formControl.errors && !field.hide) {
          this.formErrors[`step${idx}`] = this.visitedSteps[`step${idx}`]
            ? true
            : false;
        } else if (
          (field.formControl instanceof FormGroup ||
            field.formControl instanceof FormArray) &&
          !field.hide
        ) {
          field.fieldGroup.forEach(grpField => {
            if (grpField.formControl.errors && !grpField.hide) {
              this.formErrors[`step${idx}`] = this.visitedSteps[`step${idx}`]
                ? true
                : false;
            } else if (
              (grpField.formControl instanceof FormGroup ||
                grpField.formControl instanceof FormArray) &&
              !grpField.hide
            ) {
              grpField.fieldGroup.forEach(subField => {
                if (subField.formControl.errors && !subField.hide) {
                  this.formErrors[`step${idx}`] = this.visitedSteps[
                    `step${idx}`
                  ]
                    ? true
                    : false;
                }
              });
            }
          });
        }
      });
    });
  }

  checkStepValidity(idx) {
    return this.formErrors[`step${idx}`];
  }

  stepChange(ev) {
    this.visitedSteps[`step${ev.previouslySelectedIndex}`] = true;
  }

  emitStepChangeEvent() {
    this.stepChanged.emit({
      form: this.field.fieldGroup,
      visitedSteps: this.visitedSteps,
    });
  }

  validateFields(fg, group?) {
    if (Array.isArray(fg) && !group) {
      fg.forEach(field => {
        const control = field;
        if (control instanceof FormControl) {
          control.markAsTouched();
          control.updateValueAndValidity();
        } else if (
          control instanceof FormGroup ||
          control instanceof FormArray
        ) {
          this.validateFields(control, true);
        } else {
          this.validateFields(control.formControl, true);
        }
      });
    } else if (group && !(fg instanceof FormControl)) {
      Object.keys(fg.controls).forEach(field => {
        const control = fg.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched();
          control.updateValueAndValidity();
        } else if (
          control instanceof FormGroup ||
          control instanceof FormArray
        ) {
          control.markAsTouched();
          control.updateValueAndValidity();
          this.validateFields(control, true);
        }
      });
    } else if (fg instanceof FormControl) {
      fg.markAsTouched();
      fg.updateValueAndValidity();
    }
  }
}

/**
 * Extract arguments of function
 */
export type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

/**
 * Creates an object like O. Optionally provide minimum set of properties P which the objects must share to conform
 */
type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>;

/* Extract a touched changed observable from an abstract control
 * @param control AbstractControl like object with markAsTouched method
 */
export const extractTouchedChanges = (
  control: ObjectLike<AbstractControl, 'markAsTouched' | 'markAsUntouched'>
): Observable<boolean> => {
  const prevMarkAsTouched = control.markAsTouched;
  const prevMarkAsUntouched = control.markAsUntouched;

  const touchedChanges$ = new Subject<boolean>();

  function nextMarkAsTouched(
    ...args: ArgumentsType<AbstractControl['markAsTouched']>
  ) {
    touchedChanges$.next(true);
    prevMarkAsTouched.bind(control)(...args);
  }

  function nextMarkAsUntouched(
    ...args: ArgumentsType<AbstractControl['markAsUntouched']>
  ) {
    touchedChanges$.next(false);
    prevMarkAsUntouched.bind(control)(...args);
  }

  control.markAsTouched = nextMarkAsTouched;
  control.markAsUntouched = nextMarkAsUntouched;

  return touchedChanges$;
};
