<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Callbacks / Follow Up </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Opportunities Available</h4>
      </div>
    }
    <div class="title-buttons justify-content-between">
      @if (showCallBack) {
        <strong>Callbacks</strong>
      }
      @if (showCallBack) {
        <button mat-button class="call-back lists" (click)="callBackSearch()">
          <mat-icon>phone_callback</mat-icon>See your callbacks (Last 7 days)
        </button>
      }
    </div>
    @if (showCallBack) {
      <table>
        <thead>
          <th>Name</th>
          <th>Call Score</th>
          <th>Call Type</th>
          <th>Call Date</th>
        </thead>
        <tbody>
          @for (c of callbackList; track c) {
            <tr (click)="goToProfile($event, c.id)">
              <td>{{ c.name }}</td>
              <td>{{ c.score }}</td>
              <td>{{ c.type }}</td>
              <td>{{ c.date }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
    <div class="title-buttons justify-content-between">
      <strong>Follow Ups</strong>
      @if (showCallList) {
        <button mat-button class="call-list lists" (click)="callListSearch()">
          <mat-icon>contact_phone</mat-icon>See your call list
        </button>
      }
    </div>
    <table>
      <thead>
        <th>Name</th>
        <th>Suggestion</th>
        <th>Detail</th>
      </thead>
      <tbody>
        @for (c of followupList; track c) {
          <tr (click)="goToProfile($event, c.id)">
            <td>{{ c.name }}</td>
            <td>{{ c.suggestion }}</td>
            <td>{{ c.detail }}</td>
          </tr>
        }
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
