@if (data.name?.toLowerCase() !== 'pershing') {
  <app-ag-print
    [profile]="data.profile"
    [customDefinition]="data.reportDef?.definition"
    (closeDialog)="closeModal($event)"
  ></app-ag-print>
}
@if (data.name?.toLowerCase() === 'pershing') {
  <app-pershing-print
    [profile]="data.profile"
    [data]="data"
    [headline]="data.headline"
    [content]="data.content"
    [accountTypes]="data.accounts"
    (closeDialog)="closeModal($event)"
  ></app-pershing-print>
}
