<button mat-button color="{{ params?.color }}" [matMenuTriggerFor]="printMenu"
  ><mat-icon>insert_drive_file</mat-icon> Generate Report</button
>
<mat-menu #printMenu="matMenu">
  @if (customPrintDefinition && customPrintDefinition.name) {
    <button mat-menu-item (click)="customReport()">
      {{ customPrintDefinition.name }}
    </button>
  }
  @if (app?.toLowerCase() !== 'aw' && !rights?.includes('HideStandardReport')) {
    <button mat-menu-item (click)="generateStandardReport()">
      Standard Report
    </button>
    @if (isRollover) {
      @if (rights?.includes('RolloverReport')) {
        <button mat-menu-item (click)="generateRolloverReport()">
          Rollover Report
        </button>
      }
    }
  }
  @if (app?.toLowerCase() !== 'aw' && rights?.includes('ConsolidatedPrint')) {
    <button mat-menu-item (click)="consolidatedReport()">
      Consolidated Report
    </button>
  }
  @if (app?.toLowerCase() === 'aw') {
    @if (
      assessments && !assessments?.includes('validate') && !annuitySelected
    ) {
      <p class="px-3 pt-3 warning">
        Please select an annuity in order to generate a report
      </p>
    }
    @if (rights?.includes('AwFullReport')) {
      <button
        mat-menu-item
        (click)="generateStandardReport()"
        [ngClass]="{
          disabled:
            assessments && assessments?.includes('validate')
              ? false
              : !annuitySelected
        }"
        matTooltip="Select one annuity below in order to generate a report"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        [matTooltipDisabled]="
          assessments && assessments?.includes('validate')
            ? true
            : annuitySelected
        "
      >
        Standard Report
      </button>
    }
    @if (rights?.includes('AwCFVReport')) {
      <button
        mat-menu-item
        (click)="generateClientReport()"
        [ngClass]="{
          disabled:
            assessments && assessments?.includes('validate')
              ? false
              : !annuitySelected
        }"
        matTooltip="Select one annuity below in order to generate a report"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        [matTooltipDisabled]="
          assessments && assessments?.includes('validate')
            ? true
            : annuitySelected
        "
      >
        Client Report
      </button>
    }
    @if (rights?.includes('ExchangeReport') && isExchange) {
      <button
        mat-menu-item
        (click)="generateExchangeReport()"
        [ngClass]="{ disabled: !annuitySelected }"
        matTooltip="Select one annuity below in order to generate an exchange report"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        [matTooltipDisabled]="annuitySelected"
      >
        Exchange Report
      </button>
    }
    @if (rights?.includes('ConsolidatedPrint')) {
      <button
        mat-menu-item
        (click)="consolidatedReport()"
        [ngClass]="{
          disabled:
            assessments && assessments?.includes('validate')
              ? false
              : !annuitySelected
        }"
        matTooltip="Select one annuity below in order to generate a report"
        [matTooltipPosition]="'above'"
        [matTooltipClass]="'rb-tooltip'"
        [matTooltipDisabled]="
          assessments && assessments.includes('validate')
            ? true
            : annuitySelected
        "
      >
        Consolidated Report
      </button>
    }
  }
</mat-menu>
