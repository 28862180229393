import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ES6Parser, ES6StaticEval } from 'espression';

@Component({
  selector: 'app-form-integer-exp',
  styleUrls: ['form-integerExp.component.scss'],
  template: `
    @if (props.label) {
      <label class="section col-12" *[innerHTML]="props.label"></label>
    }
    @if (props.prompt) {
      <p class="explanation" [innerHTML]="props.prompt"></p>
    }
    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.placeholder }}</mat-label>
      <input
        matInput
        type="number"
        [placeholder]="props.placeholder"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
      />
      @if (props.help) {
        <mat-icon
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
        >
          help
        </mat-icon>
      }
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error>
          This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.
        </mat-error>
      }
    </mat-form-field>
  `,
})
export class FormlyIntegerExpComponent extends FieldType {
  stringEval(str) {
    const parser = new ES6Parser();
    const staticEval = new ES6StaticEval();

    const ast = parser.parse(str.replace('.', '_'));
    return staticEval.evaluate(ast);
  }
}
