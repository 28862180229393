<div class="page-container rmd-container container unified-context">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12"
    >RMD Calculation
    @if (calcComplete) {
      <button
        mat-button
        color="primary"
        class="header-button"
        (click)="editCalc()"
      >
        <mat-icon>create</mat-icon>Edit Calculation
      </button>
    }
    @if (calcComplete) {
      <button
        mat-button
        color="primary"
        class="header-button"
        (click)="resetCalc()"
      >
        <mat-icon>clear</mat-icon>Clear Calculation
      </button>
    }
  </h1>
  @if (!calcComplete) {
    <form
      #rmdForm="ngForm"
      (ngSubmit)="rmdForm.form.valid && calculate(formData)"
    >
      <div class="row">
        <h2 class="col-12">Client Information</h2>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Issue Age</mat-label>
          <input
            matInput
            name="AgeAtIssue"
            [(ngModel)]="formData.AgeAtIssue"
            type="number"
            placeholder="Age"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Mortality Age</mat-label>
          <input
            matInput
            name="MortalityAge"
            [(ngModel)]="formData.MortalityAge"
            type="number"
            placeholder="Mortality Age"
            required
          />
        </mat-form-field>
      </div>
      <div class="row">
        <h2 class="col-12">Income Annuity</h2>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Initial Premium</mat-label>
          <input
            matInput
            name="IncomeAnnuity"
            [maskito]="dollarMask"
            maxlength="20"
            [(ngModel)]="formData.IncomeAnnuity"
            placeholder="Initial Premium"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Income Annuity Growth Rate</mat-label>
          <input
            matInput
            name="IncomeAnnuityRate"
            [maskito]="percentMask"
            [(ngModel)]="formData.IncomeAnnuityRate"
            placeholder="Income Annuity Growth Rate"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Age to Start Income</mat-label>
          <input
            matInput
            name="AgeAtFirstWithdrawal"
            [(ngModel)]="formData.AgeAtFirstWithdrawal"
            type="number"
            placeholder="Age to Start Income"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Guaranteed Annual Income</mat-label>
          <input
            matInput
            name="LifetimeIncome"
            [maskito]="dollarMask"
            maxlength="20"
            [(ngModel)]="formData.LifetimeIncome"
            placeholder="Guaranteed Annual Income"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Rising Income Rate</mat-label>
          <input
            matInput
            name="IncomeRisingRate"
            [maskito]="percentMask"
            [(ngModel)]="formData.IncomeRisingRate"
            placeholder="Rising Income Rate"
            required
          />
        </mat-form-field>
      </div>
      <div class="row">
        <h2 class="col-12">Investment Portfolio</h2>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Equities</mat-label>
          <input
            matInput
            name="EquityHoldings"
            [maskito]="dollarMask"
            maxlength="20"
            [(ngModel)]="formData.EquityHoldings"
            placeholder="Equities"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Equity Growth Rate</mat-label>
          <input
            matInput
            name="EquityHoldingsRate"
            [maskito]="percentMask"
            [(ngModel)]="formData.EquityHoldingsRate"
            placeholder="Equity Growth Rate"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Bonds</mat-label>
          <input
            matInput
            name="FixedIncome"
            [maskito]="dollarMask"
            maxlength="20"
            [(ngModel)]="formData.FixedIncome"
            placeholder="Bonds"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Bonds Growth Rate</mat-label>
          <input
            matInput
            name="FixedIncomeRate"
            [maskito]="percentMask"
            [(ngModel)]="formData.FixedIncomeRate"
            placeholder="Bonds Growth Rate"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Investment Annuities</mat-label>
          <input
            matInput
            name="Annuity"
            [maskito]="dollarMask"
            maxlength="20"
            [(ngModel)]="formData.Annuity"
            placeholder="Investment Annuities"
            required
          />
        </mat-form-field>
        <mat-form-field class="col-3 mb-3">
          <mat-label>Annuity Growth Rate</mat-label>
          <input
            matInput
            name="AnnuityRate"
            [maskito]="percentMask"
            [(ngModel)]="formData.AnnuityRate"
            placeholder="Annuity Growth Rate"
            required
          />
        </mat-form-field>
      </div>
      <div class="row col-6">
        <button mat-raised-button color="accent" type="submit">
          <mat-icon>polls</mat-icon>Calculate
        </button>
      </div>
    </form>
  }

  @if (calcComplete) {
    <div>
      <div class="row my-3">
        @for (chart of chartData; track chart) {
          <div class="chart-container col-6 mx-auto results-table">
            <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="chart.options"
              class="w-100 mx-auto"
            ></highcharts-chart>
          </div>
        }
      </div>
      <h2 class="mt-5 mb-3"
        >Value at Death: {{ tableHeaderValue | currency }}</h2
      >
      <kendo-grid
        class="col-12 search-grid"
        #resultsGrid="kendoGrid"
        height="500"
        [scrollable]="true"
        [kendoGridBinding]="tableData"
        [pageable]="false"
        [sortable]="{ allowUnsort: true, mode: 'multiple' }"
        [filterable]="'menu'"
        [columnMenu]="true"
        [selectable]="false"
        [autoSize]="true"
      >
        <kendo-grid-column field="Age" title="Age"></kendo-grid-column>
        <kendo-grid-column
          field="PortfolioValue"
          title="Investment Portfolio Value"
          format="{0:c}"
        ></kendo-grid-column>
        <kendo-grid-column
          field="RMDFromPortfolio"
          title="Investment Portfolio Withdrawal"
          format="{0:c}"
        ></kendo-grid-column>
        <kendo-grid-column
          field="RMDFromPortfolioPct"
          title="Withdrawal as Percent"
          format="{0:p2}"
        ></kendo-grid-column>
        <kendo-grid-column
          field="AnnuityValue"
          title="Income Annuity Value"
          format="{0:c}"
        ></kendo-grid-column>
        <kendo-grid-column
          field="LifetimeWithdrawal"
          title="Lifetime Income"
          format="{0:c}"
        ></kendo-grid-column>
        <kendo-grid-column
          field="LifetimeWithdrawalPct"
          title="Effective Withdrawal Rate"
        ></kendo-grid-column>
        <kendo-grid-column
          field="RMDNeeded"
          title="Required Minimum Distribution"
          format="{0:c}"
        ></kendo-grid-column>
        <kendo-grid-column
          field="TotalValue"
          title="Total Porfolio Value"
          format="{0:c}"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  }
  <ngx-loading [show]="loading"></ngx-loading>
</div>
