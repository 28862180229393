<mat-dialog-header>
  <h1><mat-icon>warning</mat-icon>{{ headline }}</h1>
</mat-dialog-header>
<mat-dialog-content class="dialog-container col-12">
  <div class="inner row col-12" [innerHTML]="content"></div>
</mat-dialog-content>
<div mat-dialog-actions class="row buttons">
  <button
    mat-raised-button
    color="accent"
    (click)="dialogRef.close('continue')"
  >
    {{ confirm }}
  </button>
  @if (!hideCancel) {
    <button mat-raised-button (click)="dialogRef.close('cancel')">
      Cancel
    </button>
  }
</div>
