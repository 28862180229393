<button
  mat-button
  class="grid-column-button"
  [matMenuTriggerFor]="menu"
  color="primary"
>
  <mat-icon>view_column</mat-icon>Column Options
</button>
<mat-menu #menu="matMenu" class="column-menu">
  @for (col of columns; track col) {
    <button
      (click)="selectionChange($event, col['DataField'])"
      mat-menu-item
      [value]="col['DataField']"
      [ngClass]="{ selected: updatedColumns.includes(col['DataField']) }"
    >
      <mat-icon class="empty">check_box_outline_blank</mat-icon>
      <mat-icon class="checked">check_box</mat-icon>
      {{ col['Label'] }}
    </button>
  }
</mat-menu>
