import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedComponentsModule } from '../shared-components/shared-components.module';

import { AnnuityLookupModule } from '../annuity-lookup/annuity-lookup.module';
import { RixtremaModule } from '../rixtrema/rixtrema.module';
import { FundFeeLookupModule } from '../fund-fee-lookup/fund-fee-lookup.module';

import { AdminService } from '../admin/admin.service';

import { MatStepperModule } from '@angular/material/stepper';
import {
  CdkDrag,
  CdkDropList,
  CdkDragPlaceholder,
  CdkDragPreview,
} from '@angular/cdk/drag-drop';

import { FormlyComponent } from './formly.component';
import { FormlyCustomCurrencyComponent } from './components/form-currency/form-currency.component';
import { FormlyCustomCurrencyExpComponent } from './components/form-currencyExp/form-currencyExp.component';
import { FormlyCustomInputComponent } from './components/form-input/form-input.component';
import { FormlyCustomInputExpComponent } from './components/form-inputExp/form-inputExp.component';
import { FormlyRadioExpComponent } from './components/form-radioExp/form-radioExp.component';
import { FormlyRadioComponent } from './components/form-radio/form-radio.component';
import { FormlyIntegerExpComponent } from './components/form-integerExp/form-integerExp.component';
import { FormlyNumberExpComponent } from './components/form-numberExp/form-numberExp.component';
import { FormlyMultiSelectComponent } from './components/form-multi/form-multiselect.component';
import { FormlyPercentageComponent } from './components/form-percentage/form-percentage.component';
import { FormlyDropExpComponent } from './components/form-dropExp/form-dropExp.component';
import { FormlyDropComponent } from './components/form-drop/form-drop.component';
import { FormlyMessageComponent } from './components/form-message/form-message.component';
import { FormlySectionHeaderComponent } from './components/form-sectionHeader/form-sectionHeader.component';
import { FormlyToggleComponent } from './components/form-toggle/form-toggle.component';
import { FormlyCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormlyCustomDateComponent } from './components/form-date/form-date.component';
import { FormlyRepeatingComponent } from './components/form-repeating/form-repeating.component';
import { PanelWrapperComponent } from './components/panel/panel-wrapper.component';
import { FormlyButtonComponent } from './components/form-button/form-button.component';
import { FormlyYesNoComponent } from './components/form-yesno/form-yesno.component';
import { FormlyTextAreaComponent } from './components/form-textarea/form-textarea.component';
import { FormlyPercentageExpComponent } from './components/form-percentageExp/form-percentageExp.component';
import { FormlyNumberComponent } from './components/form-number/form-number.component';
import { FormlyRadioValidationComponent } from './components/form-radioValidation/form-radioValidation.component';
import { FormlyHashComponent } from './components/form-hash/form-hash.component';
import { FormlyInsuredSwapComponent } from './components/form-insuredSwap/form-insuredSwap.component';
import { FormlyRepCodeComponent } from './components/form-repCode/form-repCode.component';
import { FormlyStatusComponent } from './components/form-status/form-status.component';
import { FormlyDataGridComponent } from './components/form-dataGrid/form-dataGrid.component';
import { FormlyGridExpandComponent } from './components/form-gridExpand/form-gridExpand.component';
import { FormlyStepperComponent } from './components/stepper/stepper.component';
import { FormlyCompactRepeatComponent } from './components/form-compactRepeat/form-compactRepeat.component';
import { FormlyRankOrderComponent } from './components/form-rankOrder/form-rankOrder.component';
import { FormlyGridComponent } from './components/form-grid/form-grid.component';
import { FormlyTabsComponent } from './components/tabs/tabs.component';
import { FormlyTypeAheadComponent } from './components/form-typeAhead/form-typeAhead.component';
import { FormlyRadioValidationExpComponent } from './components/form-radioValidationExp/form-radioValidationExp.component';

// import { FundSearchComponent } from '../shared-components/fund-search/fund-search.component';
import { TemplatePickerComponent } from '../template-picker/template-picker.component';
import { UnifiedShellComponent } from './components/unified-shell/unified-shell.component';

import { MaskitoDirective } from '@maskito/angular';
import { FiveTwoNinePickerComponent } from './components/five-two-nine-picker/five-two-nine-picker.component';
import { FormlyCustomDateCalcComponent } from './components/form-dateCalc/form-dateCalc.component';
import { FormCalculatorComponent } from './components/form-calculator/form-calculator.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: {
        // checkExpressionOn: 'changeDetectionCheck',
        resetFieldOnHide: false,
      },
      validationMessages: [
        { name: 'required', message: 'This field is required.' },
        {
          name: 'regRequired',
          message:
            'This field is needed for suitability review. You can opt out below if the applicant refuses to provide the information.',
        },
      ],
      types: [
        {
          name: 'formly-group',
          defaultOptions: {
            defaultValue: {},
          },
        },
        {
          name: 'formly-array',
          defaultOptions: {
            defaultValue: [],
          },
        },
        { name: 'custom-currency', component: FormlyCustomCurrencyComponent },
        {
          name: 'custom-currency-exp',
          component: FormlyCustomCurrencyExpComponent,
        },
        { name: 'custom-input', component: FormlyCustomInputComponent },
        { name: 'custom-input-exp', component: FormlyCustomInputExpComponent },
        { name: 'custom-radio-exp', component: FormlyRadioExpComponent },
        { name: 'custom-radio', component: FormlyRadioComponent },
        { name: 'custom-integer-exp', component: FormlyIntegerExpComponent },
        { name: 'custom-number-exp', component: FormlyNumberExpComponent },
        { name: 'custom-number', component: FormlyNumberComponent },
        { name: 'custom-multi-select', component: FormlyMultiSelectComponent },
        { name: 'custom-percentage', component: FormlyPercentageComponent },
        {
          name: 'custom-percentage-exp',
          component: FormlyPercentageExpComponent,
        },
        { name: 'custom-drop-exp', component: FormlyDropExpComponent },
        { name: 'custom-drop', component: FormlyDropComponent },
        { name: 'custom-toggle', component: FormlyToggleComponent },
        { name: 'custom-date', component: FormlyCustomDateComponent },
        { name: 'custom-date-calc', component: FormlyCustomDateCalcComponent },
        { name: 'custom-checkbox', component: FormlyCheckboxComponent },
        {
          name: 'custom-sectionHeader',
          component: FormlySectionHeaderComponent,
        },
        {
          name: 'custom-repeating',
          component: FormlyRepeatingComponent,
          wrappers: ['panel'],
          defaultOptions: {
            defaultValue: [],
          },
        },
        { name: 'custom-message', component: FormlyMessageComponent },
        { name: 'custom-button', component: FormlyButtonComponent },
        { name: 'custom-yesno', component: FormlyYesNoComponent },
        { name: 'custom-textarea', component: FormlyTextAreaComponent },
        {
          name: 'custom-radio-validate',
          component: FormlyRadioValidationComponent,
        },
        {
          name: 'custom-radio-validate-exp',
          component: FormlyRadioValidationExpComponent,
        },
        { name: 'custom-hash', component: FormlyHashComponent },
        { name: 'custom-insuredSwap', component: FormlyInsuredSwapComponent },
        { name: 'custom-repCode', component: FormlyRepCodeComponent },
        { name: 'custom-status', component: FormlyStatusComponent },
        {
          name: 'custom-dataGrid',
          component: FormlyDataGridComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-gridExpand',
          component: FormlyGridExpandComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-grid',
          component: FormlyGridComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-rankorder',
          component: FormlyRankOrderComponent,
        },
        {
          name: 'stepper',
          component: FormlyStepperComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'tabs',
          component: FormlyTabsComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        {
          name: 'custom-type-ahead',
          component: FormlyTypeAheadComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
        { name: 'unified', component: UnifiedShellComponent },
        {
          name: 'custom-compactRepeat',
          component: FormlyCompactRepeatComponent,
          defaultOptions: {
            defaultValue: [],
          },
        },
      ],
      wrappers: [{ name: 'panel', component: PanelWrapperComponent }],
    }),
    FormlyMaterialModule,
    FormsModule,
    NgxLoadingModule,
    SharedComponentsModule,
    AnnuityLookupModule,
    RixtremaModule,
    MatStepperModule,
    FundFeeLookupModule,
    MaskitoDirective,
    CdkDrag,
    CdkDropList,
    CdkDragPlaceholder,
    CdkDragPreview,
    FormCalculatorComponent,
  ],
  declarations: [
    FormlyComponent,
    FormlyCheckboxComponent,
    FormlyCustomCurrencyComponent,
    FormlyCustomCurrencyExpComponent,
    FormlyCustomDateComponent,
    FormlyCustomDateCalcComponent,
    FormlyCustomInputComponent,
    FormlyCustomInputExpComponent,
    FormlyDropComponent,
    FormlyDropExpComponent,
    FormlyMessageComponent,
    FormlyMultiSelectComponent,
    FormlyIntegerExpComponent,
    FormlyNumberExpComponent,
    FormlyPercentageComponent,
    FormlyPercentageExpComponent,
    FormlyRadioComponent,
    FormlyRadioExpComponent,
    FormlyRepeatingComponent,
    FormlySectionHeaderComponent,
    FormlyToggleComponent,
    PanelWrapperComponent,
    FormlyButtonComponent,
    FormlyYesNoComponent,
    FormlyTextAreaComponent,
    FormlyNumberComponent,
    FormlyRadioValidationComponent,
    FormlyHashComponent,
    FormlyInsuredSwapComponent,
    FormlyRepCodeComponent,
    FormlyStatusComponent,
    FormlyDataGridComponent,
    FormlyGridExpandComponent,
    FormlyGridComponent,
    FormlyRankOrderComponent,
    FormlyStepperComponent,
    FormlyTabsComponent,
    FormlyTypeAheadComponent,
    FormlyCompactRepeatComponent,
    TemplatePickerComponent,
    UnifiedShellComponent,
    FiveTwoNinePickerComponent,
    FormlyRadioValidationExpComponent,
  ],
  exports: [FormlyComponent],
  providers: [
    AdminService,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    CdkDragPreview,
  ],
})
export class ConditionalFormModule {}
