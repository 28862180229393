<div class="page-container thank-you-container container">
  <div class="thank-you-content">
    <h1 class="text-center">{{ thankYouProfile?.Title }}</h1>
    @if (thankYouProfile) {
      <div class="check-icon-container text-center">
        <mat-icon>check</mat-icon>
      </div>
    }
    <h4 class="text-center mb-4" [innerHTML]="thankYouProfile?.Body"></h4>
    @if (thankYouProfile?.Image) {
      <div class="thank-you-image-container text-center">
        <img alt="Thank You" src="thankYouProfile.Image" />
      </div>
    }
  </div>
</div>
