<div class="page-container product-shelf-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12">Plan Lookup</h1>
  @if (!hideGrid) {
    <div class="row mb-3 mx-1 plan-lookup-filters">
      <div class="col-12 mb-3 row toolbar">
        <form class="search-form w-100" (submit)="searchResults()">
          <div class="w-40 filters-container">
            <mat-form-field class="ml-2">
              <mat-label>Plan Type</mat-label>
              <mat-select [formControl]="planType">
                @for (plan of planTypes; track plan) {
                  <mat-option [value]="plan.value">
                    {{ plan.label }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field class="ml-2">
              <mat-label>Plan/Sponsor Name</mat-label>
              <input matInput [formControl]="planName" />
            </mat-form-field>
            <mat-form-field class="ml-2">
              <mat-label>EIN</mat-label>
              <input matInput [formControl]="ein" />
            </mat-form-field>
            <mat-form-field class="ml-2">
              <mat-label>ZIP Code</mat-label>
              <input matInput [formControl]="zipCode" />
            </mat-form-field>
            <mat-form-field class="ml-2">
              <mat-label>Distance from ZIP (miles)</mat-label>
              <input
                matInput
                [formControl]="distance"
                [disabled]="!zipCode.value"
              />
            </mat-form-field>
            <button
              mat-flat-button
              color="primary"
              class="ml-2 update-search-button"
              type="submit"
              [disabled]="
                !planType.value &&
                !planName.value &&
                !ein.value &&
                !zipCode.value
              "
            >
              Update Search
            </button>
          </div>
        </form>
      </div>
      @if (gridData.headers?.length > 1) {
        <button
          mat-button
          color="primary"
          class="grid-column-button"
          [matMenuTriggerFor]="columnMenu"
        >
          <mat-icon>view_column</mat-icon>Column Options
        </button>
      }
      <mat-menu #columnMenu="matMenu" class="column-menu">
        @for (col of gridData.headers; track col) {
          <button
            (click)="toggleVisible($event, col.DataField)"
            mat-menu-item
            [value]="col.DataField"
            [ngClass]="{ selected: !col.Hidden }"
          >
            <mat-icon class="empty">check_box_outline_blank</mat-icon>
            <mat-icon class="checked">check_box</mat-icon>
            {{ col.Label }}
          </button>
        }
      </mat-menu>
    </div>
  }

  @if (!hideGrid) {
    <kendo-grid
      class="col-12"
      #productGrid="kendoGrid"
      [kendoGridBinding]="gridData.data"
      [skip]="state.skip"
      [pageSize]="state.take"
      [pageable]="true"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="state.sort"
      [filterable]="'menu'"
      [filter]="state.filter"
      [state]="state"
      [headerStyle]="{ 'white-space': 'pre-wrap' }"
      (filterChange)="updateFilters($event)"
      (dataStateChange)="dataStateChange($event)"
    >
      <kendo-grid-column title="Actions">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            mat-icon-button
            class="fs-button"
            (click)="dlFactsheet(dataItem)"
            color="primary"
            matTooltip="Download Factsheet"
          >
            <mat-icon>insert_drive_file</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-column>
      @for (column of gridData.headers; track column) {
        <kendo-grid-column
          [field]="column.DataField"
          [hidden]="column.Hidden"
          [title]="column.Label"
          [headerStyle]="{ 'white-space': 'pre-wrap' }"
          [sortable]="column.Sort"
          [filterable]="column.Filter"
          [filter]="column.DataType"
        >
          @if (column.Sort || column.Filter) {
            <ng-template kendoGridColumnMenuTemplate let-service="service">
              @if (column.Sort) {
                <kendo-grid-columnmenu-sort
                  [column]="column"
                  [service]="service"
                >
                  <kendo-grid-messages
                    [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                    [sortDescending]="getFilterMenuText(column.Type, 'desc')"
                  ></kendo-grid-messages>
                </kendo-grid-columnmenu-sort>
              }
              @if (column.Filter) {
                <kendo-grid-columnmenu-filter
                  [column]="column"
                  [service]="service"
                >
                  <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter="filter"
                    let-filterService="filterService"
                  >
                    <kendo-multiselect
                      style="width: 99%"
                      [data]="filterData[column.DataField]"
                      textField="display"
                      valueField="value"
                      [valuePrimitive]="true"
                      [value]="filter | filterValues"
                      (valueChange)="
                        filterValueChange(
                          $event,
                          column.DataField,
                          filterService
                        )
                      "
                      [fillMode]="'outline'"
                      placeholder="Filter By..."
                      [filterable]="true"
                      (filterChange)="onFilterChange($event, column.DataField)"
                    ></kendo-multiselect>
                  </ng-template>
                </kendo-grid-columnmenu-filter>
              }
            </ng-template>
          }
          @if (column.Type === 'bool'; as dataItem) {
            <ng-template kendoGridCellTemplate let-dataItem>
              @if (dataItem[column.DataField]) {
                Yes
              } @else if (
                !dataItem[column.DataField] &&
                isBool(dataItem[column.DataField])
              ) {
                No
              }
            </ng-template>
          }
          @if (column.Type === 'perc' || column.Type === 'perc3'; as dataItem) {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | percent: '1.1-3' }}
            </ng-template>
          }
          @if (column.Type === 'curr'; as dataItem) {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{
                dataItem[column.DataField] | currency: 'USD' : true : '1.0-0'
              }}
            </ng-template>
          }
          @if (column.Type === 'num'; as dataItem) {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | number }}
            </ng-template>
          }
          @if (column.DataField === 'notes'; as dataItem) {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.DataField]"></span>
            </ng-template>
          }
          @if (column.DataField === 'PlanType'; as dataItem) {
            <ng-template kendoGridCellTemplate let-dataItem>
              @if (dataItem[column.DataField] === 'DC') {
                <span>Defined Contribution</span>
              }
              @if (dataItem[column.DataField] === 'DB') {
                <span>Defined Benefit</span>
              }
            </ng-template>
          }
          @if (column.Validation; as dataItem) {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ findValue(dataItem[column.DataField], column.Validation) }}
            </ng-template>
          }
        </kendo-grid-column>
      }
    </kendo-grid>
  }
</div>
<ngx-loading [show]="loading"></ngx-loading>
