<div mat-dialog-header>
  <h1>{{ dialogTitle }} - Change Comparison</h1>
</div>
<div mat-dialog-content class="dialog-content">
  <table>
    <thead>
      <th></th>
      @for (date of changeDates; track date; let i = $index) {
        <th>
          {{ date }}<br />
          Run Count: {{ runCounts[i] }}
        </th>
      }
    </thead>
    <tbody>
      @for (row of gridRows; track row) {
        <tr>
          @if (row.IsHeader) {
            <td colspan="3" class="header">
              <h2 class="mb-0">{{ row.fieldLabel }}</h2>
            </td>
          } @else {
            <td>
              <strong>{{ row.fieldLabel }}</strong>
            </td>
            @for (value of row.Values; track value) {
              <td
                [ngClass]="{
                  highlight:
                    row.Values[1] &&
                    row.Values[0] !== row.Values[1] &&
                    !highlightExclusions.includes(row.fieldName)
                }"
              >
                {{ value }}
              </td>
            }
          }
        </tr>
      }
    </tbody>
  </table>
</div>
<div class="buttons">
  <button mat-stroked-button (click)="close()">Close</button>
</div>
