<div mat-dialog-header>
  <h1>{{ headline }}</h1>
</div>
<mat-dialog-content #scroll class="dialog-container password">
  @if (!reset && !submitted) {
    <div class="row col-12">
      <p class="w-100">{{ content }}</p>
    </div>
  }
  <ng-container>
    <form #passwordForm="ngForm">
      @if (!reset && !submitted) {
        <mat-form-field class="w-100">
          <mat-label>Please enter your username</mat-label>
          <input
            matInput
            [(ngModel)]="username"
            name="uname"
            [placeholder]="'Please enter your username'"
            [required]="required"
          />
          <mat-error>This field is required</mat-error>
        </mat-form-field>
      }
      @if (reset) {
        <mat-form-field class="w-100">
          <mat-label>New Password</mat-label>
          <input
            matInput
            [(ngModel)]="nPass"
            name="newPass"
            [placeholder]="'New Password'"
            [required]="required"
            #newPass="ngModel"
            type="password"
            strongPassword="2"
          />
          @if (newPass.errors?.required) {
            <mat-error>This field is required</mat-error>
          }
          @if (newPass.errors?.noMatch) {
            <mat-error>Passwords do not match.</mat-error>
          }
        </mat-form-field>
        <strength-meter [password]="newPass.value"></strength-meter>
        @if (newPass.errors?.strongPassword) {
          <password-feedback
            [feedback]="newPass.errors.strongPassword.feedback"
          ></password-feedback>
        }
        <mat-form-field class="w-100 verify">
          <mat-label>Verify New Password</mat-label>
          <input
            matInput
            [(ngModel)]="vPass"
            name="verifyPass"
            [placeholder]="'Verify New Password'"
            [required]="required"
            #verifyPass="ngModel"
            type="password"
          />
          @if (verifyPass.errors?.required) {
            <mat-error>This field is required</mat-error>
          }
          @if (verifyPass.errors?.noMatch) {
            <mat-error>Passwords do not match.</mat-error>
          }
        </mat-form-field>
      }
    </form>
  </ng-container>
  <div class="submitted" [hidden]="!submitted">
    @if (!reset) {
      <p class="reset-message">
        A temporary password has been sent to the email address on file.
      </p>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button color="accent" (click)="savePass()">
    {{ confirm }}
  </button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
