<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Scorecard </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Opportunities Available</h4>
      </div>
    }
    @if (scData) {
      <div class="row data-container">
        <table class="col-12">
          <thead>
            <th colspan="2">RightBridge Statistics</th>
          </thead>
          <tbody>
            <tr>
              <td>Actions MTD</td>
              <td>{{ scData.monthToDate }}</td>
            </tr>
            <tr>
              <td>Actions Last 30</td>
              <td>{{ scData.last30Days }}</td>
            </tr>
            <tr>
              <td>Active Opportunities</td>
              <td>{{ scData.activeOpportunities }}</td>
            </tr>
            <tr>
              <td># Clients with actions last 30</td>
              <td>{{ scData.clientActionsLast30Days }}</td>
            </tr>
            <tr>
              <td>Opportunities > 75%</td>
              <td>null</td>
            </tr>
            <tr>
              <td>New Clients</td>
              <td>{{ scData.newClients }}</td>
            </tr>
            <tr>
              <td>Last Login</td>
              <td>{{ scData.lastLogin }}</td>
            </tr>
          </tbody>
        </table>
        <table class="col-12">
          <thead>
            <th colspan="2">Client Base Statistics</th>
          </thead>
          <tbody>
            <tr>
              <td># Clients</td>
              <td>{{ scData.numClients }}</td>
            </tr>
            <tr>
              <td>Avg Insurance</td>
              <td>{{ scData.avgLifeInsurance | currency }}</td>
            </tr>
            <tr>
              <td>Total Assets</td>
              <td>{{ scData.totalAssets | currency }}</td>
            </tr>
            <tr>
              <td>Avg Assets</td>
              <td>{{ scData.avgAssets | currency }}</td>
            </tr>
            <tr>
              <td>Avg Profile Completeness</td>
              <td>{{ scData.profileCompleteness }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    }
  </mat-card-content>
</mat-card>
