<div mat-dialog-header>
  <h1>Custom Investment Model</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  <form #modelForm="ngForm">
    <table>
      <thead>
        <tr>
          <th>Asset Class</th>
          <th>Mode / Mix</th>
          <th>Custom Amount</th>
        </tr>
      </thead>
      <tbody>
        @for (r of classes; track r) {
          <tr>
            <td>{{ r.AssetClassName }}</td>
            <td>{{ r.Percent | percent }}</td>
            <td>
              <mat-form-field class="col-12">
                <input
                  matInput
                  type="text"
                  [placeholder]="'Allocation Percent'"
                  [name]="'cm-' + r.AssetClassID"
                  [(ngModel)]="model['cm-' + r.AssetClassID]"
                  [maskito]="percentMask"
                  (keyup)="addEntries()"
                />
                @if (
                  'modelForm.controls[cm-' + r.classId + '].errors.notEqual'
                ) {
                  <mat-error>All fields must add up to 100%</mat-error>
                }
                <mat-hint align="start">
                  <strong
                    >Minimum {{ r.PercentMin | percent }}, Maximum
                    {{ r.PercentMax | percent }}</strong
                  >
                </mat-hint>
              </mat-form-field>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row buttons">
  <div class="col-6 totalRow"
    >Total Allocated: {{ percentAllocated / 100 | percent }}</div
  >
  <button mat-raised-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="accent" (click)="save()">Save</button>
</mat-dialog-actions>
