import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from '../../../services/session-storage.service';
import { PostTradeService } from '../../post-trade.service';
import { utilityApps } from '../../../lib/post-trade-utility-apps';
@Component({
  selector: 'app-rep-review',
  templateUrl: './rep-review.component.html',
  styleUrls: ['./rep-review.component.scss'],
})
export class RepReviewComponent implements OnInit, OnDestroy {
  constructor(
    public ptSvc: PostTradeService,
    private activatedRoute: ActivatedRoute,
    private ss: SessionStorageService,
    private route: Router
  ) {}

  rights = [];
  unsubscribe: Subject<any> = new Subject();
  repId = '';
  gridConfig = {
    title: 'Financial Professional Monitor',
    gridName: 'RepReviewGrid',
    showGridFilters: true,
    showFilterManager: true,
    manualSearch: true,
    searchButtonText: 'Update Search',
    showBulkActions: true,
    downloadGridButton: true,
    gridActionColumn: true,
    rowSelector: 'RepID',
    gridType: 'rep',
    detailViewRoute: '/rep-review/',
    getSetup: this.getGridSetup,
    getData: this.getGridData,
  };
  crumbConfig = [
    {
      label: 'Post Transaction',
      link: 'post-trade/RR',
    },
    {
      label: 'Financial Professional Monitor',
      link: 'rep-review',
    },
  ];
  utilityApps = utilityApps;

  ngOnInit(): void {
    this.rights = this.ss.get('rights');
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(params => {
        if (params.id) {
          this.repId = params.id;
          this.crumbConfig.push({
            label: 'Financial Professional Detail',
            link: `trade-review/${this.repId}`,
          });
        } else {
          this.repId = '';
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  getGridSetup() {
    return this.ptSvc.getRepGridSetup();
  }

  getGridData(reqData) {
    return this.ptSvc.getRepGridData(reqData);
  }
}
