import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { states } from '../../lib/states';

@Component({
  selector: 'app-create-profile-dialog',
  templateUrl: './create-profile-dialog.component.html',
  styleUrls: ['./create-profile-dialog.component.scss'],
})
export class CreateProfileDialogComponent implements OnInit {
  @ViewChild('profileForm', { static: true }) private form: FormGroup;

  modalData = {
    acctType: null,
    state: null,
    purpose: null,
  };
  annuity;
  rider;
  states = states;

  constructor(
    public dialogRef: MatDialogRef<CreateProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.annuity = this.data.annuity;
    this.rider = this.data.rider;
    this.modalData.state = this.data.state;
  }

  cancel() {
    this.dialogRef.close({ data: 'cancel' });
  }

  save() {
    if (this.modalData.purpose && this.modalData.state) {
      this.dialogRef.close(this.modalData);
      this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef = null;
      });
    }
  }
}
