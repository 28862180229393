<div class="case-management-container container">
  <div class="page-container case-management-detail-container">
    <app-breadcrumb [crumbConfig]="crumbConfig"></app-breadcrumb>

    @if (!loading && !editCaseTitle) {
      <h1 class="header col-12 mb-1">
        {{ caseProfile['CaseManagement']['Title'] }}
        <button
          mat-icon-button
          [matTooltip]="'Edit case title'"
          (click)="toggleEditCaseTitle(true)"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </h1>
    }
    @if (!loading && editCaseTitle) {
      <div class="edit-title-container mb-3">
        <mat-form-field class="mb-0 w-50">
          <mat-label>Case Title</mat-label>
          <input
            type="text"
            [placeholder]="'Case Title'"
            matInput
            name="caseTitle"
            [(ngModel)]="caseTitle"
          />
        </mat-form-field>
        <button
          color="warn"
          mat-icon-button
          [matTooltip]="'Cancel'"
          (click)="cancelEditCaseTitle()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button
          color="primary"
          mat-icon-button
          [matTooltip]="'Save case title'"
          (click)="saveCaseTitle()"
        >
          <mat-icon>save</mat-icon>
        </button>
      </div>
    }

    @if (!loading) {
      <div class="actions-container mb-3">
        <button mat-button (click)="nav('case-management')" color="primary">
          <mat-icon>arrow_back_ios</mat-icon> Back to Case Management
        </button>
        <button mat-button (click)="nav('caseSummary')" color="primary">
          <mat-icon>checklist</mat-icon> Open Case Summary
        </button>
      </div>

      <div class="trade-info-container info-card expanded mb-4 w-100 p-0">
        <div class="card-header">
          <span class="card-title-container">
            Case Management Number:
            <span class="card-title mb-0 ml-2">{{ CRID }}</span>
          </span>
          <div class="action-btns-container">
            <span class="mr-1">
              <div class="save-case-container pull-right">
                @if (
                  !loading &&
                  caseProfile['UnitCMPostBack'] &&
                  caseProfile['UnitCMPostBack']['ShowPostBackButton'] &&
                  caseProfile['UnitCMPostbackStatus'] &&
                  caseProfile['UnitCMPostbackStatus']['ShowStatuses'].includes(
                    caseDetails['StatusID'].toString()
                  )
                ) {
                  <button
                    class=""
                    mat-raised-button
                    aria-label="Submit Note"
                    color="accent"
                    (click)="submitPostback()"
                  >
                    <mat-icon>assignment_turned_in</mat-icon>
                    {{ caseProfile['UnitCMPostBack']['PostBackButtonLabel'] }}
                  </button>
                }
              </div>
            </span>
            <button
              class=""
              mat-raised-button
              aria-label="Save"
              color="accent"
              (click)="saveCaseChanges()"
              [disabled]="saveBtnDisabled()"
            >
              @if (
                !(
                  canRespondToAssignee &&
                  this.caseProfile?.CaseManagement?.AssignedTo !==
                    this.profileID
                )
              ) {
                <mat-icon>save</mat-icon>
              }
              @if (
                canRespondToAssignee &&
                this.caseProfile?.CaseManagement?.AssignedTo === this.profileID
              ) {
                Save Changes and Respond to Assignee
              } @else if (
                canRespondToAssignee &&
                this.caseProfile?.CaseManagement?.AssignedTo !== this.profileID
              ) {
                Assigned to Reviewer. Please wait for Reviewer to Reassign.
              } @else {
                Save Changes
              }
            </button>
          </div>
        </div>
        <div class="card-info row pt-3">
          <div class="col-sm-4 col-12">
            <div class="card-info-label"> Submitted By </div>
            @if (caseHistoryData && caseHistoryData[0]) {
              <div class="card-info-value">
                {{ caseHistoryData[0]['SavedByName'] }}
              </div>
            }
          </div>
          <div class="col-sm-4 col-12">
            <div class="card-info-value">
              @if (canRespondToAssignee) {
                <div class="card-info-label"> Assigned To </div>
                {{ caseProfile['CaseManagement']['AssignedTo'] }}
              } @else if (!canRespondToAssignee) {
                <mat-form-field>
                  <mat-label>Assigned To</mat-label>
                  <mat-select
                    [(ngModel)]="caseProfile['CaseManagement']['AssignedTo']"
                    (selectionChange)="changeValue(true)"
                    [disabled]="canRespondToAssignee"
                  >
                    @if (caseUsers.length > 0) {
                      @for (user of caseUsers; track user.UserID) {
                        <mat-option [value]="user.UserID">
                          {{ user.UserName || user.UserID }}
                        </mat-option>
                      }
                    }
                  </mat-select>
                </mat-form-field>
              }
            </div>
          </div>
          <div class="col-sm-4 col-12">
            <div class="card-info-value">
              @if (canRespondToAssignee) {
                <div class="card-info-label"> Case Status </div>
                {{ caseProfile['CaseManagement']['StatusName'] }}
              } @else if (!canRespondToAssignee) {
                <mat-form-field>
                  <mat-label>Case Status</mat-label>
                  <mat-select
                    [(ngModel)]="caseProfile['CaseManagement']['StatusID']"
                    (selectionChange)="changeValue(true)"
                  >
                    @for (
                      status of caseProfile['ActiveStatuses'];
                      track status.StatusID
                    ) {
                      <mat-option
                        [value]="status.StatusID"
                        [disabled]="!status['Active']"
                      >
                        {{ status.StatusName || status.StatusID }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
            </div>
          </div>
        </div>
      </div>

      @if (aiSummaryTool && aiSummaryText) {
        <div
          class="trade-info-container info-card ai-summary-card expanded mb-4 w-100 p-0"
        >
          <div class="card-header">
            <span class="card-title mb-0">AI Case Summary</span>
            <span class="toggle-arrow">
              <button
                mat-icon-button
                (click)="expand($event, 'ai-summary-card')"
                ><mat-icon>expand_circle_down</mat-icon></button
              >
            </span>
          </div>
          <div class="card-info row">
            <app-ai-summary
              [title]="false"
              [text]="aiSummaryText"
              class="ai-summary-card-content col-12"
            ></app-ai-summary>
          </div>
        </div>
      }

      <div class="half-card-container">
        <div class="info-card expanded half-card mb-4">
          <div class="card-header">
            <span class="card-title mb-0"> Case Files </span>
          </div>

          <div class="card-details">
            <div class="details">
              @if (canUploadFiles) {
                <div class="upload-case-files mb-2">
                  <h4 class="mb-2">Upload Files</h4>
                  <input
                    type="file"
                    class="file-input"
                    (change)="onFileSelected($event)"
                    #fileUpload
                  />
                  <span class="mb-2">
                    {{ fileName || 'No file selected yet' }}
                  </span>
                  @if (fileName) {
                    <button
                      class="w-100 mb-3"
                      mat-stroked-button
                      aria-label="Choose a Different File"
                      color="warn"
                      (click)="fileUpload.click()"
                    >
                      <mat-icon>upload</mat-icon> Choose a Different File
                    </button>
                    <button
                      class="w-100 mb-3"
                      mat-raised-button
                      aria-label="Complete Upload"
                      color="primary"
                      (click)="completeFileUpload()"
                    >
                      <mat-icon>check</mat-icon> Complete Upload
                    </button>
                  }
                </div>
              }
              <div
                class="case-files list"
                [ngClass]="{ 'no-upload': !canUploadFiles }"
              >
                <div class="attached-files-title">
                  <h4 class="mb-2">
                    Attached Files
                    <small>({{ caseFiles ? caseFiles.length : 0 }})</small>
                  </h4>
                  @if (showPrintReportBtn && isCaseManager) {
                    <div class="mb-2">
                      <button
                        mat-raised-button
                        color="primary"
                        (click)="printCMReport($event)"
                        >{{ reportSettings['ButtonText'] }}</button
                      >
                    </div>
                  }
                </div>
                <div class="attached-case-files">
                  @for (caseFile of caseFiles; track caseFile) {
                    <div class="download-row">
                      <span> {{ caseFile.Name }} </span>
                      <button
                        class="ml-2"
                        mat-icon-button
                        color="primary"
                        (click)="getCaseRecordFile(caseFile)"
                        matTooltip="Download File"
                      >
                        <mat-icon>download</mat-icon>
                      </button>
                      @if (caseFile.Type.toLowerCase() === 'pdf') {
                        <button
                          class="ml-2"
                          mat-icon-button
                          color="primary"
                          (click)="loadFileIntoViewer(caseFile)"
                          matTooltip="View File"
                        >
                          <mat-icon>visibility</mat-icon>
                        </button>
                      }
                      @if (caseFile.Category !== 'System' && canDeleteFiles) {
                        <button
                          class="ml-2"
                          mat-icon-button
                          color="warn"
                          aria-label="Delete Case File"
                          (click)="deleteCaseRecordFile(caseFile)"
                          matTooltip="Delete File"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="info-card expanded half-card mb-4">
          <div class="card-header">
            <span class="card-title mb-0"> Notes </span>
          </div>
          <div class="card-details details-notes">
            <div class="details">
              <div class="chat-center">
                <div class="chat-messages-container">
                  @if (messages.length > 0) {
                    <div class="message-container w-100">
                      @for (
                        message of messages;
                        track message.NoteId;
                        let i = $index
                      ) {
                        <div
                          class="chat-message"
                          [ngClass]="{
                            incoming:
                              message.CreatedByUserId !== defaultUser.id,
                            'type-two':
                              message.NoteTypeId === '2' ||
                              message.NoteTypeId === 2,
                            hidden:
                              (message.NoteTypeId === '2' ||
                                message.NoteTypeId === 2) &&
                              !isCaseManager,
                            'top-message': i === 0
                          }"
                        >
                          <div class="sender-info">
                            {{
                              message.CreatedByUserName ||
                                message.CreatedByUserId
                            }}
                            at
                            {{ message.CreateDate }}
                          </div>
                          @if (
                            message.NoteTypeId === '2' ||
                            message.NoteTypeId === 2
                          ) {
                            <div class="sender-info">Private</div>
                          }
                          @if (editMessageId !== message.NoteId) {
                            <div class="chat-bubble mb-3">
                              {{ message.NoteText }}
                              <span class="edit-icon-container">
                                <mat-icon
                                  class="edit-icon"
                                  (click)="editNote(message)"
                                >
                                  edit_note
                                </mat-icon>
                              </span>
                            </div>
                          } @else {
                            <div class="edit-bubble">
                              @if (isCaseManager) {
                                <mat-form-field class="w-20 mr-1">
                                  <mat-label>Type</mat-label>
                                  <mat-select [(ngModel)]="editNoteType">
                                    @for (type of noteTypes; track type.value) {
                                      <mat-option [value]="type.value">
                                        {{ type.label }}
                                      </mat-option>
                                    }
                                  </mat-select>
                                </mat-form-field>
                              }
                              <div class="input-container">
                                <input
                                  matInput
                                  type="text"
                                  placeholder="Message"
                                  name="Message Input"
                                  [(ngModel)]="editMessage"
                                />
                              </div>
                              <mat-icon (click)="submitEdit()">save</mat-icon>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  } @else {
                    <div class="no-chat-container"> No messages found </div>
                  }
                </div>
                <div class="chat-input">
                  <form (submit)="submitNote()">
                    @if (rights.includes('CaseManager')) {
                      <mat-form-field class="w-20 mr-1">
                        <mat-label>Type</mat-label>
                        <mat-select [(value)]="noteType">
                          @for (type of noteTypes; track $index) {
                            <mat-option [value]="type.value">
                              {{ type.label }}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    }
                    <div class="input-container">
                      <textarea
                        matInput
                        placeholder="Message"
                        name="Message Input"
                        [(ngModel)]="message"
                      ></textarea>
                    </div>
                    <button
                      class="mx-2"
                      mat-mini-fab
                      [disabled]="!message"
                      aria-label="Submit Note"
                      color="primary"
                      type="submit"
                    >
                      <mat-icon>send</mat-icon>
                    </button>
                  </form>
                  <div
                    class="note-hint"
                    [ngClass]="{ 'note-hint-manager': isCaseManager }"
                  >
                    *Note can be edited at any time after it is sent
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      @if (pdfBase64) {
        <div
          class="trade-info-container info-card pdf-viewer-container expanded mb-4 w-100 p-0"
          [ngClass]="{
            'pdf-viewer-container-hidden': !pdfBase64,
            'pdf-viewer-container-shown': pdfBase64
          }"
        >
          <div class="card-header">
            <span class="card-title-container">
              Report:
              <span class="card-title mb-0 ml-2">{{ activeCaseFile }}</span>
            </span>
            <span class="toggle-arrow">
              <button
                mat-icon-button
                (click)="expand($event, 'pdf-viewer-container')"
                ><mat-icon>expand_circle_down</mat-icon></button
              >
            </span>
          </div>
          <div class="card-info">
            @if (pdfBase64) {
              <kendo-pdfviewer
                [data]="pdfBase64"
                [tools]="pdfViewerTools"
                style="height: 85vh"
              >
              </kendo-pdfviewer>
            }
          </div>
        </div>
      }
      @if (showCaseHistory) {
        <div class="mutual-funds-container info-card expanded mb-4 w-100 p-0">
          <div class="card-header">
            <span class="card-title mb-0"> Case History </span>
          </div>
          <div class="case-history-table">
            <table class="details col-12">
              <thead>
                @for (header of caseHistoryHeaders; track header.label) {
                  <th>{{ header.label }}</th>
                }
              </thead>
              <tbody>
                @for (entry of caseHistoryData; track entry) {
                  <tr>
                    @for (header of caseHistoryHeaders; track header.label) {
                      <td>{{ entry[header.fieldName] }}</td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    }
    <ngx-loading
      [show]="loading"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
  </div>
</div>
