import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MaskService } from '../../../services/mask.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { InvestmentWizardService } from '../../../investment-wizard/services/investment-wizard-service.service';

@Component({
  selector: 'app-form-currency-exp',
  styleUrls: ['form-currencyExp.component.scss'],
  template: `
    @if (props.label) {
      <label
        class="section w-100"
        [class.mat-error]="formControl.errors && formControl.touched"
        [innerHTML]="props.label"
      >
        @if (props.required) {
          <sup>*</sup>
        }
      </label>
    }
    @if (props.prompt) {
      <p
        class="explanation"
        [class.mat-error]="formControl.errors && formControl.touched"
        [innerHTML]="props.prompt"
      ></p>
    }

    <mat-form-field floatPlaceholder="auto" class="w-100">
      <mat-label>{{ to.placeholder }}</mat-label>
      <input
        matInput
        [maskito]="dollarMask"
        maxlength="20"
        [placeholder]="props.placeholder"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [required]="props.required"
        [defaultValue]="formControl.value || ''"
      />
      @if (props.help) {
        <mat-icon
          class="col-1 help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'rb-tooltip'"
        >
          help
        </mat-icon>
      }
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error> This field is required </mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error>
          This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.
        </mat-error>
      }
    </mat-form-field>
  `,
})
export class FormlyCustomCurrencyExpComponent
  extends FieldType
  implements OnInit
{
  dollarMask;
  unsubscribe = new Subject();

  constructor(
    public mask: MaskService,
    private iws: InvestmentWizardService
  ) {
    super();
    this.dollarMask = this.mask.dollarMaskSpecs();
  }

  ngOnInit(): void {
    this.field.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.iws.getNeedToUpdateCalc()) {
          this.iws.setUpdateExternalCalcValue(true);
        }
      });
  }
}
