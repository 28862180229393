import {
  Component,
  OnInit,
  Input,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { RixtremaService } from '../rixtrema.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RixtremaPlan } from '../../models/rixtrema-data';
import { saveAs } from '@progress/kendo-file-saver';
import { EnvironmentService } from '../../services/environment.service';
import { Platform } from '@angular/cdk/platform';
import { RightBridgeApiService } from '../..//services/right-bridge-api.service';

@Component({
  selector: 'app-rixtrema-dialog',
  templateUrl: './rixtrema-dialog.component.html',
  styleUrls: ['./rixtrema-dialog.component.scss'],
})
export class RixtremaDialogComponent implements OnInit {
  private environment;
  public searchTerm = null;
  public zip = null;
  public distance = null;
  public sponsor = null;
  public selectedPlan = null;
  public selectedPlanData = null;
  public selectedIndex;
  public showData = false;
  public showRlc = false;
  public rlcAccess = false;
  public showSubplanSelect = false;
  public resultsMessage = null;
  public plans: Array<RixtremaPlan> = new Array<RixtremaPlan>();
  public advanced = false;
  public loading = false;
  public loggedIn = false;
  public planType = null;
  public server = null;
  public buttonType = null;
  public serverName = null;
  public subplanID = null;
  public baseUrl = null;
  public ein = null;
  public searchType = 'name';
  public zipName = null;

  @Input() groupIndex;
  constructor(
    private rixtremaSrc: RixtremaService,
    private dialogRef: MatDialogRef<RixtremaDialogComponent>,
    private ref: ChangeDetectorRef,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private rbs: RightBridgeApiService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.groupIndex = data.groupIndex;
    this.planType = data.planType;
    this.server = data.server;
    this.buttonType = data.buttonType;
    this.serverName = this.server == 'rixtrema' ? 'Rixtrema' : 'RightBridge';
    this.rixtremaSrc.setServer(data.server);
  }

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    const rights = this.rbs.getRights();
    rights.subscribe(val => {
      this.rlcAccess = val.includes('RLC');
      this.showRlc = this.rlcAccess;
    });
    this.startSession();
  }

  startSession(counter = 0, callback = null) {
    if (counter > 5) {
      return;
    }
    this.rixtremaSrc.loginRixtrema().subscribe(
      () => {
        this.loggedIn = true;
        if (callback != null) {
          callback(counter + 1);
        }
      },
      () => {
        this.resultsMessage = `Error logging in to ${this.serverName}`;
        this.loggedIn = false;
        this.rbs.clearJwt();
        this.startSession(counter + 1, callback);
      }
    );
  }

  search() {
    switch (this.searchType) {
      case 'name':
        this.basicSearch();
        break;
      case 'sponsor':
        this.advancedSearch();
        break;
      case 'ein':
        this.advancedSearch();
        break;
      case 'zip':
        this.advancedSearch();
        break;
      default:
        this.basicSearch();
        break;
    }
  }

  save() {
    if (this.selectedPlanData !== null) {
      this.selectedPlanData['groupIndex'] = this.groupIndex;
      this.selectedPlanData['server'] = this.server;
      this.selectedPlanData['buttonType'] = this.buttonType;
      this.selectedPlanData['subplanID'] = this.subplanID;
      this.rixtremaSrc.planData(this.selectedPlanData);
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  getPdf() {
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    this.loading = true;

    this.rbs.getRlcPdf(this.subplanID).subscribe(
      data => {
        const filename = `${this.subplanID}`;
        this.loading = false;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          // this.loading = false;
        };
      },
      () => {
        this.loading = false;
      }
    );
  }

  getFactsheet() {
    const iosSafari =
      this.pltfrm.IOS ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    this.loading = true;
    if (this.selectedPlanData == null) {
      this.loading = false;
      return;
    }
    const ackID = this.selectedPlanData.ACK_ID;

    this.rbs.getFactsheet(ackID).subscribe(
      data => {
        const filename = `F5500_Factsheet_${ackID}.pdf`;
        this.loading = false;

        let fileData;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename, {
            proxyURL: `${this.baseUrl}/util/proxy`,
            proxyTarget: '_self',
            forceProxy: iosSafari,
          });
          // this.loading = false;
        };
      },
      () => {
        this.loading = false;
      }
    );
  }

  basicSearch(count = 0) {
    this.loading = true;
    this.planSelect(null);
    this.resultsMessage = '';
    this.rixtremaSrc
      .IncrementalSearch(30, this.searchTerm, this.planType)
      .subscribe(
        data => {
          this.loading = false;
          this.updateList(data);
        },
        () => {
          if (count < 2) {
            this.rbs.clearJwt();
            this.startSession(count, count => {
              this.basicSearch(count);
            });
          } else {
            this.loading = false;
            this.plans = null;
            this.resultsMessage = `Error Performing Search`;
          }
        }
      );
  }

  advancedSearch(count = 0) {
    this.loading = true;
    this.planSelect(null);
    this.loading = true;
    this.resultsMessage = '';

    this.distance = !this.distance ? '0' : this.distance;
    this.sponsor = this.searchType == 'zip' ? this.zipName : this.sponsor;

    this.rixtremaSrc
      .AdvancedSearch(
        this.zip,
        this.distance,
        this.sponsor,
        this.planType,
        this.ein
      )
      .subscribe(
        data => {
          this.loading = false;
          this.updateList(data);
        },
        () => {
          if (count < 2) {
            this.rbs.clearJwt();
            this.startSession(count, count => {
              this.advancedSearch(count);
            });
          } else {
            this.loading = false;
            this.plans = null;
            this.resultsMessage = `Error Performing Search`;
          }
        }
      );
  }

  searchValid() {
    if (this.loading || !this.loggedIn) {
      return false;
    }
    return (
      (this.searchTerm && this.searchTerm.length > 0) ||
      (this.ein && this.ein.length > 0) ||
      (this.zip && this.zip.length > 0) ||
      (this.distance && this.distance.length > 0) ||
      (this.sponsor && this.sponsor.length > 0)
    );
  }

  updateList(data) {
    this.loading = false;
    if (!data.Plan || data.Plan.length == 0) {
      this.resultsMessage = 'No Plans Found';
      this.plans = null;
    } else {
      this.resultsMessage = '';
      this.plans = data.Plan;
    }
  }

  toggleAdvanced() {
    this.advanced = !this.advanced;
    this.plans = null;
    this.resultsMessage = '';
    this.planSelect(null);
  }

  noneFound() {
    this.rixtremaSrc.planData({
      groupIndex: this.groupIndex,
      server: this.server,
      buttonType: this.buttonType,
    });
    this.close();
  }

  planSelect(event, count = 0) {
    this.selectedPlanData = null;
    this.selectedPlan = event;
    if (event != null) {
      this.loading = true;
      this.rixtremaSrc
        .GetPlans(this.selectedPlan['ACK_ID'], this.rlcAccess)
        .subscribe(
          data => {
            if (data.Plan.length > 0) {
              data.Plan[0]['groupIndex'] = this.groupIndex;
              this.selectedPlanData = data.Plan[0];
              this.subplanID = '';
              this.showSubplanSelect = false;
              if (this.selectedPlanData.pdfs.length > 1) {
                this.showSubplanSelect = true;
              }
              if (this.selectedPlanData.pdfs.length == 1) {
                this.subplanID = this.selectedPlanData.pdfs[0].PathFilename;
              }
              this.showData = this.selectedPlan != null;
              this.showRlc =
                this.selectedPlanData.pdfs.length > 0 && this.rlcAccess;
              this.ref.markForCheck();
            }
            this.loading = false;
          },
          () => {
            if (count < 2) {
              this.rbs.clearJwt();
              this.startSession(count, count => {
                this.planSelect(event, count);
              });
            } else {
              this.plans = null;
              this.resultsMessage = `Error Loading Plan Data`;
            }
            this.loading = false;
          }
        );
    } else {
      this.selectedPlanData = null;
      this.showData = false;
      this.subplanID = '';
    }
    this.ref.markForCheck();
  }

  clearData() {
    this.searchTerm = '';
    this.zip = '';
    this.sponsor = '';
    this.zipName = '';
    this.distance = '';
    this.ein = '';

    this.plans = null;
  }
}
