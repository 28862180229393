<div mat-dialog-header>
  @if (data) {
    <h1 class="w-100">Edit Template</h1>
  } @else {
    <h1 class="w-100">Create a New Template</h1>
  }
</div>
<div mat-dialog-content class="dialog-container">
  <div class="w-100">
    <form class="col-12 row mt-2">
      <mat-form-field class="col-12 col-md-6">
        <input
          matInput
          type="text"
          placeholder="Template Name"
          [formControl]="templateName"
          name="tmpltName"
          [(ngModel)]="template.name"
          required
        />
        @if (templateName.hasError('required')) {
          <mat-error>This field is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <input
          placeholder="Search for a Program"
          matInput
          [formControl]="programControl"
          [matAutocomplete]="programs"
          (change)="checkFundStatus($event)"
          required
        />
        <mat-autocomplete
          #programs="matAutocomplete"
          [displayWith]="displayProg"
          (optionSelected)="handleProgSelect($event)"
        >
          @for (option of filteredPrograms | async; track option) {
            <mat-option [value]="option">{{ option.DisplayName }}</mat-option>
          }
        </mat-autocomplete>
        @if (this.template.program) {
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            color="warn"
            matTooltip="Clear Filter"
            (click)="clearProgram()"
          >
            <mat-icon>close</mat-icon>
          </button>
        }
      </mat-form-field>
      <mat-form-field class="col-12 col-md-6">
        <input
          placeholder="Search for a fund"
          matInput
          [formControl]="fundControl"
          [matAutocomplete]="auto"
        />
        @if (fundControl.value) {
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            color="error"
            (click)="clearFundSearch($event)"
          >
            <mat-icon>close</mat-icon>
          </button>
        }
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFund"
          (optionSelected)="handleFundSelect($event)"
        >
          @if (dropdownLoading) {
            <mat-option disabled class="dropdownLoadingSpinner"
              ><mat-spinner diameter="48"></mat-spinner
            ></mat-option>
          }
          @if (dropdownNoResults) {
            <mat-option disabled>No Results</mat-option>
          }
          @if (!dropdownLoading && !dropdownNoResults) {
            @for (option of filteredFunds; track option.FundID) {
              <mat-option [value]="option">
                {{ option.FundID }} - {{ option.FundName }}
              </mat-option>
            }
          }
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="col-12 col-md-3">
        <input
          matInput
          [maskito]="percentMask"
          placeholder="Percent to Allocate"
          name="fundPercent"
          [(ngModel)]="selected.percent"
          [disabled]="!this.template.program"
        />
      </mat-form-field>
      <button
        mat-button
        class="col-12 col-md-2 greenButton"
        (click)="saveFund()"
        color="accent"
        [disabled]="!selected.percent || !selected.fund"
      >
        <mat-icon aria-label="Add Fund">add</mat-icon> Add Fund
      </button>
    </form>

    @if (programControl.value && showManualEntryButton) {
      <div clas="w-100 mb-3">
        <button
          mat-button
          class="greenButton"
          color="accent"
          (click)="addManualFund()"
        >
          <mat-icon aria-label="Add Fund">add_circle</mat-icon>Add securities
          that are approved but not available for selection
        </button>
      </div>
    }

    @if (template.funds || manualFunds.length > 0) {
      <table class="w-100 mb-3">
        <thead>
          <th>Symbol</th>
          <th>Name</th>
          <th>Percent</th>
          <th width="40"></th>
        </thead>
        <tbody>
          @for (fund of template.funds; track fund.ticker) {
            <tr>
              <td>{{ fund.ticker }}</td>
              <td>{{ fund.name }}</td>
              <td>
                <mat-form-field class="col-12">
                  <input
                    matInput
                    [maskito]="percentMask"
                    placeholder="Percent to Allocate"
                    name="fundPercent"
                    [(ngModel)]="fund.percent"
                    [disabled]="!this.template.program"
                  />
                </mat-form-field>
              </td>
              <td class="text-center">
                <button
                  mat-icon-button
                  (click)="deleteFund(fund.ticker)"
                  matTooltip="Delete Fund"
                  [matTooltipShowDelay]="1000"
                >
                  <mat-icon aria-label="Delete Profile">delete</mat-icon>
                </button>
              </td>
            </tr>
          }
          @for (manualFund of manualFunds; track manualFund.ticker) {
            <tr>
              <td
                ><mat-form-field class="col-12">
                  <input
                    matInput
                    placeholder="Symbol"
                    name="manualFundTicker"
                    [(ngModel)]="manualFund.ticker"
                    [disabled]="!this.template.program"
                  /> </mat-form-field
              ></td>
              <td>
                <mat-form-field class="col-12">
                  <input
                    matInput
                    placeholder="Name"
                    name="manualFundName"
                    [(ngModel)]="manualFund.name"
                    [disabled]="!this.template.program"
                  />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="col-12">
                  <input
                    matInput
                    [maskito]="percentMask"
                    placeholder="Percent to Allocate"
                    name="fundPercent"
                    [(ngModel)]="manualFund.percent"
                    [disabled]="!this.template.program"
                  />
                </mat-form-field>
              </td>
              <td class="text-center">
                <button
                  mat-icon-button
                  (click)="deleteFund(manualFund.ticker, true)"
                  matTooltip="Delete Fund"
                  [matTooltipShowDelay]="1000"
                >
                  <mat-icon aria-label="Delete Profile">delete</mat-icon>
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    }
  </div>
</div>
<div mat-dialog-actions class="row buttons">
  <mat-checkbox [(ngModel)]="shared" class="ml-3" [disabled]="disableShare"
    >Share Template</mat-checkbox
  >
  <mat-checkbox
    class="ml-3"
    [formControl]="firmTemplate"
    [(ngModel)]="template.firmTemplate"
    *hasPermission="'edit_firm_templates'"
    (click)="setScope()"
    >Firm Template</mat-checkbox
  >
  <button
    mat-raised-button
    style="margin-left: auto"
    (click)="close(); $event.preventDefault()"
    >Cancel</button
  >
  <button
    mat-raised-button
    style="margin-left: 10px"
    color="accent"
    (click)="saveData(); $event.preventDefault()"
    [disabled]="
      (template.funds?.length === 0 && manualFunds.length === 0) ||
      (this.manualFunds.length > 0 && validateManualFunds())
    "
    >Save Template</button
  >
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
