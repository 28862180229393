import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-radio-exp',
  styleUrls: ['form-radioExp.component.scss'],
  template: `
    @if (props.label) {
      <label
        class="section"
        class="w-100"
        [class.mat-error]="formControl.errors && formControl.touched"
      >
        <span [innerHTML]="props.label"></span>
        @if (props.required) {
          <sup>*</sup>
        }
        @if (props.help) {
          <mat-icon
            class="help-icon"
            matTooltip="{{ to.help }}"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'rb-tooltip'"
          >
            help
          </mat-icon>
        }
      </label>
    }
    @if (props.prompt) {
      <p
        class="explanation"
        [class.mat-error]="formControl.errors && formControl.touched"
      >
        <span [innerHTML]="props.prompt"></span>
        @if (props.required && !to.label && !to.placeholder) {
          <span> *</span>
        }
        @if (props.help && !to.label && !to.placeholder) {
          <mat-icon
            class="help-icon"
            matTooltip="{{ to.help }}"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'rb-tooltip'"
          >
            help
          </mat-icon>
        }
      </p>
    }
    @if (props.placeholder) {
      <p
        class="italic"
        [class.mat-error]="formControl.errors && formControl.touched"
      >
        <span [innerHTML]="props.placeholder"></span>
        @if (props.required && !to.label) {
          <span> *</span>
        }
        @if (props.help && !to.label && !to.prompt) {
          <mat-icon
            class="help-icon"
            matTooltip="{{ to.help }}"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'rb-tooltip'"
          >
            help
          </mat-icon>
        }
      </p>
    }

    <mat-radio-group
      class="radio-group radio-group-explanation col-12 questionnaire"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="props.required"
      (change)="props.change ? to.change(field, formControl) : ''"
    >
      @for (opt of to?.options; track opt) {
        <mat-radio-button class="radio-button" [value]="buttonValue(opt)">
          {{ opt.label }}
        </mat-radio-button>
      }
      @if (
        formControl.hasError('required') &&
        formControl.touched &&
        !formControl.hasError('regRequired')
      ) {
        <mat-error> This field is required </mat-error>
      }
      @if (formControl.hasError('regRequired') && formControl.touched) {
        <mat-error>
          This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.
        </mat-error>
      }
    </mat-radio-group>
  `,
})
export class FormlyRadioExpComponent extends FieldType {
  @HostBinding('class') classes = 'bottom-margin';
  buttonValue(option) {
    if (this.formControl.value === null && option.value === false) {
      return null;
    } else if (option.value === false) {
      return false;
    } else if (option.value === true) {
      return true;
    } else {
      return option.value;
    }
  }
}
