<div mat-dialog-header>
  <h1 class="w-100">Create a Shared Template</h1>
</div>

<div mat-dialog-content class="dialog-container">
  <div class="w-100">
    <div>
      <strong> Please enter the Template ID you would like to copy. </strong>
    </div>
    <mat-form-field class="w-100">
      <input
        matInput
        type="text"
        placeholder="Template ID"
        [formControl]="templateId"
        name="tmpltId"
        [(ngModel)]="template.id"
        required
      />
      @if (templateId.hasError('required')) {
        <mat-error> This field is required </mat-error>
      }
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button
    mat-button
    color="warn"
    style="margin-left: auto"
    (click)="close(); $event.preventDefault()"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    style="margin-left: 10px"
    color="accent"
    (click)="saveData(); $event.preventDefault()"
    [disabled]="!template.id"
  >
    Save Template
  </button>
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
