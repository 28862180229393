<div class="page-container trade-review-detail-container container pt-2">
  <h1 class="header col-12 mb-1"> Transaction Details - {{ tradeId }} </h1>
  @if (!loading) {
    <div class="actions-container mb-3">
      <button mat-button (click)="nav('trade-review')" color="primary">
        <mat-icon>arrow_back_ios</mat-icon> Back to Transaction Monitor
      </button>
    </div>

    <div class="trade-info-container info-card mb-4 w-100 p-0">
      <div class="card-header">
        <span class="card-title-container">
          <mat-icon
            [matTooltip]="'Trade Score: ' + tradeDetail.ScoreClass"
            class="scoreclass-icon mr-2"
            [class]="tradeDetail.ScoreClass"
          >
            {{ scoreClassKey[tradeDetail.ScoreClass] }}
          </mat-icon>
          Trade ID: <span class="card-title mb-0 ml-2">{{ tradeId }}</span>
        </span>

        <span>
          <button
            class="mr-2"
            mat-raised-button
            [matMenuTriggerFor]="tradeActionsMenu"
          >
            <mat-icon>settings</mat-icon> Actions
          </button>

          <button mat-raised-button [matMenuTriggerFor]="tradeAdditionalMenu">
            <mat-icon>more_vert</mat-icon> More
          </button>

          <mat-menu
            #tradeActionsMenu="matMenu"
            direction="down"
            yPosition="below"
            class="unified-menu"
          >
            @if (tradeDetail.ApprovalStatus !== 'INP') {
              <button mat-menu-item (click)="toggleApproval('INP')">
                <mat-icon>construction</mat-icon> Mark In Process
              </button>
            }
            @if (tradeDetail.ApprovalStatus !== 'APP') {
              <button mat-menu-item (click)="toggleApproval('APP')">
                <mat-icon>check_circle_outline</mat-icon> Approve
              </button>
            } @else {
              <button mat-menu-item (click)="toggleApproval('REO')">
                <mat-icon>meeting_room</mat-icon> Reopen
              </button>
            }
          </mat-menu>

          <mat-menu
            #tradeAdditionalMenu="matMenu"
            direction="down"
            yPosition="below"
            class="unified-menu"
          >
            @if (
              tradeDetail.TradeApprovalHistory &&
              !isEmpty(tradeDetail.TradeApprovalHistory)
            ) {
              <button mat-menu-item (click)="viewTradeApprovalHistory()">
                <mat-icon class="mr-0">alarm_on</mat-icon> Trade Approval
                History
              </button>
            }

            <button mat-menu-item (click)="seeDocuments()">
              <mat-icon class="mr-0">file_present</mat-icon> View Notes &
              Attachments
            </button>

            <button mat-menu-item (click)="initiateCase()">
              <mat-icon>medical_services</mat-icon> Initiate Case
            </button>

            @if (rights.includes('AccountGroup')) {
              <button mat-menu-item (click)="accountGrouping()">
                <mat-icon>dataset_linked</mat-icon> Account Grouping
              </button>
            }
          </mat-menu>
        </span>
      </div>
      <div class="card-info row">
        @for (tradeInfo of tradeInfoKey; track tradeInfo.label) {
          <div class="col-sm-3 col-12">
            <div class="card-info-label">
              {{ tradeInfo.label }}
            </div>
            <div class="card-info-value">
              @if (tradeInfo.key) {
                {{ tradeInfo.key[tradeDetail[tradeInfo.varName]] }}
              } @else if (tradeInfo.varName === 'RepCode') {
                <button
                  class="detail-link-button"
                  mat-stroked-button
                  (click)="nav('rep-review/' + tradeDetail['Rep']['RepID'])"
                  [matTooltip]="'FP Score: ' + tradeDetail['Rep']['ScoreClass']"
                >
                  <span
                    [class]="
                      'detail-score-indicator mr-1 ' +
                      tradeDetail['Rep']['ScoreClass']
                    "
                  ></span>
                  {{ tradeDetail[tradeInfo.varName] }}
                </button>
              } @else if (
                tradeInfo.varName === 'AccountNumDisplay' &&
                tradeDetail['Account']
              ) {
                <button
                  class="detail-link-button"
                  mat-stroked-button
                  (click)="
                    nav('account-review/' + tradeDetail['Account']['AccountID'])
                  "
                  [matTooltip]="
                    'Account Score: ' + tradeDetail['Account']['ScoreClass']
                  "
                >
                  <span
                    [class]="
                      'detail-score-indicator mr-1 ' +
                      tradeDetail['Account']['ScoreClass']
                    "
                  ></span>
                  {{ tradeDetail[tradeInfo.varName] }}
                </button>
              } @else {
                {{ tradeDetail[tradeInfo.varName] }}
              }
            </div>
          </div>
        }
      </div>
    </div>

    @if (rights.includes('CM') && caseHistory.length > 0) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Case History </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              <th></th>
              @for (header of caseHistoryKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (case of caseHistory; track case.CRID; let idx = $index) {
              @if (idx < caseHistoryRows) {
                <tr>
                  <td width="75">
                    <div class="action-button-container">
                      <button
                        mat-icon-button
                        class="fs-button"
                        (click)="toggleCaseReview(case)"
                        color="primary"
                        matTooltip="Review Case"
                      >
                        <mat-icon>rate_review</mat-icon>
                      </button>
                    </div>
                  </td>
                  @for (caseKey of caseHistoryKey; track caseKey.label) {
                    <td>
                      @if (
                        caseKey.valueMask && caseKey.valueMask === 'currency'
                      ) {
                        <span>{{ case[caseKey.varName] | currency }}</span>
                      } @else if (
                        caseKey.valueMask && caseKey.valueMask === 'date'
                      ) {
                        <span>{{
                          case[caseKey.varName] | localTime: 'shortDateTime'
                        }}</span>
                      } @else {
                        {{ case[caseKey.varName] }}
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (caseHistory.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="toggleRows('caseHistoryRows', caseHistory.length)"
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (caseHistoryRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (caseHistoryRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }

    @if (tradeDetail?.Details.bridges) {
      <div class="alerts-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Alerts </span>
        </div>
        <div class="card-info row">
          <div class="alerts">
            @for (bridge of tradeDetail.Details.bridges; track bridge) {
              <app-reason-text
                class="col-6"
                [data]="bridge.trueRules"
              ></app-reason-text>
            }
          </div>
        </div>
      </div>
    }

    <div class="product-info-container info-card mb-4">
      <div class="card-header">
        <span class="card-title mb-0"> Transaction Information </span>
        <!-- <button
        class="mr-2"
        mat-raised-button
        (click)="downloadFactSheet()"
        color="accent"
        >
        <mat-icon>download</mat-icon> Download Factsheet
      </button> -->
      </div>
      <div class="card-info row">
        @for (productInfo of productInfoKey; track productInfo.label) {
          @if (
            tradeDetail[productInfo.varName] ||
            (productInfo.varSet &&
              tradeDetail.Details[productInfo.varSet][productInfo.varName])
          ) {
            <div class="col-sm-2 col-12">
              <div class="card-info-label">
                {{ productInfo.label }}
              </div>
              <div class="card-info-value">
                @if (productInfo.varSet) {
                  @if (
                    productInfo.valueMask &&
                    productInfo.valueMask === 'currency'
                  ) {
                    <span>
                      {{
                        tradeDetail.Details[productInfo.varSet][
                          productInfo.varName
                        ] | currency
                      }}
                    </span>
                  } @else if (
                    productInfo.valueMask && productInfo.valueMask === 'percent'
                  ) {
                    <span>
                      {{
                        tradeDetail.Details[productInfo.varSet][
                          productInfo.varName
                        ] | percent
                      }}
                    </span>
                  } @else {
                    {{
                      tradeDetail.Details[productInfo.varSet][
                        productInfo.varName
                      ]
                    }}
                  }
                } @else if (
                  productInfo.valueMask && productInfo.valueMask === 'currency'
                ) {
                  <span>{{ tradeDetail[productInfo.varName] | currency }}</span>
                } @else if (
                  productInfo.valueMask && productInfo.valueMask === 'percent'
                ) {
                  <span>{{ tradeDetail[productInfo.varName] | percent }}</span>
                } @else {
                  {{ tradeDetail[productInfo.varName] }}
                }
              </div>
            </div>
          }
        }
      </div>
    </div>

    <div class="client-suitability-container info-card mb-4">
      <div class="card-header">
        <span class="card-title mb-0"> Client Suitability Information </span>
      </div>
      <div class="card-info row pb-0">
        @for (suitabilityInfo of suitabilityKey; track suitabilityInfo.label) {
          @if (
            tradeDetail.Details[suitabilityInfo.varSet] &&
            tradeDetail.Details[suitabilityInfo.varSet][suitabilityInfo.varName]
          ) {
            <div class="col-sm-3 col-12">
              <div class="card-info-label">
                {{ suitabilityInfo.label }}
              </div>
              <div class="card-info-value mb-2">
                @if (suitabilityInfo.key) {
                  {{
                    suitabilityInfo.key[
                      tradeDetail.Details[suitabilityInfo.varSet][
                        suitabilityInfo.varName
                      ]
                    ]
                  }}
                } @else if (
                  suitabilityInfo.valueMask &&
                  suitabilityInfo.valueMask === 'currency'
                ) {
                  {{
                    tradeDetail.Details[suitabilityInfo.varSet][
                      suitabilityInfo.varName
                    ] | currency
                  }}
                } @else if (
                  suitabilityInfo.valueMask &&
                  suitabilityInfo.valueMask === 'percent'
                ) {
                  {{
                    tradeDetail.Details[suitabilityInfo.varSet][
                      suitabilityInfo.varName
                    ] | percent
                  }}
                } @else {
                  {{
                    tradeDetail.Details[suitabilityInfo.varSet][
                      suitabilityInfo.varName
                    ]
                  }}
                }
              </div>
            </div>
          }
        }
      </div>
    </div>

    @if (tradeDetail.Details.Account) {
      <div class="account-info-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Account Info </span>
        </div>
        <div class="card-info row">
          @for (accountInfo of accountKey; track accountInfo.label) {
            @if (
              tradeDetail.Details[accountInfo.varSet] &&
              tradeDetail.Details[accountInfo.varSet][accountInfo.varName]
            ) {
              <div class="col-sm-2 col-12">
                <div class="card-info-label">
                  {{ accountInfo.label }}
                </div>
                <div class="card-info-value mb-2">
                  @if (accountInfo.key) {
                    {{
                      accountInfo.key[
                        tradeDetail.Details[accountInfo.varSet][
                          accountInfo.varName
                        ]
                      ]
                    }}
                  } @else if (
                    accountInfo.valueMask &&
                    accountInfo.valueMask === 'currency'
                  ) {
                    {{
                      tradeDetail.Details[accountInfo.varSet][
                        accountInfo.varName
                      ] | currency
                    }}
                  } @else if (
                    accountInfo.valueMask && accountInfo.valueMask === 'percent'
                  ) {
                    {{
                      tradeDetail.Details[accountInfo.varSet][
                        accountInfo.varName
                      ] | percent
                    }}
                  } @else {
                    {{
                      tradeDetail.Details[accountInfo.varSet][
                        accountInfo.varName
                      ]
                    }}
                  }
                </div>
              </div>
            }
          }
        </div>
      </div>
    }

    @if (
      tradeDetail.Details.AWExchange && !isEmpty(tradeDetail.Details.AWExchange)
    ) {
      <div class="annuity-exchange-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Annuity Exchange </span>
        </div>
        @for (awExchange of tradeDetail.Details.AWExchange; track awExchange) {
          <table>
            <thead>
              <tr>
                @for (header of awExchange.header; track header) {
                  <th>{{ header }}</th>
                }
              </tr>
            </thead>
            <tbody>
              @for (annuity of awExchange.data; track annuity) {
                <tr>
                  @for (header of awExchange.header; track header) {
                    <td>{{ annuity[header] }}</td>
                  }
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
    }

    @if (
      tradeDetail.Details.AccountGroup &&
      !isEmpty(tradeDetail.Details.AccountGroup)
    ) {
      <div class="annuity-exchange-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Breakpoint Analysis </span>
        </div>
        <div class="card-info row">
          @for (breakpoint of breakpointAnalysisKey; track breakpoint.label) {
            @if (
              tradeDetail.Details[breakpoint.varSet] &&
              tradeDetail.Details[breakpoint.varSet][breakpoint.varName]
            ) {
              <div class="col-sm-3 col-12">
                <div class="card-info-label">
                  {{ breakpoint.label }}
                </div>
                <div class="card-info-value">
                  @if (breakpoint.varName === 'GroupID') {
                    <button
                      class="detail-link-button"
                      mat-stroked-button
                      (click)="
                        nav(
                          'account-grouping/' +
                            tradeDetail.Details.AccountGroup[
                              breakpoint.varName
                            ],
                          true
                        )
                      "
                      matTooltip="View Group"
                    >
                      <mat-icon>launch</mat-icon>
                      {{ tradeDetail.Details.AccountGroup[breakpoint.varName] }}
                    </button>
                  } @else {
                    {{
                      tradeDetail.Details[breakpoint.varSet][breakpoint.varName]
                    }}
                  }
                </div>
              </div>
            }
          }
          @if (charts && charts['breakpoints']) {
            <div
              class="chart-container clearfix mt-3 mb-3"
              [ngClass]="{
                'col-6': charts['breakpoints'].ChartContainer === 'half',
                'col-12': charts['breakpoints'].ChartContainer === 'full'
              }"
            >
              @if (!isEmpty(charts['breakpoints'].Data)) {
                <div
                  class="chart pull-left px-4"
                  [ngClass]="{
                    'col-12': charts['breakpoints'].LegendPosition === 'bottom',
                    'col-6': charts['breakpoints'].LegendPosition === 'right'
                  }"
                >
                  <highcharts-chart
                    [Highcharts]="Highcharts"
                    [options]="charts['breakpoints'].Data"
                    class="mx-auto"
                  ></highcharts-chart>
                </div>
              }

              @if (charts['breakpoints'].Legend) {
                <div
                  class="chart-legend"
                  [ngClass]="{
                    'col-12': charts['breakpoints'].LegendPosition === 'bottom',
                    'col-6 ml-auto':
                      charts['breakpoints'].LegendPosition === 'right'
                  }"
                >
                  @if (charts['breakpoints'].Legend.header) {
                    <table>
                      <thead>
                        <tr>
                          @for (
                            th of charts['breakpoints'].Legend.header;
                            track th
                          ) {
                            @if (th.toLowerCase() !== 'color') {
                              <th>
                                {{ th }}
                              </th>
                            }
                          }
                        </tr>
                      </thead>
                      <tbody>
                        @for (
                          tr of charts['breakpoints'].Legend.data;
                          track tr
                        ) {
                          <tr>
                            @for (
                              td of tr | keyvalue: returnZero;
                              track td;
                              let i = $index
                            ) {
                              @if (
                                charts['breakpoints'].Legend.header[
                                  i
                                ].toLowerCase() !== 'color'
                              ) {
                                <td>
                                  @if (
                                    charts['breakpoints'].Legend.header[
                                      i
                                    ].toLowerCase() !== 'color' &&
                                    charts[
                                      'breakpoints'
                                    ].Legend.header[0].toLowerCase() ===
                                      'color' &&
                                    i === 1
                                  ) {
                                    <span
                                      [style.background]="tr['Color']"
                                      class="indicator"
                                    ></span>
                                  }
                                  {{ td.value }}
                                </td>
                              }
                            }
                          </tr>
                        }
                      </tbody>
                    </table>
                  } @else {
                    @for (c of charts['breakpoints'].Legend; track c.value) {
                      <div>
                        <strong>{{ c.label }}</strong> {{ c.value }}
                      </div>
                    }
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    }

    @if (tradeDetail.Details.TradeHistory) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Transaction History </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              <th></th>
              @for (header of tradeHistoryKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              account of tradeDetail.Details.TradeHistory;
              track account.SecID;
              let idx = $index
            ) {
              @if (idx < tradeHistoryRows) {
                <tr>
                  <td>
                    <button
                      mat-icon-button
                      class="fs-button"
                      (click)="nav('trade-review/' + account.TradeID, true)"
                      color="primary"
                      matTooltip="View Transaction"
                    >
                      <mat-icon>launch</mat-icon>
                    </button>
                  </td>
                  @for (trade of tradeHistoryKey; track trade.label) {
                    <td>
                      @if (trade.valueMask && trade.valueMask === 'currency') {
                        <span>{{ account[trade.varName] | currency }}</span>
                      } @else if (
                        trade.valueMask && trade.valueMask === 'date'
                      ) {
                        <span>{{
                          account[trade.varName] | localTime: 'shortDate'
                        }}</span>
                      } @else if (trade.key) {
                        {{ trade.key[account[trade.varName]] }}
                      } @else {
                        {{ account[trade.varName] }}
                      }
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (tradeDetail.Details.TradeHistory.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="
                toggleRows(
                  'tradeHistoryRows',
                  tradeDetail.Details.TradeHistory.length
                )
              "
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (tradeHistoryRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (tradeHistoryRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }

    @if (tradeDetail.Details.AccountPositionsDisplay) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Current Account Holdings </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              @for (header of tradePositionKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              account of tradeDetail.Details.AccountPositionsDisplay;
              track account.SecID;
              let idx = $index
            ) {
              @if (idx < mutualFundRows) {
                <tr>
                  @for (position of tradePositionKey; track position.label) {
                    @if (
                      position.valueMask && position.valueMask === 'currency'
                    ) {
                      <td>{{ account[position.varName] | currency }}</td>
                    } @else {
                      <td>
                        {{ account[position.varName] }}
                      </td>
                    }
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (tradeDetail.Details.AccountPositionsDisplay.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="
                toggleRows(
                  'mutualFundRows',
                  tradeDetail.Details.AccountPositionsDisplay.length
                )
              "
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (mutualFundRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (mutualFundRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }

    @if (tradeDetail.Details.OtherHolderPositionsDisplay) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Other Holdings </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              @for (header of otherPositionKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              account of tradeDetail.Details.OtherHolderPositionsDisplay;
              track account.SecID;
              let idx = $index
            ) {
              @if (idx < mutualFundRows) {
                <tr>
                  @for (position of otherPositionKey; track position.label) {
                    @if (
                      position.valueMask && position.valueMask === 'currency'
                    ) {
                      <td>{{ account[position.varName] | currency }}</td>
                    } @else {
                      <td>
                        {{ account[position.varName] }}
                      </td>
                    }
                  }
                </tr>
              }
            }
          </tbody>
        </table>
        @if (tradeDetail.Details.OtherHolderPositionsDisplay.length > 5) {
          <div class="toggle-row">
            <button
              class="mr-2 w-100"
              mat-button
              (click)="
                toggleRows(
                  'mutualFundRows',
                  tradeDetail.Details.OtherHolderPositionsDisplay.length
                )
              "
              color="primary"
            >
              <mat-icon class="mr-1">
                @if (mutualFundRows === 5) {
                  add
                } @else {
                  remove
                }
              </mat-icon>
              @if (mutualFundRows === 5) {
                Show More
              } @else {
                Show Less
              }
            </button>
          </div>
        }
      </div>
    }

    @if (tradeDetail.Details.AnnuityPosition) {
      <div class="mutual-funds-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Annuity Holdings </span>
        </div>
        <table class="details col-12">
          <thead>
            <tr>
              @for (header of annuityPositionKey; track header.label) {
                <th>{{ header.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (
              account of tradeDetail.Details.AnnuityPosition;
              track account.SecID
            ) {
              <tr>
                @for (position of annuityPositionKey; track position.label) {
                  <td>{{ account[position.varName] }}</td>
                }
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    @if (charts && charts['portfolio']) {
      <div class="breakpoints-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Client Holdings - Product Type </span>
        </div>
        <div class="card-info row">
          @for (chrt of charts['portfolio']; track chrt) {
            @if (chrt) {
              <div
                class="chart-container clearfix mb-2"
                [ngClass]="{
                  'col-6': chrt.ChartContainer === 'half',
                  'col-12': chrt.ChartContainer === 'full'
                }"
              >
                @if (!isEmpty(chrt.Data)) {
                  <div
                    class="chart pull-left px-4"
                    [ngClass]="{
                      'col-12': chrt.LegendPosition === 'bottom',
                      'col-6': chrt.LegendPosition === 'right'
                    }"
                  >
                    <highcharts-chart
                      [Highcharts]="Highcharts"
                      [options]="chrt.Data"
                      class="mx-auto"
                    ></highcharts-chart>
                  </div>
                }

                @if (chrt.Legend) {
                  <div
                    class="chart-legend"
                    [ngClass]="{
                      'col-12': chrt.LegendPosition === 'bottom',
                      'col-6 ml-auto': chrt.LegendPosition === 'right'
                    }"
                  >
                    @if (chrt.Legend.header) {
                      <table>
                        <thead>
                          <tr>
                            @for (th of chrt.Legend.header; track th) {
                              @if (th.toLowerCase() !== 'color') {
                                <th>
                                  {{ th }}
                                </th>
                              }
                            }
                          </tr>
                        </thead>
                        <tbody>
                          @for (tr of chrt.Legend.data; track tr) {
                            <tr>
                              @for (
                                td of tr | keyvalue: returnZero;
                                track td;
                                let i = $index
                              ) {
                                @if (
                                  chrt.Legend.header[i].toLowerCase() !==
                                  'color'
                                ) {
                                  <td>
                                    @if (
                                      chrt.Legend.header[i].toLowerCase() !==
                                        'color' &&
                                      chrt.Legend.header[0].toLowerCase() ===
                                        'color' &&
                                      i === 1
                                    ) {
                                      <span
                                        [style.background]="tr['Color']"
                                        class="indicator"
                                      ></span>
                                    }
                                    {{ td.value }}
                                  </td>
                                }
                              }
                            </tr>
                          }
                        </tbody>
                      </table>
                    } @else {
                      @for (c of chrt.Legend; track c.value) {
                        <div>
                          <strong>{{ c.label }}</strong> {{ c.value }}
                        </div>
                      }
                    }
                  </div>
                }
              </div>
            }
          }
        </div>
      </div>
    }

    @if (
      tradeDetail.Details.RightBRIDGECase &&
      !isEmpty(tradeDetail.Details.RightBRIDGECase)
    ) {
      <div class="associated-case-container info-card mb-4">
        <div class="card-header">
          <span class="card-title mb-0"> Associated RightBRIDGE Case </span>
          <button
            class="white-text mr-2"
            mat-raised-button
            (click)="downloadFactSheet()"
            color="accent"
          >
            <mat-icon>download</mat-icon> Download RightBRIDGE Report
          </button>
        </div>
        <div class="card-info row">
          @for (
            associatedCase of associatedCaseKey;
            track associatedCase.label
          ) {
            @if (tradeDetail.Details[associatedCase.varSet]) {
              <div class="col-sm-3 col-12">
                <div class="card-info-label">
                  {{ associatedCase.label }}
                </div>
                <div class="card-info-value">
                  @if (associatedCase.valueMask === 'date') {
                    {{
                      tradeDetail.Details[associatedCase.varSet][
                        associatedCase.varName
                      ] | localTime: 'shortDate'
                    }}
                  } @else {
                    {{
                      tradeDetail.Details[associatedCase.varSet][
                        associatedCase.varName
                      ]
                    }}
                  }
                </div>
              </div>
            }
          }
        </div>

        @if (productModules.length > 0) {
          <div class="card-info">
            <div class="row top-border">
              <div class="card-info-label col-12">
                Product Recommendations
              </div>
              @for (productModule of productModules; track productModule) {
                <div class="product-recommendation-container col-sm-6 col-12">
                  <div class="product-recommendation-module mb-3">
                    <div class="card-info-value recommendation-header">
                      {{ productModulesKey[productModule] }}
                    </div>

                    <div class="products-container">
                      @for (
                        product of productRecommendations[productModule];
                        track product.BridgeID
                      ) {
                        <div class="product-recommendation">
                          <mat-icon class="mr-1" [ngClass]="product.ScoreClass"
                            >circle</mat-icon
                          >{{ product.Name }} &mdash; {{ product.Amount }}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }

        @if (tradeDetail?.Details?.ClientProfiling?.Notes) {
          <div class="card-info">
            <div class="row top-border">
              <div class="card-info-label col-12"> Notes </div>
              <div class="card-info-value col-12">{{
                tradeDetail.Details.ClientProfiling.Notes
              }}</div>
            </div>
          </div>
        }
      </div>
    }
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
