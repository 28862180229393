<form class="row w-100 ml-0 align-items-center">
  <mat-form-field class="mr-3">
    <mat-label>Application</mat-label>
    <mat-select
      [(ngModel)]="selectedApp"
      name="apps"
      (selectionChange)="onAppChange($event)"
    >
      @for (app of applications; track app.value) {
        <mat-option [value]="app.value">
          {{ app.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field class="example-full-width" class="col-4">
    <input
      type="text"
      placeholder="Search For a Unit"
      matInput
      [(ngModel)]="selectedUnit"
      [matAutocomplete]="auto"
      name="unitsControl"
      [formControl]="unitsControl"
      (blur)="checkSelection()"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="autoCompleteUnitChanged($event)"
    >
      @for (unit of filteredUnits; track unit.id) {
        <mat-option [value]="unit.id">
          {{ unit.name }} - ({{ unit.id }})
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <button
    mat-raised-button
    (click)="viewUnitProfile()"
    [disabled]="!(selectedApp && selectedUnit)"
    color="accent"
  >
    Get Unit Profile
  </button>
  @if (!hideReportType) {
    <mat-form-field class="example-full-width" class="mr-3 ml-auto" z>
      <input
        type="text"
        placeholder="Pick a ReportType"
        matInput
        [(ngModel)]="selectedType"
        [matAutocomplete]="autoType"
        name="reportTypes"
      />
      <mat-autocomplete #autoType="matAutocomplete">
        @for (type of reportTypes; track type) {
          <mat-option [value]="type">
            {{ type }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <button
      mat-raised-button
      (click)="viewUnitProfileReport()"
      [disabled]="!(selectedApp && selectedUnit)"
      color="accent"
    >
      Get Unit Profile Report
    </button>
  }
</form>

<div class="row w-100">
  <button
    mat-button
    class="utility-button"
    (click)="uploadUnitProfile()"
    *hasPermission="'UnitProfileUpload'"
    color="primary"
  >
    <mat-icon>file_upload</mat-icon>Upload Unit Profile
  </button>
  <button
    mat-button
    class="utility-button"
    (click)="downloadUnitProfile()"
    [disabled]="!(selectedApp && selectedUnit)"
    *hasPermission="'UnitProfileDownload'"
    color="primary"
  >
    <mat-icon>file_download</mat-icon>Download Unit Profile
  </button>
  <button
    mat-button
    class="utility-button"
    (click)="downloadUnitProfileHistoryReport()"
    [disabled]="!(selectedApp && selectedUnit)"
    *hasPermission="'UnitProfileHistory'"
    color="primary"
  >
    <mat-icon>file_download</mat-icon>Download Unit History Report
  </button>
</div>
