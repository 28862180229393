import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Chart } from 'angular-highcharts';
import { RightBridgeApiService } from '../../services/right-bridge-api.service';
import { TagsEvaluationService } from '../../services/tags-evaluation.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { TransferService } from '../../services/transfer.service';
import { SuggestionEngineService } from '../../suggestion-engine/suggestion-engine.service';
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedReportDialogComponent } from '../report-dialog/report-dialog.component';
import { saveAs } from '@progress/kendo-file-saver';
import { Platform } from '@angular/cdk/platform';

import * as Highcharts from 'highcharts';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-result-detail',
  templateUrl: './result-detail.component.html',
  styleUrls: ['./result-detail.component.scss'],
})
export class ResultDetailComponent implements OnInit {
  private environment;
  private baseUrl: string;

  data;
  need;
  needs;
  id: string;
  idx: string;
  loading = false;
  name: string;
  path: string;
  currentApp;

  constructor(
    private params: ActivatedRoute,
    private rbs: RightBridgeApiService,
    private tes: TagsEvaluationService,
    private ts: TransferService,
    private location: Location,
    private route: Router,
    private ss: SessionStorageService,
    private ses: SuggestionEngineService,
    private dialog: MatDialog,
    private pltfrm: Platform,
    private envSvc: EnvironmentService
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.path = this.environment.assets ? this.environment.assets : '';

    this.params.params.subscribe(params => {
      this.idx = params.idx;
      this.id = params.id;
    });

    this.currentApp = this.ss.get('currentApp');

    const store = this.ts.getNeedsData();

    if (store) {
      this.need = store;
      this.need['formattedChart'] = this.need.ChartAttributes
        ? this.buildChart(this.need.ChartAttributes)
        : null;
      this.need.reasons.forEach(y => {
        y.headline = this.tes.evalTags(y.headline);
        y.explanation = this.tes.evalTags(y.explanation);
      });
      if (this.need.ChartLegend) {
        this.need.ChartLegend = JSON.parse(
          this.need.ChartLegend.replace('header: [', '"header": [').replace(
            'data: [',
            '"data": ['
          )
        );
        if (!this.need.ChartLegend.header) {
          this.need.ChartLegend = this.need.ChartLegend.filter(x => {
            if (x.label.length > 0) {
              return true;
            }
          });
        }
      }
    } else {
      this.getResults(this.id, this.idx);
    }
  }

  getResults(profile, name): any {
    this.loading = true;
    this.rbs.getClientSummary(profile).subscribe(data => {
      this.needs = data.needs;
      this.need = data.needs.find(z => z.name === name);
      this.name = `${data.FirstName} ${data.LastName}`;
      this.need['formattedChart'] = this.need.ChartAttributes
        ? this.buildChart(this.need.ChartAttributes)
        : null;
      this.need.description = this.need.description
        ? this.tes.evalTags(this.need.description)
        : '';
      this.need.reasons.forEach(y => {
        y.headline = this.tes.evalTags(y.headline);
        y.explanation = this.tes.evalTags(y.explanation);
      });
      if (this.need.ChartLegend) {
        this.need.ChartLegend = JSON.parse(
          this.need.ChartLegend.replace('header: [', '"header": [').replace(
            'data: [',
            '"data": ['
          )
        );
        if (!this.need.ChartLegend.header) {
          this.need.ChartLegend = this.need.ChartLegend.filter(x => {
            if (x.label.length > 0) {
              return true;
            }
          });
        }
      }
    });

    this.loading = false;
  }

  back() {
    this.location.back();
  }

  productDetails(need?) {
    if (need) {
      this.ts.setNeedsData(need);
    }
    this.route.navigate(['/cp/products/' + this.id + '/' + this.idx]);
  }

  bridgeAction(type, id, bridge) {
    if (type === 'like' || type === 'followUp') {
      const dialogRef = this.dialog.open(ActionDialogComponent, {
        panelClass: 'action-dialog',
        data: { id: this.id, bridge: bridge, action: type },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data != 'cancel') {
          const actionDate = new Date(result.data.date);
          const action = {
            records: [
              {
                profileID: this.id,
                bridgeID: bridge,
                actionID: result.data.type,
                note: result.data.notes,
                followUpDate: actionDate.toLocaleDateString('en-US'),
              },
            ],
          };
          this.loading = true;
          this.ses.saveBridgeActions(action).subscribe(x => {
            this.loading = false;
            this.handleProfileChange(this.id);
          });
        }
      });
    } else {
      const action = {
        records: [
          {
            profileID: this.id,
            bridgeID: bridge,
            actionID: 2290,
          },
        ],
      };
      this.loading = true;
      this.ses.saveBridgeActions(action).subscribe(x => {
        this.loading = false;
        this.handleProfileChange(this.id);
      });
    }
  }

  handleProfileChange(ev) {
    this.route.navigate(['/se/client/' + ev]);
  }

  report() {
    const dialogRef = this.dialog.open(SharedReportDialogComponent, {
      panelClass: 'report-dialog',
      data: { app: 'se', bulk: false, needs: this.needs },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data != 'cancel') {
        this.loading = true;
        const iosSafari =
          this.pltfrm.IOS ||
          (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

        const client = [this.id];
        const options = { ...result.data };
        this.rbs.getClientReport(client, options).subscribe(x => {
          const filename = result.data.filename
            ? result.data.filename + '.pdf'
            : this.name + '-report.pdf';

          let fileData;
          const reader = new FileReader();
          reader.readAsDataURL(x);
          reader.onloadend = () => {
            fileData = reader.result;
            saveAs(fileData, filename, {
              proxyURL: `${this.baseUrl}/util/proxy`,
              proxyTarget: '_self',
              forceProxy: iosSafari,
            });
            this.loading = false;
          };
        });
      }
    });
  }

  buildChart(data) {
    let chartOutput;
    switch (data.ChartType) {
      case '3ddonut':
        const tableData = [];
        data.data.forEach(e => {
          tableData.push([e.name, e.y]);
        });

        chartOutput = new Chart({
          chart: {
            type: 'pie',
            options3d: {
              enabled: true,
              alpha: 45,
            },
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: null,
          },
          legend: {
            enabled: true,
            margin: 40,
            itemStyle: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
            itemMarginBottom: 10,
          },
          plotOptions: {
            pie: {
              size: '150%',
              depth: 45,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
          },
          colors: data.ColorPalette,
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>',
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: [
            {
              name: data.data.name,
              innerSize: '40%',
              data: tableData,
              type: 'pie',
            },
          ],
        });
        break;
      case 'bar':
        chartOutput = new Chart({
          chart: {
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 5,
            },
            width: 500,
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            style: {
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
          title: {
            text: data.title,
          },
          legend: {
            enabled: false,
          },
          xAxis: {
            gridLineColor: '#a9bfda',
            categories: data.XAxisLabel,
            labels: {
              skew3d: true,
              style: {
                fontSize: '16px',
              },
            },
          },
          yAxis: {
            gridLineColor: '#a9bfda',
            allowDecimals: false,
            min: 0,
            title: {
              text: '',
            },
            stackLabels: {
              enabled: true,
              borderWidth: 0,
              // format: '{total}',
              formatter: function () {
                return '$' + Highcharts.numberFormat(this.total, 0);
              },
              style: {
                fontWeight: 'bold',
                color: 'black',
                borderWidth: 0,
                textOutline: null,
              },
            },
          },
          colors: data.ColorPalette,
          tooltip: {
            headerFormat: '',
            pointFormat: '{series.name}: ${point.y}',
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              depth: 40,
            },
          },
          exporting: { enabled: false },
          credits: {
            enabled: false,
          },
          series: data.data,
        });
        break;
    }
    return chartOutput;
  }
}
