import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-radio',
  styleUrls: ['form-radio.component.scss'],
  template: `
    @if (props.label) {
      <label
        class="section"
        class="w-100"
        [class.mat-error]="formControl.errors && formControl.touched"
      >
        <span [innerHTML]="props.label"></span>
        @if (props.required) {
          <sup>*</sup>
        }
        @if (props.help) {
          <mat-icon
            class="help-icon"
            matTooltip="{{ to.help }}"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'rb-tooltip'"
          >
            help
          </mat-icon>
        }
      </label>
    }
    <mat-radio-group
      class="radio-group radio-group-explanation col-12"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [required]="props.required"
    >
      @for (opt of to?.options; track opt) {
        <mat-radio-button class="radio-button" [value]="opt.value">{{
          opt.label
        }}</mat-radio-button>
      }
      @if (formControl.hasError('required') && formControl.touched) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired') && formControl.touched) {
        <mat-error>
          This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.
        </mat-error>
      }
    </mat-radio-group>
  `,
})
export class FormlyRadioComponent extends FieldType {
  @HostBinding('class') classes = 'bottom-margin';
}
