import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-status',
  styleUrls: ['form-status.component.scss'],
  template: `
    @for (stati of to?.options; track stati) {
      @if (stati.value == +formControl.value) {
        <div
          class="indicator"
          [ngClass]="{
            neutral: +formControl.value == 0 || +formControl.value == 3,
            positive: +formControl.value == 1 || +formControl.value == 4,
            negative: +formControl.value == 1
          }"
          [matTooltip]="stati.label"
        >
          @switch (+formControl.value) {
            @case (0) {
              <mat-icon>not_interested</mat-icon>
            }
            @case (1) {
              <mat-icon>thumb_down_alt</mat-icon>
            }
            @case (2) {
              <mat-icon>verified_user</mat-icon>
            }
            @case (3) {
              <mat-icon>report_problem</mat-icon>
            }
            @case (4) {
              <mat-icon>local_atm</mat-icon>
            }
          }
        </div>
      }
    }
  `,
})
export class FormlyStatusComponent extends FieldType {}
