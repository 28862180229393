<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Your Case Statistics </mat-card-header>
  <mat-card-content class="row">
    <div class="col-6">
      <h2>Current Case Stats</h2>
      @if (data) {
        <table class="content col-12 mb-2 cases">
          <thead>
            <th class="large positive">
              <mat-icon class="positive">check_circle</mat-icon>
              {{ data.summary.positive }} Positive
            </th>
            <th class="large neutral">
              <mat-icon class="neutral">error</mat-icon>
              {{ data.summary.neutral }} Neutral
            </th>
            <th class="large negative">
              <mat-icon class="negative">remove_circle</mat-icon>
              {{ data.summary.negative }} Negative
            </th>
          </thead>
        </table>
      }
      @if (data) {
        <table class="content col-12">
          <tbody>
            @for (case of data.cases; track case) {
              <tr
                class="case"
                routerLink="/li/questionnaire/{{ case.profileID }}"
              >
                <td>
                  @if (case.products[0].scoreClass === 'positive') {
                    <mat-icon class="positive">check_circle</mat-icon>
                  }
                  @if (case.products[0].scoreClass === 'neutral') {
                    <mat-icon class="neutral">error</mat-icon>
                  }
                  @if (case.products[0].scoreClass === 'negative') {
                    <mat-icon class="negative">remove_circle</mat-icon>
                  }
                  <strong>{{ case.fullName }}</strong>
                </td>
                @for (prod of case.products; track prod) {
                  <td class="">{{ prod.productName }}</td>
                }
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
    <div class="col-6">
      <h2>Last 30 Days</h2>
      @if (data) {
        <table class="content col-12 products">
          <thead class="mb-2">
            <th>Product</th>
            <th class="text-center">Total</th>
            <th class="large text-center">
              <mat-icon class="positive">check_circle</mat-icon>
            </th>
            <th class="large text-center">
              <mat-icon class="neutral">error</mat-icon>
            </th>
            <th class="large text-center">
              <mat-icon class="negative">remove_circle</mat-icon>
            </th>
          </thead>
          <tbody>
            @for (prdct of data.products; track prdct) {
              <tr>
                <td>{{ prdct.productName }}</td>
                <td class="text-center">
                  <span>{{ prdct.total }}</span>
                  <span>
                    {{ prdct.total / data.overall.total | percent }}
                  </span>
                </td>
                <td class="text-center">
                  <span>{{ prdct.positive }}</span>
                  <span>
                    {{
                      prdct.positive > 0
                        ? (prdct.positive / prdct.total) * 100
                        : 0
                    }}%
                  </span>
                </td>
                <td class="text-center">
                  <span>{{ prdct.neutral }}</span>
                  <span>
                    {{
                      prdct.neutral > 0
                        ? (prdct.neutral / prdct.total) * 100
                        : 0
                    }}%
                  </span>
                </td>
                <td class="text-center">
                  <span>{{ prdct.negative }}</span>
                  <span>
                    {{
                      prdct.negative > 0
                        ? (prdct.negative / prdct.total) * 100
                        : 0
                    }}%
                  </span>
                </td>
              </tr>
            }
            <tr>
              <td>Totals</td>
              <td class="text-center">
                <span>{{ data.overall.total }}</span> <span>100%</span>
              </td>
              <td class="text-center">
                <span>{{ data.overall.positive }}</span>
                <span>
                  {{
                    data.overall.positive * 1 > 0
                      ? ((data.overall.positive * 1) / data.overall.total) * 100
                      : 0
                  }}%
                </span>
              </td>
              <td class="text-center">
                <span>{{ data.overall.neutral }}</span>
                <span>
                  {{
                    data.overall.neutral * 1 > 0
                      ? ((data.overall.neutral * 1) / data.overall.total) * 100
                      : 0
                  }}%
                </span>
              </td>
              <td class="text-center">
                <span>{{ data.overall.negative }}</span>
                <span>
                  {{
                    data.overall.negative * 1 > 0
                      ? ((data.overall.negative * 1) / data.overall.total) * 100
                      : 0
                  }}%
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      }
    </div>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
  </mat-card-content>
</mat-card>
