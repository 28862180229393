<div class="page-container profile-history-container container">
  <div class="explorer-content-container unified">
    @if (!loading) {
      <h1 class="header col-12">
        Results for {{ changeSummary[0].ClientName }}
      </h1>
      <mat-accordion [multi]="false">
        @for (pageData of changeSummary; track pageData.User) {
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <strong>
                  {{ pageData.DateTime | localTime: 'date' }} &mdash;
                  {{ pageData.User }} &mdash; {{ pageData.Solution }}
                </strong>
                <span class="change-data">
                  Product Changes
                  <span class="data-point mx-1">
                    {{ pageData.ProductChanges }}
                  </span>
                  Score Class Changes
                  <span class="data-point ml-1">
                    {{ pageData.ScoreClassChanges }}
                  </span>
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="results-container row">
                <div class="products-container sub-container col-7">
                  <h3>Products</h3>
                  <table>
                    <thead>
                      <th>Product Name</th>
                      <th>Product Code</th>
                      <th>Score Class</th>
                      <th>Product Type</th>
                      <th>Amount</th>
                    </thead>
                    <tbody>
                      @for (row of pageData.products; track row.ProductCode) {
                        <tr>
                          <td>{{ row.ProductName }}</td>
                          <td>{{ row.ProductCode }}</td>
                          <td class="text-center">
                            @switch (row.ScoreClass) {
                              @case ('positive') {
                                <mat-icon class="positive">
                                  check_circle
                                </mat-icon>
                              }
                              @case ('neutral') {
                                <mat-icon class="neutral">error</mat-icon>
                              }
                              @case ('negative') {
                                <mat-sidenav-content class="negative">
                                  remove_circle
                                </mat-sidenav-content>
                              }
                            }
                          </td>
                          <td>{{ row.ProductType }}</td>
                          <td>{{ row.Amount | currency }}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div class="key-data-container sub-container col-5">
                  <h3>Key Data</h3>
                  <table>
                    <thead>
                      <th>Label</th>
                      <th>Value</th>
                    </thead>
                    <tbody>
                      @for (row of pageData.keydata; track row.label) {
                        <tr>
                          <td>{{ row.label }}</td>
                          <td>{{ row.display }}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div class="change-history-container sub-container col-12 mt-5">
                  <h3>Change History</h3>
                  @for (
                    changeHistory of pageData.profilechanges;
                    track changeHistory.date
                  ) {
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <strong>
                              {{ changeHistory.date }} &mdash;
                              {{ changeHistory.userId }} &mdash;
                              {{ changeHistory.solution }}
                            </strong>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                          <mat-tab-group>
                            @if (changeHistory.changes) {
                              <mat-tab label="Changes">
                                <table class="changes">
                                  <thead>
                                    <th>Variable</th>
                                    <th>Label</th>
                                    <th>Old Value</th>
                                    <th>New Value</th>
                                  </thead>
                                  <tbody>
                                    @for (
                                      row of changeHistory.changes;
                                      track row.label
                                    ) {
                                      <tr>
                                        <td>{{ row.variable }}</td>
                                        <td>{{ row.label }}</td>
                                        <td>{{ row.oldvalue }}</td>
                                        <td>{{ row.newvalue }}</td>
                                      </tr>
                                    }
                                  </tbody>
                                </table>
                              </mat-tab>
                            }
                            @if (changeHistory.deletions) {
                              <mat-tab label="Deletions">
                                <table class="changes">
                                  <thead>
                                    <th>Variable</th>
                                    <th>Label</th>
                                    <th>Value Removed</th>
                                  </thead>
                                  <tbody>
                                    @for (
                                      row of changeHistory.deletions;
                                      track row.variable
                                    ) {
                                      <tr>
                                        <td>{{ row.variable }}</td>
                                        <td>{{ row.label }}</td>
                                        <td>{{ row.oldvalue }}</td>
                                      </tr>
                                    }
                                  </tbody>
                                </table>
                              </mat-tab>
                            }
                            @if (changeHistory.additions) {
                              <mat-tab label="Additions">
                                <table class="changes">
                                  <thead>
                                    <th>Variable</th>
                                    <th>Label</th>
                                    <th>Value Added</th>
                                  </thead>
                                  <tbody>
                                    @for (
                                      row of changeHistory.additions;
                                      track row.variable
                                    ) {
                                      <tr>
                                        <td>{{ row.variable }}</td>
                                        <td>{{ row.label }}</td>
                                        <td>{{ row.newvalue }}</td>
                                      </tr>
                                    }
                                  </tbody>
                                </table>
                              </mat-tab>
                            }
                          </mat-tab-group>
                        </ng-template>
                      </mat-expansion-panel>
                    </mat-accordion>
                  }
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        }
      </mat-accordion>
    }
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
