<h1 mat-dialog-title>Calculate Expense Ratio</h1>
<mat-dialog-content #scroll class="dialog-container">
  <div class="toolbar clearfix w-100">
    <mat-form-field class="col-12">
      <mat-label>Search for a fund</mat-label>
      <input
        matInput
        placeholder="Search for a fund"
        [formControl]="searchControl"
        [matAutocomplete]="symbolLookup"
      />
      <mat-icon matSuffix>search</mat-icon>
      <mat-autocomplete
        #symbolLookup="matAutocomplete"
        autoActiveFirstOption
        [displayWith]="displayFn"
        (optionSelected)="selectFundLookup($event)"
      >
        @for (option of filteredSymbols | async; track option) {
          <mat-option [value]="option">
            {{ option.FundID }} - {{ option.FundName }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <button
      mat-button
      class="btn btn-primary pull-right"
      color="primary"
      type="button"
      (click)="manualAdd()"
    >
      <mat-icon class="mr-2">add_circle</mat-icon>Add other securities that are
      not Mutual Funds or ETFs
    </button>
  </div>
  @if (rows.length > 0) {
    <kendo-grid
      class="col-12 fundFeeLookupGrid"
      #grid
      [kendoGridBinding]="rows"
      [selectable]="{ enabled: false }"
      [navigable]="true"
    >
      @for (column of columns; track column) {
        <kendo-grid-column
          [field]="column.DataField"
          [title]="column.Label"
          [width]="setWidth(column.DataField)"
          [editable]="true"
        >
          @switch (column.ControlType) {
            @case ('text') {
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <mat-form-field class="col-12">
                  <input
                    matInput
                    [value]="dataItem[column.DataField]"
                    [placeholder]="column.Label"
                    [disabled]="dataItem.disable"
                    (keyup)="
                      inputChange(
                        $event,
                        rowIndex,
                        column.DataField,
                        dataItem,
                        column.ControlType
                      )
                    "
                  />
                </mat-form-field>
              </ng-template>
            }
            @case ('number') {
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <mat-form-field class="col-12">
                  <input
                    matInput
                    type="number"
                    [value]="dataItem[column.DataField]"
                    [placeholder]="column.Label"
                    [disabled]="dataItem.disable"
                    (keyup)="
                      inputChange(
                        $event,
                        rowIndex,
                        column.DataField,
                        dataItem,
                        column.ControlType
                      )
                    "
                  />
                </mat-form-field>
              </ng-template>
            }
            @case ('currency') {
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div [className]="borderCheck(rowIndex, column.DataField)">
                  <mat-form-field class="col-12 entry-border">
                    <input
                      matInput
                      [value]="dataItem[column.DataField]"
                      [placeholder]="column.Label"
                      [maskito]="dollarMask"
                      maxlength="20"
                      (keyup)="
                        inputChange(
                          $event,
                          rowIndex,
                          column.DataField,
                          dataItem,
                          column.ControlType
                        )
                      "
                    />
                  </mat-form-field>
                </div>
              </ng-template>
            }
            @case ('perc2') {
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <mat-form-field class="col-12">
                  <input
                    matInput
                    [value]="dataItem[column.DataField]"
                    [placeholder]="column.Label"
                    [maskito]="percentMask"
                    [disabled]="dataItem.disable"
                    (keyup)="
                      inputChange(
                        $event,
                        rowIndex,
                        column.DataField,
                        dataItem,
                        column.ControlType
                      )
                    "
                  />
                </mat-form-field>
              </ng-template>
            }
          }
        </kendo-grid-column>
      }
      <kendo-grid-column width="25">
        <ng-template
          kendoGridCellTemplate
          let-rowIndex="rowIndex"
          let-value="value"
          let-row="row"
        >
          <button
            mat-icon-button
            class="delete"
            (click)="remove(rowIndex, $event)"
            matToolTip="Delete Entry"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  }
</mat-dialog-content>
@if (entryError) {
  <h5 class="entry-error mb-0 mt-2">{{ entryError }}</h5>
}
<mat-dialog-actions class="row buttons mt-5">
  <button
    mat-raised-button
    color="accent"
    (click)="calculate()"
    class="pull-right"
  >
    Calculate
  </button>
  <button mat-raised-button (click)="cancel()" class="pull-right"
    >Cancel</button
  >
</mat-dialog-actions>
<ngx-loading [show]="loading"></ngx-loading>
