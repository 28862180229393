<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header>
    VIA Active Rates
    <button mat-button (click)="load()" class="header-button download-link"
      ><mat-icon>cloud_download</mat-icon></button
    >
  </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'loaded' || state === 'none') {
      <button
        mat-button
        (click)="downloadReport()"
        class="header-button download-link"
        >Download Report</button
      >
    }
    @if (state === 'loaded' || state === 'none') {
      <button
        mat-button
        (click)="download()"
        class="header-button download-link"
        ><mat-icon>save</mat-icon></button
      >
    }
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Annuities Available</h4>
      </div>
    }
    @if (state === 'error') {
      <strong>Error Loading Rates</strong>
    }
    @if (updating) {
      <table>
        <thead>
          <th>Carrier</th>
          <th>Carrier Code</th>
          <th>Product Code</th>
          <th>Modifier</th>
          <th>Version Date</th>
          <th>Full Name</th>
          <th>ID</th>
          <th>Cap Rate</th>
          <th>Participation Rate</th>
          <th>Participation Threshold</th>
          <th>Participation Tier Two</th>
          <th>Spread</th>
          <th>Step Rate</th>
          <th>Rate Lock Period</th>
          <th>Buffer</th>
          <th>Floor</th>
          <th>Term</th>
          <th>Prem Min</th>
          <th>Prem Max</th>
          <th>Effective Date</th>
          <th>Deprecate Date</th>
        </thead>
        <tbody>
          @for (a of updating; track a) {
            <tr
              [className]="
                GetClass(a['RateEffectiveDate'], a['RateDeprecateDate'])
              "
            >
              <td>{{ a['Carrier Name'] }}</td>
              <td>{{ a['CarrierCode'] }}</td>
              <td>{{ a['Product Code'] }}</td>
              <td>{{ a['Modifier'] }}</td>
              <td>{{ a['Version Date'] }}</td>
              <th>{{ a['Full Name'] }}</th>
              <th>{{ a['id'] }}</th>
              <th>{{ a['Rate Cap'] }}</th>
              <th>{{ a['Participation Rate'] }}</th>
              <th>{{ a['Participation Threshold'] }}</th>
              <th>{{ a['Participation Tier 2'] }}</th>
              <th>{{ a['Spread'] }}</th>
              <th>{{ a['Step/Trigger Rate'] }}</th>
              <th>{{ a['Rate Lock Period'] }}</th>
              <th>{{ a['Buffer'] }}</th>
              <th>{{ a['Floor'] }}</th>
              <th>{{ a['Term'] }}</th>
              <th>{{ a['Premium Minimum'] }}</th>
              <th>{{ a['Premium Maximum'] }}</th>
              <th>{{ a['Rate Effective Date'] }}</th>
              <th>{{ a['Rate Deprecate Date'] }}</th>
            </tr>
          }
        </tbody>
      </table>
    }
  </mat-card-content>
</mat-card>
