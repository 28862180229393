@if (rights && rights.includes('FundSearch') && !search529) {
  <mat-form-field class="col-12">
    <mat-label>Search for an Investment</mat-label>
    <input
      placeholder="Search for an Investment"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="symbolLookup"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete
      #symbolLookup="matAutocomplete"
      autoActiveFirstOption
      [displayWith]="displayFn"
      (optionSelected)="selectFundLookup($event)"
    >
      @if (dropdownLoading) {
        <mat-option disabled class="dropdownLoadingSpinner"
          ><mat-spinner diameter="48"></mat-spinner
        ></mat-option>
      }
      @if (dropdownNoResults) {
        <mat-option disabled>No Results</mat-option>
      }
      @if (!dropdownLoading && !dropdownNoResults) {
        @for (option of filteredSymbols; track option) {
          <mat-option [value]="option">
            {{ option.FundID }} - {{ option.FundName }}
          </mat-option>
        }
      }
    </mat-autocomplete>
  </mat-form-field>
}

@if (rights && rights.includes('FundSearch') && search529) {
  <mat-form-field class="col-12">
    <mat-label>Search for an Investment</mat-label>
    <input
      placeholder="Search for an Investment"
      matInput
      [formControl]="searchControl529"
      [matAutocomplete]="symbolLookup"
      [disabled]="!fiveTwoNinePlan"
    />
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-autocomplete
      #symbolLookup="matAutocomplete"
      autoActiveFirstOption
      [displayWith]="displayFn"
      (optionSelected)="selectFundLookup($event)"
    >
      @for (option of symbols; track option) {
        <mat-option [value]="option">
          {{ option.FundName }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}

@if (options?.fundfamily || options?.shareClass) {
  <mat-accordion class="lined">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Filter Search </mat-panel-title>
      </mat-expansion-panel-header>
      @if (options?.fundfamily) {
        <mat-form-field class="col-12 col-md-3">
          <mat-label>Filter Fund Families</mat-label>
          <mat-select placeholder="Filter Fund Families" [(value)]="fundFamily">
            @for (fam of familyList; track fam) {
              <mat-option [value]="fam.ID">
                {{ fam.Name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      @if (options?.shareclass) {
        <mat-form-field class="col-12 col-md-3">
          <mat-label>Filter Fund Classes</mat-label>
          <mat-select placeholder="Filter Fund Classes" [(value)]="shareClass">
            <mat-option value="A">A Shares</mat-option>
            <mat-option value="C">C Shares</mat-option>
          </mat-select>
        </mat-form-field>
      }
    </mat-expansion-panel>
  </mat-accordion>
}
