<div class="left-nav-container">
  @if (rights && !rights?.includes('HideHomeButtonInMenu')) {
    <button
      id="home-button"
      mat-button
      class="main-button"
      (click)="goToHome()"
      [disabled]="disableButtons"
    >
      <mat-icon>home</mat-icon>Home
    </button>
  }

  @if (!searchPage) {
    @if (
      modStatusSvc.apps() &&
      (modStatusSvc.apps().includes('pp') ||
        currentApp === 'rb' ||
        modStatusSvc.apps().includes('clientinfo'))
    ) {
      <button
        id="client-info"
        mat-button
        class="main-button client-info"
        (click)="changeApp('rb')"
        [ngClass]="{
          active: currentApp.toLowerCase() === 'rb',
          'app-complete': appStatuses.complete.includes('clientinfo'),
          'app-inprogress': appStatuses.inProgress.includes('clientinfo')
        }"
        [disabled]="disableButtons"
      >
        <mat-icon>account_box</mat-icon>Client Information
        @if (currentApp !== 'summary') {
          <div class="indicator">
            <mat-icon class="error">priority_high</mat-icon>
            <mat-icon class="complete">check</mat-icon>
          </div>
        }
      </button>

      @if (currentApp === 'rb' && stepsList.length > 1 && !loader) {
        <div class="app-steps" [ngClass]="{ open: stepsList.length > 1 }">
          @for (stp of stepsList; track stp.label; let idx = $index) {
            <button
              mat-button
              [hidden]="stp.hidden"
              [class]="checkAppStep(idx)"
              (click)="changeTab(stp, idx)"
              [disabled]="disableButtons"
            >
              <span class="label-container">
                {{ stp.label }}
              </span>
              <div class="indicator">
                <mat-icon class="error">priority_high</mat-icon>
                <mat-icon class="complete">check</mat-icon>
              </div>
            </button>
          }
        </div>
      }
    }

    @if (
      (modStatusSvc.apps() && modStatusSvc.apps().includes('pp') && profile) ||
      currentApp === 'pp'
    ) {
      <button
        id="product-profiler"
        mat-button
        class="main-button"
        (click)="changeApp('pp')"
        [ngClass]="{
          active: currentApp.toLowerCase() === 'pp',
          'app-complete': appStatuses.complete.includes('pp'),
          'app-inprogress': appStatuses.inProgress.includes('pp')
        }"
        [disabled]="disableButtons"
      >
        <mat-icon class="app-icon" svgIcon="pp_icon"></mat-icon> Product
        Profiler<div class="indicator"></div>
      </button>
    }
    @if (currentApp === 'pp' && stepsList.length > 1 && !loader) {
      <div class="app-steps pp" [ngClass]="{ open: stepsList.length > 1 }">
        @for (stp of stepsList; track stp.label; let idx = $index) {
          <button
            mat-button
            [hidden]="stp.hidden"
            [class]="checkAppStep(idx)"
            (click)="changeTab(stp, idx)"
            [disabled]="disableButtons"
          >
            <span class="label-container">
              {{ stp.label }}
            </span>
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        <button
          mat-button
          (click)="changeToPage('pp', 'results')"
          class="results-button"
          [ngClass]="{
            current: currentTab === 'results' && currentApp === 'pp'
          }"
          [disabled]="disableButtons"
        >
          Results
          <div class="indicator">
            <mat-icon class="error">priority_high</mat-icon>
            <mat-icon class="complete">check</mat-icon>
          </div>
        </button>
      </div>
    }

    @if (
      (modStatusSvc.apps() && modStatusSvc.apps().includes('aw')) ||
      currentApp === 'aw'
    ) {
      <button
        id="annuity-wizard"
        mat-button
        class="main-button"
        (click)="changeApp('aw')"
        [ngClass]="{
          active: currentApp.toLowerCase() === 'aw',
          'app-complete': appStatuses.complete?.includes('aw'),
          'app-inprogress': appStatuses.inProgress?.includes('aw')
        }"
        [disabled]="disableButtons"
      >
        <mat-icon class="app-icon" svgIcon="aw_icon"></mat-icon> Annuity
        Wizard<div class="indicator"></div>
      </button>
    }
    @if (currentApp === 'aw' && stepsList.length >= 1 && !loader) {
      <div class="app-steps aw" [ngClass]="{ open: stepsList.length >= 1 }">
        @for (stp of stepsList; track stp.label; let idx = $index) {
          <button
            mat-button
            [hidden]="stp.hidden"
            class="{{ checkAppStep(idx) }}"
            (click)="changeTab(stp, idx)"
            [disabled]="disableButtons"
          >
            <span class="label-container">
              {{ stp.label }}
            </span>
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (modStatusSvc.hasAwComparisonPage()) {
          <button
            mat-button
            (click)="changeToPage('aw', 'compare-results')"
            [ngClass]="{
              current: currentTab === 'compare-results' && currentApp === 'aw',
              visited: currentTab === 'results' && currentApp === 'aw'
            }"
            [disabled]="disableButtons"
          >
            Income Comparison
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        <button
          mat-button
          (click)="changeToPage('aw', 'results')"
          [ngClass]="{
            current: currentTab === 'results' && currentApp === 'aw'
          }"
          class="results-button"
          [disabled]="disableButtons"
        >
          Results
          <div class="indicator">
            <mat-icon class="error">priority_high</mat-icon>
            <mat-icon class="complete">check</mat-icon>
          </div>
        </button>
      </div>
    }
    @if (
      (modStatusSvc.apps() && modStatusSvc.apps().includes('iw')) ||
      currentApp === 'iw'
    ) {
      <button
        id="investment-wizard"
        mat-button
        class="main-button"
        (click)="changeApp('iw')"
        [ngClass]="{
          active: currentApp.toLowerCase() === 'iw',
          'app-complete': appStatuses.complete.includes('iw'),
          'app-inprogress': appStatuses.inProgress.includes('iw')
        }"
        [disabled]="disableButtons"
      >
        <mat-icon class="app-icon" svgIcon="iw_icon"></mat-icon> Investment
        Wizard<div class="indicator"></div>
      </button>
    }
    @if (currentApp === 'iw' && stepsList.length > 1 && !loader) {
      <div class="app-steps iw" [ngClass]="{ open: stepsList.length > 1 }">
        @for (stp of stepsList; track stp.label; let idx = $index) {
          <button
            mat-button
            [hidden]="stp.hidden"
            class="{{ checkAppStep(idx) }}"
            (click)="changeTab(stp, idx)"
            [disabled]="disableButtons"
          >
            <span class="label-container">
              {{ stp.label }}
            </span>
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (!iwValidateTrack) {
          <button
            mat-button
            (click)="changeToPage('iw', 'program-selection')"
            [ngClass]="{
              visited:
                currentTab === 'advisory' ||
                currentTab === 'investment-model' ||
                currentTab === 'results' ||
                currentTab === 'validate' ||
                currentTab === 'fund-allocations',
              current: currentTab === 'program-selection' && currentApp === 'iw'
            }"
            class="program-selection"
            [disabled]="disableButtons"
          >
            Program Selection
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (!iwValidateTrack && iwProg && iwProg !== 'Advisory') {
          <button
            mat-button
            (click)="changeToPage('iw', 'investment-model')"
            [ngClass]="{
              visited:
                currentTab === 'advisory' ||
                currentTab === 'results' ||
                currentTab === 'validate' ||
                currentTab === 'fund-allocations',
              current: currentTab === 'investment-model' && currentApp === 'iw'
            }"
            class="investment-model"
            [disabled]="disableButtons"
          >
            Investment Model
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (!iwValidateTrack && iwProg && iwProg !== 'Advisory') {
          <button
            mat-button
            (click)="changeToPage('iw', 'fund-allocations')"
            [ngClass]="{
              visited:
                currentTab === 'advisory' ||
                currentTab === 'results' ||
                currentTab === 'validate',
              current: currentTab === 'fund-allocations' && currentApp === 'iw'
            }"
            class="fund-allocation"
            [disabled]="disableButtons"
          >
            Fund Allocation
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        <button
          class="results-button"
          mat-button
          (click)="changeToPage('iw', 'validate')"
          [ngClass]="{
            visited: currentTab === 'results',
            current: currentTab === 'validate' && currentApp === 'iw'
          }"
          [disabled]="disableButtons"
        >
          Results
          <div class="indicator">
            <mat-icon class="error">priority_high</mat-icon>
            <mat-icon class="complete">check</mat-icon>
          </div>
        </button>
      </div>
    }
    @if (
      (modStatusSvc.apps() && modStatusSvc.apps().includes('li')) ||
      currentApp === 'li'
    ) {
      <button
        id="life-wizard"
        mat-button
        class="main-button"
        (click)="changeApp('li')"
        [ngClass]="{
          active: currentApp.toLowerCase() === 'li',
          'app-complete': appStatuses.complete.includes('li'),
          'app-inprogress': appStatuses.inProgress.includes('li')
        }"
        [disabled]="disableButtons"
      >
        <mat-icon class="app-icon" svgIcon="lw_icon"></mat-icon> Life Insurance
        Wizard<div class="indicator"></div>
      </button>
    }
    @if (currentApp === 'li' && stepsList.length >= 1 && !loader) {
      <div class="app-steps li" [ngClass]="{ open: stepsList.length >= 1 }">
        @for (stp of stepsList; track stp.label; let idx = $index) {
          <button
            mat-button
            [hidden]="stp.hidden"
            class="{{ checkAppStep(idx) }}"
            (click)="changeTab(stp, idx)"
            [disabled]="disableButtons"
          >
            <span class="label-container">
              {{ stp.label }}
            </span>
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (!liValidateTrack) {
          <button
            mat-button
            (click)="changeToPage('li', 'results')"
            [ngClass]="{
              visited: currentTab === 'compare' || currentTab === 'products',
              current: currentTab === 'results' && currentApp === 'li'
            }"
            [disabled]="disableButtons"
          >
            Life Insurance Review
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (!liValidateTrack) {
          <button
            mat-button
            (click)="changeToPage('li', 'compare')"
            [ngClass]="{
              visited: currentTab === 'products',
              current: currentTab === 'compare' && currentApp === 'li'
            }"
            [disabled]="disableButtons"
          >
            Compare Scenarios
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (!liValidateTrack) {
          <button
            class="product-result"
            mat-button
            (click)="changeToPage('li', 'products')"
            [ngClass]="{
              visited: visitedSteps?.includes('products'),
              current: currentTab === 'products' && currentApp === 'li'
            }"
            [disabled]="disableButtons"
          >
            Results
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
        @if (liValidateTrack) {
          <button
            class="validate-result"
            mat-button
            (click)="changeToPage('li', 'validate')"
            [ngClass]="{
              visited: visitedSteps?.includes('validate'),
              current: currentTab === 'validate' && currentApp === 'li'
            }"
            [disabled]="disableButtons"
          >
            Results
            <div class="indicator">
              <mat-icon class="error">priority_high</mat-icon>
              <mat-icon class="complete">check</mat-icon>
            </div>
          </button>
        }
      </div>
    }

    @if (profile && modStatusSvc.showCaseSummary()) {
      <button
        mat-button
        id="case-summary"
        class="main-button"
        (click)="changeApp('summary')"
        [ngClass]="{ active: currentApp === 'summary' }"
        [disabled]="disableButtons"
      >
        <mat-icon>list</mat-icon>Case Summary<div class="indicator"></div>
      </button>
    }
  }
</div>

<div class="status-container"></div>
