<div class="col-12 chart-container">
  <div [chart]="pieChart"></div>
  <div class="slider-container">
    <label class="w-20 flex">Permanent</label>
    <mat-slider
      [max]="100"
      [min]="0"
      [step]="1"
      [thumbLabel]="true"
      discrete
      [displayWith]="returnPercent"
      class="w-66 flex"
      [disabled]="disableSlider"
      #ngSlider
    >
      <input
        matSliderThumb
        [(ngModel)]="sliderValue"
        (change)="
          updateChart({
            source: ngSliderThumb,
            parent: ngSlider,
            value: ngSliderThumb.value
          })
        "
        #ngSliderThumb="matSliderThumb"
      />
    </mat-slider>
    <label class="w-20 flex text-right">Term</label>
  </div>
  @if (fundData?.showEdit) {
    <mat-form-field class="w-100 mb-5">
      <mat-label>{{ fundData.cashFlowLabel }}</mat-label>
      <input
        matInput
        [placeholder]="fundData.cashFlowLabel"
        [(ngModel)]="cashFlow"
        [readonly]="editAmount"
        [maskito]="dollarMask"
        maxlength="20"
        (input)="updateCashValue()"
      />
      @if (editAmount) {
        <button matSuffix mat-icon-button (click)="editCashAmount()">
          <mat-icon>mode_edit</mat-icon>
        </button>
      }
      @if (!editAmount) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearEdit()"
        >
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-form-field>
  }
  @if (tableData.perm.length) {
    <table class="info-table">
      <thead>
        <th></th>
        <th>Right Now</th>
        @if (tableData.perm[1]) {
          <th>Age 65</th>
        }
        @if (tableData.perm[2]) {
          <th>Age 85</th>
        }
      </thead>
      <tbody>
        <tr>
          <td>Annual Premium</td>
          <td>
            {{ tableData.prem[0] | currency: 'USD' : 'symbol' : '1.0-0' }}
          </td>
          @if (tableData.perm[1]) {
            <td>
              {{ tableData.prem[1] | currency: 'USD' : 'symbol' : '1.0-0' }}
            </td>
          }
          @if (tableData.perm[2]) {
            <td>
              {{ tableData.prem[2] | currency: 'USD' : 'symbol' : '1.0-0' }}
            </td>
          }
        </tr>
        <tr>
          <td>Net Death Benefit</td>
          <td>
            {{ tableData.temp[0] | currency: 'USD' : 'symbol' : '1.0-0' }}
          </td>
          @if (tableData.perm[1]) {
            <td>
              {{ tableData.temp[1] | currency: 'USD' : 'symbol' : '1.0-0' }}
            </td>
          }
          @if (tableData.perm[2]) {
            <td>
              {{ tableData.temp[2] | currency: 'USD' : 'symbol' : '1.0-0' }}
            </td>
          }
        </tr>
        <tr>
          <td>Net Cash Value</td>
          <td>
            {{ tableData.perm[0] | currency: 'USD' : 'symbol' : '1.0-0' }}
          </td>
          @if (tableData.perm[1]) {
            <td>
              {{ tableData.perm[1] | currency: 'USD' : 'symbol' : '1.0-0' }}
            </td>
          }
          @if (tableData.perm[2]) {
            <td>
              {{ tableData.perm[2] | currency: 'USD' : 'symbol' : '1.0-0' }}
            </td>
          }
        </tr>
      </tbody>
    </table>
  }
  <div [chart]="compareChart"></div>
  <div [chart]="premiumChart"></div>
</div>
