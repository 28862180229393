<div class="utility-nav-container" [ngClass]="{ vertical: vertical }">
  @if (current !== 'se') {
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
  }

  <ng-container *hasPermission="'NewDashboardTab'">
    @if (links.includes('dash')) {
      <button
        mat-button
        (click)="nav('dashboard')"
        [ngClass]="{ active: current === 'rb' }"
      >
        <mat-icon>dashboard</mat-icon>
        <span class="text-container">Dashboard</span>
      </button>
    }
  </ng-container>

  @if (current === 'se') {
    <button
      mat-button
      (click)="nav('se/search')"
      [ngClass]="{ active: current === 'search' }"
    >
      <mat-icon>search</mat-icon>
      <span class="text-container">Find Opportunities</span>
    </button>
  }

  <ng-container *hasPermission="'CaseExplorer'">
    @if (links.includes('caseExplorer')) {
      <button
        mat-button
        (click)="nav('/case-explorer')"
        [ngClass]="{ active: current === 'caseExplorer' }"
      >
        <mat-icon>find_in_page</mat-icon>
        <span class="text-container">Case Explorer</span>
      </button>
    }
  </ng-container>

  <ng-container *hasPermission="'ReportingTabInMenu'">
    @if (links.includes('reporting')) {
      <button
        mat-button
        (click)="nav('reporting')"
        [ngClass]="{ active: current === 'reporting' }"
      >
        <mat-icon>assessment</mat-icon>
        <span class="text-container">Reporting</span>
      </button>
    }
  </ng-container>
</div>
