<div mat-dialog-header>
  <h1>{{ headline }}</h1>
</div>
<div mat-dialog-content>
  <div class="row dialog-content">
    @if (displayHierarchyFields) {
      <app-hierarchy-modal
        [templateInData]="{ mode: 'SE' }"
        [isDialog]="false"
        (templateOutData)="setHierarchyData($event)"
        class="embedded-hierarchy col-12"
      ></app-hierarchy-modal>
    }
    <div class="search-options col-12">
      @if (searchType === 'demographics') {
        <div class="demo-search search row">
          <div class="col-12 mt-3">
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="Postal Codes"
                [(ngModel)]="searchModel.postalCode"
              />
            </mat-form-field>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="City"
                [(ngModel)]="searchModel.city"
              />
            </mat-form-field>
          </div>
          <div class="col-6 mt-3">
            <label class="col-12">Age</label>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="From"
                [(ngModel)]="searchModel.ageFrom"
              />
            </mat-form-field>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="To"
                [(ngModel)]="searchModel.ageTo"
              />
            </mat-form-field>
          </div>
          <div class="col-6 mt-3">
            <label class="col-12">Income</label>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="From"
                [(ngModel)]="searchModel.incomeFrom"
              />
            </mat-form-field>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="To"
                [(ngModel)]="searchModel.incomeTo"
              />
            </mat-form-field>
          </div>
          <div class="col-6 mt-3">
            <label class="col-12">Assets</label>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="From"
                [(ngModel)]="searchModel.assetsFrom"
              />
            </mat-form-field>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="To"
                [(ngModel)]="searchModel.assetsTo"
              />
            </mat-form-field>
          </div>
          <div class="col-6 mt-3">
            <label class="col-12">Products</label>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="From"
                [(ngModel)]="searchModel.productsFrom"
              />
            </mat-form-field>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="To"
                [(ngModel)]="searchModel.productsTo"
              />
            </mat-form-field>
          </div>
          <div class="col-6 mt-3">
            <label class="col-12">Client Profile</label>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="From"
                [(ngModel)]="searchModel.profileFrom"
              />
            </mat-form-field>
            <mat-form-field class="col-6">
              <input
                matInput
                placeholder="To"
                [(ngModel)]="searchModel.profileTo"
              />
            </mat-form-field>
          </div>
          <div class="col-6 mt-3">
            <mat-form-field>
              <mat-select
                placeholder="Prospect Source"
                [(ngModel)]="searchModel.source"
              >
                <mat-option value="18">2018 Tax Year</mat-option>
                <mat-option value="17">2017 Tax Year</mat-option>
                <mat-option value="16">2016 Tax Year</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 mt-3">
            <label class="col-12">Market Category</label>
            <mat-checkbox
              class="col-3 sugg-option"
              [(ngModel)]="searchModel.marketCategory.youngConsumers"
            >
              Young Consumers
            </mat-checkbox>
            <mat-checkbox
              class="col-3 sugg-option"
              [(ngModel)]="searchModel.marketCategory.preRetirees"
            >
              Pre-retirees
            </mat-checkbox>
            <mat-checkbox
              class="col-3 sugg-option"
              [(ngModel)]="searchModel.marketCategory.retirees"
            >
              Retirees
            </mat-checkbox>
            <mat-checkbox
              class="col-3 sugg-option"
              [(ngModel)]="searchModel.marketCategory.smallBusiness"
            >
              Small Business
            </mat-checkbox>
            <mat-checkbox
              class="col-3 sugg-option"
              [(ngModel)]="searchModel.marketCategory.professionals"
            >
              Professionals
            </mat-checkbox>
            <mat-checkbox
              class="col-3 sugg-option"
              [(ngModel)]="searchModel.marketCategory.others"
            >
              Others
            </mat-checkbox>
          </div>
          <label class="col-12 mx-3 mt-3">Additional Filters</label>
          <mat-radio-group
            class="col-12 mx-3"
            [(ngModel)]="searchModel.additional"
          >
            <mat-radio-button class="mr-3" value="all">All</mat-radio-button>
            <mat-radio-button class="mr-3" value="clients">
              Clients
            </mat-radio-button>
            <mat-radio-button class="mr-3" value="prospects">
              Prospects
            </mat-radio-button>
          </mat-radio-group>
        </div>
      }

      @if (searchType === 'callBack') {
        <div class="callback-search search">
          <label class="w-100">Date Range</label>
          <mat-form-field>
            <input
              required
              matInput
              [matDatepicker]="picker1"
              placeholder="Start Date"
              [(ngModel)]="searchModel.callBackStart"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <span class="mx-5">To</span>
          <mat-form-field>
            <input
              required
              matInput
              [matDatepicker]="picker2"
              placeholder="End Date"
              [(ngModel)]="searchModel.callBackEnd"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <label class="w-100">Filter By</label>
          <mat-radio-group [(ngModel)]="searchModel.callBackFilter">
            <mat-radio-button value="all">All</mat-radio-button>
            <mat-radio-button value="clients">Clients</mat-radio-button>
            <mat-radio-button value="prospects">Prospects</mat-radio-button>
          </mat-radio-group>
        </div>
      }

      @if (searchType === 'timeSensitive') {
        <div class="time-search search">
          <label class="col-12">Filter By</label>
          <mat-radio-group [(ngModel)]="searchModel.timeSensitive">
            <mat-radio-button value="all">All</mat-radio-button>
            <mat-radio-button value="clients">Clients</mat-radio-button>
            <mat-radio-button value="prospects">Prospects</mat-radio-button>
          </mat-radio-group>
        </div>
      }

      @if (searchType === 'name') {
        <div class="name-search search">
          <mat-form-field class="col-6">
            <input
              matInput
              placeholder="First Name"
              [(ngModel)]="searchModel.firstName"
            />
          </mat-form-field>
          <mat-form-field class="col-6">
            <input
              matInput
              placeholder="Last Name"
              [(ngModel)]="searchModel.lastName"
            />
          </mat-form-field>
        </div>
      }

      @if (searchType === 'opp') {
        <div class="opp-search search">
          <mat-form-field class="col-12">
            <mat-label>Enter Number of Relevant Opportunities</mat-label>
            <input
              matInput
              type="number"
              placeholder="Enter Number of Relevant Opportunities"
              [(ngModel)]="searchModel.oppNum"
            />
          </mat-form-field>
        </div>
      }

      @if (searchType === 'suggestion' && suggestions) {
        <div class="suggestion-search search">
          <div class="row">
            <mat-form-field class="col-4">
              <mat-label>Relevance Score Threshold</mat-label>
              <input
                matInput
                placeholder="Relevance Score Threshold"
                type="number"
                [(ngModel)]="searchModel.relThresh"
              />
            </mat-form-field>
          </div>
          <div class="row sugg-container">
            @for (sugg of suggestions | keyvalue; track sugg) {
              <div class="col-12 mb-4 row">
                <h4 class="w-100 sugg-title">{{ sugg.key }}</h4>
                @for (item of sugg.value; track item; let idx = $index) {
                  <mat-checkbox
                    class="col-4 sugg-option"
                    [(ngModel)]="selectedSuggestions[item.id]"
                    name="{{ item.name }}"
                  >
                    {{ item.name }}
                  </mat-checkbox>
                }
              </div>
            }
          </div>
        </div>
      }

      @if (searchType === 'lastAction') {
        <div class="action-search search">
          <mat-form-field class="col-6">
            <mat-select [(ngModel)]="searchModel.actionDate">
              <mat-option value="30">30 Days</mat-option>
              <mat-option value="60">60 Days</mat-option>
              <mat-option value="90">90 Days</mat-option>
              <mat-option value="120">120 Days</mat-option>
              <mat-option value="365">365 Days</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="action-container row px-3 pt-3">
            <mat-checkbox
              [(ngModel)]="selectedActions['2100']"
              class="col-4 action-option"
            >
              Follow Up
            </mat-checkbox>
            <mat-checkbox
              [(ngModel)]="selectedActions['2300']"
              class="col-4 action-option"
            >
              I Like it
            </mat-checkbox>
            <mat-checkbox
              [(ngModel)]="selectedActions['2200']"
              class="col-4 action-option"
            >
              No Thanks
            </mat-checkbox>
            <mat-checkbox
              [(ngModel)]="selectedActions['2900']"
              class="col-4 action-option"
            >
              Completed
            </mat-checkbox>
          </div>
        </div>
      }
    </div>
  </div>
  <div mat-dialog-actions class="row buttons">
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <button
      mat-raised-button
      color="accent"
      (click)="setSearchData()"
      [disabled]="isDisabled()"
    >
      Search
    </button>
  </div></div
>
