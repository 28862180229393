export const utilityApps = [
  {
    label: 'Downloadable Reports',
    link: '/post-trade/reports',
    icon: 'summarize',
  },
  {
    label: 'Account Grouping',
    link: '/account-grouping',
    right: 'AccountGroup',
    icon: 'dataset_linked',
  },
];
