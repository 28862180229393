import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ReportingService } from '../../../../reporting/reporting.service';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { SimpleLoadingComponent } from '../../../../shared-components/simple-loading/simple-loading.component';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-bridge-action-detail-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    GridModule,
    PDFModule,
    ExcelModule,
    MatButtonModule,
    SimpleLoadingComponent,
  ],
  templateUrl: './bridge-action-detail-modal.component.html',
  styleUrl: './bridge-action-detail-modal.component.scss',
})
export class BridgeActionDetailModalComponent implements OnInit {
  detailData;
  loading = false;

  public pdfSVG: SVGIcon = filePdfIcon;
  public excelSVG: SVGIcon = fileExcelIcon;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BridgeActionDetailModalComponent>,
    private rptSvc: ReportingService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.rptSvc
      .getBridgeActionDetail(
        this.data.dataItem?.parentID,
        this.data.dataItem?.unitID
      )
      .subscribe(res => {
        this.detailData = res.results;
        this.loading = false;
      });
  }

  closeModal(ev) {
    this.dialogRef.close();
  }
}
