<mat-card appearance="outlined" class="col-12">
  <mat-card-header> Investment Wizard Fund Comparison </mat-card-header>
  <mat-card-content>
    <div class="search-container row">
      <form class="col-12 row my-3">
        <mat-form-field class="col-12 col-md-3">
          <mat-label>Select a Program</mat-label>
          <mat-select
            [formControl]="programControl"
            (selectionChange)="updateFunds($event, 'all')"
            required
          >
            @for (option of programList; track option) {
              <mat-option [value]="option">{{ option.DisplayName }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-3">
          <mat-label>Filter Fund Family</mat-label>
          <mat-select
            multiple
            [formControl]="familyControl"
            (selectionChange)="updateFunds($event, 'all')"
          >
            @for (option of familyList; track option) {
              <mat-option [value]="option">{{ option.Name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-3">
          <mat-label>Select a Share Class</mat-label>
          <mat-select
            [formControl]="classControl"
            (selectionChange)="updateFunds($event, 'product')"
            [(ngModel)]="fundClass"
            required
          >
            <mat-option value="A">A</mat-option>
            <mat-option value="C">C</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-3">
          <mat-label>Number of Funds to Compare</mat-label>
          <mat-select [formControl]="numberControl" [value]="fundNum">
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="7">7</mat-option>
            <mat-option value="8">8</mat-option>
            <mat-option value="9">9</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="11">11</mat-option>
            <mat-option value="12">12</mat-option>
            <mat-option value="13">13</mat-option>
            <mat-option value="13">14</mat-option>
            <mat-option value="13">15</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12">
          <input
            placeholder="Select a Fund"
            matInput
            [formControl]="fundControl"
            [matAutocomplete]="auto"
            required
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFund"
            required
          >
            @for (option of filteredFunds | async; track option) {
              <mat-option [value]="option">
                {{ option.FundID }} - {{ option.FundName }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <div class="col-12">
        <button
          class="pull-right"
          mat-raised-button
          color="accent"
          [disabled]="!fundControl.value"
          (click)="getPdf()"
          ><mat-icon>insert_drive_file</mat-icon>Get Comparison Report</button
        >
      </div>
    </div>
  </mat-card-content>
</mat-card>
