<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Time Sensitive </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Opportunities Available</h4>
      </div>
    }
    @if (state === 'loaded') {
      <table>
        <thead>
          <th>Name</th>
          <th>Suggestion</th>
          <th class="text-center">Score</th>
        </thead>
        <tbody>
          @for (op of options; track op) {
            <tr (click)="goToProfile($event, op.id)">
              <td>{{ op.name }}</td>
              <td>{{ op.suggestion }}</td>
              <td class="text-center">{{ op.score }}</td>
            </tr>
          }
        </tbody>
      </table>
    }
  </mat-card-content>
</mat-card>
