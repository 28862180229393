<div mat-dialog-header>
  <h1>
    Financial Professional Monitor History
    <span> - {{ data.repDetail?.RepID }}</span>
  </h1>
</div>
<mat-dialog-content class="dialog-container row">
  <div class="annuity-exchange-container info-card mb-4">
    <table>
      <thead>
        <tr>
          <th></th>
          @for (header of repReviewKey; track header.label) {
            <th>{{ header.label }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (repReview of data.repDetail?.RepHistory; track repReview) {
          <tr>
            <td class="action-container">
              <span>
                <button
                  matSuffix
                  mat-icon-button
                  color="primary"
                  matTooltip="View financial professional history event"
                  (click)="viewHistoryEvent(repReview)"
                >
                  <mat-icon>preview</mat-icon>
                </button>
              </span>
            </td>
            @for (header of repReviewKey; track header.label) {
              @if (header.key) {
                <td>{{ header.key[repReview[header.varName]] }}</td>
              } @else {
                <td>{{ repReview[header.varName] }}</td>
              }
            }
          </tr>
        }
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
  <button mat-raised-button (click)="cancel()">Close</button>
</mat-dialog-actions>
