<div class="grid-component">
  @if (props.label) {
    <label [innerHTML]="props.label"></label>
  }
  <div class="toolbar clearfix w-100">
    @if (showTemplates) {
      <mat-form-field class="col-9">
        <mat-label>{{ label }}</mat-label>
        <mat-select
          [placeholder]="placeholder"
          (selectionChange)="applyTemplate($event)"
          [disabled]="!firmTemplates && !templates"
        >
          @if (firmTemplates && firmTemplates.length > 0) {
            <mat-optgroup label="Firm Templates">
              @for (tmplt of firmTemplates; track tmplt) {
                <mat-option [value]="tmplt">
                  {{ tmplt.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (templates && templates.length > 0) {
            <mat-optgroup label="User Templates">
              @for (tmplt of templates; track tmplt) {
                <mat-option [value]="tmplt">
                  {{ tmplt.name }}
                </mat-option>
              }
            </mat-optgroup>
          }
        </mat-select>
        <mat-hint></mat-hint>
      </mat-form-field>
    }
    @if (fiveTwoNineSearch) {
      <mat-form-field class="col-12">
        <mat-label>Select a Plan</mat-label>
        <mat-select
          (selectionChanged)="handle529($event)"
          [(ngModel)]="selected529"
        >
          @for (plan of plans529; track plan.PlanName) {
            <mat-option [value]="plan">{{ plan.PlanName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    @if (searchOptions) {
      <app-fund-search
        [type]="props.searchType"
        [options]="searchOptions"
        [program]="program"
        [fiveTwoNinePlan]="selected529?.PlanId"
        (fundSelected)="handleFund($event)"
        class="search-box mb-3"
      ></app-fund-search>
      @if (buttonOption && this.field['showButton']) {
        <button
          mat-button
          class="btn btn-primary pull-right"
          color="primary"
          type="button"
          (click)="add()"
        >
          <mat-icon class="mr-2">add_circle</mat-icon>{{ buttonOption }}
        </button>
      }
    }
    @if (!searchOptions && this.field['showButton'] && !hideDelete) {
      <button
        mat-button
        class="btn btn-primary pull-right"
        color="primary"
        type="button"
        (click)="add()"
      >
        <mat-icon class="mr-2">add_circle</mat-icon>Add
      </button>
    }
  </div>

  @if (this.field['showCsvFunctions']) {
    <div>
      <span class="pull-right">
        <button
          mat-button
          class="btn btn-primary pull-right"
          color="primary"
          (click)="downloadCsvTemplate($event)"
        >
          <mat-icon>download</mat-icon>Download CSV Template
        </button>
      </span>
      <span class="pull-right">
        <input
          type="file"
          class="file-input"
          (change)="onFileSelected($event)"
          #csvUpload
        />

        <span class="file-upload">
          @if (fileName) {
            <div>
              {{ fileName }}
            </div>
            <button
              mat-stroked-button
              class="pull-right ml-2 mb-2"
              (click)="csvUpload.click()"
            >
              <mat-icon>upload</mat-icon>Choose a different file
            </button>
            <button
              mat-raised-button
              class="pull-right mb-2"
              color="accent"
              (click)="completeCsvUpload()"
            >
              <mat-icon class="mr-2">check_circle_outline</mat-icon>Complete
              upload
            </button>
          } @else {
            <button
              mat-button
              class="btn btn-primary pull-right"
              color="primary"
              (click)="csvUpload.click()"
            >
              <mat-icon class="mr-2">upload</mat-icon>Upload CSV/Excel
            </button>
          }
        </span>
      </span>
    </div>
  }

  @if (field.key === 'SelectedFunds') {
    <h5 class="w-100 pull-right">
      Investment Program: {{ investmentProgramLabel }}
    </h5>
  }

  @if (!loading) {
    <kendo-grid
      class="col-12"
      #gridExp
      [kendoGridBinding]="model"
      [selectable]="{ enabled: false }"
      [kendoGridExpandDetailsBy]="expandDetailsBy"
      [(expandedDetailKeys)]="expandedDetailKeys"
      [navigable]="true"
    >
      @for (column of columns; track column) {
        @if (!hideColumn(field, column, 0)) {
          <kendo-grid-column
            [field]="column.key"
            [editable]="true"
            [hidden]="column.visuallyHidden"
          >
            <ng-template kendoGridHeaderTemplate>
              <div [ngClass]="{ 'has-help': column.help }">
                {{ column.label }}
                @if (column.help) {
                  <mat-icon
                    class="help-icon"
                    [matTooltip]="column.help"
                    [matTooltipPosition]="'above'"
                    [matTooltipClass]="'rb-tooltip'"
                  >
                    help
                  </mat-icon>
                }
              </div>
            </ng-template>

            @if (column.key === 'CurrentPosition_Sell') {
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row"
              >
                <mat-checkbox
                  (change)="changeSell(rowIndex, field, column, rowIndex)"
                  [checked]="sellChecked(rowIndex)"
                >
                  <span>Sell</span>
                </mat-checkbox>
              </ng-template>
            } @else {
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row"
                k-grid-ignore-click
              >
                <formly-field
                  [field]="getField(field, column, rowIndex)"
                  k-grid-ignore-click
                ></formly-field>
              </ng-template>
            }
          </kendo-grid-column>
        }
      }
      @if (!hideDelete) {
        <kendo-grid-column width="60">
          <ng-template
            kendoGridCellTemplate
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
          >
            <button
              mat-icon-button
              class="delete"
              (click)="remove(rowIndex, $event)"
              matToolTip="Delete Entry"
            >
              <mat-icon>cancel</mat-icon>
            </button>
          </ng-template>
        </kendo-grid-column>
      }

      <ng-template
        kendoGridDetailTemplate
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <section>
          @for (subField of subFields; track subField) {
            @if (
              subField.props.subField &&
              !getField(field, subField, rowIndex).hide
            ) {
              <formly-field
                [field]="getField(field, subField, rowIndex)"
                k-grid-ignore-click
              ></formly-field>
            }
          }
        </section>
      </ng-template>
      <ng-template kendoGridNoRecordsTemplate>{{ noRecords }}</ng-template>
    </kendo-grid>
  }
</div>
