<div class="page-container explorer-container container mt-5 w-100">
  <app-utility-toolbar
    [current]="'caseExplorer'"
    [vertical]="true"
    class="w-15 vertical"
  ></app-utility-toolbar>
  <div class="explorer-content-container w-85">
    <h1 class="header col-12"
      >Case Explorer
      <button
        mat-button
        color="primary"
        class="grid-button header-button"
        (click)="dlXlsx()"
      >
        <mat-icon>cloud_download</mat-icon>Download Excel File
      </button>
    </h1>
    <div class="tool-section">
      <div class="col-12 mb-3 row toolbar">
        <div class="filters-container">
          <div class="solution-select-container">
            <mat-form-field class="ml-2">
              <mat-label>Solution</mat-label>
              <mat-select [(ngModel)]="solution">
                @for (solution of solutions; track solution.value) {
                  <mat-option [value]="solution.value">
                    {{ solution.label }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="date-radio-container">
            <div class="date-select-container ml-2">
              <mat-form-field class="mr-2 startDate">
                <mat-label>Start Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="startPicker"
                  placeholder="Choose a start date"
                  [formControl]="startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="mr-2 endDate">
                <mat-label>End Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="endPicker"
                  placeholder="Choose an end date"
                  [formControl]="endDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="radio-container">
              <mat-label class="ml-2"><strong>View Mode:</strong></mat-label>
              <mat-radio-group [(ngModel)]="flatOrGrouped">
                <mat-radio-button class="mr-1" value="GROUP">
                  Case
                </mat-radio-button>
                <mat-radio-button class="mr-1" value="FLAT">
                  Product/Category
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="update-search-button-container">
          <button
            mat-flat-button
            color="primary"
            class="update-search-button ml-2"
            (click)="getCaseStatsSummary()"
            [disabled]="disableSearch()"
          >
            Update Search
          </button>
        </div>
      </div>

      <div class="utilities">
        <div class="col-6 hierarchy pull-left mb-2">
          @if (chooseHierarchy) {
            <button mat-button (click)="setHierarchy()" color="primary">
              <mat-icon>search</mat-icon>Change Search Hierarchy
            </button>
          }
          @if (selectedUnit || selectedUser || selectedRepCode) {
            <p>
              <strong>Searching for: </strong>
              @if (selectedUnit && !selectedUser && !selectedRepCode) {
                <ng-container class="search-as">
                  Unit: {{ selectedUnit.name }}
                </ng-container>
              }
              @if (selectedUser && !selectedRepCode) {
                <ng-container class="search-as">
                  User: {{ selectedUser.name }}
                </ng-container>
              }
              @if (selectedRepCode) {
                <ng-container class="search-as">
                  Rep Code: {{ selectedRepCode.name }}
                </ng-container>
              }
            </p>
            <button mat-button (click)="clearHierarchy()" class="clear">
              <mat-icon>close</mat-icon>
            </button>
          }
        </div>
        <button
          mat-button
          color="primary"
          class="reset-button header-button pull-right"
          (click)="resetGrid()"
        >
          <mat-icon>replay</mat-icon>Reset
        </button>
      </div>
    </div>

    @if (!hideGrid) {
      <kendo-grid
        class="col-12 case-stats-activity-grid"
        #explorerGrid="kendoGrid"
        [kendoGridBinding]="gridData.data"
        [sortable]="{
          allowUnsort: true,
          mode: 'multiple'
        }"
        [sort]="state.sort"
        [columnMenu]="{ columnChooser: false }"
        [style.maxHeight.px]="700"
        [filterable]="'menu'"
        [filter]="filter"
        [pageable]="true"
        [pageSize]="pageSize"
        [skip]="state.skip"
        [height]="gridHeight()"
      >
        @for (column of gridData.headers; track column) {
          <ng-template>
            <kendo-grid-column
              [field]="column.DataField"
              [title]="column.Label"
              [sortable]="column.Sort"
              [hidden]="isHidden(column.DataField)"
              [filterable]="column.Filter"
              [groupable]="column.Group"
              [media]="column.RespVis"
              [width]="column.DataField === 'User' ? 275 : 150"
              [class]="{
                'text-center':
                  column.DataField === 'ScoreClass' ||
                  column.DataField === 'ProductChanges' ||
                  column.DataField === 'ScoreClassChanges' ||
                  column.DataField === 'ProductType' ||
                  column.DataField === 'RunCount' ||
                  column.DataField === 'Track' ||
                  column.DataField === 'PurchaseType' ||
                  column.DataField === 'Amount' ||
                  column.DataField === 'AssessmentType',
                clickable:
                  column.DataField === 'User' ||
                  column.DataField === 'ClientName'
              }"
            >
              @if (column.Sort || column.Filter) {
                <ng-template kendoGridColumnMenuTemplate let-service="service">
                  @if (column.Sort) {
                    <kendo-grid-columnmenu-sort
                      [column]="column"
                      [service]="service"
                    >
                      <kendo-grid-messages
                        [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                        [sortDescending]="
                          getFilterMenuText(column.Type, 'desc')
                        "
                      ></kendo-grid-messages>
                    </kendo-grid-columnmenu-sort>
                  }
                  @if (column.Filter) {
                    <kendo-grid-columnmenu-filter
                      [column]="column"
                      [service]="service"
                    >
                      @if (column.FilterListDynamic || column.FilterList) {
                        <ng-template
                          kendoGridFilterMenuTemplate
                          let-column="column"
                          let-filter="filter"
                          let-filterService="filterService"
                        >
                          <app-multicheck-filter
                            [isPrimitive]="true"
                            [field]="column.field"
                            [filterService]="filterService"
                            [currentFilter]="filter"
                            [data]="distinctPrimitive(column.field)"
                          ></app-multicheck-filter>
                        </ng-template>
                      }
                    </kendo-grid-columnmenu-filter>
                  }
                </ng-template>
              }
              @if (column.DataField === 'User') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.User }}
                  @if (solution !== 'ALL') {
                    <button
                      mat-icon-button
                      (click)="viewHistory($event, dataItem)"
                      matTooltip="View Case History"
                      class="pull-right"
                      color="warn"
                    >
                      <mat-icon>history</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="viewCase($event, dataItem)"
                      matTooltip="Open Case"
                      class="pull-right"
                      color="primary"
                    >
                      <mat-icon>work</mat-icon>
                    </button>
                  }
                </ng-template>
              }
              @if (column.DataField === 'ClientName') {
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-columnIndex="columnIndex"
                >
                  {{ dataItem.ClientName }}
                  @if (columnIndex === 0 && solution !== 'ALL') {
                    <button
                      mat-icon-button
                      (click)="viewHistory($event, dataItem)"
                      matTooltip="View Case History"
                      class="pull-right"
                      color="warn"
                    >
                      <mat-icon>history</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="viewCase($event, dataItem)"
                      matTooltip="Open Case"
                      class="pull-right"
                      color="primary"
                    >
                      <mat-icon>work</mat-icon>
                    </button>
                  }
                </ng-template>
              }
              @if (column.DataField === 'ScoreClass') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  @if (
                    dataItem.ScoreClass &&
                    dataItem.ScoreClass.toLowerCase() === 'positive'
                  ) {
                    <mat-icon class="positive">check_circle</mat-icon>
                  }
                  @if (
                    dataItem.ScoreClass &&
                    dataItem.ScoreClass.toLowerCase() === 'neutral'
                  ) {
                    <mat-icon class="neutral">error</mat-icon>
                  }
                  @if (
                    dataItem.ScoreClass &&
                    dataItem.ScoreClass.toLowerCase() === 'negative'
                  ) {
                    <mat-icon class="negative">remove_circle</mat-icon>
                  }
                </ng-template>
              }
              @if (column.Type === 'curr') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem[column.DataField] | currency }}
                </ng-template>
              }
              @if (column.DataField === 'DateTime') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem['DateTime'] | localTime: 'shortDateTime' }}
                </ng-template>
              }
              @if (column.Validation) {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ findValue(dataItem[column.DataField], column.Validation) }}
                </ng-template>
              }
            </kendo-grid-column>
          </ng-template>
        }
        @if (gridData['subHead']) {
          <ng-template kendoGridDetailTemplate let-dataItem>
            <section>
              <table class="w-95 pull-right detail-table">
                <thead>
                  @for (header of gridData['subHead']; track header.Label) {
                    <th>
                      {{ header.Label }}
                    </th>
                  }
                </thead>
                <tbody>
                  @for (
                    product of dataItem.products;
                    track product.ProductName
                  ) {
                    <tr>
                      @for (
                        header of gridData['subHead'];
                        track header.DataField
                      ) {
                        <td>
                          @if (header.DataField === 'ScoreClass') {
                            @if (
                              product.ScoreClass &&
                              product.ScoreClass.toLowerCase() === 'positive'
                            ) {
                              <mat-icon class="positive">check_circle</mat-icon>
                            }
                            @if (
                              product.ScoreClass &&
                              product.ScoreClass.toLowerCase() === 'neutral'
                            ) {
                              <mat-icon class="neutral">error</mat-icon>
                            }
                            @if (
                              product.ScoreClass &&
                              product.ScoreClass.toLowerCase() === 'negative'
                            ) {
                              <mat-icon class="negative"
                                >remove_circle</mat-icon
                              >
                            }
                          } @else if (header.Type === 'curr') {
                            {{ product[header.DataField] | currency }}
                          } @else {
                            {{ product[header.DataField] }}
                          }
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            </section>
          </ng-template>
        }
        <ng-template
          kendoPagerTemplate
          let-totalPages="totalPages"
          let-currentPage="currentPage"
        >
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-numeric-buttons
            [buttonCount]="buttonCount"
          ></kendo-pager-numeric-buttons>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-pager-input></kendo-pager-input>
          <kendo-pager-info></kendo-pager-info>
          <kendo-pager-page-sizes
            [pageSizes]="pageSizeOptions"
          ></kendo-pager-page-sizes>
        </ng-template>
        <kendo-grid-excel></kendo-grid-excel>
      </kendo-grid>
    }
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
