@if (loadHistoryData) {
  <kendo-grid
    class="col-12 mt-2 load-history-grid"
    [kendoGridBinding]="loadHistoryData.data"
    [height]="600"
  >
    @for (column of loadHistoryData.headers; track column) {
      <kendo-grid-column [field]="column.DataField" [title]="column.Label">
      </kendo-grid-column>
    }
  </kendo-grid>
}
