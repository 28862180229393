<div class="page-container fia-container container">
  <div class="row w-100 controls">
    <form [formGroup]="form" class="col-4">
      <mat-form-field class="col-12 default-select">
        <mat-select
          [placeholder]="'Preset Table Filtering and Sorting'"
          formControlName="defaultsControl"
          (selectionChange)="useDefault($event)"
        >
          @for (opt of defaults; track opt.label) {
            <mat-option [value]="opt.label">{{ opt.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </form>
    <app-grid-column-filter
      [columns]="gridColumns"
      (columnsUpdated)="externalColumnsUpdated($event)"
    ></app-grid-column-filter>

    <button
      mat-button
      color="primary"
      class="reset-button pull-right"
      (click)="useDefault({ value: 'clear' }, true)"
    >
      <mat-icon>replay</mat-icon>Reset
    </button>
    <button
      mat-button
      color="primary"
      class="grid-button"
      (click)="exportToExcel(viaGrid)"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
  </div>
  <kendo-grid
    class="col-12"
    #viaGrid="kendoGrid"
    [kendoGridBinding]="ogData"
    [skip]="state.skip"
    [pageSize]="state.take"
    [pageable]="true"
    [resizable]="true"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [sort]="state.sort"
    [filterable]="'menu'"
    [columnMenu]="{ columnChooser: false }"
    [filter]="filter"
    filterable="menu"
    (filterChange)="filterChange($event)"
    [groupable]="true"
    [group]="state.group"
    (dataStateChange)="dataStateChange($event)"
    [height]="gridHeight()"
  >
    @for (column of gridColumns; track column) {
      <kendo-grid-column
        [field]="column.DataField"
        [hidden]="isHidden(column.DataField)"
        [title]="column.Label"
        [sortable]="column.Sort"
        [filterable]="column.Filter"
        [groupable]="column.Group"
        [media]="column.RespVis"
        [filter]="column.DataType"
        [width]="column.Width ? column.Width : 150"
        [sticky]="column.Sticky"
      >
        @if (column.Sort || column.Filter) {
          <ng-template kendoGridColumnMenuTemplate let-service="service">
            @if (column.Sort) {
              <kendo-grid-columnmenu-sort [column]="column" [service]="service">
                <kendo-grid-messages
                  [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                  [sortDescending]="getFilterMenuText(column.Type, 'desc')"
                ></kendo-grid-messages>
              </kendo-grid-columnmenu-sort>
            }
            @if (column.Filter) {
              <kendo-grid-columnmenu-filter
                [column]="column"
                [service]="service"
              >
                <ng-template
                  kendoGridFilterMenuTemplate
                  let-filter="filter"
                  let-filterService="filterService"
                >
                  <kendo-multiselect
                    style="width: 99%"
                    [data]="filterData[column.DataField]"
                    textField="display"
                    valueField="value"
                    [valuePrimitive]="true"
                    [value]="filter | filterValues"
                    (valueChange)="
                      filterValueChange($event, column.DataField, filterService)
                    "
                    [filterable]="true"
                    (filterChange)="onFilterChange($event, column.DataField)"
                    [fillMode]="'outline'"
                    placeholder="Filter By..."
                  >
                  </kendo-multiselect>
                </ng-template>
              </kendo-grid-columnmenu-filter>
            }
          </ng-template>
        }
        @switch (column.Type) {
          @case ('integer') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | number: '1.0-0' }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
              >{{ value | number: '1.0-0' }}</ng-template
            >
          }
          @case ('perc') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{
                dataItem[column.DataField] === 'N/A' ||
                dataItem[column.DataField] === 'Uncapped'
                  ? dataItem[column.DataField]
                  : (dataItem[column.DataField] | percent)
              }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent }}
            </ng-template>
          }
          @case ('perc2') {
            <ng-template kendoGridCellTemplate let-dataItem>
              @if (
                dataItem[column.DataField] === -9.99 ||
                dataItem[column.DataField] === -999
              ) {
                N/A
              } @else {
                @if (
                  dataItem[column.DataField] === 'N/A' ||
                  dataItem[column.DataField] === 'Uncapped'
                ) {
                  {{ dataItem[column.DataField] }}
                } @else {
                  {{ dataItem[column.DataField] | percent: '1.2' }}
                }
              }
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | percent: '1.2' }}
            </ng-template>
          }
          @case ('curr') {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem[column.DataField] | currency }}
            </ng-template>
            <ng-template
              kendoGridGroupHeaderTemplate
              let-group
              let-field="field"
              let-value="value"
            >
              {{ value | currency }}
            </ng-template>
          }
        }

        @if (column.DataField === 'VARate$RateLockPeriod') {
          <ng-template kendoGridCellTemplate let-dataItem>
            @switch (dataItem[column.DataField]) {
              @case ('F') {
                Full Term
              }
              @case ('A') {
                Annual
              }
              @case ('S') {
                Semi-annual
              }
              @case ('Q') {
                Quarterly
              }
              @case ('M') {
                Monthly
              }
              @case ('D') {
                Daily
              }
            }
          </ng-template>
        }
      </kendo-grid-column>
    }
    <kendo-grid-excel fileName="RightBridge-RILA.xlsx" [fetchData]="excelData">
    </kendo-grid-excel>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="buttonCount"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes
        [pageSizes]="pageSizeOptions"
      ></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
