import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Application, Unit } from '../../models/admin-models';
import { AdminService } from '../admin.service';
import { saveAs } from '@progress/kendo-file-saver';
import { UnitProfileUploadComponent } from '../unit-profile-upload/unit-profile-upload.component';
import { UnitHistoryDownloadModalComponent } from '../../reporting/reports/unit-profile-history/unit-history-download-modal/unit-history-download-modal.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Output() unitAppSelected = new EventEmitter();
  @Output() unitAppReport = new EventEmitter();
  @Input() hideReportType = false;
  @Input() profileFormat = false;
  @Input() ignoreUnitValidation = false;

  unitsControl = new FormControl();
  units: Unit[] = [] as Unit[];
  reportTypes: string[];
  applications: Application[] = [
    { value: 'aw', ui: 'aw', name: 'Annuity Wizard' },
    { value: 'iw', ui: 'iw', name: 'Investment Wizard' },
    { value: 'li', ui: 'li', name: 'Life Insurance Wizard' },
    { value: 'pp', ui: 'pp', name: 'Product Profiler' },
    { value: 'rb', ui: 'rb', name: 'Control KB (RB)' },
    { value: 'cm', ui: 'cm', name: 'Case Management (CM)' },
    { value: 'pm', ui: 'pm', name: 'Book Management (PM)' },
    { value: 'shared', ui: 'rb-unit-shared', name: 'Shared' },
  ];
  selectedApp;
  selectedUnit;
  selectedType;
  filteredUnits;
  uiType;

  constructor(
    private admnSrvc: AdminService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getUnits();
    this.reportTypes = [];
  }

  getUnits() {
    this.unitsControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(value => {
        if (value) {
          this.admnSrvc.getUnitList(value, true).subscribe(resp => {
            if (resp['names']) {
              this.filteredUnits = resp['names'];
            }
          });
        }
      });
  }

  checkSelection() {
    if (!this.ignoreUnitValidation) {
      if (
        !this.filteredUnits ||
        !this.filteredUnits.find(unit => {
          return (
            unit.id.toLowerCase() === this.unitsControl.value.toLowerCase()
          );
        })
      ) {
        this.unitsControl.setValue(null);
      }
    }
  }

  autoCompleteUnitChanged(ev) {
    this.unitsControl.setValue(ev.option.value);
  }

  onAppChange(ev) {
    const app = this.selectedApp == 'shared' ? 'rb' : this.selectedApp;
    this.admnSrvc.getReportTypes(app).subscribe(data => {
      this.reportTypes = data['reportTypes'];
      this.selectedType = this.reportTypes[0];
    });
  }

  viewUnitProfile() {
    const uiConfig =
      this.selectedApp == 'shared'
        ? this.applications.find(x => x.value === this.selectedApp).ui
        : null;
    const app = this.selectedApp == 'shared' ? 'rb' : this.selectedApp;
    this.unitAppSelected.emit({
      app: app,
      unit: this.selectedUnit,
      uiType: uiConfig,
    });
  }

  viewUnitProfileReport() {
    const app = this.selectedApp == 'shared' ? 'rb' : this.selectedApp;
    this.unitAppReport.emit({
      app: app,
      unit: this.selectedUnit,
      type: this.selectedType,
    });
  }

  uploadUnitProfile() {
    let dialogRef = this.dialog.open(UnitProfileUploadComponent);
    dialogRef.afterClosed().subscribe(() => {
      dialogRef = null;
    });
  }

  downloadUnitProfile() {
    const app = this.selectedApp == 'shared' ? 'rb' : this.selectedApp;
    this.admnSrvc
      .downloadUnitProfile(app, this.selectedUnit)
      .subscribe(data => {
        const date = new Date();

        let fileData;
        const filename = `${app}-${
          this.selectedUnit
        }-unitProfile-${date.getMonth()}${date.getDay()}${date.getFullYear()}.xml`;
        const reader = new FileReader();
        reader.readAsDataURL(data);

        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename);
        };
      });
  }

  downloadUnitProfileHistoryReport() {
    const app = this.selectedApp == 'shared' ? 'rb' : this.selectedApp;
    let dialogRef = this.dialog.open(UnitHistoryDownloadModalComponent, {
      data: {
        unit: this.selectedUnit,
        app: app,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      dialogRef = null;
    });
  }
}
