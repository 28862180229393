import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { SessionStorageService } from '../services/session-storage.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

interface BreadCrumb {
  label: string;
  link: string;
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  imports: [MatIconModule, RouterLink, MatButtonModule],
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() crumb: string;
  @Input() crumbConfig: Array<BreadCrumb>;

  crumbs: any[];
  id: string = this.activatedRoute.snapshot.paramMap?.get('id');
  currApp: string;
  appName = 'Home';
  hideCrumbs = false;
  toolsLanding = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private ss: SessionStorageService
  ) {}

  ngOnChanges(changes: any) {
    this.updateCrumb();
  }

  ngOnInit() {
    this.currApp = this.ss.get('currentApp');
    this.hideCrumbs = this.ss.get('rights')
      ? this.ss.get('rights').includes('HideMenu')
      : null;
    this.toolsLanding =
      this.ss.get('rights') &&
      this.ss.get('rights').includes('ToolsLandingPage');

    switch (this.currApp) {
      case 'aw':
        this.appName = 'Annuity Wizard';
        break;
      case 'cp':
        this.appName = 'Client Profiler';
        break;
      case 'iw':
        this.appName = 'Investment Wizard';
        break;
      case 'li':
        this.appName = 'Life Insurance Wizard';
        break;
      case 'pp':
        this.appName = 'Product Profiler';
        break;
      case 'rw':
        this.appName = 'Rollover Wizard';
        break;
      case 'se':
        this.appName = 'Suggestion Engine';
        break;

      default:
        break;
    }

    this.updateCrumb();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  updateCrumb() {
    switch (this.crumb) {
      case 'search':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
        ];
        break;
      case 'questionnaire':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
        ];
        break;
      case 'no-save-questionnaire':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
        ];
        break;
      case 'model':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/program-selection/' + this.id,
            label: 'Fund Program',
          },
          {
            link: '/' + this.currApp + '/investment-model/' + this.id,
            label: 'Investment Model',
          },
        ];
        break;
      case 'program':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/program-selection/' + this.id,
            label: 'Fund Program',
          },
        ];
        break;
      case 'program-validate':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/program-selection/' + this.id,
            label: 'Fund Program',
          },
        ];
        break;
      case 'funds':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/program-selection/' + this.id,
            label: 'Fund Program',
          },
          {
            link: '/' + this.currApp + '/investment-model/' + this.id,
            label: 'Investment Model',
          },
          {
            link: '/' + this.currApp + '/fund-allocations/' + this.id,
            label: 'Fund Selection',
          },
        ];
        break;
      case 'iw-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/program-selection/' + this.id,
            label: 'Fund Program',
          },
          {
            link: '/' + this.currApp + '/investment-model/' + this.id,
            label: 'Investment Model',
          },
          {
            link: '/' + this.currApp + '/fund-allocations/' + this.id,
            label: 'Fund Selection',
          },
          {
            link: '/' + this.currApp + '/validate/' + this.id,
            label: 'Validate Portfolio',
          },
        ];
        break;
      case 'advisory':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/program-selection/' + this.id,
            label: 'Fund Program',
          },
          {
            link: '/' + this.currApp + '/advisory/' + this.id,
            label: 'Validate Portfolio',
          },
        ];
        break;
      case 'iw-validate-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/validate/' + this.id,
            label: 'Validate Portfolio',
          },
        ];
        break;
      case 'funds-validate':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/program-selection/' + this.id,
            label: 'Fund Program',
          },
          {
            link: '/' + this.currApp + '/fund-allocations/' + this.id,
            label: 'Fund Selection',
          },
        ];
        break;
      case 'product-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Product Results',
          },
        ];
        break;
      case 'annuity-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Annuity Results',
          },
        ];
        break;
      case 'no-save-annuity-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Annuity Results',
          },
        ];
        break;
      case 'need-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
        ];
        break;
      case 'need-results-detail':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
          {
            link:
              '/' + this.currApp + '/results/' + this.id + '/result-details',
            label: 'Need Detail',
          },
        ];
        break;
      case 'cp-products-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
          {
            link: '/' + this.currApp + '/products/' + this.id,
            label: 'Product Categories',
          },
        ];
        break;
      case 'validate-products':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/validate/' + this.id,
            label: 'Validate Products',
          },
        ];
        break;
      case 'validate-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/validate/' + this.id,
            label: 'Validate Products',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
        ];
        break;
      case 'validate-compare':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/validate/' + this.id,
            label: 'Validate Products',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
          {
            link: '/' + this.currApp + '/compare/' + this.id,
            label: 'Compare Products',
          },
        ];
        break;
      case 'product-compare':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
          {
            link: '/' + this.currApp + '/compare/' + this.id,
            label: 'Compare Products',
          },
        ];
        break;
      case 'se-products-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: 'Dashboard' },
          { link: '/' + this.currApp + '/search', label: 'Opportunities' },
          {
            link: '/' + this.currApp + '/client/' + this.id,
            label: 'Client Profile',
          },
        ];
        break;
      case 'se-opportunities':
        this.crumbs = [
          { link: '/' + this.currApp, label: 'Dashboard' },
          { link: '/' + this.currApp + '/search', label: 'Opportunities' },
        ];
        break;
      case 'se-details':
        this.crumbs = [
          { link: '/' + this.currApp, label: 'Dashboard' },
          { link: '/' + this.currApp + '/search', label: 'Opportunities' },
          {
            link: '/' + this.currApp + '/client/' + this.id,
            label: 'Client Profile',
          },
          {
            link: '/' + this.currApp + '/client/' + this.id,
            label: 'More Details',
          },
        ];
        break;
      case 'scenarios':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
          {
            link: '/' + this.currApp + '/compare/' + this.id,
            label: 'Compare Strategies',
          },
        ];
        break;
      case 'scenariosNoResults':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/compare/' + this.id,
            label: 'Compare Strategies',
          },
        ];
        break;
      case 'li-products-results':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Needs Results',
          },
          {
            link: '/' + this.currApp + '/compare/' + this.id,
            label: 'Compare Strategies',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Product Results',
          },
        ];
        break;
      case 'li-products-results-no-needs':
        this.crumbs = [
          { link: '/' + this.currApp, label: this.appName },
          { link: '/' + this.currApp + '/search', label: 'Search' },
          {
            link: '/' + this.currApp + '/questionnaire/' + this.id,
            label: 'Questionnaire',
          },
          {
            link: '/' + this.currApp + '/compare/' + this.id,
            label: 'Compare Strategies',
          },
          {
            link: '/' + this.currApp + '/results/' + this.id,
            label: 'Product Results',
          },
        ];
        break;
      case 'profile-history':
        this.crumbs = [
          {
            link: '/dashboard',
            label: '<mat-icon>arrow_back</mat-icon> Dashboard',
          },
        ];
        break;
    }
  }
}
