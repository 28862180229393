<div class="page-container asset-container container unified">
  @if (!loading) {
    <div class="products-container">
      @for (grp of bridgeTypes; track grp) {
        @switch (grp) {
          @case ('portfolio') {
            <h2>Portfolio</h2>
          }
          @case ('program') {
            <h2>Selected Program</h2>
          }
          @case ('altProgram') {
            <h2
              class="w-100"
              [ngClass]="{
                'group-collapsed': !altsExpanded && grp === 'altProgram'
              }"
            >
              Alternative Programs
              <button
                mat-button
                color="primary"
                class="pull-right expand-button"
                (click)="toggleAlternatives('programs')"
              >
                @if (!altsExpanded) {
                  <span>
                    <mat-icon>add</mat-icon>
                    View Alternatives
                  </span>
                } @else {
                  <span>
                    <mat-icon>remove</mat-icon>
                    Hide Alternatives
                  </span>
                }
              </button>
            </h2>
          }
          @case ('ALT') {
            <h2>
              {{
                bridgeTypes.includes('ALT') && selectedAltsHeader
                  ? selectedAltsHeader
                  : 'Selected Alternative Investments '
              }}
            </h2>
          }
          @case ('ALTopt') {
            <h2
              class="w-100"
              [ngClass]="{
                'group-collapsed': !altsOptionsExpanded && grp === 'ALTopt'
              }"
            >
              {{
                bridgeTypes.includes('ALT') && otherAltsHeader
                  ? otherAltsHeader
                  : 'Other Alternative Investments'
              }}
              <button
                mat-button
                color="primary"
                class="pull-right expand-button"
                (click)="toggleAlternatives('alts')"
              >
                @if (!altsOptionsExpanded) {
                  <span>
                    <mat-icon>add</mat-icon>
                    View Alternatives
                  </span>
                } @else {
                  <span>
                    <mat-icon>remove</mat-icon>
                    Hide Alternatives
                  </span>
                }
              </button>
            </h2>
          }
          @case (grp === 'Plans') {
            <h2>Selected Plans</h2>
          }
          @case ('altPlan') {
            <h2
              class="w-100"
              [ngClass]="{
                'group-collapsed': !altsPlansExpanded && grp === 'altPlan'
              }"
            >
              Alternative Plans
              <button
                mat-button
                color="primary"
                class="pull-right expand-button"
                (click)="toggleAlternatives('plans')"
              >
                @if (!altsPlansExpanded) {
                  <span>
                    <mat-icon>add</mat-icon>
                    View Alternatives
                  </span>
                } @else {
                  <span>
                    <mat-icon>remove</mat-icon>
                    Hide Alternatives
                  </span>
                }
              </button>
            </h2>
          }
          @case ('funds') {
            <h2>Selected Investments</h2>
          }
        }
        <ul
          class="products col-12 mb-5"
          [ngClass]="{
            'group-collapsed':
              (!altsExpanded && grp === 'altProgram') ||
              (!altsPlansExpanded && grp === 'altPlan') ||
              (!altsOptionsExpanded && grp === 'ALTopt')
          }"
        >
          @for (x of bridges; track x) {
            @if (
              (x.BridgeType === grp && x.BridgeType !== 'ALT') ||
              (x.BridgeType === grp &&
                x.BridgeType === 'ALT' &&
                x.SelectedBridge.toLowerCase() === 'yes') ||
              (grp === 'ALTopt' &&
                x.BridgeType === 'ALT' &&
                x.SelectedBridge.toLowerCase() === 'no')
            ) {
              <li
                class="product col-12 {{ x.ScoreClass }} {{ x.expandedClass }}"
                [ngClass]="{
                  expanded: expandedBridges.includes(x.BridgeNoteType)
                }"
              >
                @if (
                  rights.includes('BridgeNotes') &&
                  !moduleNotes.include &&
                  x.vars.CurrentBridgeNote &&
                  (x.vars.CurrentBridgeNote.Required === 2 ||
                    (x.vars.CurrentBridgeNote.Required === 3 &&
                      x.negativeReason))
                ) {
                  <div
                    class="notes-notification"
                    [ngClass]="{ valid: x.notesValid }"
                  >
                    @if (x.notesValid) {
                      <mat-icon>thumb_up</mat-icon>Required Notes Entered for
                      This Item
                    } @else {
                      <mat-icon>error</mat-icon>Notes Required for This Item
                    }
                  </div>
                }

                <div class="w100 unified-content">
                  @if (x.PlanName) {
                    <div class="type-indicator {{ x.indicatorClass }}">
                      {{ x.PlanName }}
                    </div>
                  }
                  <div class="score-icon {{ x.ScoreClass }}">
                    <ng-container *hasPermission="'RelevanceBridgeIcon'">
                      @switch (x.ScoreClass) {
                        @case ('positive') {
                          <mat-icon>check_circle</mat-icon>
                        }
                        @case ('neutral') {
                          <mat-icon>error</mat-icon>
                        }
                        @case ('negative') {
                          <mat-icon>remove_circle</mat-icon>
                        }
                      }
                    </ng-container>
                  </div>
                  <div class="actions"></div>
                  <div class="content">
                    <h4>
                      {{ x.outline.sections.ShortName }}
                      <span *hasPermission="'!RelevanceHideBridgeScore'">
                        | {{ x.score / 10 }}</span
                      >
                    </h4>
                    <div class="sub">{{ x.subhead }}</div>
                  </div>

                  <div class="details-button-container">
                    <button mat-icon-button (click)="expand($event)">
                      <span class="collapsed-content">
                        <mat-icon>expand_circle_down</mat-icon>
                      </span>
                      <span class="expanded-content">
                        <mat-icon>expand_circle_down</mat-icon>
                      </span>
                    </button>
                  </div>
                </div>

                @if (x.PlanGrid) {
                  <div class="plan-grid-table col-11">
                    <table class="plan-grid">
                      <thead>
                        <tr>
                          @for (h of x.PlanGrid.Heade; track h) {
                            <th>{{ h }}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        @for (t of x.PlanGrid.Data; track t) {
                          <tr>
                            @for (z of t; track z) {
                              <td>{{ z }}</td>
                            }
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                }

                @if (x.SubItemChart) {
                  <app-sub-item-chart
                    [data]="x.SubItemChart"
                    [chartTitle]="'Brokerage Subtypes'"
                    [chartStyle]="'minimal'"
                  ></app-sub-item-chart>
                }
                @if (x.BridgeDetailChart) {
                  <app-sub-item-chart
                    [data]="x.BridgeDetailChart"
                    [chartTitle]="x.BridgeDetailChart.Label"
                    [chartStyle]="'minimal'"
                  ></app-sub-item-chart>
                }

                <div
                  class="details col-12 mx-auto custom-tabs"
                  [ngClass]="{ 'unified-selected': x.SelectedBridge === 'Yes' }"
                >
                  <mat-tab-group
                    mat-stretch-tabs
                    [ngClass]="{
                      'left-align':
                        (!x.grids || x.grids.length <= 0) &&
                        (!x.bridgeCharts ||
                          (x.bridgeCharts && !x.bridgeCharts.Bridges))
                    }"
                  >
                    <mat-tab [label]="grp === 'funds' ? 'Analysis' : 'Reasons'">
                      @if (
                        x.ComplianceChart && x.ComplianceChart.data.length > 0
                      ) {
                        <app-sub-item-chart
                          [data]="x.ComplianceChart"
                          [chartTitle]="'Compliance Information'"
                        >
                        </app-sub-item-chart>
                      }
                      @if (x.trueRules) {
                        <app-reason-text
                          class="col-6"
                          [data]="x.trueRules"
                        ></app-reason-text>
                      }
                      @if (
                        x.alternatives && x.alternatives.headers.length > 2
                      ) {
                        <div class="mb-4">
                          <h5>Alternatives</h5>
                          <kendo-grid
                            [kendoGridBinding]="x.alternatives.rows"
                            [pageable]="true"
                            [pageSize]="altGridPageSize"
                            class="iw-compare"
                          >
                            <kendo-grid-column
                              [field]="x.alternatives.headers[0].field"
                              [title]="x.alternatives.headers[0].field.title"
                            ></kendo-grid-column>
                            <kendo-grid-column-group title="Selected">
                              <kendo-grid-column
                                [field]="x.alternatives.headers[1].field"
                                [title]="x.alternatives.headers[1].title"
                                [class]="'selected'"
                                [headerClass]="'selected'"
                              >
                              </kendo-grid-column>
                            </kendo-grid-column-group>
                            @if (x.alternatives.headers.length > 2) {
                              <kendo-grid-column-group title="Alternatives">
                                @for (
                                  column of x.alternatives.headers | slice: 2;
                                  track column
                                ) {
                                  <ng-template>
                                    <kendo-grid-column
                                      [field]="column.field"
                                      [title]="column.title"
                                    >
                                      <ng-template
                                        kendoGridHeaderTemplate
                                        let-column
                                        let-columnIndex="columnIndex"
                                      >
                                        {{
                                          x.alternatives.headers[columnIndex]
                                            .title
                                        }}
                                        <div>
                                          <mat-icon
                                            class="swap-icon"
                                            (click)="
                                              alternativeSwap(
                                                x,
                                                x.alternatives.headers[
                                                  columnIndex
                                                ].fundID
                                              )
                                            "
                                            matTooltip="Switch to this investment"
                                          >
                                            swap_horizontal_circle
                                          </mat-icon>
                                        </div>
                                      </ng-template>
                                    </kendo-grid-column>
                                  </ng-template>
                                }
                              </kendo-grid-column-group>
                            }
                            <ng-template kendoPagerTemplate>
                              <button
                                mat-button
                                color="primary"
                                [showButtons]="false"
                                class="expand-btn w-100"
                                (click)="
                                  toggleGrid($event, x.alternatives.rows.length)
                                "
                              >
                                @if (altGridExpand) {
                                  See less
                                } @else {
                                  See more
                                }
                              </button>
                            </ng-template>
                          </kendo-grid>
                        </div>
                      }
                      @if (
                        rights.includes('BridgeNotes') && !moduleNotes.include
                      ) {
                        <app-notes-entry
                          class="w-100 mt-2"
                          [bridgeVal]="x.trueRules"
                          (noteEntered)="handleNotesValidation($event, x)"
                          [notesData]="{
                            app: 'iw',
                            profile: id,
                            bridgeId: x.vars.CurrentBridgeNote.NoteBridgeID,
                            note: x.vars.CurrentBridgeNote.NoteText,
                            prompt: x.vars.CurrentBridgeNote.Prompt,
                            required:
                              x.vars.CurrentBridgeNote.Required === 2 ||
                              (x.vars.CurrentBridgeNote.Required === 3 &&
                                x.negativeReason)
                          }"
                        ></app-notes-entry>
                      }
                    </mat-tab>
                    @if (x.programDescription) {
                      <mat-tab label="Description">
                        <strong>{{ x.programDescription.Description }}</strong>
                        <ul>
                          @for (
                            bul of x.programDescription.DescriptionBullets;
                            track bul
                          ) {
                            <li>{{ bul }}</li>
                          }
                        </ul>
                        <span
                          class="program-instructions"
                          [innerHTML]="x.programDescription.Instructions"
                        ></span>
                      </mat-tab>
                    }
                    @if (grp === 'funds') {
                      <mat-tab label="Details">
                        @for (gr of x.fundDetails; track gr) {
                          <div class="grid-table-row w-100 row my-5">
                            @for (tb of gr.tables; track tb) {
                              <table
                                [ngClass]="{
                                  'col-12': gr.tables.length === 1,
                                  'col-6': gr.tables.length === 2,
                                  'col-4': gr.tables.length === 3,
                                  'col-3': gr.tables.length === 4
                                }"
                              >
                                <thead>
                                  @for (hd of tb.headers; track hd) {
                                    @if (hd !== 'Remove Me') {
                                      <th>{{ hd }}</th>
                                    }
                                  }
                                </thead>
                                <tbody>
                                  @for (rw of tb.data; track rw) {
                                    <tr>
                                      @for (cell of rw.row; track cell) {
                                        <td [innerHTML]="cell"></td>
                                      }
                                    </tr>
                                  }
                                </tbody>
                              </table>
                            }
                          </div>
                        }
                        <div class="row chart-row">
                          @for (chrt of x.charts; track chrt) {
                            @if (chrt) {
                              <div
                                class="chart-container clearfix mb-5"
                                [ngClass]="{
                                  'col-6': chrt.ChartContainer === 'half',
                                  'col-12': chrt.ChartContainer === 'full'
                                }"
                              >
                                <div
                                  class="chart pull-left px-4"
                                  [ngClass]="{
                                    'col-12': chrt.LegendPosition === 'bottom',
                                    'col-6': chrt.LegendPosition === 'right'
                                  }"
                                >
                                  <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="chrt.Data"
                                    class="mx-auto"
                                  ></highcharts-chart>
                                </div>
                                @if (chrt.Legend) {
                                  <div
                                    class="chart-legend"
                                    [ngClass]="{
                                      'col-12':
                                        chrt.LegendPosition === 'bottom',
                                      'col-6 ml-auto':
                                        chrt.LegendPosition === 'right'
                                    }"
                                  >
                                    @if (chrt.Legend.header) {
                                      <table>
                                        <thead>
                                          <tr>
                                            @for (
                                              th of chrt.Legend.header;
                                              track th
                                            ) {
                                              @if (
                                                th.toLowerCase() !== 'color'
                                              ) {
                                                <th>
                                                  {{ th }}
                                                </th>
                                              }
                                            }
                                          </tr>
                                        </thead>
                                        <tbody>
                                          @for (
                                            tr of chrt.Legend.data;
                                            track tr
                                          ) {
                                            <tr>
                                              @for (
                                                td of tr | keyvalue: returnZero;
                                                track td;
                                                let i = $index
                                              ) {
                                                @if (
                                                  chrt.Legend.header[
                                                    i
                                                  ].toLowerCase() !== 'color'
                                                ) {
                                                  <td>
                                                    @if (
                                                      chrt.Legend.header[
                                                        i
                                                      ].toLowerCase() !==
                                                        'color' &&
                                                      chrt.Legend.header[0].toLowerCase() ===
                                                        'color' &&
                                                      i === 1
                                                    ) {
                                                      <span
                                                        [style.background]="
                                                          tr['Color']
                                                        "
                                                        class="indicator"
                                                      ></span>
                                                    }
                                                    {{ td.value }}
                                                  </td>
                                                }
                                              }
                                            </tr>
                                          }
                                        </tbody>
                                      </table>
                                    } @else {
                                      @for (c of chrt.Legend; track c) {
                                        <div>
                                          <strong>{{ c.label }}</strong>
                                          {{ c.value }}
                                        </div>
                                      }
                                    }
                                  </div>
                                }
                              </div>
                            }
                          }
                        </div>
                      </mat-tab>
                    }
                    @if (
                      x.charts && !isEmpty(x.charts[0]?.Data) && grp !== 'funds'
                    ) {
                      <mat-tab label="Charts">
                        <div class="row chart-row">
                          @for (chrt of x.charts; track chrt) {
                            @if (chrt) {
                              <div
                                class="chart-container clearfix mb-5"
                                [ngClass]="{
                                  'col-6': chrt.ChartContainer === 'half',
                                  'col-12': chrt.ChartContainer === 'full'
                                }"
                              >
                                @if (isTableChart(chrt)) {
                                  <div
                                    class="chart px-4"
                                    [ngClass]="{
                                      'col-10 mx-auto':
                                        chrt.LegendPosition === 'bottom',
                                      'col-7 pull-left':
                                        chrt.LegendPosition === 'right'
                                    }"
                                  >
                                    <highcharts-chart
                                      [Highcharts]="Highcharts"
                                      [options]="chrt.Data"
                                      class="mx-auto"
                                    ></highcharts-chart>
                                  </div>
                                }
                                @if (chrt.Legend) {
                                  <div
                                    class="chart-legend"
                                    [ngClass]="{
                                      'col-12':
                                        chrt.LegendPosition === 'bottom',
                                      'col-5 ml-auto':
                                        chrt.LegendPosition === 'right'
                                    }"
                                  >
                                    @if (chrt.Legend.header) {
                                      <table>
                                        <thead>
                                          <tr>
                                            @for (
                                              th of chrt.Legend.header;
                                              track th
                                            ) {
                                              @if (
                                                th.toLowerCase() !== 'color'
                                              ) {
                                                <th>
                                                  {{ th }}
                                                </th>
                                              }
                                            }
                                          </tr>
                                        </thead>
                                        <tbody>
                                          @for (
                                            tr of chrt.Legend.data;
                                            track tr
                                          ) {
                                            <tr>
                                              @for (
                                                td of tr | keyvalue: returnZero;
                                                track td;
                                                let i = $index
                                              ) {
                                                @if (
                                                  chrt.Legend.header[
                                                    i
                                                  ].toLowerCase() !== 'color'
                                                ) {
                                                  <td>
                                                    @if (
                                                      chrt.Legend.header[
                                                        i
                                                      ].toLowerCase() !==
                                                        'color' &&
                                                      chrt.Legend.header[0].toLowerCase() ===
                                                        'color' &&
                                                      i === 1
                                                    ) {
                                                      <span
                                                        [style.background]="
                                                          tr['Color']
                                                        "
                                                        class="indicator"
                                                      ></span>
                                                    }
                                                    {{ td.value }}
                                                  </td>
                                                }
                                              }
                                            </tr>
                                          }
                                        </tbody>
                                      </table>
                                    } @else {
                                      @for (c of chrt.Legen; track c) {
                                        <div>
                                          <strong>{{ c.label }}</strong>
                                          {{ c.value }}
                                        </div>
                                      }
                                    }
                                  </div>
                                }
                              </div>
                            }
                          }
                        </div>
                      </mat-tab>
                    }
                    @if (x.BridgeNoteType === 'ExchangeSwap') {
                      <mat-tab label="Switch Comparison">
                        <div class="row chart-row">
                          @if (switchCompareHeaders.length > 0) {
                            <div class="col-12 chart-container clearfix mb-5">
                              <div class="exchange-table col-12">
                                <table>
                                  <thead class="source-destination">
                                    <tr>
                                      <th></th>
                                      @for (
                                        th of x.vars.SwitchGridOut.IsSource;
                                        track th
                                      ) {
                                        <th
                                          [ngClass]="{
                                            'is-source': th,
                                            'is-destination': !th
                                          }"
                                        ></th>
                                      }
                                    </tr>
                                  </thead>
                                  <thead>
                                    <tr>
                                      <th></th>
                                      @for (
                                        th of x.vars.SwitchGridOut.DisplayName;
                                        track th;
                                        let idx = $index
                                      ) {
                                        <th
                                          [ngClass]="{
                                            'is-source':
                                              x.vars.SwitchGridOut.IsSource[idx]
                                          }"
                                        >
                                          {{ th }}
                                        </th>
                                      }
                                    </tr>
                                  </thead>
                                  <tbody>
                                    @for (
                                      tr of switchCompareHeaders;
                                      track tr
                                    ) {
                                      <tr>
                                        <td>{{ tr.Description }}</td>
                                        @if (x.vars.SwitchGridOut[tr.VarName]) {
                                          @for (
                                            td of x.vars.SwitchGridOut[
                                              tr.VarName
                                            ];
                                            track td
                                          ) {
                                            <td>
                                              {{ td }}
                                            </td>
                                          }
                                        }
                                      </tr>
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          }
                        </div>
                      </mat-tab>
                    }
                  </mat-tab-group>
                </div>
                <div
                  class="rating-line {{ x.ScoreClass }}"
                  [style.width.%]="x.score / 10"
                ></div>
              </li>
            }
          }
        </ul>
      }

      @if (moduleNotes.include && !loading) {
        <app-notes-entry
          class="w-100 mt-2"
          [moduleNotes]="true"
          (noteEntered)="handleNotesValidation($event)"
          [notesData]="{
            app: 'iw',
            profile: id,
            note: moduleNotes.note,
            prompt: moduleNotes.prompt,
            required: moduleNotes.required
          }"
          [moduleBridges]="bridges"
          (updateModuleNote)="updateModuleNote($event)"
        ></app-notes-entry>
      }
    </div>
  }
</div>
