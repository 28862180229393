<div class="page-container search-container container unified">
  <div class="row">
    @if (!rights?.includes('HideHomeButtonInMenu')) {
      <app-left-nav [searchPage]="true"></app-left-nav>
    }
    <div class="content">
      <h1 class="unified-header col-6">Case Search</h1>
      <app-profile-search
        (caseSelected)="onSelect($event)"
        class="search"
      ></app-profile-search>
    </div>
  </div>
</div>
