<div mat-dialog-content class="validate-container row">
  <form [formGroup]="form" class="col-12">
    <h4>Generate Report</h4>
    <mat-form-field class="col-12 pl-0">
      <input
        matInput
        formControlName="filename"
        [placeholder]="'Custom Filename'"
      />
      <mat-hint align="start"
        >Enter a filename here if you would like to personalize the file.
        Otherwise the system will generate one for you.</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="col-12 mt-3 pl-0">
      <textarea
        matInput
        formControlName="explanation"
        [placeholder]="explanationPlaceholder"
        required
      ></textarea>
    </mat-form-field>

    @if (!this.hideDetails) {
      @if (!data.noSelection) {
        <mat-checkbox class="col-12 details-check" formControlName="details">
          Include fund details in report
        </mat-checkbox>
      }
      <mat-checkbox class="col-12 details-check" formControlName="alternative">
        Include program alternatives reason text
      </mat-checkbox>
      @if (!data.noSelection) {
        <mat-checkbox
          class="col-12 details-check"
          formControlName="includeFundAlternativesChart"
        >
          Include selected fund alternatives charts
        </mat-checkbox>
      }
    }
  </form>
</div>
<mat-dialog-actions class="row buttons">
  <button mat-raised-button (click)="cancel()" class="close">Cancel</button>
  <button mat-raised-button color="accent" (click)="report()" class="generate">
    <mat-icon>insert_drive_file</mat-icon>Generate Report
  </button>
</mat-dialog-actions>
<ngx-loading [show]="loading"></ngx-loading>
