<mat-button-toggle-group
  class="view-toggle mb-3 pull-right"
  [(ngModel)]="viewMode"
>
  <mat-button-toggle value="grid">
    <mat-icon>view_module</mat-icon>
  </mat-button-toggle>
  <mat-button-toggle value="list">
    <mat-icon>view_list</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>

<div
  class="row col-12 px-0 mx-0"
  [ngClass]="{
    'unified-selected': !!selected,
    'yellow-negative': yellowNegative
  }"
>
  <div
    [ngClass]="{
      'col-6': viewMode === 'grid',
      'col-12': viewMode !== 'grid',
      'pr-0': viewMode !== 'grid'
    }"
    class="column pl-0"
  >
    @for (r of reasons; track r; let i = $index) {
      @if ((viewMode === 'list' || !isOdd(i)) && r.headline !== '') {
        <div
          [ngClass]="{ mass: org.toLowerCase() === 'mm' }"
          class="col-12 reason {{ r.reasonType.toLowerCase() }}"
        >
          <strong class="header">
            <img
              alt="reason icon"
              src="{{
                './assets/' + path + r.reasonType.toLowerCase() + '.png'
              }}"
            />
            <span [innerHTML]="r.headline"></span>
            <span *hasPermission="'!RelevanceHideReasonScores'">
              | {{ r.score }}
            </span>
          </strong>
          @if (r.explanation) {
            <p class="explanation" [innerHTML]="r.explanation"></p>
          }
        </div>
      }
    }
  </div>
  @if (viewMode === 'grid') {
    <div
      [ngClass]="{
        'col-6': viewMode === 'grid',
        'col-12': viewMode !== 'grid',
        'pl-0': viewMode !== 'grid'
      }"
      class="column pr-0"
    >
      @for (r of reasons; track r; let i = $index) {
        @if (isOdd(i) && r.headline !== '') {
          <div
            [ngClass]="{ mass: org.toLowerCase() === 'mm' }"
            class="col-12 reason {{ r.reasonType.toLowerCase() }}"
          >
            <strong class="header">
              <img
                alt="reason icon"
                src="{{
                  './assets/' + path + r.reasonType.toLowerCase() + '.png'
                }}"
              />
              <span [innerHTML]="r.headline"></span>
              <span *hasPermission="'!RelevanceHideReasonScores'">
                | {{ r.score }}
              </span>
            </strong>
            @if (r.explanation) {
              <p class="explanation" [innerHTML]="r.explanation"></p>
            }
          </div>
        }
      }
    </div>
  }
</div>
