<mat-card appearance="outlined" class="col-12 stretch">
  <mat-card-header> Top 5 </mat-card-header>
  <mat-card-content>
    <ngx-loading
      [show]="state === 'loading'"
      [config]="{ fullScreenBackdrop: false }"
    ></ngx-loading>
    @if (state === 'none') {
      <div class="no-results">
        <h4>No Opportunities Available</h4>
      </div>
    }
    <table>
      <thead>
        <th>Name</th>
        <th>Suggestion</th>
        <th>Score</th>
      </thead>
      <tbody>
        @for (op of clientOpts; track op) {
          <tr (click)="goToProfile($event, op.id)">
            <td>{{ op.name }}</td>
            <td>{{ op.bridgeName }}</td>
            <td>{{ op.bridgeScore }}</td>
          </tr>
        }
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
