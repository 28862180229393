@if (this.title) {
  <h5>{{ this.title }}</h5>
}
<div class="clearfix mb-5 tables-wrapper">
  <table class="{{ style }}">
    @if (style !== 'minimal') {
      <thead>
        <tr>
          @for (h of chart.Header; track h; let idx = $index) {
            <th [class.text-center]="h === 'Status'"> {{ h }}</th>
          }
        </tr>
      </thead>
    }
    <tbody>
      @for (t of chart.Data; track t; let idx = $index) {
        @if (idx < chart.Limit) {
          <tr [ngClass]="t.Status">
            <td class="label">
              @if (style === 'minimal') {
                @if (t.Status === 'Positive') {
                  <mat-icon>check_circle</mat-icon>
                }
                @if (t.Status === 'Negative') {
                  <mat-icon>remove_circle</mat-icon>
                }
                @if (t.Status === 'Warning') {
                  <mat-icon>error</mat-icon>
                }
              }
              {{ t.Area }}
            </td>
            @if (style !== 'minimal') {
              <td class="text-center">
                @if (
                  t.Status === 'Positive' ||
                  t.Status === 'Negative' ||
                  t.Status === 'Warning'
                ) {
                  @if (t.Status === 'Positive') {
                    <mat-icon>check</mat-icon>
                  }
                  @if (t.Status === 'Negative') {
                    <mat-icon>close</mat-icon>
                  }
                  @if (t.Status === 'Warning') {
                    <mat-icon>priority_high</mat-icon>
                  }
                } @else {
                  <strong>{{ t.Status }}</strong>
                }
              </td>
            }
          </tr>
        }
      }
    </tbody>
  </table>
  <table class="{{ style }}">
    @if (style !== 'minimal') {
      <thead>
        <tr>
          @for (h of chart.Header; track h; let idx = $index) {
            <th [class.text-center]="h === 'Status'"> {{ h }}</th>
          }
        </tr>
      </thead>
    }
    <tbody>
      @for (t of chart.Data; track t; let idx = $index) {
        @if (idx >= chart.Limit) {
          <tr [ngClass]="t.Status">
            <td class="label">
              @if (style === 'minimal') {
                @if (t.Status === 'Positive') {
                  <mat-icon>check_circle</mat-icon>
                }
                @if (t.Status === 'Negative') {
                  <mat-icon>remove_circle</mat-icon>
                }
                @if (t.Status === 'Warning') {
                  <mat-icon>error</mat-icon>
                }
              }
              {{ t.Area }}
            </td>
            @if (style !== 'minimal') {
              <td class="text-center">
                @if (
                  t.Status === 'Positive' ||
                  t.Status === 'Negative' ||
                  t.Status === 'Warning'
                ) {
                  @if (t.Status === 'Positive') {
                    <mat-icon>check</mat-icon>
                  }
                  @if (t.Status === 'Negative') {
                    <mat-icon>close</mat-icon>
                  }
                  @if (t.Status === 'Warning') {
                    <mat-icon>priority_high</mat-icon>
                  }
                } @else {
                  <strong>{{ t.Status }}</strong>
                }
              </td>
            }
          </tr>
        }
      }
    </tbody>
  </table>
</div>
