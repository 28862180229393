<div mat-dialog-header>
  <h1>Upload Unit Profile</h1>
</div>
<div mat-dialog-content class="dialog-content">
  @if (uploadForm) {
    <form [formGroup]="uploadForm">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="col-6 pl-0">
            <mat-label>Application</mat-label>
            <mat-select formControlName="app" name="apps">
              @for (app of applications; track app.value) {
                <mat-option [value]="app.value">
                  {{ app.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field class="col-6 p-0">
            <input
              type="text"
              placeholder="Search For a Unit"
              matInput
              [matAutocomplete]="auto"
              name="unitsControl"
              [formControl]="unitsControl"
            />
            <mat-autocomplete #auto="matAutocomplete">
              @for (unit of filteredUnits | async; track unit.id) {
                <mat-option [value]="unit.id">
                  {{ unit.name }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>

          <div class="mb-3">
            <input
              type="file"
              class="file-input"
              (change)="onFileSelected($event)"
              #fileUpload
            />

            <span class="file-upload">
              {{ fileName || 'No file selected yet' }}

              <button
                mat-mini-fab
                color="primary"
                class="upload-btn"
                (click)="fileUpload.click()"
              >
                <mat-icon>attach_file</mat-icon>
              </button>
            </span>
          </div>

          <mat-form-field class="col-6 p-0">
            <mat-label>Bug Tracker ID</mat-label>
            <input
              type="text"
              placeholder="Bug Tracker ID"
              matInput
              name="bugTrackerId"
              formControlName="bugTrackerId"
            />
          </mat-form-field>

          <div class="failure">
            <strong>
              IMPORTANT:<br />
              <ol>
                <li
                  >Notes for Unit Profiles will be seen by Home Office Users in
                  the Unit History Report.</li
                >
                <li
                  >Unit Profile notes should be a high level description. All
                  details are logged in bug tracker (documents, pictures).</li
                >
                <li
                  >Include the bug tracker id, or create one if it does not
                  exist, for all Unit Profiles.</li
                >
              </ol>
            </strong>
          </div>

          <mat-form-field class="col-12 p-0">
            <textarea
              rows="7"
              matInput
              formControlName="note"
              placeholder="Notes..."
            ></textarea>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  }
  <h4 class="{{ statusClass }}">{{ status }}</h4>
</div>

<mat-dialog-actions class="row buttons pb-4 pr-3">
  <button mat-button color="warn" class="ml-auto" (click)="close()"
    >Close</button
  >
  <button mat-raised-button color="accent" (click)="onFileSubmit()">
    <mat-icon>file_upload</mat-icon>Upload
  </button>
</mat-dialog-actions>
