<mat-tab [label]="label">
  @if ((dataItem[tab.key] && dataItem[tab.key].length > 0) || !noResultsType) {
    <table class="detail-table">
      <thead>
        @for (header of tab.value; track header) {
          @if (!header.Hidden) {
            <th>
              {{ header.Label }}
            </th>
          }
        }
      </thead>
      <tbody>
        @for (product of dataItem[tab.key]; track product) {
          <tr>
            @for (header of tab.value; track header) {
              @if (!header.Hidden) {
                <td>
                  @if (header.DataField === 'ScoreClass') {
                    @if (
                      product.ScoreClass &&
                      product.ScoreClass.toLowerCase() === 'positive'
                    ) {
                      <mat-icon class="positive"> check_circle </mat-icon>
                    }
                    @if (
                      product.ScoreClass &&
                      product.ScoreClass.toLowerCase() === 'neutral'
                    ) {
                      <mat-icon class="neutral"> error </mat-icon>
                    }
                    @if (
                      product.ScoreClass &&
                      product.ScoreClass.toLowerCase() === 'negative'
                    ) {
                      <mat-icon class="negative"> remove_circle </mat-icon>
                    }
                  } @else {
                    @switch (header.Type) {
                      @case ('perc') {
                        {{ product[header.DataField] | percent }}
                      }
                      @case ('perc2') {
                        {{ product[header.DataField] | percent: '1.2' }}
                      }
                      @case ('curr') {
                        {{ product[header.DataField] | currency }}
                      }
                      @default {
                        @if (header.Validation) {
                          {{
                            findValue(
                              product[header.DataField],
                              header.Validation
                            )
                          }}
                        } @else {
                          {{ product[header.DataField] }}
                        }
                      }
                    }
                  }
                </td>
              }
            }
          </tr>
        }
      </tbody>
    </table>
  }
  @if (dataItem[tab.key] && dataItem[tab.key].length < 1 && noResultsType) {
    <h4 class="w-100 text-center my-4">
      No {{ noResultsType }} were found for this annuity based on your firm's
      product offering
    </h4>
  }
</mat-tab>
