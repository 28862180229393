<div mat-dialog-header>
  <h1>Account Grouping <span>- Recommended Accounts</span></h1>
</div>
<div mat-dialog-content class="dialog-container">
  @if (!loading) {
    @if (tableView) {
      <table class="details col-12">
        <thead>
          <tr>
            <th></th>
            @for (header of headers; track header.DataField) {
              @if (!header.Hidden) {
                <th>{{ header.Label }}</th>
              }
            }
          </tr>
        </thead>
        <tbody>
          @if (recommendedGroups && recommendedGroups.length > 0) {
            @for (group of recommendedGroups; track group.ID) {
              <tr>
                <td width="75">
                  <div class="action-button-container">
                    <mat-checkbox
                      matTooltip="Select Group"
                      [checked]="selectedGroup === group.GroupID"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="selectGroup(group.GroupID)"
                    ></mat-checkbox>
                    <button
                      mat-icon-button
                      (click)="viewAccountGroup(group)"
                      color="primary"
                      matTooltip="View Account Group"
                    >
                      <mat-icon>preview</mat-icon>
                    </button>
                  </div>
                </td>
                @for (column of headers; track column.DataField) {
                  @if (!column.Hidden) {
                    <td>{{ group[column.DataField] }}</td>
                  }
                }
              </tr>
            }
          } @else {
            <tr>
              <td colspan="7" class="no-results"> No results found </td>
            </tr>
          }
        </tbody>
      </table>
    } @else if (!tableView && !data.initialStystemData) {
      <div>
        <form [formGroup]="groupForm">
          <div class="row">
            <mat-form-field class="col-3">
              <mat-label>Account #</mat-label>
              <input
                matInput
                [formControl]="AccountIDControl"
                [placeholder]="'Account #'"
                (blur)="hashInput($event, 'AccountID')"
                (keyup)="valueHashed = false"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Full Name</mat-label>
              <input
                matInput
                [formControlName]="'FullName'"
                [placeholder]="'Full Name'"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>SSN/TIN</mat-label>
              <input
                matInput
                [formControl]="HashedSSNControl"
                [placeholder]="'SSN/TIN'"
                (blur)="hashInput($event, 'HashedSSN')"
                (keyup)="valueHashed = false"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>ROA</mat-label>
              <input
                matInput
                [formControlName]="'ROANumber'"
                [placeholder]="'ROA'"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Cumulative Discount #</mat-label>
              <input
                matInput
                [formControlName]="'CumulativeDiscountNum'"
                [placeholder]="'Cumulative Discount #'"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Group ID</mat-label>
              <input
                matInput
                [formControlName]="'GroupID'"
                [placeholder]="'Group ID'"
              />
            </mat-form-field>
            <mat-form-field class="col-3">
              <mat-label>Group Description</mat-label>
              <input
                matInput
                [formControlName]="'GroupDescription'"
                [placeholder]="'Group Description'"
              />
            </mat-form-field>
          </div>
        </form>
      </div>
    }
  }
</div>
<div mat-dialog-actions class="row buttons">
  <span>
    @if (!data.createGroup) {
      @if (tableView) {
        <button mat-stroked-button (click)="toggleView()">
          <mat-icon>troubleshoot</mat-icon> Refine Search
        </button>
      } @else {
        <button mat-raised-button color="accent" (click)="toggleView()">
          <mat-icon>search</mat-icon> View Results
        </button>
      }
    }
  </span>
  <span>
    <button mat-button color="warn" (click)="dialogRef.close('Cancel')">
      Cancel
    </button>
    @if (tableView) {
      <button
        mat-raised-button
        [disabled]="selectedGroup === ''"
        color="primary"
        (click)="linkAccount()"
      >
        <mat-icon>save</mat-icon> Link Account
      </button>
    }
  </span>
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
