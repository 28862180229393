<div class="page-container asset-container container unified">
  <div class="needs-container">
    <div class="col-12 row section-header">
      @if (data && data.vars) {
        <div class="col-11 col-xl-6 intro">
          <h2>
            <mat-icon>account_balance</mat-icon>Additional Insurance Needed:
          </h2>
          <mat-form-field appearance="fill">
            <input
              matInput
              appearance="fill"
              type="currency"
              [(ngModel)]="
                data.vars['InsuranceNeedAmount^EditedInsuranceNeed'].raw[0]
              "
              [readonly]="editAmount"
              [maskito]="dollarMask"
              maxlength="20"
              class="currency-editor"
            />
            @if (editAmount) {
              <button
                matSuffix
                mat-icon-button
                aria-label="Edit"
                (click)="editNeedAmount()"
                class="currency-editor"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
            }
            @if (!editAmount) {
              <button
                matSuffix
                mat-icon-button
                aria-label="Save"
                (click)="updateValue($event)"
                class="currency-editor"
              >
                <mat-icon>check</mat-icon>
              </button>
            }
            @if (!editAmount) {
              <button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearEdit()"
                class="currency-editor"
              >
                <mat-icon>close</mat-icon>
              </button>
            }
          </mat-form-field>
        </div>
      }
      @if (data && data.vars) {
        <div class="col-11 col-xl-6 intro">
          <h2>
            <mat-icon>pie_chart</mat-icon>Calculated Insurance Mix:
            {{ data.vars['InsuranceMix^PermPercent'].display[0] }} Permanent,
            {{ data.vars['InsuranceMix^TermPercent'].display[0] }} Term
          </h2>
        </div>
      }
    </div>
    @for (need of needs; track need) {
      <div class="row main-container">
        <div class="col-12 col-lg-6 content">
          <h2>
            {{ need.ShortName
            }}<span *hasPermission="'!RelevanceHideBridgeScore'">
              | {{ scoreFormat(need.score) }}</span
            >
          </h2>
          <p class="description" [innerHTML]="need.description"></p>
          <ul class="details">
            @for (r of need.reasons; track r; let i = $index) {
              <li class="col-12 reason no-gutter">
                <strong>
                  <span [innerHTML]="r.headline"></span>
                  <span *hasPermission="'!RelevanceHideBridgeScore'">
                    | {{ scoreFormat(r.score) }}
                  </span>
                </strong>
                <p class="explanation" [innerHTML]="r.explanation"></p>
              </li>
            }
          </ul>
        </div>
        @if (need.formattedCharts) {
          <div class="col-12 col-lg-6 chart-container">
            @for (c of need.formattedCharts; track c; let idx = $index) {
              <div [chart]="c"></div>
              @if (need.charts[idx].Legend) {
                <div class="chart-legend">
                  @if (need.charts[idx].Legend.header) {
                    <table>
                      <thead>
                        <tr>
                          @for (
                            th of need.charts[idx].Legend.header;
                            track th
                          ) {
                            @if (th.toLowerCase() !== 'color') {
                              <th>
                                {{ th }}
                              </th>
                            }
                          }
                        </tr>
                      </thead>
                      <tbody>
                        @for (tr of need.charts[idx].Legend.data; track tr) {
                          <tr>
                            @for (td of tr; track td; let i = $index) {
                              @if (
                                need.charts[idx].Legend.header[
                                  i
                                ].toLowerCase() !== 'color'
                              ) {
                                <td>
                                  @if (
                                    need.charts[idx].Legend.header[
                                      i
                                    ].toLowerCase() !== 'color' &&
                                    need.charts[
                                      idx
                                    ].Legend.header[0].toLowerCase() ===
                                      'color' &&
                                    i === 1
                                  ) {
                                    <span
                                      [style.background]="tr[0]"
                                      class="indicator"
                                    ></span>
                                  }
                                  {{ td }}
                                </td>
                              }
                            }
                          </tr>
                        }
                      </tbody>
                    </table>
                  } @else {
                    @for (c of need.charts[idx].Legend; track c) {
                      <div>
                        <strong>{{ c.label }}</strong> {{ c.value }}
                      </div>
                    }
                  }
                </div>
              }
            }
          </div>
        }
      </div>
    }
  </div>
</div>
