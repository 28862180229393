<div mat-dialog-header>
  <h1>{{ header }}</h1>
</div>
<mat-dialog-content #scroll class="dialog-container">
  @for (gr of grids; track gr) {
    <div class="grid-table w-100 row my-5">
      @for (tb of gr.tables; track tb) {
        <table
          [ngClass]="{
            'col-12': gr.tables.length === 1,
            'col-6': gr.tables.length === 2,
            'col-4': gr.tables.length === 3,
            'col-3': gr.tables.length === 4
          }"
        >
          <thead>
            @for (hd of tb.headers; track hd) {
              @if (hd !== 'Remove Me') {
                <th>{{ hd }}</th>
              }
            }
          </thead>
          <tbody>
            @for (rw of tb.data; track rw) {
              <tr>
                @for (cell of rw.row; track cell) {
                  <td [innerHTML]="cell"></td>
                }
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions class="row buttons col-12">
  @if (!informationOnly) {
    <form class="w-75">
      <mat-form-field
        floatPlaceholder="auto"
        class="pl-0 pull-left col-6 col-md-4"
      >
        <input
          matInput
          [maskito]="dollarMask"
          maxlength="20"
          placeholder="Dollars to allocate"
          name="alloc"
          [formControl]="dollarControl"
          (input)="updateValues($event, 'dollar', dollarControl)"
        />
        <mat-error>This field is required</mat-error>
      </mat-form-field>
      <mat-form-field
        floatPlaceholder="auto"
        class="pl-0 pull-left col-6 col-md-4"
      >
        <input
          matInput
          [maskito]="percentMask"
          placeholder="Percentage to allocate"
          name="allocPerc"
          [formControl]="percControl"
          (input)="updateValues($event, 'perc', percControl)"
        />
        <mat-error>This field is required</mat-error>
      </mat-form-field>
    </form>
  }
  <button mat-raised-button (click)="cancel()">Cancel</button>
  @if (!informationOnly) {
    <button mat-raised-button color="accent" (click)="save()">Add Fund</button>
  }
</mat-dialog-actions>
