<div mat-dialog-content class="dialog-container annuity-lookup">
  @if (!dtccEnabled || (dtccEnabled && !noShowBeacon)) {
    <h1 class="w-100"> Select a Product </h1>
  }
  @if (dtccEnabled && startDtcc) {
    <h1 class="w-100">Annuity Policy Search</h1>
  }

  <!--  -->
  <!-- Begin DTCC -->
  <!--  -->
  @if (dtccEnabled && showAdvisorQuestion) {
    <div class="col-12 mt-5">
      <p class="section text-center w-100 mb-3">
        Is the Financial Professional the Agent of Record on the Annuity Policy?
      </p>
      <div class="advisor-button-container w-100">
        <button mat-raised-button (click)="triggerLookup(true)">
          <div>
            <h3>Yes</h3>
          </div>
          <div class="sub-button"> Look up Existing Policy </div>
        </button>
        <button mat-raised-button (click)="triggerLookup(false)">
          <div>
            <h3>No</h3>
          </div>
          <div class="sub-button"> Historical Annuity Lookup </div>
        </button>
      </div>
    </div>
  }

  @if (dtccEnabled && startDtcc) {
    <div class="col-12 row mt-3">
      @if (dtccWindowClosed) {
        <div class="col-12 pl-0">
          <p class="warning">
            This functionality may be down for weekly maintenance and will be
            available after 2pm EST Sunday
          </p>
        </div>
      }
      @if (homeOfficeUser && autoPopulateNPN) {
        <mat-form-field class="col-10 pl-0">
          <mat-label>Select a Rep</mat-label>
          <input matInput [matAutocomplete]="repAuto" [formControl]="repCtrl" />
          <mat-autocomplete
            #repAuto="matAutocomplete"
            (optionSelected)="selectRep($event)"
            [displayWith]="displayReps"
          >
            @if (repDropdownNoResults) {
              <mat-option disabled>{{ repDropdownMessage }}</mat-option>
            } @else {
              @for (rep of filteredReps; track rep) {
                <mat-option [value]="rep">{{ rep['name'] }}</mat-option>
              }
            }
          </mat-autocomplete>
        </mat-form-field>
        <button
          [disabled]="!selectedRep || simpleLoading"
          class="col-2 search-button"
          mat-raised-button
          color="accent"
          (click)="searchRepForNPNs()"
        >
          <mat-icon>search</mat-icon> Search
        </button>
        @if (repMultiSelectSubtitle) {
          <div class="col-12 pl-0">
            <p>{{ repMultiSelectSubtitle }}</p>
          </div>
        }
      }
      @if (autoPopulateNPN && searchUserID) {
        <mat-form-field
          [ngClass]="NPNs && NPNs.length === 1 ? 'col-12' : 'col-10'"
          class="pl-0"
        >
          <mat-label>Rep Name - National Producer Number (NPN)</mat-label>
          <mat-select
            #NPNDropdown
            [disabled]="simpleLoading"
            [(ngModel)]="producerNum"
          >
            @for (npn of NPNs; track npn) {
              <mat-option [value]="npn.NPN"
                >{{ npn.Name }} - {{ npn.NPN }}</mat-option
              >
            }
          </mat-select>
          <mat-hint>{{ DTCCNotePrompt }}</mat-hint>
        </mat-form-field>
      }
      @if (!autoPopulateNPN) {
        <mat-form-field class="col-10 pl-0">
          <mat-label>National Insurance Producer Number</mat-label>
          <input
            [disabled]="simpleLoading"
            matInput
            placeholder="Producer Number"
            [(ngModel)]="producerNum"
          />
          <mat-hint>{{ DTCCNotePrompt }}</mat-hint>
        </mat-form-field>
      }
      @if (
        !autoPopulateNPN ||
        (autoPopulateNPN &&
          searchUserID &&
          (!NPNs || (NPNs && NPNs.length !== 1)))
      ) {
        <button
          [disabled]="dtccWindowClosed || !producerNum || simpleLoading"
          class="col-2 search-button"
          mat-raised-button
          color="accent"
          (click)="submitOrSearchProducerNumber()"
        >
          <mat-icon>{{ producerNumBtnIcon }}</mat-icon> {{ producerNumBtnTxt }}
        </button>
      }
      @if (!autoPopulateNPN) {
        <div class="col-12 pl-0">
          <p>
            Don't know your National Producer Number (NPN)? Look it up
            <a href="https://nipr.com/help/look-up-your-npn" target="_blank"
              >here</a
            >.
          </p>
        </div>
      }
    </div>
  }

  @if (dtccEnabled && submittedProducerNum) {
    <div class="col-12 row mt-3">
      <mat-form-field class="col-10 pl-0">
        <mat-label>Policy Number</mat-label>
        <input matInput [disabled]="simpleLoading" [(ngModel)]="policyNum" />
      </mat-form-field>
      <button
        mat-raised-button
        class="col-2 search-button"
        color="accent"
        (click)="searchDtcc()"
        [disabled]="!(policyNum && producerNum) || simpleLoading"
      >
        <mat-icon>search</mat-icon> Search
      </button>
    </div>
  }

  @if (dtccEnabled && ogData && ogData.length > 0 && !simpleLoading) {
    <div class="col-12 row grid-table mt-5 justify-content-start">
      @if (ogData && ogData.length > 1) {
        <h5 class="col-12">Filter Results</h5>
        <mat-form-field class="col-auto">
          <mat-label>Policy Number</mat-label>
          <input
            matInput
            #policyNumFilter
            (input)="
              filterValueChange(
                policyNumFilter.value,
                'insurancePolicyPolNumber'
              )
            "
          />
        </mat-form-field>
        <mat-form-field class="col-auto">
          <mat-label>Name</mat-label>
          <input
            matInput
            #fullNameFilter
            (input)="
              filterValueChange(
                fullNameFilter.value,
                'insurancePolicyPartyFullName'
              )
            "
          />
        </mat-form-field>
        <mat-form-field class="col-auto">
          <mat-label>Product Name</mat-label>
          <input
            matInput
            #productNameFilter
            (input)="
              filterValueChange(
                productNameFilter.value,
                'insuranceProductCusipName'
              )
            "
          />
        </mat-form-field>
        <mat-form-field class="col-auto">
          <mat-label>Product Type</mat-label>
          <input
            matInput
            #productTypeFilter
            (input)="
              filterValueChange(
                productTypeFilter.value,
                'insuranceProductTypeCode'
              )
            "
          />
        </mat-form-field>
      }

      @if (gridData && gridData.length > 0) {
        <table class="w-100">
          <thead>
            <th></th>
            <th>Policy Number</th>
            <th>Name</th>
            <th>Product Name</th>
            <th>Product Type</th>
            <th>Contract Value</th>
          </thead>
          <tbody>
            @for (row of gridData; track row) {
              <tr (click)="setPolicyDetails(row)">
                <td class="selected-policy">
                  @if (
                    row.insurancePolicyPolNumber ===
                    selectedPolicyDetails?.insurancePolicyPolNumber
                  ) {
                    <mat-icon>check_circle</mat-icon>
                  }
                </td>
                <td>{{ row.insurancePolicyPolNumber }}</td>
                <td>
                  {{ row.insurancePolicyPartyFirstName }}
                  {{ row.insurancePolicyPartyLastName }}
                </td>
                <td>{{ row.insuranceProductCusipName }}</td>
                <td>{{ row.insuranceProductTypeCode }}</td>
                <td>{{ row.insurancePolicyContractValueAmount | currency }}</td>
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
  }

  @if (dtccEnabled && noResults && !simpleLoading) {
    <div class="col-12 error-container mt-5 mx-auto">
      <h3 class="text-center w-100 error-message">
        {{ errorMessage }}
      </h3>
    </div>
  }

  @if (simpleLoading) {
    <div class="col-12 mt-5 mx-auto">
      <app-simple-loading [message]="simpleLoadingMessage"></app-simple-loading>
    </div>
  }

  <!--  -->
  <!-- Begin Beacon -->
  <!--  -->
  @if (!dtccEnabled || (dtccEnabled && !noShowBeacon)) {
    <div class="col-12 row">
      <form class="col-12 col-md-7" [formGroup]="form" (ngSubmit)="saveData()">
        <mat-form-field class="col-12">
          <mat-label>Annuity Purchase Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [placeholder]="'Annuity Purchase Date'"
            formControlName="dateCtrl"
            (dateChange)="setPurchDate($event)"
            [max]="maxDate"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          @if (form.controls.dateCtrl.errors) {
            <mat-error>This field is required</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="col-12">
          <mat-label>Select a Carrier</mat-label>
          <input
            type="text"
            placeholder="Select a Carrier"
            aria-label="Carrier"
            matInput
            formControlName="compCtrl"
            [matAutocomplete]="carrierSelect"
          />
          <mat-autocomplete
            #carrierSelect="matAutocomplete"
            (optionSelected)="getProducts($event)"
            [displayWith]="displayComp"
          >
            @for (opt of filteredCompanies | async; track opt) {
              <mat-option
                [value]="opt"
                matTooltip="{{ opt.alternate_name }}"
                matToolTipPosition="before"
                matTooltipShowDelay="750"
                >{{ opt.alternate_name }}</mat-option
              >
            }
          </mat-autocomplete>
          @if (form.controls.compCtrl.errors) {
            <mat-error>This field is required</mat-error>
          }
        </mat-form-field>
        @if (!noProductsReturned) {
          <mat-form-field
            class="col-12"
            [ngClass]="{ 'col-12': !canSelectClass, 'col-8': canSelectClass }"
          >
            <mat-label>Select an Annuity</mat-label>
            <input
              type="text"
              placeholder="Select an Annuity"
              aria-label="Annuity"
              matInput
              formControlName="prodCtrl"
              [matAutocomplete]="annuitySelect"
            />
            <mat-autocomplete
              #annuitySelect="matAutocomplete"
              (optionSelected)="selectProduct($event)"
              [displayWith]="displayProd"
            >
              @for (opt of filteredProducts | async; track opt) {
                <mat-option
                  [value]="opt"
                  matTooltip="{{ opt.product_name }}"
                  matToolTipPosition="before"
                  matTooltipShowDelay="750"
                  >{{ opt.product_name }}</mat-option
                >
              }
            </mat-autocomplete>
            @if (form.controls.prodCtrl.errors) {
              <mat-error>This field is required</mat-error>
            }
          </mat-form-field>
        } @else {
          <p class="error small noProds">
            No annuity contract information is available for this carrier based
            on the purchase date provided
          </p>
        }
        @if (noProductDetails) {
          <p class="error small noDetails">
            No annuity contract information is currently available for this
            product
          </p>
        }
        @if (canSelectClass) {
          <mat-form-field class="col-4">
            <mat-label>Select a Share Class</mat-label>
            <mat-select
              placeholder="Share Class"
              class="w-100"
              (selectionChange)="selectShareClass($event)"
              formControlName="shareClassCtrl"
              required
            >
              @for (opt of shareClassOptions; track opt.value) {
                <mat-option [value]="opt.value">
                  {{ opt.display }}
                </mat-option>
              }
            </mat-select>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        }
        @if (canSelectHistorical) {
          <mat-form-field class="col-12">
            <mat-label>Select Annual Charges</mat-label>
            <mat-select
              formControlName="HistoricalProdChargesCtrl"
              (selectionChange)="selectHistoricalProdChargeOption($event)"
            >
              @for (opt of historicalProdChargesOptions; track opt.value) {
                <mat-option class="radio-button" [value]="opt">
                  {{ formatPercent(opt.MEA) }}% - {{ opt.MECurrentChargeText }}
                </mat-option>
              }
            </mat-select>
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        }
        <div class="death-container">
          @if (canSelectDeath) {
            <p class="col-12 mt-2"> Death Benefit on Contract </p>
            <mat-form-field class="col-12">
              <mat-label>Select a Death Benefit</mat-label>
              <mat-select
                placeholder="Select a Death Benefit"
                class="w-100"
                (selectionChange)="selectRider($event, 'death')"
                formControlName="deathRiderCtrl"
                required
              >
                @for (opt of deathRiders; track opt.Key) {
                  <mat-option [value]="opt.Key">
                    {{ opt.Name }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }

          @if (charges['death'] && charges['death'].length > 0) {
            <mat-form-field class="col-12">
              <mat-label>Select Charges</mat-label>
              <mat-select
                placeholder="Select Charges"
                class="w-100"
                name="deathChargesCtrl"
                formControlName="deathChargesCtrl"
                (selectionChange)="selectCharges($event, 'death')"
                required
              >
                @for (opt of charges['death']; track opt.value) {
                  <mat-option [value]="opt.value">
                    {{ opt.display }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }

          @if (canSelectCases) {
            <mat-form-field class="col-12">
              <mat-label>Select a Case</mat-label>
              <mat-select
                placeholder="Select a Case"
                class="w-100"
                name="casesCtrl"
                formControlName="casesCtrl"
                (selectionChange)="selectCase($event)"
                required
              >
                @for (opt of cases; track opt.value) {
                  <mat-option [value]="opt.value">
                    {{ opt.display }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }
        </div>
        <div class="living-container">
          @if (canSelectLiving) {
            <p class="col-12 mt-2"> Client Purchased Living Benefit </p>
            <mat-radio-group
              class="radio-group col-12"
              [ngClass]="{
                'mat-error':
                  form.controls.purchasedLivingCtrl.errors &&
                  !form.controls.purchasedLivingCtrl.untouched
              }"
              formControlName="purchasedLivingCtrl"
              (change)="benefitChange($event, 'living')"
              required
            >
              <mat-radio-button class="radio-button" value="true">
                Yes
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="false">
                No
              </mat-radio-button>
              @if (
                form.controls.purchasedLivingCtrl.errors &&
                !form.controls.purchasedLivingCtrl.untouched
              ) {
                <mat-error class="mat-error" style="font-size: 75%">
                  This field is required
                </mat-error>
              }
            </mat-radio-group>
          }
          @if (
            canSelectLiving &&
            form.controls.purchasedLivingCtrl.value === 'true'
          ) {
            <mat-form-field class="col-12">
              <mat-label>Select a Living Benefit</mat-label>
              <mat-select
                placeholder="Select a Living Benefit"
                class="w-100"
                (selectionChange)="selectRider($event, 'living')"
                formControlName="livingRiderCtrl"
                required
              >
                @for (opt of livingRiders; track opt.Key) {
                  <mat-option [value]="opt.Key">
                    {{ opt.Name }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }

          @if (canSelectJoint) {
            <p class="col-12">Single or Joint Account</p>
          }
          @if (canSelectBands) {
            <mat-radio-group
              class="radio-group col-12"
              [ngClass]="{
                'mat-error':
                  form.controls.singleJointBandCtrl.errors &&
                  !form.controls.singleJointBandCtrl.untouched
              }"
              name="singleJointBandCtrl"
              formControlName="singleJointBandCtrl"
              value="single_life"
              (change)="selectBand()"
              required
            >
              <mat-radio-button
                class="radio-button"
                value="single_life"
                required
              >
                Single
              </mat-radio-button>
              <mat-radio-button
                class="radio-button"
                value="joint_life"
                required
              >
                Joint
              </mat-radio-button>
              @if (
                form.controls.singleJointBandCtrl.errors &&
                !form.controls.singleJointBandCtrl.untouched
              ) {
                <mat-error class="mat-error" style="font-size: 75%">
                  This field is required
                </mat-error>
              }
            </mat-radio-group>
          }
          @if (canSelectBandOption) {
            <mat-form-field class="col-12">
              <mat-label>Select a Withdrawal Option</mat-label>
              <mat-select
                placeholder="Select a Withdrawal Option"
                class="w-100"
                name="bandOptionCtrl"
                formControlName="bandOptionCtrl"
                (selectionChange)="selectBand('withdrawal')"
                required
              >
                @for (opt of ageBandOptions; track opt.value) {
                  <mat-option [value]="opt.value">
                    {{ opt.display }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }
          @if (canSelectBands) {
            <mat-form-field class="col-12">
              <mat-label>Select an Age Band</mat-label>
              <mat-select
                placeholder="Select an Age Band"
                class="w-100"
                name="bandCtrl"
                formControlName="bandCtrl"
                (selectionChange)="selectBand('ageBand')"
                required
              >
                @for (opt of ageBands; track opt.value) {
                  <mat-option [value]="opt.value">
                    {{ opt.display }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }
          @if (rollups['living'] && rollups['living'].length > 0) {
            <mat-form-field class="col-12">
              <mat-label>Select a Roll Up</mat-label>
              <mat-select
                placeholder="Select a Roll Up"
                class="w-100"
                name="livingRollupCtrl"
                formControlName="livingRollupCtrl"
                (selectionChange)="selectRollup($event)"
                required
              >
                @for (opt of rollups['living']; track opt.value) {
                  <mat-option [value]="opt.value">
                    {{ opt.display }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }
          @if (livingChargesOptions && livingChargesOptions.length > 0) {
            <mat-form-field class="col-12">
              <mat-label>Select Charges</mat-label>
              <mat-select
                placeholder="Select Charges"
                class="w-100"
                name="livingChargesCtrl"
                formControlName="livingChargesCtrl"
                (selectionChange)="selectCharges($event, 'living')"
                required
              >
                @for (opt of livingChargesOptions; track opt.value) {
                  <mat-option [value]="opt.value">
                    {{ opt.display }}
                  </mat-option>
                }
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          }
        </div>
        @if (canSelectAccumulation) {
          <div class="accumulation-container">
            <p class="col-12 mt-2">Client Purchased Accumulation Benefit</p>
            <mat-radio-group
              class="radio-group col-12"
              [ngClass]="{
                'mat-error':
                  form.controls.purchasedAccumulationCtrl.errors &&
                  !form.controls.purchasedAccumulationCtrl.untouched
              }"
              formControlName="purchasedAccumulationCtrl"
              (change)="benefitChange($event, 'accumulation')"
              required
            >
              <mat-radio-button class="radio-button" value="true" required>
                Yes
              </mat-radio-button>
              <mat-radio-button class="radio-button" value="false" required>
                No
              </mat-radio-button>
              @if (
                form.controls.purchasedAccumulationCtrl.errors &&
                !form.controls.purchasedAccumulationCtrl.untouched
              ) {
                <mat-error class="mat-error" style="font-size: 75%">
                  This field is required
                </mat-error>
              }
            </mat-radio-group>
            @if (
              canSelectAccumulation &&
              form.controls.purchasedAccumulationCtrl.value === 'true'
            ) {
              <mat-form-field class="col-12">
                <mat-label>Select a Accumulation Benefit</mat-label>
                <mat-select
                  placeholder="Select an Accumulation Benefit"
                  class="w-100"
                  (selectionChange)="selectRider($event, 'accumulation')"
                  formControlName="accumulationRiderCtrl"
                  required
                >
                  @for (opt of accumulationRiders; track opt.value) {
                    <mat-option [value]="opt.Key">
                      {{ opt.Name }}
                    </mat-option>
                  }
                </mat-select>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            }
            @if (
              charges['accumulation'] && charges['accumulation'].length > 0
            ) {
              <mat-form-field class="col-12">
                <mat-label>Select Charges</mat-label>
                <mat-select
                  placeholder="Select Charges"
                  class="w-100"
                  name="accumulationChargesCtrl"
                  formControlName="accumulationChargesCtrl"
                  (selectionChange)="selectCharges($event, 'accumulation')"
                  required
                >
                  @for (opt of charges['accumulation']; track opt.value) {
                    <mat-option [value]="opt.value">
                      {{ opt.display }}
                    </mat-option>
                  }
                </mat-select>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            }
          </div>
        }
      </form>

      @if (!showData) {
        <div class="col-12 col-md-5 placeholder">
          <div><strong class="w-25"></strong> <span class="w-50"></span></div>
          <div><strong class="w-10"></strong> <span class="w-75"></span></div>
          <div><strong class="w-15"></strong> <span class="w-33"></span></div>
          <div><strong class="w-10"></strong> <span class="w-50"></span></div>
          <div><strong class="w-33"></strong> <span class="w-33"></span></div>
          <div><strong class="w-15"></strong> <span class="w-33"></span></div>

          <div><strong class="w-33"></strong> <span class="w-50"></span></div>
          <div><strong class="w-15"></strong> <span class="w-33"></span></div>
          <div><strong class="w-25"></strong> <span class="w-33"></span></div>
          <div><strong class="w-10"></strong> <span class="w-25"></span></div>
          <div><strong class="w-55"></strong> <span class="w-33"></span></div>
          <div><strong class="w-33"></strong> <span class="w-15"></span></div>
        </div>
      } @else {
        <div class="col-12 col-md-5 dataDisplay">
          <h4>Product Information</h4>
          @if (exchangeData[this.getVarname('_Carrier')]) {
            <div>
              <strong>Carrier:</strong>
              {{ exchangeData[this.getVarname('_Carrier')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_PlanName')]) {
            <div>
              <strong>Product:</strong>
              {{ exchangeData[this.getVarname('_PlanName')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_DisplayShareClass')]) {
            <div>
              <strong>Share Class:</strong>
              {{ exchangeData[this.getVarname('_DisplayShareClass')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalFee')]) {
            <div>
              <strong>Product M&amp;E:</strong>
              {{
                exchangeData[this.getVarname('_BrokeragePercentageFee')]
                  | percent: '1.2-4'
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_CDSCSchedule')]) {
            <div>
              <strong>CDSC Schedule:</strong>
              {{ exchangeData[this.getVarname('_CDSCSchedule')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_RidersAvailable')]) {
            <div>
              <strong>Riders Available:</strong>
              {{ exchangeData[this.getVarname('_RidersAvailable')] }}
            </div>
          }
          @if (
            exchangeData[this.getVarname('_HasWithdrawalBenefit')] &&
            exchangeData[this.getVarname('_HasWithdrawalBenefit')] !== 'N'
          ) {
            <div>
              <strong>Withdrawal Benefit:</strong>
              {{
                +exchangeData[this.getVarname('_HasWithdrawalBenefit')] / 100
                  | percent: '1.2-4'
              }}
            </div>
          }

          @if (exchangeData[this.getVarname('_LivingBenefitName')]) {
            <h4> Living Benefit Rider Details: </h4>
            <div>
              <strong>Name:</strong>
              {{ exchangeData[this.getVarname('_LivingBenefitName')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalLBDescription')]) {
            <div>
              <strong>Description:</strong>
              {{ exchangeData[this.getVarname('_HistoricalLBDescription')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalLBOtherInfo')]) {
            <div>
              <strong>Other Information:</strong>
              {{ exchangeData[this.getVarname('_HistoricalLBOtherInfo')] }}
            </div>
          }
          @if (
            exchangeData[this.getVarname('_HistoricalLBBenefitBaseGeneral')]
          ) {
            <div>
              <strong>Base:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalLBBenefitBaseGeneral')]
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalLBStateExceptions')]) {
            <div>
              <strong>State Exceptions:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalLBStateExceptions')]
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_RiderMandE')]) {
            <div>
              <strong>Charges:</strong>
              {{ exchangeData[this.getVarname('_RiderMandE')] }}
            </div>
          }
          @if (
            exchangeData[this.getVarname('_HistoricalLBWithdrawalPercent')]
          ) {
            <div>
              <strong>Withdrawal Percent:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalLBWithdrawalPercent')]
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalLBRollUpsAvailable')]) {
            <div>
              <strong>Roll Ups Available:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalLBRollUpsAvailable')]
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_DeferralBonus')]) {
            <div>
              <strong>Selected Roll Up:</strong>
              {{
                +exchangeData[this.getVarname('_DeferralBonus')]
                  | percent: '1.2-4'
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalLBDeferralLength')]) {
            <div>
              <strong>Selected Roll Up Deferral Length:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalLBDeferralLength')]
                  | number: '1.0-1'
              }}
              Years
            </div>
          }

          @if (exchangeData[this.getVarname('_GMABName')]) {
            <h4> Accumulation Rider Details: </h4>
            <div>
              <strong>Name:</strong>
              {{ exchangeData[this.getVarname('_GMABName')] }}
            </div>
          }

          @if (exchangeData[this.getVarname('_GMABRiderMandE')]) {
            <div>
              <strong>Charges:</strong>
              {{ exchangeData[this.getVarname('_GMABRiderMandE')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_GMABNotes')]) {
            <div>
              <strong>Notes:</strong>
              <span
                [innerHTML]="exchangeData[this.getVarname('_GMABNotes')]"
              ></span>
            </div>
          }

          @if (exchangeData[this.getVarname('_DeathBenefitName')]) {
            <h4> Death Benefit Rider Details: </h4>
            <div>
              <strong>Name:</strong>
              {{ exchangeData[this.getVarname('_DeathBenefitName')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalDBDescription')]) {
            <div>
              <strong>Description:</strong>
              {{ exchangeData[this.getVarname('_HistoricalDBDescription')] }}
            </div>
          }
          @if (
            exchangeData[this.getVarname('_HistoricalDBBenefitBaseGeneral')]
          ) {
            <div>
              <strong>Base:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalDBBenefitBaseGeneral')]
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalDBOtherInfo')]) {
            <div>
              <strong>Base:</strong>
              {{ exchangeData[this.getVarname('_HistoricalDBOtherInfo')] }}
            </div>
          }
          @if (
            exchangeData[this.getVarname('_HistoricalDBSpousalContinuation')]
          ) {
            <div>
              <strong>Spousal Protection:</strong>
              {{
                exchangeData[
                  this.getVarname('_HistoricalDBSpousalContinuation')
                ]
              }}
            </div>
          }
          @if (
            exchangeData[this.getVarname('_HistoricalDBCancellationOption')]
          ) {
            <div>
              <strong>Charges:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalDBCancellationOption')]
              }}
            </div>
          }
          @if (exchangeData[this.getVarname('_DBBasisCost')]) {
            <div>
              <strong>Charge:</strong>
              {{ exchangeData[this.getVarname('_DBBasisCost')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalDBFeeAccount')]) {
            <div>
              <strong>Charge Basis:</strong>
              {{ exchangeData[this.getVarname('_HistoricalDBFeeAccount')] }}
            </div>
          }
          @if (exchangeData[this.getVarname('_HistoricalDBFeeCalcFrequency')]) {
            <div>
              <strong>Charge Frequency:</strong>
              {{
                exchangeData[this.getVarname('_HistoricalDBFeeCalcFrequency')]
              }}
            </div>
          }
        </div>
      }
    </div>
  }
</div>
<div mat-dialog-actions class="row">
  @if ((dtccEnabled && startDtcc) || (dtccEnabled && !noShowBeacon)) {
    <button mat-button [disabled]="simpleLoading" (click)="stepBack()">
      <mat-icon>arrow_back</mat-icon>Back
    </button>
  }
  <div class="buttons">
    @if (
      this.dtccEnabled &&
      (this.noResults || (this.ogData && this.ogData.length > 0)) &&
      !this.simpleLoading
    ) {
      <button
        mat-stroked-button
        class="cant-find-btn"
        color="primary"
        (click)="cantFindPolicy()"
      >
        <mat-icon>troubleshoot</mat-icon> I Can't Find It
      </button>
    }
    @if (!dtccEnabled || (dtccEnabled && !noShowBeacon)) {
      <button
        mat-button
        (click)="dlFactSheet(); $event.preventDefault()"
        [disabled]="!selectedProduct.product_key"
        color="primary"
      >
        <mat-icon>insert_drive_file</mat-icon>Download Fact Sheet
      </button>
    }
    <span>
      <button
        mat-button
        color="warn"
        [disabled]="simpleLoading"
        (click)="close(); $event.preventDefault()"
      >
        Cancel
      </button>
      @if (dtccEnabled && gridData) {
        <button
          mat-raised-button
          color="accent"
          (click)="getPolicyDetails()"
          [disabled]="!selectedPolicyDetails || simpleLoading"
        >
          <mat-icon>check</mat-icon>Use This One
        </button>
      }
      @if (!dtccEnabled || (dtccEnabled && !noShowBeacon)) {
        <button mat-raised-button color="accent" (click)="saveData()">
          Save Annuity Data
        </button>
      }
    </span>
  </div>
</div>
<ngx-loading
  [show]="loading || loadingSignal()"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
