<div class="page-container product-shelf-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12">Product Shelf</h1>
  @if (gridView) {
    @if (!hideGrid) {
      <div class="mb-3 grid-view-row">
        <h5 class="w-100">Grid View:</h5>
        <mat-button-toggle-group
          [(ngModel)]="gridView"
          (change)="changeView($event)"
        >
          <mat-button-toggle value="product">Product</mat-button-toggle>
          <mat-button-toggle value="income">Income Riders</mat-button-toggle>
          <mat-button-toggle value="death"
            >Death Benefit Riders</mat-button-toggle
          >
          @if (rights && rights.includes('AnnuityGrid')) {
            <mat-button-toggle value="fa">FA Rates</mat-button-toggle>
          }
          @if (rights && rights.includes('FIAGrid')) {
            <mat-button-toggle value="fia">FIA Rates</mat-button-toggle>
          }
          @if (rights && rights.includes('ShowVIAFunds')) {
            <mat-button-toggle value="rila">RILA Rates</mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
    }

    @if (!rateViews.includes(gridView)) {
      @if (!loading && !hideGrid) {
        <app-grid-filters
          class="col-12"
          (filtersUpdated)="updateFilters($event)"
          [filters]="externalFilters"
          [reset]="resetFilters"
          [preexistingFilters]="filter"
          (clearSingleFilter)="clearSingleFilter($event)"
          [gridName]="gridView"
          [showFilterManager]="false"
        ></app-grid-filters>
      }
      @if (!hideGrid) {
        <div class="all-types-notification" *hasPermission="'UIExplanations'">
          @if (!showFilterMessage) {
            <span
              class="default-types"
              [ngClass]="{ 'roll-in': !showFilterMessage }"
            >
              All annuity types are visible by default.
            </span>
          }
          <span
            class="click-information"
            [ngClass]="{ 'roll-in': showFilterMessage }"
          >
            Click the <mat-icon class="clear-indicator">close</mat-icon> to
            filter by all annuity types
          </span>
        </div>
      }
      @if (!hideGrid) {
        <div class="row mb-3 mx-1">
          <button
            mat-button
            color="primary"
            class="mr-0 ml-2"
            (click)="compare()"
            [disabled]="compareList.length < 2"
          >
            <mat-icon class="mr-2">compare_arrows</mat-icon>Compare Products
          </button>
          <app-grid-column-filter
            [columns]="gridColumns"
            (columnsUpdated)="externalColumnsUpdated($event)"
          ></app-grid-column-filter>
          @if (gridView === 'income') {
            <button
              mat-button
              color="primary"
              class="mr-0 ml-2"
              (click)="calculateIncome()"
            >
              <mat-icon class="mr-2">payments</mat-icon
              >{{ incomeCalcButtonLabel }}
            </button>
          }
          <button
            mat-button
            color="primary"
            class="ml-auto mr-0"
            (click)="reset(false)"
          >
            <mat-icon class="mr-2">replay</mat-icon>Reset
          </button>
          @if (
            (gridView === 'product' ||
              gridView === 'income' ||
              gridView === 'death') &&
            managerUnitCount > 0
          ) {
            <button
              mat-button
              color="primary"
              class="mr-0 ml-2"
              (click)="downloadExcel(productGrid)"
            >
              <mat-icon class="mr-2">cloud_download</mat-icon>Download Excel
              File
            </button>
          }
        </div>
      }

      @if (!hideGrid) {
        <kendo-grid
          class="col-12"
          #productGrid="kendoGrid"
          [kendoGridBinding]="gridData.data"
          [skip]="state.skip"
          [pageSize]="state.take"
          [pageable]="true"
          [resizable]="true"
          [sortable]="{ allowUnsort: true, mode: 'multiple' }"
          [sort]="state.sort"
          [filterable]="'menu'"
          [columnMenu]="{ columnChooser: false }"
          [filter]="state.filter"
          (filterChange)="filterChange($event)"
          [state]="state"
          (dataStateChange)="dataStateChange($event)"
          [height]="gridHeight()"
        >
          <kendo-grid-column [autoSize]="true" title="Actions">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="action-buttons-container">
                @if (rights.includes('AnnuityFactsSheet')) {
                  <button
                    mat-icon-button
                    class="fs-button"
                    (click)="dlFactsheet(dataItem)"
                    color="primary"
                    matTooltip="Download Factsheet"
                    [disabled]="
                      dataItem.annuityType &&
                      (dataItem.annuityType.toLowerCase() === 'spia' ||
                        dataItem.annuityType.toLowerCase() === 'dia')
                    "
                  >
                    <mat-icon>insert_drive_file</mat-icon>
                  </button>
                }
                @if (!hideLaunchToAnnuityBtn) {
                  <button
                    mat-icon-button
                    class="fs-button"
                    (click)="createNewCase(dataItem, true)"
                    color="primary"
                    matTooltip="Create New Case for This Annuity"
                  >
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                }
                @if (showHyposAndBrochuresBtn && dataItem['Link']) {
                  <button
                    mat-icon-button
                    class="fs-button"
                    (click)="launchExternalLink(dataItem['Link'])"
                    color="primary"
                    matTooltip="Carrier Hypotheticals and Client Brochures"
                  >
                    <mat-icon>grading</mat-icon>
                  </button>
                }
                <mat-checkbox
                  matTooltip="Compare Annuity"
                  (change)="compareListUpdate($event, dataItem)"
                  [(ngModel)]="compareChecked[dataItem.uniqueCode]"
                  [ngModelOptions]="{ standalone: true }"
                >
                </mat-checkbox>
              </div>
            </ng-template>
          </kendo-grid-column>
          @for (column of gridData.headers; track column) {
            <kendo-grid-column
              [field]="column.DataField"
              [hidden]="isHidden(column.DataField)"
              [title]="column.Label"
              [sortable]="column.Sort"
              [filterable]="column.Filter"
              [groupable]="column.Group"
              [media]="column.RespVis"
              [filter]="column.DataType"
              [headerClass]="columnStyleCheck(column.DataField)"
              [class]="columnStyleCheck(column.DataField)"
              [width]="column.Width ? column.Width : 150"
              [sticky]="column.Sticky"
            >
              @if (column.Sort || column.Filter) {
                <ng-template kendoGridColumnMenuTemplate let-service="service">
                  @if (column.Sort) {
                    <kendo-grid-columnmenu-sort
                      [column]="column"
                      [service]="service"
                    >
                      <kendo-grid-messages
                        [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                        [sortDescending]="
                          getFilterMenuText(column.Type, 'desc')
                        "
                      ></kendo-grid-messages>
                    </kendo-grid-columnmenu-sort>
                  }
                  @if (column.Filter) {
                    <kendo-grid-columnmenu-filter
                      [column]="column"
                      [service]="service"
                    >
                      <ng-template
                        kendoGridFilterMenuTemplate
                        let-filter="filter"
                        let-filterService="filterService"
                      >
                        <kendo-multiselect
                          style="width: 99%"
                          [data]="filterData[column.DataField]"
                          textField="display"
                          valueField="value"
                          [valuePrimitive]="true"
                          [value]="filter | filterValues"
                          (valueChange)="
                            filterValueChange(
                              $event,
                              column.DataField,
                              filterService
                            )
                          "
                          [fillMode]="'outline'"
                          placeholder="Filter By..."
                          [filterable]="true"
                          (filterChange)="
                            onFilterChange($event, column.DataField)
                          "
                        ></kendo-multiselect>
                      </ng-template>
                    </kendo-grid-columnmenu-filter>
                  }
                </ng-template>
              }
              @if (column.Type === 'integer') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem[column.DataField] | number: '1.0-0' }}
                </ng-template>
                <ng-template
                  kendoGridGroupHeaderTemplate
                  let-group
                  let-field="field"
                  let-value="value"
                >
                  {{ value | number: '1.0-0' }}
                </ng-template>
              }
              @if (column.Type === 'commaSeparator') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem[column.DataField] | number: '1.0-2' }}
                </ng-template>
                <ng-template
                  kendoGridGroupHeaderTemplate
                  let-group
                  let-field="field"
                  let-value="value"
                >
                  {{ value | number: '1.0-2' }}
                </ng-template>
              }
              @if (column.Type === 'perc') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem[column.DataField] | percent }}
                </ng-template>
                <ng-template
                  kendoGridGroupHeaderTemplate
                  let-group
                  let-field="field"
                  let-value="value"
                >
                  {{ value | percent }}
                </ng-template>
              }
              @if (column.Type === 'perc2') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem[column.DataField] | percent: '1.2' }}
                </ng-template>
                <ng-template
                  kendoGridGroupHeaderTemplate
                  let-group
                  let-field="field"
                  let-value="value"
                >
                  {{ value | percent: '1.2' }}
                </ng-template>
              }
              @if (column.Type === 'curr') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem[column.DataField] | currency }}
                </ng-template>
                <ng-template
                  kendoGridGroupHeaderTemplate
                  let-group
                  let-field="field"
                  let-value="value"
                >
                  {{ value | currency }}
                </ng-template>
              }
              @if (column.DataField === 'notes') {
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span [innerHtml]="dataItem[column.DataField]"></span>
                </ng-template>
              }
              @if (column.Validation) {
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ findValue(dataItem[column.DataField], column.Validation) }}
                </ng-template>
              }
            </kendo-grid-column>
          }
          @if (gridData['subHead']) {
            <ng-template kendoGridDetailTemplate let-dataItem>
              <!--  -->
              <!-- Have to make specific tabs to force order and not have multiple rate summary sections show up and the ability to show messages for empty datasets -->
              <!-- Good code is commented out below... -->
              <!--  -->
              @if (gridView === 'product') {
                <mat-tab-group mat-stretch-tabs>
                  @for (
                    tab of gridData.subHead | keyvalue: originalOrder;
                    track tab
                  ) {
                    @if (tab.key === 'riders') {
                      <app-product-detail-tab
                        [label]="'Income Riders'"
                        [dataItem]="dataItem"
                        [tab]="tab"
                        [noResultsType]="'riders'"
                      ></app-product-detail-tab>
                    }
                    @if (tab.key === 'deathRiders') {
                      <app-product-detail-tab
                        [label]="'Death Benefit Riders'"
                        [dataItem]="dataItem"
                        [tab]="tab"
                        [noResultsType]="'death benefit riders'"
                      ></app-product-detail-tab>
                    }
                    @if (
                      (tab.key === 'fiaRates' &&
                        dataItem.annuityType.toLowerCase() === 'fia') ||
                      (tab.key === 'faRates' &&
                        dataItem.annuityType.toLowerCase() === 'fa') ||
                      (tab.key === 'viaRates' &&
                        dataItem.annuityType.toLowerCase() === 'rila')
                    ) {
                      <app-product-detail-tab
                        [label]="'Rates Summary'"
                        [dataItem]="dataItem"
                        [tab]="tab"
                        [noResultsType]="'rates'"
                      ></app-product-detail-tab>
                    }
                    @if (
                      tab.key === 'funds' &&
                      dataItem[tab.key] &&
                      dataItem[tab.key].length > 0
                    ) {
                      <app-product-detail-tab
                        [label]="'Investment Options'"
                        [dataItem]="dataItem"
                        [tab]="tab"
                      ></app-product-detail-tab>
                    }
                  }
                  @if (showNotes(dataItem.notes)) {
                    <mat-tab label="Notes">
                      @for (
                        note of dataItem.notes | keyvalue;
                        track note;
                        let idx = $index
                      ) {
                        @if (note.key === 'notes' && note.value.length > 0) {
                          <h5 [ngClass]="{ 'mt-3': idx === 0 }"> Notes: </h5>
                        }
                        @if (
                          note.key === 'firmNotes' && note.value.length > 0
                        ) {
                          <h5 [ngClass]="{ 'mt-3': idx === 0 }">
                            Firm Notes:
                          </h5>
                        }
                        @if (note.value.length > 0) {
                          <p [innerHTML]="note.value"></p>
                        }
                      }
                    </mat-tab>
                  }
                </mat-tab-group>
              } @else {
                <table class="w-100 detail-table">
                  @for (
                    headers of gridData.subHead | keyvalue: originalOrder;
                    track headers
                  ) {
                    <thead>
                      @for (hdr of headers.value; track hdr) {
                        <th>{{ hdr.Label }}</th>
                      }
                    </thead>
                  }
                  <tbody>
                    @for (
                      column of gridData.subHead | keyvalue: originalOrder;
                      track column
                    ) {
                      <tr>
                        @for (
                          clm of column.value | keyvalue: originalOrder;
                          track clm
                        ) {
                          <td
                            [innerHTML]="
                              dataItem.subInfo[0][clm.value.DataField]
                            "
                          ></td>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              }
            </ng-template>
          }
          <ng-template
            kendoPagerTemplate
            let-totalPages="totalPages"
            let-currentPage="currentPage"
          >
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons
              [buttonCount]="buttonCount"
            ></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-page-sizes
              [pageSizes]="pageSizeOptions"
            ></kendo-pager-page-sizes>
          </ng-template>
          <kendo-grid-excel
            fileName="RightBridge-ProductShelf.xlsx"
            [fetchData]="excelData"
          ></kendo-grid-excel>
        </kendo-grid>
      }
    }

    @if (gridView === 'fia') {
      <app-fia></app-fia>
    }

    @if (gridView === 'fa') {
      <app-fa></app-fa>
    }

    @if (gridView === 'rila') {
      <app-via></app-via>
    }
  }
</div>
<ngx-loading [show]="loading"></ngx-loading>
