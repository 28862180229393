<div
  mat-dialog-close
  class="close-button"
  (click)="close()"
  (keydown)="handleKeyDown($event)"
  tabindex="0"
>
  <mat-icon>close</mat-icon>
</div>
<div mat-dialog-header>
  <h1 class="mb-0"> New Case </h1>
</div>

<div mat-dialog-content class="dialog-content">
  <div class="row">
    <div class="col-12 case-info">
      <mat-form-field class="mb-3">
        <mat-label>Rep Code</mat-label>
        <input
          type="text"
          [placeholder]="'Rep Code'"
          matInput
          [matAutocomplete]="auto"
          name="unitsControl"
          [value]="repCodeValue"
          [formControl]="repCodeControl"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="setRepCode($event)"
        >
          @for (opt of caseUsers; track $index) {
            <mat-option value="{{ opt.value }}">
              {{ opt.value }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="mb-3">
        <mat-label>Case Title</mat-label>
        <input
          type="text"
          [placeholder]="'Case Title'"
          matInput
          name="caseTitle"
          [formControl]="caseTitle"
        />
      </mat-form-field>

      <div class="save-case-container">
        <button
          class="w-100"
          mat-raised-button
          aria-label="Create Case"
          color="primary"
          (click)="createCase()"
          [disabled]="!repCode || !caseTitle.value"
        >
          <mat-icon>save</mat-icon> Create Case
        </button>
      </div>
    </div>
  </div>
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
