<mat-form-field class="col-12">
  <mat-label>Select a Plan</mat-label>
  <mat-select
    #fiveTwoNineField
    [disabled]="this.plans529.length < 1"
    (selectionChange)="handle529($event)"
    [(ngModel)]="selected"
  >
    @for (plan of plans529; track plan) {
      <mat-option [value]="plan.PlanId">{{ plan.PlanName }}</mat-option>
    }
  </mat-select>
</mat-form-field>
