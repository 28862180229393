<div class="container unified-report-container">
  @if (printParams && !generating) {
    <h1>Print a Report</h1>
  }
  @if (printParams && !generating) {
    <mat-form-field class="col-6">
      <input
        matInput
        name="text"
        [(ngModel)]="customFilename"
        placeholder="Please Enter a Custom Filename"
      />
    </mat-form-field>
  }

  @if (printParams && !generating) {
    <h4> Please Select Reports and Options to Include </h4>
  }
  @if (printParams && !generating) {
    <div class="report-sections">
      @if (printParams.ClientProfilingOut.ValidPPRan !== 'Y') {
        <div class="app-fields-container pb-3 no-pp-warning">
          <span class="warning">
            <mat-icon>warning</mat-icon>Please return to the Product Profiler
            and run a valid profile to view Consolidated Report.
          </span>
        </div>
      }
      <!-- Fee Compare Section -->
      @if (showFeeCompare) {
        <div class="app-fields-container feeCompare">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'FeeCompare')"
            [checked]="enablePrint.FeeCompare"
            [disabled]="!enableFeeCompare"
          >
            Fee Compare Report
          </mat-checkbox>
          @if (!enableFeeCompare) {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (
            enablePrint.FeeCompare &&
            printParams.ClientProfilingOut.ValidPPRan !== 'Y'
          ) {
            <div class="options col-12">
              @if (printParams.IWProfilingResults.IWRan === 'Y') {
                <mat-checkbox
                  class="col-12"
                  (change)="
                    updateReportParams($event, 'FeeCompare', 'includeIW')
                  "
                  [checked]="reportPayload.FeeCompare.includeIW"
                >
                  Include Investment Wizard?
                </mat-checkbox>
              }
              @if (
                printParams.AWProfilingResults.AWRan === 'Y' &&
                awProductsSelected
              ) {
                <mat-checkbox
                  class="col-12"
                  (change)="
                    updateReportParams($event, 'FeeCompare', 'includeAW')
                  "
                  [checked]="reportPayload.FeeCompare.includeAW"
                >
                  Include Annuity Wizard?
                </mat-checkbox>
              }
              @if (printParams.LIProfilingResults.LIRan === 'Y') {
                <mat-checkbox
                  class="col-12"
                  (change)="
                    updateReportParams($event, 'FeeCompare', 'includeLI')
                  "
                  [checked]="reportPayload.FeeCompare.includeLI"
                >
                  Include Life Insurance Wizard?
                </mat-checkbox>
              }
            </div>
          }
        </div>
      }
      <!-- PP Section -->
      @if (appAccess.includes('PP')) {
        <div class="app-fields-container pp">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'ProductProfiler')"
            [checked]="enablePrint.ProductProfiler"
            [disabled]="printParams.ClientProfilingOut.ValidPPRan !== 'Y'"
          >
            Product Profiler
          </mat-checkbox>
          @if (printParams.ClientProfilingOut.ValidPPRan !== 'Y') {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (enablePrint.ProductProfiler) {
            <div class="options col-12">
              @if (
                printParams.UnitPrintDefaults.AWShowAlternativesOption === 'Y'
              ) {
                <mat-checkbox
                  class="col-12"
                  (change)="
                    updateReportParams(
                      $event,
                      'ProductProfiler',
                      'includeDetail'
                    )
                  "
                  [checked]="reportPayload.ProductProfiler.includeDetail"
                >
                  Include Alternatives ReasonText
                </mat-checkbox>
              }
              @if (
                printParams.UnitPrintDefaults.PPIncludeFeeCompare &&
                showFeeCompare
              ) {
                <mat-checkbox
                  class="col-12"
                  (change)="updateApps($event, 'FeeCompare')"
                  [checked]="enablePrint.FeeCompare"
                >
                  Include Fee Compare Report
                </mat-checkbox>
              }
              @if (printParams.UnitPrintDefaults.PPAllowRolloverReport) {
                <mat-checkbox
                  class="col-12"
                  (change)="updateApps($event, 'ProductProfiler')"
                  [checked]="enablePrint.Rollover"
                >
                  Include Rollover Report
                </mat-checkbox>
              }
            </div>
          }
        </div>
      }
      <!-- IW Section -->
      @if (appAccess.includes('IW')) {
        <div class="app-fields-container iw">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'InvestmentWizard')"
            [checked]="enablePrint.InvestmentWizard"
            [disabled]="
              printParams.IWProfilingResults.IWRan !== 'Y' ||
              printParams.ClientProfilingOut.ValidPPRan !== 'Y'
            "
          >
            Investment Wizard
          </mat-checkbox>
          @if (printParams.IWProfilingResults.IWRan !== 'Y') {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (
            enablePrint.InvestmentWizard &&
            printParams.ClientProfilingOut.ValidPPRan !== 'Y'
          ) {
            <div class="options col-12">
              @if (
                printParams.UnitPrintDefaults.IWFundDetails === 1 ||
                printParams.UnitPrintDefaults.IWFundDetails === 2
              ) {
                <mat-checkbox
                  class="col-12 details-check"
                  [checked]="printParams.UnitPrintDefaults.IWFundDetails === 1"
                  (change)="
                    updateReportParams(
                      $event,
                      'InvestmentWizard',
                      'includeDetail'
                    )
                  "
                >
                  Include fund details in report
                </mat-checkbox>
              }
              @if (
                printParams.UnitPrintDefaults.IWProgramDetails === 1 ||
                printParams.UnitPrintDefaults.IWProgramDetails === 2
              ) {
                <mat-checkbox
                  class="col-12 details-check"
                  [checked]="
                    printParams.UnitPrintDefaults.IWProgramDetails === 1
                  "
                  (change)="
                    updateReportParams(
                      $event,
                      'InvestmentWizard',
                      'includeAlternatives'
                    )
                  "
                >
                  Include program alternatives reason text
                </mat-checkbox>
              }
              @if (
                printParams.UnitPrintDefaults.IWFundAlternatives === 1 ||
                printParams.UnitPrintDefaults.IWFundAlternatives === 2
              ) {
                <mat-checkbox
                  class="col-12 details-check"
                  [checked]="
                    printParams.UnitPrintDefaults.IWFundAlternatives === 1
                  "
                  (change)="
                    updateReportParams(
                      $event,
                      'InvestmentWizard',
                      'includeFundAlternativesChart'
                    )
                  "
                >
                  Include Selected fund alternatives charts
                </mat-checkbox>
              }
            </div>
          }
        </div>
      }
      <!-- AW Section -->
      @if (appAccess.includes('AW') && rights.includes('AwFullReport')) {
        <div class="app-fields-container aw">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'AWReport')"
            [checked]="enablePrint.AWReport"
            [disabled]="
              printParams.AWProfilingResults.AWRan !== 'Y' ||
              !awProductsSelected ||
              printParams.ClientProfilingOut.ValidPPRan !== 'Y'
            "
          >
            Annuity Wizard Full Report
          </mat-checkbox>
          @if (
            printParams.AWProfilingResults.AWRan !== 'Y' ||
            !awProductsSelected ||
            printParams.ClientProfilingOut.ValidPPRan !== 'Y'
          ) {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (
            enablePrint.AWReport &&
            printParams.AWProfilingResults.AWRan === 'Y' &&
            awProductsSelected &&
            printParams.ClientProfilingOut.ValidPPRan === 'Y'
          ) {
            <div class="options col-12">
              <mat-checkbox
                *hasPermission="'ExcludeAlternativesReportPages'"
                class="col-12"
                (change)="
                  updateReportParams($event, 'AWReport', 'excludeAlternatives')
                "
                [checked]="reportPayload.AWReport.excludeAlternatives"
              >
                Exclude Alternatives ReasonText™
              </mat-checkbox>
              <mat-checkbox
                *hasPermission="'ShowVAFunds'"
                class="col-12"
                (change)="updateReportParams($event, 'AWReport', 'ivao')"
                [checked]="reportPayload.AWReport.ivao"
              >
                Include VA fund options in report
              </mat-checkbox>
            </div>
          }
        </div>
      }
      @if (appAccess.includes('AW') && rights.includes('AwCFVReport')) {
        <div class="app-fields-container aw">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'AWClientReport')"
            [checked]="enablePrint.AWClientReport"
            [disabled]="
              printParams.AWProfilingResults.AWRan !== 'Y' ||
              !awProductsSelected ||
              printParams.ClientProfilingOut.ValidPPRan !== 'Y'
            "
          >
            Annuity Wizard Client Report
          </mat-checkbox>
          @if (
            printParams.AWProfilingResults.AWRan !== 'Y' ||
            !awProductsSelected ||
            printParams.ClientProfilingOut.ValidPPRan !== 'Y'
          ) {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (
            enablePrint.AWClientReport &&
            printParams.AWProfilingResults.AWRan === 'Y' &&
            awProductsSelected &&
            printParams.ClientProfilingOut.ValidPPRan === 'Y'
          ) {
            <div class="options col-12">
              <mat-checkbox
                *hasPermission="'ExcludeAlternativesReportPages'"
                class="col-12"
                (change)="
                  updateReportParams(
                    $event,
                    'AWClientReport',
                    'excludeAlternatives'
                  )
                "
                [checked]="reportPayload.AWClientReport.excludeAlternatives"
              >
                Exclude Alternatives ReasonText™
              </mat-checkbox>
              <mat-checkbox
                *hasPermission="'ShowVAFunds'"
                class="col-12"
                (change)="updateReportParams($event, 'AWClientReport', 'ivao')"
                [checked]="reportPayload.AWClientReport.ivao"
              >
                Include VA fund options in report
              </mat-checkbox>
            </div>
          }
        </div>
      }
      <!-- LI Section -->
      @if (appAccess.includes('LI')) {
        <div class="app-fields-container li">
          <mat-checkbox
            class="col-3 mb-4"
            (change)="updateApps($event, 'LifeWizard')"
            [checked]="enablePrint.LifeWizard"
            [disabled]="
              printParams.LIProfilingResults.LIRan !== 'Y' ||
              printParams.ClientProfilingOut.ValidPPRan !== 'Y'
            "
          >
            Life Insurance Wizard
          </mat-checkbox>
          @if (printParams.LIProfilingResults.LIRan !== 'Y') {
            <span class="pull-right warning">
              <mat-icon>warning</mat-icon>Report is not available.
            </span>
          }
          @if (
            enablePrint.LifeWizard &&
            printParams.ClientProfilingOut.ValidPPRan !== 'Y'
          ) {
            <div class="options col-12"> </div>
          }
        </div>
      }
    </div>
  }

  @if (printParams && !generating) {
    <div class="buttons row">
      <button
        mat-raised-button
        color="accent"
        (click)="generateReport()"
        [disabled]="printParams.ClientProfilingOut.ValidPPRan !== 'Y'"
      >
        Generate Report
      </button>
      <button mat-raised-button (click)="cancel()">Cancel</button>
    </div>
  }
</div>

@if (generating) {
  <div class="message mt-5">
    <h1 class="mt-5">
      <ngx-loading
        [show]="generating"
        [config]="{
          fullScreenBackdrop: false,
          backdropBackgroundColour: 'rgba(0,0,0,0)'
        }"
      ></ngx-loading>
      Please wait while your report is generated
    </h1>
  </div>
}

<ngx-loading [show]="loading && !generating"></ngx-loading>
