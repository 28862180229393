<div class="page-container product-shelf-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12 mb-2">Product Shelf</h1>
  @if (!hideGrid) {
    <div class="row mb-3 mx-1">
      <app-grid-filters
        class="col-12"
        (filtersUpdated)="updateFilters($event)"
        [filters]="externalFilters"
        [reset]="resetFilters"
      ></app-grid-filters>
      <div class="col-12 mb-2 mx-1 filter-modal-result">
        <p>
          <strong>Showing Products from Program: </strong>{{ programToFilter }}
        </p>
        <button mat-button (click)="openProgramFilterModal()">
          <mat-icon>search</mat-icon>Change Program Name Filter
        </button>
      </div>
      <button
        mat-button
        color="primary"
        class="mr-0 ml-2"
        (click)="compare()"
        [disabled]="compareList.length < 2"
      >
        <mat-icon class="mr-2">compare_arrows</mat-icon>Compare Products
      </button>
      <app-grid-column-filter
        [columns]="gridColumns"
        (columnsUpdated)="externalColumnsUpdated($event)"
      ></app-grid-column-filter>
      @if (canExportGrid && !hideGrid) {
        <div>
          <button mat-button color="primary" (click)="exportGrid($event)">
            Export Product Shelf
          </button>
        </div>
      }
      <button mat-button color="primary" class="ml-auto mr-0" (click)="reset()">
        <mat-icon class="mr-2">replay</mat-icon>Reset
      </button>
    </div>
  }

  @if (!hideGrid) {
    <kendo-grid
      class="col-12"
      #productGrid="kendoGrid"
      [data]="gridData"
      [skip]="state.skip"
      [pageSize]="state.take"
      [pageable]="true"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="state.sort"
      [filterable]="'menu'"
      [columnMenu]="{ columnChooser: false }"
      [filter]="state.filter"
      [state]="state"
      (dataStateChange)="dataStateChange($event)"
      [height]="gridHeight()"
    >
      <kendo-grid-column
        [width]="100"
        [sortable]="false"
        [filterable]="false"
        [groupable]="false"
        [columnMenu]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="action-buttons-container">
            <button
              mat-icon-button
              class="fs-button"
              (click)="dlFundFactSheet(dataItem)"
              color="primary"
              matTooltip="Download Factsheet"
            >
              <mat-icon>insert_drive_file</mat-icon>
            </button>
            <mat-checkbox
              matTooltip="Compare Fund"
              (click)="compareListUpdate($event, dataItem)"
              [(ngModel)]="compareChecked[dataItem.FundID]"
              [ngModelOptions]="{ standalone: true }"
            >
            </mat-checkbox>
          </div>
        </ng-template>
      </kendo-grid-column>
      @for (column of gridData.headers; track column) {
        <kendo-grid-column
          [field]="column.DataField"
          [hidden]="isHidden(column.DataField)"
          [title]="column.Label"
          [sortable]="column.Sort"
          [filterable]="column.Filter"
          [groupable]="column.Group"
          [media]="column.RespVis"
          [filter]="column.DataType"
          [width]="column.Width ? column.Width : 150"
          [sticky]="column.Sticky"
        >
          @if (column.Sort || column.Filter) {
            <ng-template kendoGridColumnMenuTemplate let-service="service">
              @if (column.Sort) {
                <kendo-grid-columnmenu-sort
                  [column]="column"
                  [service]="service"
                >
                  <kendo-grid-messages
                    [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                    [sortDescending]="getFilterMenuText(column.Type, 'desc')"
                  ></kendo-grid-messages>
                </kendo-grid-columnmenu-sort>
              }
              @if (column.Filter) {
                <kendo-grid-columnmenu-filter
                  [column]="column"
                  [service]="service"
                >
                  <ng-template
                    kendoGridFilterMenuTemplate
                    let-filter="filter"
                    let-filterService="filterService"
                  >
                    @switch (column.FilterType) {
                      @case ('Dropdown') {
                        <kendo-multiselect
                          style="width: 99%"
                          [data]="filterData[column.DataField]"
                          [filterable]="true"
                          (filterChange)="
                            onFilterChange($event, column.DataField)
                          "
                          textField="display"
                          valueField="value"
                          [valuePrimitive]="true"
                          [value]="filter | filterValues"
                          (valueChange)="
                            multiselectFilterValueChange(
                              $event,
                              column.DataField,
                              filterService
                            )
                          "
                          [fillMode]="'outline'"
                          placeholder="Filter By..."
                        ></kendo-multiselect>
                      }
                      @case ('Search') {
                        <kendo-textbox
                          placeholder="John Smith"
                          style="width: 99%"
                          [value]="filter | filterValues"
                          (valueChange)="
                            searchFilterValueChange(
                              $event,
                              column.DataField,
                              filterService
                            )
                          "
                          [fillMode]="'outline'"
                        ></kendo-textbox>
                      }
                      @case ('Range') {
                        <app-date-range-filter
                          [field]="column.DataField"
                          [filter]="filter"
                          [filterService]="filterService"
                          (updateFilter)="
                            dateFilterValueChange($event, column.DataField)
                          "
                        ></app-date-range-filter>
                      }
                    }
                  </ng-template>
                </kendo-grid-columnmenu-filter>
              }
            </ng-template>
          }
          @switch (column.Type) {
            @case ('perc') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] }}%
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | percent }}
              </ng-template>
            }
            @case ('date') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | date: 'M/d/y' }}
              </ng-template>
            }
            @case ('curr') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | currency }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | currency }}
              </ng-template>
            }
          }
          @if (column.DataField === 'notes') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.DataField]"></span>
            </ng-template>
          }
          @if (column.Validation) {
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ findValue(dataItem[column.DataField], column.Validation) }}
            </ng-template>
          }
        </kendo-grid-column>
      }
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="buttonCount"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizeOptions"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  }
</div>
<ngx-loading [show]="loading"></ngx-loading>
