<button
  mat-button
  (click)="navSearch('name')"
  class="search"
  *hasPermission="'DisplayClientNameSearchLink'"
>
  <mat-icon>search</mat-icon> Search For Clients
</button>
<form [formGroup]="searchForm" *hasPermission="'DisplayClientNameSearch'">
  <mat-form-field class="w-100">
    <input
      matInput
      type="text"
      class="search-field"
      placeholder="Search for a client"
      formControlName="searchInput"
      [matAutocomplete]="auto"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-autocomplete
      #auto="matAutocomplete"
      autoActiveFirstOption
      (optionSelected)="handleSelect($event)"
      [displayWith]="displayFn"
    >
      @if (isLoading) {
        <mat-option></mat-option>
        <mat-option></mat-option>
        <ngx-loading
          [show]="isLoading"
          [config]="{ fullScreenBackdrop: false }"
        ></ngx-loading>
      }
      @if (!isLoading) {
        @for (option of filteredSearch; track option) {
          <mat-option [value]="option"
            >{{ option.first }} {{ option.last }}</mat-option
          >
        }
      }
    </mat-autocomplete>
    @if (searchValue) {
      <button
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        class="clearButton"
        (click)="clearSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
  </mat-form-field>
</form>
