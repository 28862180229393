import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { SessionStorageService } from '../../services/session-storage.service';
import { PostTradeService } from '../post-trade.service';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'app-post-trade-reports',
  templateUrl: './post-trade-reports.component.html',
  styleUrls: ['./post-trade-reports.component.scss'],
})
export class PostTradeReportsComponent implements OnInit, OnDestroy {
  rights = [];
  unsubscribe: Subject<any> = new Subject();
  headers = [
    {
      label: 'Report Name',
      fieldName: 'FileName',
    },
    {
      label: 'Create Date',
      fieldName: 'RequestDate',
      fieldMask: 'date',
    },
    {
      label: 'Category',
      fieldName: 'Category',
    },
  ];
  reports = [];
  crumbConfig = [
    {
      label: 'Post Transaction',
      link: 'post-trade',
    },
    {
      label: 'Downloadable Reports',
      link: 'post-trade/reports',
    },
  ];

  constructor(
    private ss: SessionStorageService,
    public ptSvc: PostTradeService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.rights = this.ss.get('rights');

    this.ptSvc.getUserReportsList().subscribe(res => {
      if (res['Reports']) {
        this.reports = res['Reports'];
      }
    });
  }

  saveFile(fileID, fileName) {
    this.ptSvc.downloadUserReport(fileID).subscribe(res => {
      const filename = `${fileName}.zip`;
      let fileData;
      const reader = new FileReader();

      reader.readAsDataURL(res);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename);
      };
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }
}
