import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-multi-select',
  styleUrls: ['form-multiselect.component.scss'],
  template: `
    <mat-form-field class="col-12 p-0">
      <mat-label>{{ to.label }}</mat-label>
      <mat-select
        [formControl]="formControl"
        [placeholder]="props.placeholder"
        [formlyAttributes]="field"
        multiple
        class="w-100"
        [required]="props.required"
      >
        @for (opt of to?.options; track opt) {
          <mat-option [value]="opt.value">
            {{ opt.label }}
          </mat-option>
        }
      </mat-select>
      @if (props.help) {
        <mat-icon
          class="help-icon"
          matTooltip="{{ to.help }}"
          [matTooltipPosition]="'above'"
          >help</mat-icon
        >
      }
      @if (
        formControl.hasError('required') && !formControl.hasError('regRequired')
      ) {
        <mat-error>This field is required</mat-error>
      }
      @if (formControl.hasError('regRequired')) {
        <mat-error
          >This field is needed for suitability review. You can opt out below if
          the applicant refuses to provide the information.</mat-error
        >
      }
    </mat-form-field>
  `,
})
export class FormlyMultiSelectComponent extends FieldType {}
