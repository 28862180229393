<div class="page-container case-management-container container">
  <app-breadcrumb [crumbConfig]="crumbConfig"></app-breadcrumb>

  <h1 class="header col-12">
    Case Management
    <button
      mat-button
      color="primary"
      class="pull-right"
      (click)="createAdHocCase()"
      *hasPermission="'CreateCMCaseAdHoc'"
    >
      <mat-icon>add_circle_outline</mat-icon>Create Case
    </button>
  </h1>

  @if (!hideGrid) {
    <div class="row mb-0 mx-1">
      <app-grid-filters
        class="col-12"
        (filtersUpdated)="updateFilters($event)"
        [filters]="externalFilters"
        [reset]="resetFilters"
        [manualSearch]="true"
        (triggerSearch)="searchResults()"
        [searchButtonText]="'Search Results'"
        [showFilterManager]="true"
        [gridName]="'CaseManagement'"
      ></app-grid-filters>
    </div>

    <div class="row mb-0 mx-1 grid-controls">
      <app-grid-column-filter
        [columns]="gridColumns"
        (columnsUpdated)="externalColumnsUpdated($event)"
        class="ml-auto mr-2"
      ></app-grid-column-filter>

      <button mat-button color="primary" class="mr-0" (click)="reset()">
        <mat-icon class="mr-2">replay</mat-icon>Reset
      </button>
    </div>

    <kendo-grid
      class="col-12 mb-5"
      #caseManagementGrid="kendoGrid"
      [data]="gridData"
      [skip]="state.skip"
      [pageSize]="state.take"
      [pageable]="true"
      [columnMenu]="{ columnChooser: false }"
      [filter]="filter"
      [filterable]="false"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      (pageChange)="pageChange($event)"
      [height]="gridHeight()"
    >
      <kendo-grid-column width="75" title="">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            mat-icon-button
            class="fs-button"
            (click)="reviewCase(dataItem)"
            color="primary"
            matTooltip="Review Case"
          >
            <mat-icon>rate_review</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="55">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <mat-icon
              class="icon-{{ dataItem.Color }}"
              [ngClass]="{ 'icon-grey': !dataItem.Color }"
            >
              {{ dataItem.Icon }}
            </mat-icon>
          </div>
        </ng-template>
      </kendo-grid-column>
      @for (column of gridColumns; track column) {
        <ng-template>
          <kendo-grid-column
            [field]="column.DataField"
            [hidden]="isHidden(column.DataField)"
            [title]="column.Label"
            [sortable]="column.Sort"
            [groupable]="column.Group"
            [media]="column.RespVis"
            [width]="column.Width ? column.Width : 150"
            [filterable]="column.Filter"
            [sticky]="column.Sticky"
          >
            @if (column.Sort || column.Filter) {
              <ng-template kendoGridColumnMenuTemplate let-service="service">
                @if (column.Sort) {
                  <kendo-grid-columnmenu-sort
                    [column]="column"
                    [service]="service"
                  >
                    <kendo-grid-messages
                      [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                      [sortDescending]="getFilterMenuText(column.Type, 'desc')"
                    ></kendo-grid-messages>
                  </kendo-grid-columnmenu-sort>
                }
                @if (column.Filter) {
                  <kendo-grid-columnmenu-filter
                    [column]="column"
                    [service]="service"
                  >
                    <ng-template
                      kendoGridFilterMenuTemplate
                      let-filter="filter"
                      let-filterService="filterService"
                    >
                      <kendo-multiselect
                        style="width: 99%"
                        [data]="filterData[column.DataField]"
                        [filterable]="true"
                        (filterChange)="
                          onFilterChange($event, column.DataField)
                        "
                        textField="display"
                        valueField="value"
                        [valuePrimitive]="true"
                        [value]="filter | filterValues"
                        (valueChange)="
                          filterValueChange(
                            $event,
                            column.DataField,
                            filterService
                          )
                        "
                        [fillMode]="'outline'"
                        placeholder="Filter By..."
                      ></kendo-multiselect>
                    </ng-template>
                  </kendo-grid-columnmenu-filter>
                }
              </ng-template>
            }
            @if (column.Type === 'integer') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | number: '1.0-0' }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | number: '1.0-0' }}
              </ng-template>
            }
            @if (column.Type === 'perc') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | percent }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | percent }}
              </ng-template>
            }
            @if (column.Type === 'perc2') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | percent: '1.2' }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | percent: '1.2' }}
              </ng-template>
            }
            @if (column.Type === 'curr') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | currency }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | currency }}
              </ng-template>
            }
            @if (column.Type === 'date') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | date: 'short' }}
              </ng-template>
            }
            @if (column.DataField === 'notes') {
              <ng-template kendoGridCellTemplate let-dataItem>
                <span [innerHtml]="dataItem[column.DataField]"></span>
              </ng-template>
            }
          </kendo-grid-column>
        </ng-template>
      }
      @if (canDeleteCase) {
        <kendo-grid-column width="75" title="">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              mat-icon-button
              class="fs-button"
              (click)="deleteCase(dataItem)"
              color="warn"
              matTooltip="Delete Case"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </ng-template>
        </kendo-grid-column>
      }
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="buttonCount"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizeOptions"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  }

  <ngx-loading [show]="loading"></ngx-loading>
</div>
