<mat-form-field class="" class="mr-3">
  <input
    matInput
    placeholder="Search For a Unit"
    [(ngModel)]="usage.unit"
    [matAutocomplete]="usageAuto"
    name="usageUnitsControl"
    [formControl]="usageUnitsControl"
    (blur)="checkSelection()"
  />
  <mat-autocomplete
    #usageAuto="matAutocomplete"
    (optionSelected)="autoCompleteUnitChanged($event)"
  >
    @for (unit of filteredUnits; track unit.id) {
      <mat-option [value]="unit.id">
        {{ unit.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
<mat-form-field class="mr-3">
  <input
    matInput
    [matDatepicker]="startPicker"
    placeholder="Start Date"
    [(ngModel)]="usage.start"
  />
  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
  <mat-datepicker #startPicker></mat-datepicker>
</mat-form-field>
<mat-form-field class="mr-3">
  <input
    matInput
    [matDatepicker]="endPicker"
    placeholder="End Date"
    [(ngModel)]="usage.end"
  />
  <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
  <mat-datepicker #endPicker></mat-datepicker>
</mat-form-field>
<button
  mat-raised-button
  color="accent"
  class="mx-auto"
  (click)="getUsage()"
  [disabled]="!usage.unit"
>
  Get Report
</button>
@if (usageGridData.data) {
  <button
    mat-button
    color="primary"
    class="grid-button pull-right"
    (click)="exportToExcel(unitUsageGrid)"
  >
    <mat-icon>cloud_download</mat-icon>Download Excel File
  </button>
}

<kendo-grid
  #unitUsageGrid="kendoGrid"
  [hidden]="!usageGridData.data"
  class="col-12 mt-3 usage-grid"
  [kendoGridBinding]="usageGridData.data"
  [height]="600"
  [selectable]="true"
>
  @for (col of usageGridData.header; track col) {
    <kendo-grid-column
      [field]="col.DataField"
      [title]="col.Label"
    ></kendo-grid-column>
  }
  <kendo-grid-excel
    fileName="RightBridge-UnitUsageReport.xlsx"
  ></kendo-grid-excel>
</kendo-grid>
